import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: top;
  background-color: white;
  width: 700px;
  height: auto;
  border-radius: 25px;
  z-index: 100;
`;

export const Title = styled.div`
  font-family: 'Pretendard';
  font-style: normal;
  font-size: 25px;
  font-weight: 700;
  margin-left: 41px;
  padding: 41px 0;
`;

export const DuplicateButton = styled.button`
  width: 50px;
  height: 25px;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  background: #2b77f5;
  color: white;
  margin-left: 15px;
`;

export const InputList = styled.div`
  width: 100%;
  height: 880px;
  overflow-y: scroll;
`;
export const InputList2 = styled.div`
  margin: 0 auto;
  width: 90%;
  //height: auto;
  height: 300px;
  overflow-y: scroll;
`;
export const CloseButton = styled.img`
  position: absolute;
  top: 46px;
  right: 41px;
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer-events;
  }
`;
export const SaveWrap = styled.div<{ modal: boolean; isNull: boolean }>`
  padding-bottom: 30px;
  margin: ${({ modal }) => (modal ? '0px 16px 0px 41px;' : '0px 16px 0px 16px;')};
  button {
    width: 100%;
    height: 50px;
    background: #2b77f5;
    border-radius: 8px;
    padding: 12px 7px;
    margin-top: 20px;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: white;
    &:disabled {
      background: gray;
      &:hover {
        background: gray;
        cursor: auto;
      }
    }
    &:hover {
      background-color: #0d67f9;
    }
  }
`;
