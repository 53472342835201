import * as S from 'pages/Product/styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetChaptertitleQuery,
  useGetClassroomDetailQuery,
  useGetLectureQuery,
} from 'store/services/classroom';
import DetailTabs from 'components/DetailTabs';
import LectureInfo from './LectureInfo';
import LectureFile from './File';
import useLectureMutation from './hooks/useLectureMutation';

const TAB_LIST = ['강의 정보', '수업 자료'];

function LectureDetail() {
  const navigate = useNavigate();
  const { classroomId, chapterId, id } = useParams();
  const { data: classroomData } = useGetClassroomDetailQuery(Number(classroomId));
  const { data: chapterData } = useGetChaptertitleQuery({ classroomId, chapterId });
  const { data: lectureData } = useGetLectureQuery({
    classroomId: Number(classroomId),
    chapterId: Number(chapterId),
    id: Number(id),
  });
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);
  const { deleteLecture } = useLectureMutation();

  return (
    <S.Container>
      <S.DepthNavi>
        <S.NaviButton onClick={() => navigate(`/classroom/${classroomId}`)}>
          {classroomData?.title}
        </S.NaviButton>
        <span> / </span>
        <S.NaviButton onClick={() => navigate(`/classroom/${classroomId}/chapter/${chapterId}`)}>
          {chapterData?.title}
        </S.NaviButton>
        <span> / </span>
        <S.CurrentPage>{lectureData?.title}</S.CurrentPage>
      </S.DepthNavi>
      <h1>
        강의 수정
        <S.DeleteButton onClick={deleteLecture}>삭제</S.DeleteButton>
      </h1>
      <DetailTabs tabs={tabs} selected={currentTab} />
      {currentTab === TAB_LIST[0] && <LectureInfo lectureData={lectureData} />}
      {currentTab === TAB_LIST[1] && <LectureFile />}
    </S.Container>
  );
}

export default LectureDetail;
