import { ColumnsType } from 'antd/es/table';
import { CourseComponentHeader } from 'model/course.model';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCourseComponentListQuery } from 'store/services/course';
import * as S from 'pages/Product/styles';
import { Pagination, Table } from 'antd';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import CustomForm from 'components/CustomForm';
import { useState } from 'react';
import useCourseComponentMutation from '../hooks/useCourseComponentMutation';
import useChangeOrd from 'hooks/useChangeOrd';

const TYPE_COLUMNS = [{ title: '컴포넌트 종류', dataIndex: 'type', key: 'type' }];
const SELECT_TYPE_LIST = [
  // { type: 'EDITOR' },
  // { type: 'INTRODUCE' },
  // { type: 'REPLY' },
  // { type: 'REVIEW' },

  // { type: 'MATERIALS' },
  // { type: 'MENTO' },
  // { type: 'SUMMARY' },
  // { type: 'IMAGE_SLIDE' },
  { type: 'SPECIAL_SECTION_1' },
  { type: 'SPECIAL_SECTION_2' },
  { type: 'TUTOR_SET' },
  { type: 'CLASS_SET' },
  { type: 'CURRICULUM2' },
  { type: 'CLASS_INFO_SET' },
  { type: 'PRICE_SET' },
  { type: 'MATERIALS_2' },
  { type: 'FAQ' },
];

const COMPONENT_COLUMNS: ColumnsType<CourseComponentHeader> = [
  {
    title: '순서',
    dataIndex: 'ord',
    key: 'ord',
  },
  {
    title: '제목',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '타입',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    render: (value) => (value === true ? '비공개' : '공개'),
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '헤더 포함 여부',
    dataIndex: 'isPartOfHeader',
    key: 'isPartOfHeader',
    render: (value) => (value === true ? '포함' : '미포함'),
  },
];

export default function CourseComponentList() {
  const { id } = useParams();
  const [page, setPage] = useNumberSearchParams('page');
  const { data: componentData } = useGetCourseComponentListQuery(Number(id));
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const { addCourseComponent, patchCourseComponentOrd } = useCourseComponentMutation();
  const { list, columns } = useChangeOrd({ data: componentData, baseColumn: COMPONENT_COLUMNS });

  return (
    <>
      {componentData && (
        <>
          <div style={{ margin: '0px 16px' }}>
            <S.SubEditButton onClick={() => setVisible(true)}>추가</S.SubEditButton>
            <S.SubEditButton
              onClick={() => patchCourseComponentOrd(list)}
              style={{ margin: '0px 16px' }}
            >
              순서 저장하기
            </S.SubEditButton>
          </div>
          <br />
          <div style={{ color: 'red' }}>
            상세페이지 헤더에 표시 될 섹션은 각 상세 페이지에서 헤더 포함 여부를 체크해주세요.
          </div>
          <S.TableContainer>
            <Table
              dataSource={list.map((content) => ({ ...content, tags: ['순서 변경'] }))}
              columns={columns}
              pagination={false}
              rowKey={(record) => record.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`component/${record.id}`, '_blank');
                    } else navigate(`component/${record.id}`);
                  },
                };
              }}
            />
            <Pagination
              pageSize={15}
              current={page}
              total={componentData.totalElements}
              onChange={setPage}
              showSizeChanger={false}
              showQuickJumper
            />
          </S.TableContainer>
          <CustomForm.TableSelectModal
            columns={TYPE_COLUMNS}
            list={SELECT_TYPE_LIST}
            visible={visible}
            onCancel={() => setVisible(false)}
            onSelect={(record) => {
              addCourseComponent(record.type);
              setVisible(false);
            }}
            page={page}
            setPage={setPage}
            totalElements={10}
          />
        </>
      )}
    </>
  );
}
