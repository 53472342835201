import { createSlice } from '@reduxjs/toolkit';
import { SideNavState } from 'model/store.model';

const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
} as SideNavState;

const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    setSideBarShow(state: SideNavState, action: { payload: boolean }) {
      state.sidebarShow = action.payload;
    },
    setSideBarUnfoldable(state: SideNavState, action: { payload: boolean }) {
      state.sidebarUnfoldable = action.payload;
    },
  },
});

export const { setSideBarShow, setSideBarUnfoldable } = sideNavSlice.actions;
export default sideNavSlice.reducer;
