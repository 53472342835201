import * as S from '../styles';
import { useParams } from 'react-router-dom';
import { useGetMentorQuery } from 'store/services/mento';
import CustomForm from 'components/CustomForm';
import useMentorMutation from './hooks/useMentorMutation';

//단일 페이지로 수정 요청

function MentorDetail() {
  const { id } = useParams();
  const { patchMentorMutation, deleteMentorMutation } = useMentorMutation();
  const { data: mentorData } = useGetMentorQuery(Number(id));
  const [form] = CustomForm.useForm();

  return (
    <S.Container>
      <S.DepthNavi>
        <S.CurrentPage>{mentorData?.displayName}</S.CurrentPage>
      </S.DepthNavi>
      <h1>
        튜터 수정
        <S.DeleteButton onClick={deleteMentorMutation}>삭제</S.DeleteButton>
      </h1>
      {mentorData && (
        <CustomForm
          form={form}
          initialValues={mentorData}
          onFinish={() => patchMentorMutation(form.getFieldsValue(true))}
        >
          <CustomForm.Divider orientation="left">기본 정보</CustomForm.Divider>
          <CustomForm.InputNumber name="userId" label="튜터 ID" disabled />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input name="createdAt" label="생성일" disabled />
            <CustomForm.Input name="updatedAt" label="최종 수정일" disabled />
          </CustomForm.GridRow>
          <CustomForm.Input
            name="displayName"
            label="튜터 이름"
            rules={[{ required: true, message: '튜터 이름을 추가해주세요!' }]}
          />
          <CustomForm.Input
            name="displayJob"
            label="표시 직업"
            rules={[{ required: true, message: '튜터 직업을 추가해주세요!' }]}
          />
          <CustomForm.Divider orientation="left">이미지 업로드 (비율 w1:h1)</CustomForm.Divider>
          <CustomForm.Upload name="image" />
          <CustomForm.Divider orientation="left">아이콘 업로드 (비율 w1:h1)</CustomForm.Divider>
          <CustomForm.Upload name="icon" />
          <CustomForm.Divider orientation="left">튜터 소개</CustomForm.Divider>
          <CustomForm.MarkdownEditor name="content" form={form} />
          <CustomForm.Divider orientation="left">튜터 경력</CustomForm.Divider>
          <CustomForm.List name="personalHistories">
            {(personalHistories, { add, remove }) => (
              <div>
                {personalHistories.map((history, index) => (
                  <CustomForm.GridRow gridColumns="1fr auto">
                    <CustomForm.Input label="이력" name={[index, 'content']} />
                    <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                      삭제
                    </CustomForm.Button>
                  </CustomForm.GridRow>
                ))}
                <CustomForm.Button onClick={() => add()} htmlType="button">
                  추가
                </CustomForm.Button>
              </div>
            )}
          </CustomForm.List>
          <CustomForm.Divider orientation="left">튜터 SNS</CustomForm.Divider>
          <CustomForm.List name="personalSNS">
            {(personalSNS, { add, remove }) => (
              <div>
                {personalSNS.map((SNS, index) => (
                  <S.SNSWrapper>
                    <CustomForm.Input label="종류" name={[index, 'name']} />
                    <CustomForm.Input label="주소" name={[index, 'url']} />
                    <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                      삭제
                    </CustomForm.Button>
                  </S.SNSWrapper>
                ))}
                <CustomForm.Button onClick={() => add()} htmlType="button">
                  추가
                </CustomForm.Button>
              </div>
            )}
          </CustomForm.List>
          <S.SaveWrap>
            <button onClick={patchMentorMutation}>저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
    </S.Container>
  );
}

export default MentorDetail;
