import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import CustomForm from 'components/CustomForm';

import { usePatchCollectionMutation, useGetCollectionQuery } from 'store/services/collection';

function CollectionInfo() {
  const { id } = useParams();

  const [patchCollection] = usePatchCollectionMutation();

  const { data } = useGetCollectionQuery(`${id}`);
  const [form] = CustomForm.useForm();

  const handlePatchClick = (formData) => {
    patchCollection(formData)
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
        console.log('fulfilled', payload);
      })
      .catch((error) => alert(error.data.errorMessage));
  };
  return (
    <>
      <CustomForm
        form={form}
        initialValues={data}
        onFinish={() => {
          handlePatchClick(form.getFieldsValue(true));
          //이걸 patch로 옮겨
        }}
      >
        <CustomForm.Checkbox name="isAllPackagesIncluded" label="컬렉션 전체 적용" />
        <CustomForm.InputNumber label="컬렉션 ID" name="id" disabled />
        <CustomForm.Input label="컬렉션 이름" name="name" />
        <CustomForm.Input label="컬렉션 설명" name="description" />
        <S.SaveWrap>
          <button type="submit">저장</button>
        </S.SaveWrap>
      </CustomForm>
    </>
  );
}

export default CollectionInfo;
