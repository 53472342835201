import { Button, Divider, Form, Input, InputNumber } from 'antd';
import styled from 'styled-components';
import { onNotLarge } from 'styles/mediaQueries';

export const GridWrap = styled.div<{ gridColumns: string }>`
  display: grid;
  grid-template-columns: ${(props) => props.gridColumns};
`;

export const FormItem = styled(Form.Item)`
  margin-bottom: 0;
  height: 44px;
  .ant-row,
  .ant-form-item-control-input-content,
  .ant-form-item-control-input,
  .ant-input-textarea,
  .ant-select,
  .ant-select-selector,
  .ant-input,
  .ant-input-number,
  .ant-btn {
    height: 100% !important;
    font-size: 16px;
  }
  .ant-select-selector {
    display: flex;
    align-items: center;
  }
  .ant-form-item-label > label {
    width: 160px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    color: #2c3e50;
    background-color: rgb(243, 243, 243);
    border: rgb(217, 217, 217) solid;
    border-width: 1px;
    border-radius: 0;
    &:after {
      display: none;
      flex-basis: auto;
    }
  }
  // 기본 설정된 반응형에 의한 디자인 깨짐 방지용
  ${onNotLarge} {
    .ant-row .ant-form-item-label {
      flex: 0 1 auto;
      padding: 0;
    }
    .ant-row .ant-form-item-control {
      flex: 1 1 auto;
      padding: 0;
    }
    .ant-form-horizontal .ant-form-item-control {
      /* flex-basis: 0 !important; */
    }
  }

  .ant-form-item-explain-error {
    position: absolute;
    right: 12px;
    z-index: 10;
    top: 5px;
  }
`;

export const FormItemCheckbox = styled(Form.Item)``;

export const StyledInput = styled(Input)`
  border: rgb(217, 217, 217) solid;
  border-width: 1px;
  border-radius: 0;
  display: inline-block;
  height: 100%;
  font-size: 16px;
`;

export const StyledInputNumber = styled(InputNumber)`
  border: rgb(217, 217, 217) solid;
  border-width: 1px;
  border-radius: 0;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const StyledButton = styled(Button)`
  border-radius: 0;
  min-width: 80px;
`;

export const FormDivider = styled(Divider)`
  margin-top: 40px !important;
  font-weight: 600 !important;
  font-size: 22px !important;
`;

export const UploadWrapper = styled.div`
  height: 110px;
`;

export const SwitchWrap = styled.h4`
  display: flex;
  gap: 5px;
  margin: 5px 0;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  //align-items: center;
`;
