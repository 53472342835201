import useNumberSearchParams from 'hooks/useNumberSearchParams';
import * as S from '../styles';
import { useDeleteCouponCategoryMutation } from 'store/services/coupon';
import { Pagination, Select, Table, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import useFileSearchParams from 'hooks/useFileSearchParams';
import { useRef, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import {
  useDeleteBannerCategoryMutation,
  useGetBannerCategoryDetailQuery,
  useGetBannerCategoryQuery,
  usePatchBannerCategoryMutation,
} from 'store/services/banner';
import BannerCategoryAddModal from '../InputModal/BannerCategoryAddModal';
import useBooleanSearchParams from 'hooks/useBooleanSearchParams';
import useBooleanState from '../Classroom/ClassroomDetail/hooks/useBooleanState';
import { useDragSort } from 'utils/Hook/useDragSort';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import CustomRow from 'components/CustomTable/CustomRow';
import useBannerDetailMutation from './hooks/useBannerDetailMutation';
interface EditData {
  name: string;
  code: string;
  bannerCategoryId: number | null;
}

function BannerList() {
  const navigate = useNavigate();
  const [page, setPage] = useNumberSearchParams('page');
  const [isBannerPublic, setIsBannerPublic] = useBooleanSearchParams('bannerPublic');
  const [parentId, setParentId] = useFileSearchParams('parentId');
  const { value: isSort, setFalse: hideSort, setTrue: showSort } = useBooleanState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { data: bannerCategoty } = useGetBannerCategoryQuery({
    size: 5,
    page,
    rootBannerCategoryId: parentId,
  });

  const { data: bannerDetailData } = useGetBannerCategoryDetailQuery({
    id: parentId,
    isPrivate: !isBannerPublic,
  });

  //console.log('bannerDetailData', bannerDetailData);

  const [editData, setEditData] = useState<EditData>({
    name: '',
    code: '',
    bannerCategoryId: null,
  });
  const [addBannerCategory] = usePatchBannerCategoryMutation();
  const [deleteBannerCategory] = useDeleteBannerCategoryMutation();

  const { patchBannerSort } = useBannerDetailMutation();
  const [editingId, setEditingId] = useState<number | null>(null); //현재 수정중인 id

  const editCategory = (id: number) => {
    setEditingId(id); // 편집 모드로 변경//현재 편집중인 열의 id를 저장
    setEditData({
      //그 id에 해당하는 데이터를 저장
      ...editData,
    });
  };

  const saveCategory = (id: number) => {
    console.log(editData);
    setEditingId(null); // 저장 후 편집 모드 종료

    if (editData.bannerCategoryId === null || editData.bannerCategoryId === 0) {
      editData.bannerCategoryId = null;
    }
    addBannerCategory({ ...editData, id })
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
      })
      .catch((error) => alert(error.data.message)); //여기가 patch

    setEditData({
      name: '',
      code: '',
      bannerCategoryId: null,
    });
  };

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '카테고리 이름',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                name: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.name}</span>
        );
      },
    },

    {
      title: '카테고리 코드',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                code: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.code}</span>
        );
      },
    },

    editingId
      ? {
          title: '상위 카테고리 ID',
          dataIndex: 'bannerCategoryId',
          key: parentId,
          render: (text, record) => {
            const isEditing = record.id === editingId;
            return isEditing ? (
              <input
                defaultValue={parentId}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    bannerCategoryId: Number(e.target.value),
                  });
                }}
              />
            ) : (
              <span>{parentId}</span>
            );
          },
        }
      : { title: '하위 카테고리 수', dataIndex: 'numberOfChildren', key: 'numberOfChildren' },
    { title: ' 하위 배너 수', dataIndex: 'numberOfBanners', key: 'numberOfBanners' },

    {
      title: '편집',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <span>
            <S.EditButton
              onClick={(e) => {
                e.stopPropagation();
                console.log(record.id);
                console.log(editData);
                saveCategory(record.id);
              }}
            >
              저장
            </S.EditButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                setEditingId(null);
              }}
            >
              취소
            </S.DeleteButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation(); // 이벤트 버블링을 중단합니다.
                console.log(record);
                if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
                  deleteBannerCategory(record.id)
                    .unwrap()
                    .then((payload) => {
                      alert('삭제가 완료되었습니다');
                    })
                    .catch((error) => alert(error.data.message));
                }
                setEditingId(null);
              }}
            >
              삭제
            </S.DeleteButton>
          </span>
        ) : (
          <S.EditButton
            disabled={editingId !== null}
            onClick={(e) => {
              e.stopPropagation(); // 이벤트 버블링을 중단합니다.
              //편집을 눌렀을떄 해당 record를 가지고 셋을 다시 해
              editCategory(record.id);
              setEditData({
                ...editData,
                name: record.name,
                code: record.code,
                bannerCategoryId: Number(parentId),
              });
            }}
          >
            편집
          </S.EditButton>
        );
      },
    },
  ];

  const COUPON_COLUMNS = [
    isSort
      ? {
          key: 'sort',
          width: '3%',
          render: () => <MenuOutlined style={{ cursor: 'move' }} />,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: '3%',
        },
    {
      title: '베너 제목',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
    },
    {
      title: '배경색',
      dataIndex: 'backgroundColor',
      key: 'backgroundColor',
      width: '8%',
    },
    {
      title: '이동 URL',
      dataIndex: 'url',
      key: 'url',
      width: '13%',
    },
    {
      title: 'PC img',
      dataIndex: 'imgSrc',
      key: 'imgSrc',
      width: '28%',
    },
    { title: 'Mobile img', dataIndex: 'mimgSrc', key: 'mimgSrc', width: '28%' },
  ];
  const { dataSource, onDragEnd } = useDragSort(bannerDetailData, COUPON_COLUMNS);

  const handelSortIds = (id: number) => {
    const sortBannerIds = dataSource.map((item) => item.id);
    patchBannerSort(id, sortBannerIds)
      .then((payload) => {
        hideSort();
        if (payload) {
          if (payload) messageApi.success('정렬이 완료되었습니다.');
        }
      })
      .catch((error) => {
        messageApi.error(error.data.message);
      });
    console.log(sortBannerIds);
  };

  //모달
  const bannerCategoryAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(bannerCategoryAddRef, true);

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <BannerCategoryAddModal ref={bannerCategoryAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          배너 카테고리 조회
          <S.AddButton
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 배너 카테고리 추가
          </S.AddButton>
        </h1>
      </S.Container>
      {bannerCategoty && (
        <S.StaticTableContainer>
          <Table
            dataSource={bannerCategoty.content.map((content) => {
              return {
                ...content,
                tags: ['수정'],
              };
            })}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: async (e) => {
                  if (editingId) return;
                  console.log(record.id);
                  setParentId(record.id);
                },
              };
            }}
          />
          {bannerCategoty && (
            <Pagination
              pageSize={5}
              current={page}
              total={bannerCategoty.totalElements}
              onChange={setPage}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </S.StaticTableContainer>
      )}
      <S.Container>
        <h1>
          배너 조회
          {isSort ? (
            <>
              <S.SortButton
                onClick={() => {
                  handelSortIds(Number(parentId));
                }}
              >
                정렬 완료
              </S.SortButton>
              <S.CancelButton onClick={hideSort}>취소</S.CancelButton>
            </>
          ) : (
            <>
              <S.AddButton onClick={() => navigate('/newaddbanner')}>+ 배너 추가</S.AddButton>
              <S.SortButton
                onClick={() => {
                  showSort();
                }}
              >
                순서 변경
              </S.SortButton>
            </>
          )}
        </h1>
      </S.Container>
      <S.StyledSwitch
        unCheckedChildren="비공개"
        checkedChildren="공개"
        checked={isBannerPublic}
        onChange={setIsBannerPublic}
      />
      <S.TableContainer>
        {dataSource && (
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={dataSource.map((i) => i.id)}
              strategy={verticalListSortingStrategy}
            >
              <Table
                // dataSource={bannerDetailData?.map((content) => {
                //   const newContent = JSON.parse(JSON.stringify(content));
                //   return { ...newContent, tags: ['수정'] };
                // })}
                components={{ body: { row: CustomRow } }}
                dataSource={dataSource as any}
                columns={COUPON_COLUMNS}
                pagination={false}
                rowKey={(record) => record.id}
                //rowKey={(record) => {
                // return record.id;
                //}}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      console.log(record.id);
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/banner/${record.id}`, '_blank');
                      } else navigate(`/banner/${record.id}`);
                    },
                  };
                }}
              />
            </SortableContext>
          </DndContext>
        )}
      </S.TableContainer>
    </>
  );
}

export default BannerList;
