import * as S from 'pages/Product/styles';
import CustomForm from 'components/CustomForm';
import useLectureMutation from './hooks/useLectureMutation';
import { Checkbox } from 'antd';
import useLazyState from 'hooks/useLazyState';

function LectureInfo({ lectureData }) {
  const [form] = CustomForm.useForm();
  const { patchLecture } = useLectureMutation();
  const [hasMission, setHasMission] = useLazyState(lectureData?.hasMission as undefined | boolean);
  const [hasVod, setHasVod] = useLazyState(lectureData?.hasVod as undefined | boolean);
  return (
    <>
      {lectureData && (
        <CustomForm
          form={form}
          initialValues={lectureData}
          onFinish={() => {
            patchLecture({ ...form.getFieldsValue(true), hasMission, hasVod });
          }}
        >
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="강의 ID" name="id" disabled />
            <CustomForm.InputNumber label="강의 순서" name="ord" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input label="강의 번호" name="sequence" />
            <CustomForm.Input label="강의명" name="title" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input label="인정 최소 수강시간(초)" name="runningTime" />
            <CustomForm.Input label="강의 총 시간(초)" name="time" />
          </CustomForm.GridRow>
          <CustomForm.Checkbox name="isPreview" label="미리보기 가능" />
          <CustomForm.Checkbox name="isActive" label="활성화 (접근 가능)" />
          <CustomForm.Checkbox name="isPrivate" label="강의 비공개" />
          <CustomForm.Divider orientation="left">Vod</CustomForm.Divider>
          <S.MissionContainer>
            <Checkbox
              name="VOI 여부"
              checked={hasVod}
              onClick={() => setHasVod(!hasVod)}
              id="hasVod"
            />
            <S.Label htmlFor="hasVod">VOD 있음</S.Label>
          </S.MissionContainer>
          <CustomForm.InputNumber name="vodFileId" label="Vod ID" />
          <CustomForm.Divider orientation="left">미션</CustomForm.Divider>
          <S.MissionContainer>
            <Checkbox
              name="미션 여부"
              checked={hasMission}
              onClick={() => setHasMission(!hasMission)}
              id="hasMission"
            />
            <S.Label htmlFor="hasMission">미션 여부</S.Label>
          </S.MissionContainer>
          {hasMission && <CustomForm.MarkdownEditor form={form} name="mission" height="500px" />}

          <CustomForm.Divider orientation="left">수업 노트</CustomForm.Divider>
          <CustomForm.MarkdownEditor form={form} name="content" height="500px" />

          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
    </>
  );
}

export default LectureInfo;
