import { useAddRefundCheckoutMutation } from 'store/services/checkout';
import { useParams } from 'react-router-dom';
import { RefundForm } from 'model/checkout.model';

const useCheckoutMutation = () => {
  const { id } = useParams(); //id를 가져와. 아니면 params로 넘길까? api가

  const [addRefundCheckoutRequest] = useAddRefundCheckoutMutation();
  const addRefundCheckout = (form: RefundForm) => {
    const body = {
      amount: form.amount,
      reason: form.reason,
    };
    if (window.confirm('환불하시겠습니까?'))
      addRefundCheckoutRequest({ id, ...body })
        .unwrap()
        .then(() => {
          alert('환불이 완료되었습니다.');
        })
        .catch((err) => {
          alert('환불에 실패했습니다.');
        });
  };
  return {
    addRefundCheckout,
  };
};

export default useCheckoutMutation;
