import { useGetUserMemoQuery } from 'store/services/user';
import * as S from '../styles';
import { UserMemoDetail } from 'model/user.model';
import useUserMemoMutation from './Hook/useUserMemoMutation';
import Table, { ColumnsType } from 'antd/lib/table';
import SelectOrderInput from 'components/form/SelectOrderInput';
import { ChangeEvent, useState } from 'react';
import { Pagination } from 'antd';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

function MemoList() {
  const { deleteUserMemo } = useUserMemoMutation();

  const ORD_TYPE = ['오래된순', '최신순'];
  const ORD_TYPE_MAPPED = {
    오래된순: 'REG_DATE_ASC',
    최신순: 'REG_DATE_DESC',
  };
  const SEARCH_TYPE = ['선택', '유저ID', '유저닉네임', 'CONTENT', 'ETC', 'HASHTAG'];
  const SEARCH_TYPE_MAPPED = {
    선택: 'ALL',
    유저ID: 'USER_ID',
    유저닉네임: 'USER_NICKNAME',
    내용: 'CONTENT',
    기타: 'ETC',
    해시태그: 'HASHTAG',
  };
  const TRANSMISSION_TYPE = ['ALL', '발신', '수신'];
  const TRANSMISSION_TYPE_MAPPED = {
    ALL: '',
    발신: 'SENDING',
    수신: 'RECEIVING',
  };
  const CONSULTATION_ROUTE = ['ALL', '채널톡', '메시지', '전화', '기타'];
  const CONSULTATION_ROUTE_MAPPED = {
    ALL: '',
    채널톡: 'CHANNEL_TALK',
    메시지: 'MESSAGE',
    전화: 'PHONE',
    기타: 'OTHERS',
  };

  const [filter, setFilter] = useState({
    ordType: '최신순',
    searchType: '선택',
    TransmissionType: 'ALL',
    ConsultationRoute: 'ALL',
    target: '',
  });

  const [page, setPage] = useNumberSearchParams('page');

  const { data: MemoList } = useGetUserMemoQuery({
    ordType: ORD_TYPE_MAPPED[filter.ordType], //'REG_DATE_DESC',
    searchType: SEARCH_TYPE_MAPPED[filter.searchType], // 'ALL',
    TransmissionType: TRANSMISSION_TYPE_MAPPED[filter.TransmissionType], //'',
    ConsultationRoute: CONSULTATION_ROUTE_MAPPED[filter.ConsultationRoute], //'',
    target: filter.target,
    page: page,
    size: 10,
  });

  const MEMOCOLUMNS: ColumnsType<UserMemoDetail> = [
    {
      title: '유저 ID',
      dataIndex: 'userId',
      key: 'id',
      width: '5%',
    },
    {
      title: '태그',
      dataIndex: 'userMemoCxHashtags',
      key: 'userMemoCxHashtags',
      width: '10%',
      render: (value, record: UserMemoDetail) => {
        return <div>{value.map((item) => item.cxHashtag.tag).join(', ')}</div>;
      },
    },
    {
      title: '상담 내용', //이 부분은 수정이 가능하게 진행되어야 함
      dataIndex: 'content',
      key: 'content',
      width: '30%',
      render: (value, record: UserMemoDetail) => {
        return <div>{record.content}</div>;
      },
    },
    {
      title: '<->',
      dataIndex: 'transmissionType',
      key: 'transmissionType',
      width: '9%',
      render: (value, record: UserMemoDetail) => {
        return <div>{record.transmissionType}</div>;
      },
    },
    {
      title: '상담경로',
      dataIndex: 'consultationRoute',
      key: 'consultationRoute',
      width: '9%',
      render: (value, record: UserMemoDetail) => {
        return <div>{record.consultationRoute}</div>;
      },
    },
    {
      title: '작성일자',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '15%',
    },
    {
      title: '기타',
      dataIndex: 'etc',
      key: 'etc',
      width: '15%',
      render: (value, record: UserMemoDetail) => {
        return <div>{record.etc}</div>;
      },
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '7%',
      render: (param, record: any & { tags: string[] }) => {
        return (
          <span>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                deleteUserMemo(Number(record.userId), record.id);
              }}
            >
              삭제
            </S.DeleteButton>
          </span>
        );
      },
    },
  ];

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  return (
    <S.Container>
      <h1>
        유저 메모
        <SelectOrderInput
          selectValue={filter.ordType}
          array={ORD_TYPE}
          label="정렬"
          name="ordType"
          onChange={handleFilterChange}
          type="order"
        />
        <SelectOrderInput
          selectValue={filter.TransmissionType}
          array={TRANSMISSION_TYPE}
          label="타입"
          name="TransmissionType"
          onChange={handleFilterChange}
          type="state"
        />
        <SelectOrderInput
          selectValue={filter.ConsultationRoute}
          array={CONSULTATION_ROUTE}
          label="상담경로"
          name="ConsultationRoute"
          onChange={handleFilterChange}
          type="state"
        />
        <S.SearchContainer>
          <SelectOrderInput
            selectValue={filter.searchType}
            array={SEARCH_TYPE}
            label="검색"
            name="searchType"
            onChange={handleFilterChange}
            type="search"
          />
          <input name="target" value={filter.target} onChange={handleFilterChange} />
        </S.SearchContainer>
      </h1>
      <S.TableContainer>
        {MemoList && (
          <Table
            columns={MEMOCOLUMNS}
            dataSource={MemoList?.content.map((userMemo) => {
              return { ...userMemo, tags: ['수정'] };
            })}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  e.preventDefault();
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/user/${record.userId}`, '_blank');
                  } else window.open(`/user/${record.userId}`, '_blank');
                },
              };
            }}
          />
        )}
        {MemoList && (
          <Pagination
            pageSize={15}
            current={page}
            total={MemoList.totalElements}
            onChange={setPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default MemoList;
