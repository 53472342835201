import { Button, Divider, Modal, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import * as S from '../../styles';
import { CourseListHeader } from 'model/course.model';
import {
  useGetCollectionCourseDetailQuery,
  useGetCollectionCourseListQuery,
  usePatchCollectionPackageMutation,
} from 'store/services/collection';
import { useParams } from 'react-router-dom';

const COLUMNS: ColumnsType<CourseListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '4%',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
    width: '35%',
  },
  {
    title: '시리즈(카테고리)',
    dataIndex: 'catagoryName',
    key: 'catagoryName',
    width: '15%',
  },
  {
    title: '적용 패키지 수',
    dataIndex: 'collectionPackageCount',
    key: 'collectionPackageCount',
    width: '13%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    render: (value) => (value == true ? '비공개' : '공개'),
    width: '8%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <Button key={tag}>{tag.toUpperCase()}</Button>;
        })}
      </>
    ),
  },
];

const PACKAGE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '패키지명',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '콜렉션 포함 여부',
    dataIndex: 'belongsToCollection',
    key: 'belongsToCollection',
    render: (value) => (value == true ? '포함' : '미포함'),
  },

  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

export default function AddCollectionCourse({ visible, onOk, onCancel, collectionId }) {
  const [page, setPage] = useState(1);
  const [packagePage, setPackagePage] = useState(1);
  const { id } = useParams();
  const { data: courseList } = useGetCollectionCourseListQuery({
    id,
    page,
    type: 'ALL',
  });
  const [selectedCourseId, setSelectedCourseId] = useState<null | number>(null);
  const [selectedPackageId, setSelectedPackageId] = useState<number[]>([]);
  const [selectedCourseTitle, setSelectedCourseTitle] = useState<null | string>(null);

  const { data: packageData } = useGetCollectionCourseDetailQuery(
    {
      collectionId: id,
      courseId: selectedCourseId,
      page: packagePage,
      type: 'ALL',
    },
    { skip: selectedCourseId === null }
  );

  const [addCollectionPackage] = usePatchCollectionPackageMutation();
  return (
    <Modal
      title="컬렉션 코스 추가"
      visible={visible}
      onOk={() => {
        if (selectedPackageId) {
          console.log('collectionId', collectionId);
          console.log('idList', selectedPackageId);
          addCollectionPackage({
            collectionId: collectionId,
            idList: selectedPackageId,
          }).then(() => {
            alert('패키지가 추가되었습니다.');
            onOk();
          });
        }
      }}
      onCancel={onCancel}
      maskClosable={false}
      okText="적용"
      cancelText="취소"
      width={1000}
    >
      <h5>
        선택된 수업: <strong>{selectedCourseTitle}</strong>
      </h5>
      <h5>
        패키지 ID: <strong>{selectedPackageId.join(', ')}</strong>
      </h5>
      <Button
        type="primary"
        onClick={() => {
          setSelectedCourseId(null);
          setSelectedCourseTitle(null);
          setSelectedPackageId([]);
        }}
      >
        <strong>{'< '}</strong>
      </Button>

      <Divider />

      {courseList?.content && (
        <S.TableContainer>
          {selectedCourseId && packageData ? (
            <>
              <Table
                dataSource={packageData.content}
                columns={PACKAGE_COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      console.log(record);
                      setSelectedPackageId((prev) => {
                        // 이미 선택된 패키지 ID가 있다면 제거
                        if (prev.includes(record.id)) {
                          return prev.filter((id) => id !== record.id);
                        }
                        // 아니라면 ID를 추가
                        else {
                          return [...prev, record.id];
                        }
                      });
                    },
                  };
                }}
              />
              <Pagination
                pageSize={10}
                current={page}
                total={packageData.totalElements}
                onChange={(page) => {
                  setPackagePage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            </>
          ) : (
            <>
              <Table
                dataSource={courseList?.content.map((content) => ({
                  ...content,
                  tags: ['선택'],
                }))}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record: any) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      setSelectedCourseId(record.id);
                      setSelectedCourseTitle(record.title);
                    },
                  };
                }}
              />
              <Pagination
                pageSize={10}
                current={page}
                total={courseList.totalElements}
                onChange={(page) => {
                  setPage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            </>
          )}
        </S.TableContainer>
      )}
    </Modal>
  );
}
