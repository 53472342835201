import { useGetProductPackageDiscountListQuery } from 'store/services/package';
import * as S from '../../styles';
import { useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { Table } from 'antd';
import Pagination from 'antd/es/pagination';
import useModal from 'hooks/useModal';
import PackageDiscountAddModal from '../../InputModal/PackageDiscountAddModal';

const PACKAGE_DISCOUNT_COLUMNS = [
  {
    title: '패키지 할인 ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '할인 이름',
    dataIndex: 'discountTitle',
    key: 'discountTitle',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '할인 가격',
    dataIndex: 'discountPrice',
    key: 'discountPrice',
  },
  {
    title: '할인율',
    dataIndex: 'discountRate',
    key: 'discountRate',
  },
  {
    title: '시작 기간',
    dataIndex: 'startAt',
    key: 'startAt',
  },
  {
    title: '종료 기간',
    dataIndex: 'endAt',
    key: 'endAt',
  },
];
function PackageDiscount() {
  const { courseId, packageId, id } = useParams();
  const [page, setPage] = useState(1);
  const { data: discountList } = useGetProductPackageDiscountListQuery({ id, page });

  const navigate = useNavigate();

  const packageDiscountAddRef = useRef<HTMLDivElement>(null);

  const [isAddModalOpen, open] = useModal(packageDiscountAddRef, true);

  return (
    <S.Container>
      {discountList && (
        <>
          {isAddModalOpen && (
            <S.ModalBackground>
              <PackageDiscountAddModal ref={packageDiscountAddRef} open={open} />
            </S.ModalBackground>
          )}
          <S.AddButton
            style={{ margin: '0px 16px', marginBottom: '10px' }}
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            추가
          </S.AddButton>
          <S.TableContainer>
            <Table
              dataSource={discountList.content}
              columns={PACKAGE_DISCOUNT_COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              onRow={(record) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`discount/${record.id}`, '_blank');
                    } else navigate(`discount/${record.id}`);
                  },
                };
              }}
            />
            {discountList && (
              <Pagination
                pageSize={15}
                current={page}
                total={discountList.totalElements}
                onChange={setPage}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        </>
      )}
    </S.Container>
  );
}

export default PackageDiscount;
