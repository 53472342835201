import { Table } from 'antd';
import * as S from '../styles';
import { useRef } from 'react';
import { useGetHashtagListQuery } from '../../../store/services/hashtag';
import useModal from '../../../hooks/useModal';
import HashtagAddModal from '../InputModal/HashtagAddModal';
import HashtagPatchModal from '../InputModal/HashtagPatchModal';
import Pagination from 'antd/es/pagination';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

function Hashtag() {
  const [page, setPage] = useNumberSearchParams('page');
  const { data: hashtagList } = useGetHashtagListQuery(page);
  const hashtagRef = useRef('');
  const hashtagId = hashtagRef.current;

  //모달
  const hashtagAddRef = useRef<HTMLDivElement>(null);
  const hashtagPatchRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(hashtagAddRef);
  const [isPatchModalOpen, patchModalopen] = useModal(hashtagPatchRef);

  // 해시태그 데이터 칼럼
  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'HashTag',
      dataIndex: 'tag',
      key: 'tag',
    },
  ];

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <HashtagAddModal ref={hashtagAddRef} open={open} />
        </S.ModalBackground>
      )}
      {isPatchModalOpen && (
        <S.ModalBackground>
          <HashtagPatchModal ref={hashtagPatchRef} open={patchModalopen} hashtagId={hashtagId} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          해시태그
          <S.AddButton
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 추가
          </S.AddButton>
        </h1>
        {hashtagList && (
          <>
            <S.TableContainer>
              <Table
                style={{ width: 'auto' }}
                size={'small'}
                dataSource={hashtagList.content}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      event.stopPropagation();
                      patchModalopen(true);
                      hashtagRef.current = String(record.id);
                    },
                  };
                }}
              />
              {hashtagList && (
                <Pagination
                  pageSize={15}
                  current={page}
                  total={hashtagList.totalElements}
                  onChange={setPage}
                  showSizeChanger={false}
                  showQuickJumper
                />
              )}
            </S.TableContainer>
          </>
        )}
      </S.Container>
    </>
  );
}

export default Hashtag;
