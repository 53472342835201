import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useFileSearchParams = (key: string): [number | string, (value: number | string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const file = searchParams.get(key) ? Number(searchParams.get(key)) : '';
  const setFile = useCallback(
    (nextChild: number | string, replace?: boolean) => {
      const params = new URLSearchParams(searchParams);
      params.set(key, String(nextChild));
      if (replace) setSearchParams(params, { replace: true });
      else setSearchParams(params);
    },
    [key, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.get(key) === null) {
      setFile('', true); //replace 가 필요한 듯 하다.
    }
  }, [key, searchParams, setFile]);
  return [file, setFile];
};

export default useFileSearchParams;
