import { UploadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Select, Switch, Upload } from 'antd';
import React, { ReactNode } from 'react';
import * as S from './CustomForm.style';
import axios from 'axios';
import CustomMarkdownEditor from './CustomMarkdownEditor';
import { NamePath } from 'antd/lib/form/interface';
import { Rule } from 'antd/lib/form';
import CustomTableSelectModal from './CustomTableSelectModal';

interface InputProps {
  label: string;
  name: NamePath;
  rules?: any;
  disabled?: boolean;
  readOnly?: boolean;
  validateTrigger?: any;
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
}

interface TextAreaProps {
  label: string;
  name: NamePath;
  maxLength?: number;
}

interface GridProps {
  children: ReactNode;
  gridColumns: string;
}

interface ButtonProps {
  children: string;
  danger?: boolean;
  ghost?: boolean;
  icon?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  htmlType?: 'button' | 'submit' | 'reset';
}

function GridRow({ children, gridColumns }: GridProps) {
  return <S.GridWrap gridColumns={gridColumns}>{children}</S.GridWrap>;
}

function CustomInput({ label, name, rules, disabled, readOnly, type }: InputProps) {
  return (
    <S.FormItem label={label} name={name} rules={rules}>
      <S.StyledInput disabled={disabled} readOnly={readOnly} type={type} />
    </S.FormItem>
  );
}

function CustomInputNumber({ label, name, rules, disabled, validateTrigger }: InputProps) {
  return (
    <S.FormItem label={label} name={name} rules={rules}>
      <S.StyledInputNumber controls={false} disabled={disabled} />
    </S.FormItem>
  );
}

function CusctomTextArea({ label, name, maxLength }: TextAreaProps) {
  return (
    <S.FormItem label={label} name={name} style={{ height: '80px', fontSize: '16px' }}>
      <Input.TextArea showCount maxLength={maxLength} />
    </S.FormItem>
  );
}

function CustomCheckbox({ name, label }) {
  return (
    <S.FormItem name={name} valuePropName="checked">
      <Checkbox>{label}</Checkbox>
    </S.FormItem>
  );
}

function CustomUpload({
  name,
  isCard = false,
  acceptAll = false,
  rules,
}: {
  name: NamePath;
  isCard?: boolean;
  acceptAll?: boolean;
  rules?: any;
}) {
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file } = options;

    const image = new FormData();
    image.append('multipartFile', file);

    await axios({
      method: 'post',
      url: `/file?fileType=IMAGE`,
      data: image,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((res) => {
        onSuccess(res.data.url);
      })
      .catch((e) => {
        onError(e);
      });
  };

  return (
    <S.UploadWrapper>
      <S.FormItem
        name={name}
        valuePropName="fileList"
        rules={rules}
        getValueFromEvent={(e: any) => {
          if (e?.fileList[0].response) {
            return [
              {
                thumbUrl: e.fileList[0].response,
                url: e.fileList[0].response,
                name: e.fileList[0].response,
                uid: '-1',
              },
            ];
          }
          return e?.fileList;
        }}
      >
        <Upload
          customRequest={handleFileUpload}
          listType={isCard ? 'picture-card' : 'picture'}
          className="upload-list-inline"
          accept={acceptAll ? '' : 'image/*'}
          maxCount={1}
          showUploadList={{
            showRemoveIcon: false,
          }}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </S.FormItem>
    </S.UploadWrapper>
  );
}

function CustomButton({ children, danger, ghost, icon, onClick, htmlType }: ButtonProps) {
  return (
    <S.FormItem>
      <S.StyledButton
        htmlType={htmlType}
        type="primary"
        danger={danger}
        ghost={ghost}
        icon={icon}
        onClick={onClick}
      >
        {children}
      </S.StyledButton>
    </S.FormItem>
  );
}

interface FormItemProps {
  label: string;
  name: string;
  disabled?: boolean;
  rules?: Rule[];
}

function CustomSelect({
  options,
  label,
  name,
  rules,
  disabled,
  onChange,
}: FormItemProps & {
  options: Record<string, string>;
  onChange?: (value: string) => void;
}) {
  return (
    <S.FormItem label={label} name={name} rules={rules}>
      <Select disabled={disabled} onChange={onChange}>
        {Object.entries(options).map(([key, val]) => (
          <Select.Option value={Number.isNaN(Number(key)) ? key : Number(key)} key={key}>
            {val}
          </Select.Option>
        ))}
      </Select>
    </S.FormItem>
  );
}

function CustomDivider({ children, ...args }) {
  return (
    <S.FormDivider orientationMargin="0" {...args}>
      {children}
    </S.FormDivider>
  );
}

function CustomSwitch({ name, checkedChildren, unCheckedChildren, label }) {
  return (
    <S.SwitchWrap>
      {`${label} `}
      <S.FormItem name={name} valuePropName="checked">
        <Switch checkedChildren={checkedChildren} unCheckedChildren={unCheckedChildren} />
      </S.FormItem>
    </S.SwitchWrap>
  );
}

const CustomForm = Object.assign(Form, {
  GridRow,
  Button: CustomButton,
  Input: CustomInput,
  InputNumber: CustomInputNumber,
  TextArea: CusctomTextArea,
  Upload: CustomUpload,
  Checkbox: CustomCheckbox,
  Divider: CustomDivider,
  MarkdownEditor: CustomMarkdownEditor,
  Select: CustomSelect,
  Switch: CustomSwitch,
  TableSelectModal: CustomTableSelectModal,
});

export default CustomForm;
