import styled from 'styled-components';

export const AppContainer = styled.div<{ sidebarShow: boolean; sidebarUnfoldable: boolean }>`
  padding-left: ${({ sidebarUnfoldable, sidebarShow }) =>
    sidebarShow ? (sidebarUnfoldable ? '64px' : '256px') : '0'};
  width: 1600px;
`;

export const MainSection = styled.main`
  padding: 0 24px;
  h1 {
    margin-left: 20px;
    font-weight: 600;
  }

  h2 {
    margin-left: 15px;
    font-weight: 500;
  }
`;
