import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetSpecificReplyList, SpecificReplyListRes } from 'model/specificReply.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const specificReplyApi = createApi({
  reducerPath: 'specificReply',
  tagTypes: ['SpecificReply'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/classroom/specific-reply',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSpecificReplyList: builder.query<GetSpecificReplyList, number>({
      query: (page) => `/?size=20&page=${page - 1}`,
      providesTags: ['SpecificReply'],
      transformResponse: (response: SpecificReplyListRes): GetSpecificReplyList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            ord: tableData.ord,
            nickname: tableData.classRoomLectureReply.user.nickname,
            content: tableData.classRoomLectureReply.content,
            commentId: tableData.classRoomLectureReply.id,
            courseId: tableData.course.id,
            tags: ['위로', '아래로'],
          };
        });
        return { content: tableHeaderData, totalElements, totalPages };
      },
    }),

    addSpecificReply: builder.mutation({
      query: (specificReplyAddForm) => ({
        url: `/`,
        method: 'POST',
        body: specificReplyAddForm,
      }),
      invalidatesTags: ['SpecificReply'],
    }),

    getSpecificReply: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'SpecificReply', id }],
    }),

    patchSpecificReplyOrd: builder.mutation<void, { specificReplyIds: number[] }>({
      query: ({ specificReplyIds }) => ({
        url: `/`,
        method: 'PATCH',
        body: specificReplyIds,
      }),
      invalidatesTags: ['SpecificReply'],
    }),

    deleteSpecificReply: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SpecificReply'],
    }),
  }),
});

export const {
  useGetSpecificReplyListQuery,
  useAddSpecificReplyMutation,
  useDeleteSpecificReplyMutation,
  usePatchSpecificReplyOrdMutation,
  useGetSpecificReplyQuery,
} = specificReplyApi;
