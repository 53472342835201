import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../styles';
import { useNavigate } from 'react-router-dom';
import { ChangeEvent, useState } from 'react';
import Pagination from 'antd/es/pagination';
import { UserListHeader } from 'model/user.model';
import { useGetUserListQuery } from 'store/services/user';
import SelectOrderInput from 'components/form/SelectOrderInput';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const COLUMNS: ColumnsType<UserListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '성함',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '생년월일',
    dataIndex: 'birthYear',
    key: 'birthYear',
  },
  {
    title: '휴대폰 번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: '유저 타입',
    dataIndex: 'userType',
    key: 'userType',
    // render: (value) => (value == true ? '비공개' : '공개'),
  },

  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          //let color = 'green';
          return <S.SubEditButton key={tag}>{tag.toUpperCase()}</S.SubEditButton>;
        })}
      </>
    ),
  },
];

const ORD_TYPE = ['오래된순', '최신순'];
const ORD_TYPE_MAPPED = {
  오래된순: 'REG_DATE_ASC',
  최신순: 'REG_DATE_DESC',
};

const SEARCH_TYPE = ['선택', 'ID', '닉네임', '생년월일', '휴대폰번호'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  ID: 'ID',
  닉네임: 'NICKNAME',
  생년월일: 'BIRTH_DAY',
  휴대폰번호: 'PHONE_NUMBER',
};

const STATE_TYPE = ['전체', '가입', '탈퇴'];
const STATE_TYPE_MAPPED = {
  전체: 'ALL',
  가입: 'ACTIVE',
  탈퇴: 'DEACTIVE',
};

const TYPE = ['전체', '학생', '부모', '멘토', '사업자'];
const TYPE_MAPPED = {
  전체: 'ALL',
  학생: 'STUDENT',
  부모: 'PARENTS',
  멘토: 'MENTOR',
  사업자: 'BUSINESS',
};

function UserList() {
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    ordType: '최신순',
    stateType: '전체',
    userType: '전체',
    searchType: '선택',
    target: '',
  });

  const { data: userList } = useGetUserListQuery({
    page,
    ordType: ORD_TYPE_MAPPED[filter.ordType],
    searchType: SEARCH_TYPE_MAPPED[filter.searchType],
    state: STATE_TYPE_MAPPED[filter.stateType],
    target: filter.target,
    type: TYPE_MAPPED[filter.userType],
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  return (
    <S.Container>
      <h1>
        유저 조회
        <SelectOrderInput
          selectValue={filter.ordType}
          array={ORD_TYPE}
          label="정렬"
          name="ordType"
          onChange={handleFilterChange}
          type="order"
        />
        <SelectOrderInput
          selectValue={filter.stateType}
          array={STATE_TYPE}
          label="회원여부"
          name="stateType"
          onChange={handleFilterChange}
          type="state"
        />
        <SelectOrderInput
          selectValue={filter.userType}
          array={TYPE}
          label="유저 타입"
          name="userType"
          onChange={handleFilterChange}
          type="filter"
        />
        <S.SearchContainer>
          <SelectOrderInput
            selectValue={filter.searchType}
            array={SEARCH_TYPE}
            label="검색"
            name="searchType"
            onChange={handleFilterChange}
            type="search"
          />
          <input name="target" value={filter.target} onChange={handleFilterChange} />
        </S.SearchContainer>
      </h1>
      <S.TableContainer>
        {userList && (
          <Table
            dataSource={userList?.content.map((user, idx) => {
              return { ...user, tags: ['수정'] };
            })}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/user/${record.id}`, '_blank');
                  } else navigate(`/user/${record.id}`);
                },
              };
            }}
          />
        )}
        {userList && (
          <Pagination
            pageSize={15}
            current={page}
            total={userList.totalElements}
            onChange={setPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default UserList;
