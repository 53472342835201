import {
  useGetLectureFileQuery,
  useDeleteLectureFileMutation,
} from '../../../store/services/classroom';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from '../styles';
import CustomForm from 'components/CustomForm';

function FileDetail() {
  const { classroomId, chapterId, lectureId, id } = useParams();
  const { data } = useGetLectureFileQuery({ classroomId, chapterId, lectureId, id });
  const [form] = CustomForm.useForm();
  const navigate = useNavigate();
  const [deleteLectureFile] = useDeleteLectureFileMutation();

  const onDeleteFile = () => {
    try {
      if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
        deleteLectureFile({ classroomId, chapterId, lectureId, id });
        alert('삭제가 완료되었습니다.');
        navigate(-1);
      }
    } catch (e: any) {
      throw new Error(e);
    }
  };

  return (
    <>
      {data && (
        <S.Container>
          <S.DepthNavi>
            <S.CurrentPage>{data?.file.name}</S.CurrentPage>
          </S.DepthNavi>
          <h1>
            수업 자료 조회
            <S.DeleteButton onClick={onDeleteFile}>삭제</S.DeleteButton>
          </h1>
          <CustomForm form={form} initialValues={data}>
            <CustomForm.InputNumber label="ID" name="id" disabled />
          </CustomForm>
        </S.Container>
      )}
    </>
  );
}

export default FileDetail;
