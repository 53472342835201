import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetMentor, MentorDetail, MentorDetailForm, MentorListRes } from 'model/mento.model';
import serverImageToForm from 'utils/serverImageToForm';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const mentoApi = createApi({
  reducerPath: 'mentor',
  tagTypes: ['Mentor'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/user/mentoProfile',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMentorList: builder.query<
      GetMentor,
      {
        page: number;
        ordType: string;
        searchType: string;
        target: string;
        size?: number;
      }
    >({
      query: ({ page, ordType, searchType, target, size = 15 }) =>
        `/?size=${size}&page=${
          page - 1
        }&ordType=${ordType}&searchType=${searchType}&target=${target}`,
      providesTags: ['Mentor'],
      transformResponse: (response: MentorListRes): GetMentor => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            displayName: tableData.displayName,
            displayJob: tableData.displayJob,
            updatedAt: tableData.updatedAt,
            createdAt: tableData.createdAt,
          };
        });
        return { content: tableHeaderData, totalElements, totalPages };
      },
      keepUnusedDataFor: 10000,
    }),

    addMentor: builder.mutation({
      query: (mentorAddForm) => ({
        url: `/`,
        method: 'POST',
        body: mentorAddForm,
      }),
      invalidatesTags: ['Mentor'],
    }),

    getMentor: builder.query<MentorDetailForm, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Mentor', id: id }],
      transformResponse: (response: MentorDetail): MentorDetailForm => {
        return {
          userId: response.user.id,
          createdAt: response.createdAt,
          updatedAt: response.updatedAt,
          content: response.content,
          displayJob: response.displayJob,
          displayName: response.displayName,
          icon: serverImageToForm(response.icon),
          image: serverImageToForm(response.image),
          personalHistories: response.personalHistories,
          personalSNS: response.personalSNS,
        };
      },
    }),

    patchMentor: builder.mutation({
      query: ({ id, ...patch }: MentorDetailForm & { id: number }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          userId: patch.userId,
          displayName: patch.displayName || '',
          displayJob: patch.displayJob || '',
          content: patch.content || '',
          icon: patch.icon[0].url,
          image: patch.image[0].url,
          personalHistories: patch.personalHistories.map((history) => ({
            content: history.content,
            icon: 'https://image.schooldots.me/2022/06/02/57b2807f-c7b1-428d-b12e-776e39b5c8d2-1654135182071.png',
          })),
          personalSNS: patch.personalSNS,
        },
      }),
      invalidatesTags: (result, error, id) => ['Mentor', { type: 'Mentor', id: Number(id) }],
    }),

    deleteMentor: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Mentor'],
    }),
  }),
});

export const {
  useGetMentorListQuery,
  useAddMentorMutation,
  useGetMentorQuery,
  usePatchMentorMutation,
  useDeleteMentorMutation,
} = mentoApi;
