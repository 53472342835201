import styled from 'styled-components';
import { onNotLarge } from '../../styles/mediaQueries';
import downArrow from '../../assets/images/downArrow.png';

export const Container = styled.div<{ type }>`
  position: relative;
  display: flex;
  font-family: 'Pretendard';
  align-items: center;
  gap: 15px;
  font-style: normal;
  width: ${({ type }) => (type ? 'auto' : '190px')};
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  margin: ${({ type }) =>
    type ? (type == 'search' ? '0px 0px 0px 25px' : ' 0px 15px 0px 25px') : ' 0px 16px 0px 16px'};
  label {
    font-size: '15px';
    font-weight: '500';
    width: ${({ type }) => (type ? 'auto' : '60px')};
    ${onNotLarge} {
      margin-left: ${({ type }) => type == 'search' && '3px;'};
    }
  }
  select {
    font-size: 12px;
    font-weight: 400;
    width: calc(100% + 20px);
    height: ${({ type }) => (type ? '35px' : '45px')};
    padding: ${({ type }) => (type ? '6px 8px 6px 12px' : '15px')};
    color: #393939;
    border-radius: ${({ type }) => (type ? (type == 'search' ? '8px 0 0 8px' : '8px') : '5px')};
    border: ${({ type }) => (type ? '1px solid #dad9d9;' : '1px solid #d9d9d9;')};
    background-color: ${({ type }) => type == 'search' && '#dad9d9'};
    appearance: none;
  }
  select::-ms-expand {
    display: none;
  }
  img {
    position: absolute;
    top: ${({ type }) => (type == 'search' ? '21px' : '17px')};
    right: -8px;
    width: 8px;
    height: 5px;
    margin-left: 5px;
  }
`;

function SelectOrderInput({ selectValue, label, name, onChange, array, type }: any) {
  return (
    <Container type={type}>
      <label>{label}</label>
      <div>
        <select value={selectValue} name={name} onChange={onChange}>
          {array.map((step, idx) => {
            return <option key={idx}>{step}</option>;
          })}
        </select>
        <img src={downArrow} alt="" />
      </div>
    </Container>
  );
}

export default SelectOrderInput;
