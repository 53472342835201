import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';
import { useAddMentorMutation } from '../../../store/services/mento';

const MentorAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const [addMentor] = useAddMentorMutation();
  const MATCH_LABEL = [
    { name: '튜터 직업', variable: 'displayJob', type: 'text' },
    { name: '튜터 이름', variable: 'displayName', type: 'text' },
  ];

  // 튜터 추가
  const emptyMentoProfile = {
    content: ' ',
    displayJob: '',
    displayName: '',
    headline: ' ',
    icon: 'https://image.schooldots.me/blank.png',
    image: 'https://image.schooldots.me/blank.png',
    personalHistories: [
      {
        content: ' ',
        icon: 'https://image.schooldots.me/2022/06/02/57b2807f-c7b1-428d-b12e-776e39b5c8d2-1654135182071.png',
      },
    ],
    personalSNS: [
      {
        name: '네이버',
        url: 'www.naver.com',
      },
    ],
    userId: 108,
  };
  const [addMentorForm, setAddMentorForm] = useState<any>(emptyMentoProfile);

  const onAddMentor = () => {
    addMentor(addMentorForm)
      .unwrap()
      .then(() => {
        setAddMentorForm({
          emptyMentoProfile,
        });
        open(false);
      })
      .catch((error) => {
        console.log(error.data.errorMessage);
      });
  };

  const { isValid, onChangeText } = useValidCheck(addMentorForm, setAddMentorForm);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>튜터 프로필 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={addMentorForm[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={onAddMentor} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default MentorAddModal;
