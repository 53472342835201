import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../styles';
import { useNavigate } from 'react-router-dom';
import { useGetCollectionListQuery } from '../../../store/services/collection';
import { ICollectionList } from 'model/store.model';
import useModal from '../../../hooks/useModal';
import { useRef } from 'react';
import CollectionAddModal from '../InputModal/CollectionAddModal';
import Pagination from 'antd/es/pagination';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

function NewCollection() {
  const navigate = useNavigate();

  const COLUMNS: ColumnsType<ICollectionList> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '컬렉션 이름',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '최종 수정 시간',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            return <S.SubEditButton key={tag}>{tag.toUpperCase()}</S.SubEditButton>;
          })}
        </>
      ),
    },
  ];
  const [page, setPage] = useNumberSearchParams('page');
  const { data } = useGetCollectionListQuery({ page });

  //모달
  const collectionAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(collectionAddRef, true);

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <CollectionAddModal ref={collectionAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          컬렉션
          <S.AddButton
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 추가
          </S.AddButton>
        </h1>
        {data && (
          <S.TableContainer>
            <Table
              dataSource={data.content.map((content) => ({ ...content, tags: ['수정'] }))}
              columns={COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/collection/${record.id}`, '_blank');
                    } else navigate(`/collection/${record.id}`);
                  },
                };
              }}
            />
            {data && (
              <Pagination
                pageSize={15}
                current={page}
                total={data.totalElements}
                onChange={setPage}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        )}
      </S.Container>
    </>
  );
}

export default NewCollection;
