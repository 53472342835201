import { Checkbox, Table, message } from 'antd';
//import type { ColumnsType } from 'antd/es/table';
import * as S from '../../../../styles';
import { LectureTableList } from 'model/classroom.model';
import { useNavigate, useParams } from 'react-router-dom';
import { useAddLectureMutation, useGetChapterListForAlarmQuery } from 'store/services/classroom';
import useCheckboxSearch from 'hooks/useCheckboxSearch';
import useBooleanState from '../../hooks/useBooleanState';
import { useState } from 'react';
import Search from 'antd/lib/input/Search';
import CustomForm from 'components/CustomForm';
import { ColumnsType } from 'antd/lib/table';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import useClassroomDetailMutation from '../../hooks/useClassroomDetailMutation';
import { MenuOutlined } from '@ant-design/icons';
import { useDragSort } from 'utils/Hook/useDragSort';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import CustomRow from 'components/CustomTable/CustomRow';

const COURSE_COLUMNS: ColumnsType = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '30%',
  },
  {
    title: '챕터명',
    dataIndex: 'title',
    key: 'title',
    width: '70%',
  },
];

function LectureList({ chapterData }) {
  const navigate = useNavigate();
  const { classroomId, id } = useParams();
  const [modalPage, setModalPage] = useNumberSearchParams('page');

  const [addLecture] = useAddLectureMutation();
  const { postClassroomLectureDuplicate, patchClassroomLectureSort } = useClassroomDetailMutation({
    classroomId: Number(classroomId),
    chapterId: Number(id),
  });
  const { data: chapterList } = useGetChapterListForAlarmQuery(Number(classroomId));
  const { value: isSort, setFalse: hideSort, setTrue: showSort } = useBooleanState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const COLUMNS: ColumnsType<LectureTableList> = [
    isSort
      ? {
          key: 'sort',
          width: '3%',
          render: () => <MenuOutlined style={{ cursor: 'move' }} />,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (value, record) =>
            isLecturesDulicating ? (
              <Checkbox
                checked={lecturesIsCheckedList.includes(record.id)}
                onChange={(e) => {
                  lecturesHandelCheckedboxChange(record.id);
                }}
              />
            ) : (
              value
            ),
        },
    {
      title: '강의 번호',
      dataIndex: 'sequence',
      key: 'sequence',
    },
    {
      title: '강의명',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '공개 여부',
      dataIndex: 'isPrivate',
      key: 'isPrivate',
      render: (value) => (value == true ? '비공개' : '공개'),
    },
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: '최종 수정일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ];

  const {
    value: isLecturesDulicating,
    setFalse: endLecturesDulicating,
    setTrue: startLecturesDulicating,
  } = useBooleanState(false);

  const {
    //체크 박스 관련 hook
    isCheckedList: lecturesIsCheckedList,
    setIsCheckedList: lecturesSetIsCheckedList,
    isAllChecked: lecturesIsAllChecked,
    handleCheckboxChange: lecturesHandelCheckedboxChange,
    handleSelectAllChange: lecturesHandelSelectAllChange,
  } = useCheckboxSearch(chapterData?.classRoomLectures);

  const [targets, setTargets] = useState<number[]>([]);
  const [visible, setVisible] = useState(false);
  const { dataSource, onDragEnd } = useDragSort(chapterData.classRoomLectures, COLUMNS);

  const handelSortIds = () => {
    const lecturesIds = dataSource.map((content) => content.id);
    console.log(lecturesIds);
    patchClassroomLectureSort(lecturesIds)
      .then((result) => {
        hideSort();
        if (result) messageApi.success('성공적으로 업데이트 되었습니다.');
      })
      .catch(() => {
        messageApi.error('입력을 다시 확인하시오.');
      });
  };

  const checkMode = () => {
    if (isLecturesDulicating) return '강의 복사';
    if (isSort) return '순서 수정';
    return '기본';
  };

  const mode = checkMode();

  return (
    <>
      {contextHolder}
      {chapterData && (
        <>
          <h1>
            강의 목록
            {mode === '강의 복사' && (
              <>
                <Search
                  style={{ width: '200px', marginLeft: '100px', height: '32px' }}
                  placeholder="복사할 코스를 선택하세요."
                  allowClear
                  enterButton="검색"
                  size="large"
                  value={targets.map((target) => target).join(', ')}
                  onSearch={(e) => {
                    setVisible(true);
                  }}
                  readOnly
                />
                <S.DuplicatePackageButton
                  onClick={(event: any) => {
                    console.log('targets', targets);
                    event.stopPropagation();
                    postClassroomLectureDuplicate(lecturesIsCheckedList, targets)
                      .then(() => {
                        endLecturesDulicating();
                        lecturesSetIsCheckedList([]);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }}
                >
                  복사
                </S.DuplicatePackageButton>
                <S.CancelDuplicatePackageButton
                  onClick={() => {
                    endLecturesDulicating();
                  }}
                >
                  취소
                </S.CancelDuplicatePackageButton>
              </>
            )}
            {mode === '순서 수정' && (
              <>
                <S.SortButton onClick={handelSortIds}>정렬 완료</S.SortButton>
                <S.CancelButton onClick={hideSort}>취소</S.CancelButton>
              </>
            )}
            {mode === '기본' && (
              <>
                <S.AddButton onClick={() => addLecture({ classroomId, id })}>+ 추가</S.AddButton>
                <S.DuplicatePackageButton
                  onClick={() => {
                    startLecturesDulicating();
                    lecturesSetIsCheckedList([]);
                    setTargets([]);
                  }}
                >
                  복사
                </S.DuplicatePackageButton>
                <S.SortButton
                  onClick={() => {
                    showSort();
                  }}
                >
                  챕터 순서 정렬
                </S.SortButton>
              </>
            )}
          </h1>
          {isLecturesDulicating && (
            <Checkbox
              style={{ fontSize: '15px', marginLeft: '20px', marginTop: '20px' }}
              checked={lecturesIsAllChecked}
              onChange={lecturesHandelSelectAllChange}
            >
              전체 선택
            </Checkbox>
          )}

          <S.TableContainer>
            {dataSource && (
              <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                <SortableContext
                  items={dataSource.map((i) => i.id)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    //dataSource={chapterData.classRoomLectures}
                    components={{ body: { row: CustomRow } }}
                    dataSource={dataSource as any}
                    columns={COLUMNS}
                    pagination={{ pageSize: 15 }}
                    rowKey={(record) => record.id}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => {
                          if (isLecturesDulicating === false && isSort === false) {
                            if (e.ctrlKey || e.metaKey) {
                              window.open(`lecture/${record.id}`, '_blank');
                            } else navigate(`lecture/${record.id}`);
                          }
                        },
                      };
                    }}
                  />
                </SortableContext>
              </DndContext>
            )}
          </S.TableContainer>
          {chapterList && (
            <CustomForm.TableSelectModal
              columns={COURSE_COLUMNS}
              list={chapterList}
              visible={visible}
              onCancel={() => setVisible(false)}
              onSelect={(record) => {
                if (targets.includes(record.id)) {
                  setTargets(targets.filter((id) => id !== record.id));
                } else {
                  setTargets([...targets, record.id]);
                }
              }}
              page={modalPage}
              setPage={setModalPage}
              totalElements={1}
            />
          )}
        </>
      )}
    </>
  );
}

export default LectureList;
