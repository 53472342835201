import { Button, DatePicker, Divider, Modal, Pagination, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useAddClassroomEnrollmentMutation } from 'store/services/classroom';
import * as S from '../../styles';
import { useGetCourseListQuery } from 'store/services/course';
import { CourseListHeader } from 'model/course.model';
import {
  useGetPackageCategoryPackageListQuery,
  useGetPackageCategoryQuery,
} from 'store/services/package';

import useFileSearchParams from 'hooks/useFileSearchParams';
import useBooleanSearchParams from 'hooks/useBooleanSearchParams';

const COLUMNS: ColumnsType<CourseListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
    width: '38%',
  },
  {
    title: '시리즈(카테고리)',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: '15%',
  },
  {
    title: '코스 스텝',
    dataIndex: 'courseStep',
    key: 'courseStep',
    width: '10%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    render: (value) => (value == true ? '비공개' : '공개'),
    width: '8%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <Button key={tag}>{tag.toUpperCase()}</Button>;
        })}
      </>
    ),
  },
];

const PACKAGE_CATEGORY_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
  {
    title: '하위 패키지 수',
    dataIndex: 'numberOfProductPackages',
    key: 'numberOfProductPackages',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];
const PACKAGE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '패키지명',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '최대 자리',
    dataIndex: 'capacity',
    key: 'capacity',
  },
  {
    title: '연결된 클래스룸 이름',
    dataIndex: 'classroomTitle',
    key: 'classroomTitle',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

export default function AddClassroomModal({ visible, onOk, onCancel, userId }) {
  const [page, setPage] = useState(1);
  const [packagePage, setPackagePage] = useState(1); //패키지 관련
  const [isCount, setIsCount] = useState(false);
  const [status, setStatus] = useState(true);
  const { data: courseList } = useGetCourseListQuery({
    page,
    stepType: 'ALL',
    stateType: 'ALL',
    searchType: 'ALL',
    ordType: 'RECENT',
    target: '',
  });

  const [selectedCourseId, setSelectedCourseId] = useState<null | number>(null);
  const [selectedPackageId, setSelectedPackageId] = useState<null | number>(null);
  const [selectedCourseTitle, setSelectedCourseTitle] = useState<null | string>(null);
  const [parentId, setParentId] = useFileSearchParams('parentId');

  const [categoryPage, setCategoryPage] = useState(1); //카테고리 관련한 페이지네이션

  // const [page, setPage] = useNumberSearchParams('page');
  const [isPackageCategoryPublic, setIsPackageCategoryPublic] =
    useBooleanSearchParams('categoryPublic');
  const [isPackagePublic, setIsPackagePublic] = useBooleanSearchParams('packagePublic');

  const { data: packageCategory } = useGetPackageCategoryQuery(
    {
      //여기가 새로운 패키지
      courseId: Number(selectedCourseId),
      rootProductPackageCategoryId: parentId,
      page: categoryPage,
      size: 3,
      isPublic: isPackageCategoryPublic,
    },
    { skip: selectedCourseId === null }
  );
  console.log(
    'packageCategory 테스트입니다 이게 패키지 카테고리 조회에 띄울 녀석이에요 그리고 선택을 하면  RootId가 바뀌어여얗효요',
    packageCategory
  );

  const { data: packageCategoryPackage } = useGetPackageCategoryPackageListQuery(
    {
      //여기가 새로운 패키지 진짜 패키지 목록들
      courseId: Number(selectedCourseId),
      productPackageCategoryId: parentId,
      page: packagePage,
      size: 3,
      isPublic: isPackagePublic,
    },
    { skip: selectedCourseId === null }
  );
  console.log('packageCategoryPackage 테스트입니다 ', packageCategoryPackage);

  console.log('packageData', packageCategoryPackage);
  const [endDate, setEndDate] = useState<string>();
  const [addEnrollment] = useAddClassroomEnrollmentMutation();

  return (
    <Modal
      title="강의 권한 추가"
      visible={visible}
      onOk={() => {
        console.log('selectedCourseId', selectedCourseId);
        console.log('selectedPackageId', selectedPackageId);
        if (endDate && selectedCourseId && selectedPackageId) {
          addEnrollment({
            endAt: `${endDate} 23:59:59`,
            classRoomId: selectedCourseId,
            productPackageId: selectedPackageId,
            isCount,
            userId,
          }).then(() => onOk());
        }
      }}
      onCancel={() => {
        onCancel();
        setSelectedCourseId(null);
        setSelectedCourseTitle(null);
        setSelectedPackageId(null);
        setParentId('');
      }}
      maskClosable={false}
      okText="적용"
      cancelText="취소"
      width={1000}
    >
      <h5>
        <S.ButtonWrap>
          <Switch
            checkedChildren="구매 횟수 카운트"
            unCheckedChildren="구매 횟수 카운트"
            onChange={(checked) => setIsCount(checked)}
          />
          {selectedCourseId !== null && (
            <Switch
              checkedChildren="판매중"
              unCheckedChildren="종료됨"
              defaultChecked
              onChange={setStatus}
            />
          )}
        </S.ButtonWrap>
      </h5>
      <h5>
        <Button
          type="primary"
          onClick={() => {
            setSelectedCourseId(null);
            setSelectedCourseTitle(null);
            setSelectedPackageId(null);
            setParentId('');
          }}
          style={{ position: 'absolute', left: 50, top: 238 }}
        >
          <strong>{'<'}</strong>
        </Button>
        선택된 수업: <strong>{selectedCourseTitle}</strong>
      </h5>
      <h5>
        패키지 ID: <strong>{selectedPackageId}</strong>
      </h5>

      <h5>
        {endDate !== '2100-12-31' && (
          <>
            만료 기간: <DatePicker onChange={(_, dateString) => setEndDate(dateString)} />
          </>
        )}
        <Button type="primary" onClick={() => setEndDate('2100-12-31')}>
          100년
        </Button>
      </h5>
      <Divider />
      {courseList?.content && (
        <S.TableContainer>
          {selectedCourseId && packageCategory ? ( //여기가 이제 패키지 고르는 부분인데 여기가 이제 배너처럼 되어야해.
            <>
              {' '}
              <S.StyledSwitch
                unCheckedChildren="비공개"
                checkedChildren="공개"
                checked={isPackageCategoryPublic}
                onChange={setIsPackageCategoryPublic}
              />
              <Table
                dataSource={packageCategory.content}
                columns={PACKAGE_CATEGORY_COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      setParentId(record.id); //이거는 이제 카테고리 고를때야
                    },
                  };
                }}
              />
              <Pagination
                pageSize={3}
                current={categoryPage}
                total={packageCategory.totalElements}
                onChange={(categoryPage) => {
                  setCategoryPage(categoryPage);
                }}
                showSizeChanger={false}
                showQuickJumper
              />{' '}
              <S.StyledSwitch
                unCheckedChildren="비공개"
                checkedChildren="공개"
                checked={isPackagePublic}
                onChange={setIsPackagePublic}
              />
              <Table
                dataSource={packageCategoryPackage?.content}
                columns={PACKAGE_COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      setSelectedPackageId(record.id); //이거는 이제 패키지 고를때야
                      //setParentId(record.id); //이거는 이제 카테고리 고를때야
                    },
                  };
                }}
              />
              <Pagination
                pageSize={3}
                current={packagePage}
                total={packageCategoryPackage?.totalElements}
                onChange={(packagePage) => {
                  setPackagePage(packagePage);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            </>
          ) : (
            <>
              <Table
                dataSource={courseList?.content.map((content) => ({
                  ...content,
                  tags: ['선택'],
                }))}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record: any) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      setSelectedCourseId(record.id);
                      setSelectedCourseTitle(record.title);
                    },
                  };
                }}
              />
              <Pagination
                pageSize={10}
                current={page}
                total={courseList.totalElements}
                onChange={(page) => {
                  setPage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            </>
          )}
        </S.TableContainer>
      )}
    </Modal>
  );
}
