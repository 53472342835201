import CustomForm from 'components/CustomForm';
import * as S from '../../styles';
import { useParams } from 'react-router-dom';
import usePackageInfoMutation from '../hook/usePackageInfoMutation';
import { useGetPackageCategoryQuery, useGetPackageQuery } from 'store/services/package';
import { useGetClassroomListQuery } from 'store/services/classroom';
import { useState } from 'react';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const COLLECTION_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
  {
    title: '하위 패키지 수',
    dataIndex: 'numberOfProductPackages',
    key: 'numberOfProductPackages',
  },
];

const CLASSROOM_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '30%',
  },
  {
    title: '클래스룸 이름',
    dataIndex: 'title',
    key: 'title',
    width: '70%',
  },
];
function PackageInfo() {
  const { id } = useParams();
  console.log('id', id);
  const { data } = useGetPackageQuery(Number(id));
  console.log('data', data);

  const [form] = CustomForm.useForm();
  const { patchPackage } = usePackageInfoMutation(Number(id));
  const [visible, setVisible] = useState(false);
  const [categoryVisible, setCategoryVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [categoryModalPage, setCategoryModalPage] = useState(1);
  const { data: classroomList } = useGetClassroomListQuery({
    page: page,
    stateType: 'ALL',
    ordType: 'RECENT_DESC',
    searchType: 'ALL',
    target: '',
    type: '',
    size: 10,
  });
  const handlePatchPackage = (values: any) => {
    console.log('values', values);
    const { courseId, classRoom, course, productPackageCategory, ...finalValues } = values;
    console.log('finalValues', finalValues);
    patchPackage(finalValues);
  };

  const { data: packageCategory } = useGetPackageCategoryQuery({
    //이게 카테고리 목록 불러온 것으로 진행.
    courseId: Number(data?.courseId),
    size: 5,
    page: categoryModalPage,
    rootProductPackageCategoryId: 0,
    isPublic: true,
  });
  console.log('packageCategory', packageCategory);
  console.log('classroomList', classroomList);

  return (
    <>
      <div>
        <CustomForm
          form={form}
          initialValues={data}
          onFinish={() => handlePatchPackage(form.getFieldsValue(true))}
        >
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="패키지 ID" name="id" disabled />
            <CustomForm.Input label="패키지 이름" name="title" />
          </CustomForm.GridRow>
          <CustomForm.Input label="패키지 설명" name="description" />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="코스 ID" name="courseId" disabled />
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.InputNumber label="클래스룸 ID" name="classRoomId" />
              <CustomForm.Button onClick={() => setVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
          </CustomForm.GridRow>{' '}
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.InputNumber label="패키지 카테고리 ID" name="productPackageCategoryId" />
              <CustomForm.Button onClick={() => setCategoryVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.InputNumber label="최대 수용인원" name="capacity" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="가격 (정가)" name="salePrice" />
            <CustomForm.InputNumber label="최소 결제 금액" name="minimumAmount" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="N개월 할부" name="installmentsMonth" />
            <CustomForm.InputNumber label="월 가격(N개월 할부 시)" name="installmentsPrice" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input
              label="판매 시작일"
              name="saleStartAt"
              rules={[
                {
                  pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                  message: 'YYYY-MM-DD 형식으로 작성해주세요!',
                },
                { required: true },
              ]}
            />
            <CustomForm.Input
              label="판매 종료일"
              name="saleEndAt"
              rules={[
                {
                  pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
                  message: 'YYYY-MM-DD 형식으로 작성해주세요!',
                },
                { required: true },
              ]}
            />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input label="생성 시간" name="createdAt" disabled />
            <CustomForm.Input label="최종 수정 시간" name="updatedAt" disabled />
          </CustomForm.GridRow>
          <CustomForm.Switch
            checkedChildren="공개"
            unCheckedChildren="비공개"
            name="isPublic"
            label="공개 여부"
          />
          {/* <CustomForm.Select label="현재 판매 상태" name="status" options={STATUS_ARRAY} /> */}
          <CustomForm.Checkbox label="주소 확인 여부" name="isAddressRequired" />
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
          {classroomList && (
            <CustomForm.TableSelectModal
              columns={CLASSROOM_COLUMNS}
              list={classroomList.content}
              visible={visible}
              onCancel={() => setVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ classRoomId: record.id });
                setVisible(false);
              }}
              size={15}
              page={page}
              setPage={setPage}
              totalElements={classroomList.totalElements}
            />
          )}
          {packageCategory && (
            <CustomForm.TableSelectModal
              columns={COLLECTION_COLUMNS}
              list={packageCategory.content}
              visible={categoryVisible}
              onCancel={() => setCategoryVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ rootId: record.id }); //그냥 표시
                form.setFieldsValue({ productPackageCategoryIdName: record.name }); //그냥 표시
                setCategoryVisible(false);
              }}
              size={5}
              page={categoryModalPage}
              setPage={setCategoryModalPage}
              totalElements={packageCategory.totalElements}
            />
          )}
        </CustomForm>
      </div>
    </>
  );
}

export default PackageInfo;
