import Table, { ColumnsType } from 'antd/es/table';
import * as S from '../../../../styles';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { useParams } from 'react-router-dom';
import { useGetClassroomUserListQuery } from 'store/services/user';
import { ClassroomUserListHeader } from 'model/user.model';
import Pagination from 'antd/es/pagination';

const COLUMNS: ColumnsType<ClassroomUserListHeader> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '15%',
  },
  {
    title: '이름',
    dataIndex: 'name',
    key: 'name',
    width: '10%',
  },
  {
    title: '신분',
    dataIndex: 'userType',
    key: 'userType',
    width: '10%',
  },
  {
    title: '핸드폰 번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: '20%',
  },
  {
    title: '이메일',
    dataIndex: 'email',
    key: 'email',
  },
];

function ClassroomUserList() {
  const { id } = useParams();
  const [page, setPage] = useNumberSearchParams('page');
  const { data: userListData } = useGetClassroomUserListQuery({
    page: page - 1,
    size: 10,
    ordType: 'REG_DATE_DESC',
    searchType: 'CLASSROOM',
    state: 'ALL',
    target: String(id),
    type: 'ALL',
  });
  return (
    <>
      <S.Container>
        <h1>유저 리스트</h1>
        {userListData && (
          <S.TableContainer>
            {userListData && (
              <Table
                dataSource={userListData?.content.map((content) => ({
                  ...content,
                  tags: ['수정'],
                }))}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record: any) => {
                  return record.id;
                }}
                onRow={(record) => {
                  return {
                    onClick: (e) => {
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/user/${record.id}`, '_blank');
                      } else window.open(`/user/${record.id}`, '_blank');
                    },
                  };
                }}
              />
            )}
            {userListData && (
              <Pagination
                pageSize={10}
                current={page}
                total={userListData.totalElements}
                onChange={setPage}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        )}
      </S.Container>
    </>
  );
}

export default ClassroomUserList;
