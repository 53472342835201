import { Button, Pagination, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../styles';
import { useEffect, useState } from 'react';
import {
  useGetSpecificReplyListQuery,
  usePatchSpecificReplyOrdMutation,
} from 'store/services/specificReply';
import { SpecificReplyHeader } from 'model/specificReply.model';
import moveDownArrow from 'assets/images/moveDownArrow.png';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const BASE_COLUMNS: ColumnsType<SpecificReplyHeader> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '순서',
    dataIndex: 'ord',
    key: 'ord',
    width: '5%',
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '10%',
  },
  {
    title: '댓글 내용',
    dataIndex: 'content',
    key: 'content',
    width: '60%',
  },
  {
    title: '코스 ID',
    dataIndex: 'courseId',
    key: 'courseId',
    width: '5%',
  },
  {
    title: '댓글 ID',
    dataIndex: 'commentId',
    key: 'commentId',
    width: '5%',
  },
];

function SpecificReplyList() {
  const [page, setPage] = useNumberSearchParams('page');
  const { data } = useGetSpecificReplyListQuery(page);
  const [specificReplyList, setSpecificReplyList] = useState(data?.content);
  const [patchSpecificReply] = usePatchSpecificReplyOrdMutation();

  const patchReplyOrd = () => {
    const ordList = specificReplyList?.map(({ id }) => id);
    patchSpecificReply({ specificReplyIds: ordList! });
  };

  useEffect(() => {
    setSpecificReplyList(data?.content);
  }, [data]);

  const moveUp = (idx: number) => {
    if (idx === 0 || !specificReplyList) return;
    const newOrderList = specificReplyList.slice();
    const temp = newOrderList[idx];
    newOrderList[idx] = newOrderList[idx - 1];
    newOrderList[idx - 1] = temp;
    setSpecificReplyList(newOrderList);
  };

  const moveDown = (idx: number) => {
    if (!specificReplyList?.length) return;
    if (idx === specificReplyList.length - 1 || !specificReplyList) return;
    const newOrderList = specificReplyList.slice();
    const temp = newOrderList[idx];
    newOrderList[idx] = newOrderList[idx + 1];
    newOrderList[idx + 1] = temp;
    setSpecificReplyList(newOrderList);
  };

  const COLUMNS: ColumnsType<SpecificReplyHeader> = BASE_COLUMNS.concat({
    title: '순서 변경',
    dataIndex: 'changeOrder',
    key: 'changeOrder',
    width: '10%',
    render: (_, col, idx) => {
      return (
        <>
          <S.SubMoveUpArrow
            key={col.tags[0]}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              moveUp(idx);
            }}
            src={moveDownArrow}
            alt=""
          />
          <S.SubMoveDownArrow
            key={col.tags[1]}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              moveDown(idx);
            }}
            src={moveDownArrow}
            alt=""
          />
        </>
      );
    },
  });

  return (
    <S.Container>
      <h1>
        모범 댓글{' '}
        <Button type="primary" style={{ marginLeft: '20px' }} onClick={patchReplyOrd}>
          순서 저장
        </Button>
      </h1>
      <S.TableContainer>
        {specificReplyList && (
          <Table
            dataSource={specificReplyList}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
          />
        )}
        {data && (
          <Pagination
            pageSize={15}
            current={page}
            total={data.totalPages}
            onChange={
              setPage
              //setPageParams(page);
            }
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default SpecificReplyList;
