import { CourseDetailForm, CourseMentorForm, PatchCourseDetailForm } from 'model/course.model';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteCourseMutation,
  usePatchCourseHashtagMutation,
  usePatchCourseMentorMutation,
  usePatchCourseMutation,
  usePostCourseDuplicateMutation,
} from 'store/services/course';
//import { useGetCoursePackageQuery } from 'store/services/package';

const useCourseDetailMutation = (id: number) => {
  const navigate = useNavigate();
  const [deleteCourseRequest] = useDeleteCourseMutation();
  const [patchCourseRequest] = usePatchCourseMutation();
  const [patchCourseMentorRequest] = usePatchCourseMentorMutation();
  const [patchCourseHashtagRequest] = usePatchCourseHashtagMutation();
  const [postCourseDuplicateRequest] = usePostCourseDuplicateMutation();

  //const { data: packageData } = useGetCoursePackageQuery({ id, page: 1, status: 'SALE' });

  const deleteCourse = (form) => {
    // if (packageData?.content?.length && packageData?.content?.length > 0) {
    //   alert('패키지를 가지고 있는 코스는 삭제할 수 없습니다.');
    // } else
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      navigate(-1);
      deleteCourseRequest({ id: id })
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다');
          navigate('/course');
        })
        .catch((error) => alert(error.data.errorMessage));
    }
  };

  const patchCourseInfo = (form: CourseDetailForm) => {
    const body: Partial<PatchCourseDetailForm> & { id: number } = {
      id,
      thumbnail: form.thumbnail[0].url,
      mobileThumbnail: form.mobileThumbnail[0].url,
      title: form.title,
      subTitle: form.subTitle,
      description: form.description,
      age: form.age,
      week: form.week,
      difficulty: form.difficulty,
      exposureScore: form.exposureScore,
      courseStep: form.courseStep,
      lectureCount: form.lectureCount,
      missionCount: form.missionCount,
      isPrivate: form.isPrivate,
      info: form.info || [],
      startAt: form.startAt + ' 00:00:00',
      endAt: form.endAt + ' 23:59:59',
      courseType: form.courseType,
      courseCategoryId: form.courseCategoryId,
      isVisible: true,
      sellingUrl: form.sellingUrl,
      mainColor: form.mainColor,
      detailThumbnail: form.detailThumbnail[0].url,
      mobileDetailThumbnail: form.mobileDetailThumbnail[0].url,
      packageTitle: form.packageTitle,
    };

    if (form.previewVideoId) {
      body.previewVideoId = form.previewVideoId;
    }

    patchCourseRequest(body)
      .unwrap()
      .then(() => {
        alert('업데이트가 완료되었습니다');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const patchCourseMentor = (form: CourseMentorForm) => {
    patchCourseMentorRequest({
      id,
      mentorList: form.mentorList.map((mentor) => ({ mentoProfileId: mentor.id })),
    })
      .unwrap()
      .then(() => alert('튜터 정보가 변경되었습니다.'));
  };

  const patchCourseHashtag = (form) => {
    patchCourseHashtagRequest({
      id,
      hashtagForm: form.hashtagList.map((hashtag) => ({ contentHashtagId: hashtag.id })),
    })
      .unwrap()
      .then(() => alert('해시태그 정보가 변경되었습니다.'));
  };

  const postCourseDuplicate = () => {
    postCourseDuplicateRequest(id)
      .unwrap()
      .then((res) => {
        window.open(`/course/${res.id}`, '_blank');
      });
  };

  return {
    deleteCourse,
    patchCourseInfo,
    patchCourseMentor,
    patchCourseHashtag,
    postCourseDuplicate,
  };
};

export default useCourseDetailMutation;
