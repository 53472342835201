import { useState } from 'react';

export default function useCheckboxSearch(data) {
  let isAllChecked = false;
  const [isCheckedList, setIsCheckedList] = useState<number[]>([]);

  if (data) {
    isAllChecked = isCheckedList.length === data?.length;
  }

  const handleCheckboxChange = (value: number) => {
    setIsCheckedList((prev) => {
      const updatedList = prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value];
      return updatedList;
    });
  };

  const handleSelectAllChange = (e) => {
    if (e.target.checked) {
      const allIds = data!.map((item) => item.id);
      setIsCheckedList(allIds);
    } else {
      setIsCheckedList([]);
    }
  };

  return {
    isCheckedList,
    setIsCheckedList,
    isAllChecked,
    handleCheckboxChange,
    handleSelectAllChange,
  } as const;
}

//여기서 해당 모든 allcheck라는 것이 있었으면 좋겠어
//그리고 allcheck라는걳을 확인하는 변수랑
