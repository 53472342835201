import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetCollectionQuery,
  useDeleteCollectionMutation,
  usePatchCollectionMutation,
  useGetCollectionCourseListQuery,
} from '../../../store/services/collection';
//import { useGetPackageListQuery } from '../../../store/services/package';
import DetailTabs from '../../../components/DetailTabs';
import CustomForm from 'components/CustomForm';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import CollectionCourseList from './CollectionCourseList';
import CollectionInfo from './CollectionInfo';

//페이지네이션 필요.
function NewCollectionDetail() {
  const navigate = useNavigate();
  // const [isSearch, setIsSearch] = useState<boolean>(false); // 추가 하는 용일때.
  const [page, setPage] = useNumberSearchParams('page');

  const { id } = useParams();
  // const { data: packagedata } = useGetPackageListQuery('');
  // const { data: collectionCourseList } = useGetCollectionCourseListQuery({
  //   id,
  //   page,
  //   type: 'ONLY',
  // });
  const { data } = useGetCollectionQuery(`${id}`);
  const [deleteColleciton] = useDeleteCollectionMutation();

  const TAB_LIST = data?.isAllPackagesIncluded
    ? ['컬렉션 정보']
    : ['컬렉션 정보', '적용 코스 리스트'];
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  const handleDeleteClick = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      deleteColleciton(`${id}`)
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다.');
          navigate('/collection');
        })
        .catch((e: any) => {
          alert('컬렉션을 삭제할 수 없습니다');
          throw new Error(e);
        });
    }
  };

  return (
    <S.Container>
      {data && (
        <>
          <S.DepthNavi>
            <S.CurrentPage>{data?.name}</S.CurrentPage>
          </S.DepthNavi>
          <h1>
            컬렉션 수정
            <S.DeleteButton onClick={handleDeleteClick}>삭제</S.DeleteButton>
          </h1>
          <DetailTabs tabs={tabs} selected={currentTab} />
          {currentTab === TAB_LIST[0] && <CollectionInfo />}
          {currentTab === TAB_LIST[1] && <CollectionCourseList />}
        </>
      )}
    </S.Container>
  );
}

export default NewCollectionDetail;
