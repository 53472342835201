import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { url } from 'inspector';
import {
  GetPackageDiscountList,
  GetPackageList,
  Package,
  PackageCategoryResponseData,
  PackageDiscountList,
  PackageForm,
  PackageList,
  PackageListResponse,
  PackageStatistics,
} from 'model/package.model';
import { PackageDiscount } from 'model/package.model';
import { IPackageListRes, IPackage } from 'model/store.model';
const baseUrl = process.env.REACT_APP_SERVER_API_URL;

const defaultPackage = {
  capacity: 0,
  classRoomId: 2,
  courseId: 6,
  description: '추가된 패키지의 Description',
  installmentsMonth: 0,
  installmentsPrice: 0,
  ord: 0,
  saleEndAt: '2022-01-01 00:00:00',
  salePrice: 0,
  saleStartAt: '2022-01-01 00:00:00',
  status: 'SALE',
  title: '추가된 패키지',
};

export const packageApi = createApi({
  reducerPath: 'package',
  tagTypes: ['Package', 'Discount', 'PackageCategory'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/package',

    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  //refetchOnMountOrArgChange: 10,
  refetchOnFocus: true,
  endpoints: (builder) => ({
    getPackage: builder.query<PackageForm, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Package', id }],
      transformResponse: (response: Package) => {
        return {
          ...response,
          courseTitle: response.title,
          classRoomId: response.classRoom.id,
          saleStartAt: response.saleStartAt ? response.saleStartAt.slice(0, 10) : '',
          saleEndAt: response.saleEndAt ? response.saleEndAt.slice(0, 10) : '',
        };
      },
    }),

    patchPackage: builder.mutation<IPackage, Partial<IPackage> & Pick<IPackage, 'id'>>({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Package', id: id }, 'Package'],
    }),

    deletePackage: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Package', id: id }, 'Package'],
    }),

    addPackage: builder.mutation<IPackageListRes, string>({
      query: () => ({
        url: '/',
        method: 'POST',
        body: defaultPackage,
      }),
      invalidatesTags: ['Package'],
    }),

    getPackageList: builder.query({
      query: (page) =>
        `/v2/?ordType=RECENT&page=${page}&searchType=ALL&size=100&isVisible=true&status=SALE`,
      providesTags: ['Package'],
      transformResponse: (response: PackageList): GetPackageList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            title: tableData.title,
            classroomId: tableData.classRoom.id,
            classroomTitle: tableData.classRoom.title,
            description: tableData.description,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    // getCoursePackage: builder.query({
    //   query: ({ id, page, status }) =>
    //     `/v2/?ordType=ORD&page=${page - 1}&query=${id}&searchType=COURSE&size=15&status=${status}`,
    //   providesTags: ['Package'],
    //   transformResponse: (response: PackageList): GetPackageList => {
    //     const { content, totalPages, totalElements } = response;
    //     const tableHeaderData = content.map((tableData) => {
    //       return {
    //         id: tableData.id,
    //         title: tableData.title,
    //         classroomId: tableData.classRoom.id,
    //         classroomTitle: tableData.classRoom.title,
    //         createdAt: tableData.createdAt,
    //         packageEnrollmentCount: tableData.packageEnrollmentCount,
    //         remain: tableData.capacity - tableData.packageEnrollmentCount,
    //         capacity: tableData.capacity,
    //         //belongsToCollection: tableData.belongsToCollection,
    //       };
    //     });
    //     return { content: tableHeaderData, totalPages, totalElements };
    //   },
    //   keepUnusedDataFor: 10000,
    // }),

    getCoursePackage: builder.query({
      query: ({ id, page, status }) =>
        `/v2/?ordType=ORD&page=${page - 1}&query=${id}&searchType=COURSE&size=15&status=${status}`,
      providesTags: ['Package'],
      transformResponse: (response: PackageList): GetPackageList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            title: tableData.title,
            classroomId: tableData.classRoom.id,
            classroomTitle: tableData.classRoom.title,
            createdAt: tableData.createdAt,
            packageEnrollmentCount: tableData.packageEnrollmentCount,
            remain: tableData.capacity - tableData.packageEnrollmentCount,
            capacity: tableData.capacity,

            //belongsToCollection: tableData.belongsToCollection,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
      keepUnusedDataFor: 10000,
    }),

    addProductPackageDiscount: builder.mutation({
      query: (addPackageDiscountForm) => ({
        url: `/discount`,
        method: 'POST',
        body: addPackageDiscountForm,
      }),
      invalidatesTags: ['Discount'],
    }),

    getProductPackageDiscountList: builder.query({
      query: ({ id, page }) => `/discount?productPackageId=${id}&size=15&page=${page - 1}`,
      providesTags: ['Discount'],
      transformResponse: (response: PackageDiscountList): GetPackageDiscountList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            discountTitle: tableData.title,
            createdAt: tableData.createdAt,
            discountPrice: tableData.discountPrice,
            discountRate: tableData.discountRate,
            startAt: tableData.startAt,
            endAt: tableData.endAt,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    getProductPackageDiscount: builder.query({
      query: (id) => `/discount/${id}`,
      ///providesTags: ['Discount'], //여기 폼 변환. 판매 시작일 판매 종료일 시간 없애게 그리고 가장 상단의 것만 name이 되는 이슈 있음 수정 필요.
      providesTags: (result, error, id) => [{ type: 'Discount', id }],
      transformResponse: (response: PackageDiscount) => {
        return {
          ...response, //해당 부분 다시 보기.
          // startAt: response.startAt ? response.startAt.slice(0, 10) : '',
          // endAt: response.endAt ? response.endAt.slice(0, 10) : '',
        };
      },
    }),

    patchProductPackageDiscount: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `discount/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Discount'],
    }),

    deleteProductPackageDiscount: builder.mutation({
      query: (id) => ({
        url: `discount/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Discount'],
    }),

    addCoursePackage: builder.mutation({
      query: (addPackageForm) => ({
        url: '/',
        method: 'POST',
        body: { ...addPackageForm, isAddressRequired: false, isPublic: false },
      }),
      invalidatesTags: ['Package'],
    }),
    postPackageDuplicate: builder.mutation({
      query: ({ id, targetCourseId }) => ({
        url: `/${id}/duplicate?targetCourseId=${targetCourseId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Package'], // 해당 부분 캐시 무효화 id 필요 할 수도
    }),

    getPackageCategory: builder.query<
      PackageCategoryResponseData,
      {
        courseId: number;
        rootProductPackageCategoryId: string | number | null;
        page: number;
        size: number;
        isPublic: boolean;
      }
    >({
      query: ({ courseId, rootProductPackageCategoryId, page, size, isPublic }) =>
        `/category?courseId=${courseId}&rootProductPackageCategoryId=${rootProductPackageCategoryId}&page=${
          page - 1
        }&size=${size}&isPublic=${isPublic}`,
      providesTags: (result, error, rootId) => ['PackageCategory'],
    }),
    getPackageCategoryPackageList: builder.query<
      PackageListResponse,
      {
        courseId: number;
        productPackageCategoryId: string | number | null;
        page: number;
        size: number;
        isPublic: boolean;
      }
    >({
      query: ({ courseId, productPackageCategoryId, page, size, isPublic }) =>
        `?courseId=${courseId}&productPackageCategoryId=${productPackageCategoryId}&page=${
          page - 1
        }&size=${size}&isPublic=${isPublic}`,
      providesTags: (result, error, productPackageCategoryId) => [
        { type: 'Package', productPackageCategoryId },
      ],
    }),
    patchPackageCategory: builder.mutation({
      //패키지 카테고리 수정
      query: ({ id, ...patch }) => ({
        url: `/category/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PackageCategory' }],
    }),

    deletePackageCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PackageCategory' }],
    }),
    postPackageCategory: builder.mutation({
      //패키지 카테고리 생성
      query: ({ ...patch }) => ({
        url: `/category`,
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'PackageCategory' }],
    }),
    patchPackageSort: builder.mutation({
      query: ({ courseId, isPublic, productPackageCategoryId, sortIds }) => ({
        url: `/order?courseId=${courseId}&isPublic=${isPublic}&productPackageCategoryId=${productPackageCategoryId}`,
        method: 'PATCH',
        body: sortIds,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Package' }, { type: 'PackageCategory' }],
    }),
    patchPackageCategorySort: builder.mutation({
      query: ({ courseId, isPublic, productPackageCategoryId, sortIds }) => ({
        url: `category/order?courseId=${courseId}&isPublic=${isPublic}&rootProductPackageCategoryId=${productPackageCategoryId}`,
        method: 'PATCH',
        body: sortIds,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'Package' }, { type: 'PackageCategory' }],
    }),
    getPackageStatistics: builder.query<
      PackageStatistics,
      {
        courseId: number;
        productPackageCategoryId: string | number | null;
      }
    >({
      query: ({ courseId, productPackageCategoryId }) =>
        `statistics?courseId=${courseId}&productPackageCategoryId=${productPackageCategoryId} `,
      providesTags: (result, error, productPackageCategoryId) => [
        { type: 'Package', productPackageCategoryId },
      ],
    }),
  }),
});

export const {
  useGetPackageQuery,
  usePatchPackageMutation,
  useDeletePackageMutation,
  useAddPackageMutation,
  useAddProductPackageDiscountMutation,
  useGetProductPackageDiscountListQuery,
  useGetProductPackageDiscountQuery,
  usePatchProductPackageDiscountMutation,
  useDeleteProductPackageDiscountMutation,
  useAddCoursePackageMutation,
  usePostPackageDuplicateMutation,
  useGetPackageCategoryQuery,
  useGetPackageCategoryPackageListQuery,
  usePatchPackageCategoryMutation,
  useDeletePackageCategoryMutation,
  usePostPackageCategoryMutation,
  usePatchPackageSortMutation,
  usePatchPackageCategorySortMutation,
  useGetPackageStatisticsQuery,
} = packageApi;
