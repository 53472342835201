import * as S from './InputModal.style';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import CustomForm from 'components/CustomForm';
import { useGetCouponCategoryQuery, usePostCouponCategoryMutation } from 'store/services/coupon';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { useGetBannerCategoryQuery, usePostBannerCategoryMutation } from 'store/services/banner';

const COLLECTION_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '하위 배너 수',
    dataIndex: 'numberOfBanners',
    key: 'numberOfBanners',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
];
const BannerCategoryAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const [categoryPage, setcategoryPage] = useNumberSearchParams('catagoryPage');
  const [patchBannerCategory] = usePostBannerCategoryMutation();

  const { data: bannerCategory } = useGetBannerCategoryQuery({
    size: 5,
    page: categoryPage,
    rootBannerCategoryId: 0,
  });

  const handleFinishValue = (values: any) => {
    const { parentIdName, ...finalValues } = values;
    AddBannerCategoty(finalValues);
  };

  const AddBannerCategoty = (value) => {
    console.log('value', value);
    patchBannerCategory(value)
      .unwrap()
      .then(() => {
        alert('카테고리가 생성되었습니다.');
        open(false);
      })
      .catch((error) => {
        alert('입력하신 정보를 확인해주세요');
        console.log(error.data.message);
      });
  };

  const [form] = CustomForm.useForm();
  const [categoryVisible, setCategoryVisible] = useState(false);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>배너 카테고리 생성</S.Title>
      <S.InputList2>
        <CustomForm
          form={form}
          initialValues={{ rootId: null }}
          onFinish={() => handleFinishValue(form.getFieldsValue(true))}
        >
          <CustomForm.Input label="카테고리 제목" name="name" rules={[{ required: true }]} />
          <CustomForm.Input label="카테고리 코드" name="code" rules={[{ required: true }]} />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.Input label="상위 카테고리 ID" name="rootId" />
              <CustomForm.Button onClick={() => setCategoryVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.Input label="상위 카테고리 제목" name="parentIdName" disabled />
          </CustomForm.GridRow>
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
          {bannerCategory && (
            <CustomForm.TableSelectModal
              columns={COLLECTION_COLUMNS}
              list={bannerCategory.content}
              visible={categoryVisible}
              onCancel={() => setCategoryVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ rootId: record.id }); //그냥 표시
                form.setFieldsValue({ parentIdName: record.name }); //그냥 표시
                setCategoryVisible(false);
              }}
              page={categoryPage}
              setPage={setcategoryPage}
              totalElements={bannerCategory.totalElements}
            />
          )}
        </CustomForm>
      </S.InputList2>
    </S.Container>
  );
});

export default BannerCategoryAddModal;
