import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';
import { useGetCourseComponentQuery } from 'store/services/course';
import * as S from 'pages/Product/styles';
import { FormInstance } from 'antd';
import { useState } from 'react';
import { useGetClassroomListQuery } from 'store/services/classroom';
import Table, { ColumnsType } from 'antd/lib/table';
import { IClassroomList } from 'model/store.model';
import useCourseComponentMutation from '../hooks/useCourseComponentMutation';
import Curriculum2Component from './CourseComponentCurriculum';
import { add } from '@dnd-kit/utilities';

// function EditorComponent({ form }: { form: FormInstance }) {
//   return (
//     <div>
//       <CustomForm.MarkdownEditor name="editor" form={form} />
//     </div>
//   );
// }

// function IntroduceComponent() {
//   return (
//     <div>
//       <CustomForm.Input name={['courseIntroduce', 'title']} label="소개 제목" />
//       <CustomForm.Input name={['courseIntroduce', 'subtitle']} label="소개 부제목" />
//       <CustomForm.TextArea name={['courseIntroduce', 'summary']} label="요약" />
//       <CustomForm.TextArea name={['courseIntroduce', 'description']} label="상세 설명" />
//       <CustomForm.Input name={['courseIntroduce', 'hashValue']} label="포인트 색상값" />
//     </div>
//   );
// }

const COLUMNS: ColumnsType<IClassroomList> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%',
  },
  {
    title: '클래스룸명',
    dataIndex: 'title',
    key: 'title',
    width: '50%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    width: '20%',
    render: (value) => (value == true ? '비공개' : '공개'),
  },
  {
    title: '수강 방식',
    dataIndex: 'type',
    key: 'type',
    width: '20%',
  },
];

// function ReplyComponent({ form }) {
//   const [page, setPage] = useState(1);
//   const [visible, setVisible] = useState(false);
//   const [selectedLine, setSelectedLine] = useState(0);
//   const { data: classroomList } = useGetClassroomListQuery({
//     page,
//     stateType: 'ALL',
//     ordType: 'RECENT_DESC',
//     searchType: 'ALL',
//     target: '',
//     type: 'ALL',
//     size: 10,
//   });

//   return (
//     <div>
//       <CustomForm.Divider orientation="left">연결할 클래스룸 목록</CustomForm.Divider>
//       <CustomForm.List name="classRoomIds">
//         {(classRoomIds, { add, remove }) => (
//           <div>
//             {classRoomIds.map((id, index) => (
//               <CustomForm.GridRow gridColumns="1fr auto auto" key={index}>
//                 <CustomForm.InputNumber label="ID" name={[index]} />
//                 <CustomForm.Button
//                   onClick={() => {
//                     setSelectedLine(index);
//                     setVisible(true);
//                   }}
//                   htmlType="button"
//                   ghost
//                 >
//                   검색
//                 </CustomForm.Button>
//                 <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
//                   삭제
//                 </CustomForm.Button>
//               </CustomForm.GridRow>
//             ))}
//             <CustomForm.Button onClick={() => add()} htmlType="button">
//               추가
//             </CustomForm.Button>
//           </div>
//         )}
//       </CustomForm.List>
//       {classroomList && (
//         <CustomForm.TableSelectModal
//           columns={COLUMNS}
//           list={classroomList.content}
//           visible={visible}
//           onCancel={() => setVisible(false)}
//           onSelect={(record) => {
//             form.setFieldValue(['classRoomIds', selectedLine], record.id);
//             setVisible(false);
//           }}
//           page={page}
//           setPage={setPage}
//           totalElements={classroomList.totalElements}
//         />
//       )}
//     </div>
//   );
// }

function FAQComponent() {
  return (
    <div>
      <CustomForm.Divider orientation="left">연결할 클래스룸 목록</CustomForm.Divider>
      <CustomForm.List name={['content', 'question', 'qnas']}>
        {/* info 이름 변경 필요 */}
        {(question, { add, remove }) => (
          <div>
            {question.map((id, index) => (
              <CustomForm.GridRow gridColumns="1fr 1fr auto" key={index}>
                <CustomForm.Input
                  label="질문"
                  name={[index, 'question']}
                  rules={[{ required: true }]}
                />
                <CustomForm.Input
                  label="답변"
                  name={[index, 'answer']}
                  rules={[{ required: true }]}
                />
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  삭제
                </CustomForm.Button>
              </CustomForm.GridRow>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              추가
            </CustomForm.Button>
          </div>
        )}
      </CustomForm.List>
    </div>
  );
}

function ClassInfoComponent({ form }: { form: FormInstance }) {
  //클래스  인포 부분 반 정보 등등
  //ClassInfoComponent
  return (
    <div>
      <CustomForm.Divider orientation="left">클래스 정보</CustomForm.Divider>

      <CustomForm.List name={['content', 'classInfoSet', 'classInfos']}>
        {(classInfos, { add, remove }) => (
          <>
            <CustomForm.Button onClick={() => add()} htmlType="button">
              클래스 정보 추가
            </CustomForm.Button>
            <br />
            {classInfos.map((id, index) => (
              <>
                <CustomForm.Input
                  label="타이틀"
                  name={[index, 'title']}
                  rules={[{ required: true }]}
                />
                <CustomForm.Input
                  label="수업 기간"
                  name={[index, 'period']}
                  rules={[{ required: true }]}
                />
                <CustomForm.List name={[index, 'types']}>
                  {(types, { add, remove }) => (
                    <>
                      <br />
                      <CustomForm.Button onClick={() => add()} htmlType="button">
                        반 추가
                      </CustomForm.Button>

                      {types.map((id, index) => (
                        <>
                          <CustomForm.GridRow gridColumns="auto auto">
                            <CustomForm.Input
                              label="반 제목"
                              name={[index, 'title']}
                              rules={[{ required: true }]}
                            />
                            <CustomForm.Button
                              onClick={() => remove(index)}
                              htmlType="button"
                              danger
                            >
                              반 삭제
                            </CustomForm.Button>
                          </CustomForm.GridRow>
                          <CustomForm.List name={[index, 'options']}>
                            {/* //이 부분에서 옵션이 객체형태로 리스트화가 되는것이 아니라 string 으로 리스트가 되어야함. */}
                            {(options, { add, remove }) => (
                              <>
                                <br />
                                <CustomForm.Button onClick={() => add()} htmlType="button">
                                  반 옵션 추가
                                </CustomForm.Button>
                                {options.map((id, index) => (
                                  <>
                                    <CustomForm.GridRow gridColumns="auto auto">
                                      <CustomForm.Input
                                        label="반 옵션"
                                        name={[index]}
                                        rules={[{ required: true }]}
                                      />
                                      <CustomForm.Button
                                        onClick={() => remove(index)}
                                        htmlType="button"
                                        danger
                                      >
                                        반 옵션 삭제
                                      </CustomForm.Button>
                                    </CustomForm.GridRow>
                                  </>
                                ))}
                                <br />
                              </>
                            )}
                          </CustomForm.List>
                        </>
                      ))}
                      <br />
                    </>
                  )}
                </CustomForm.List>
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  클래스 정보 삭제
                </CustomForm.Button>
                <br />
                {/* </CustomForm.GridRow> */}
              </>
            ))}
            <br />
          </>
        )}
      </CustomForm.List>
    </div>
  );
}
//PriceComponent
function PriceComponent({ form }: { form: FormInstance }) {
  //여기가 가격 구성이 들어갈 수 잇는 것임.
  return (
    <div>
      <CustomForm.Divider orientation="left">상단 텍스트</CustomForm.Divider>
      <div style={{ color: 'red' }}>줄바꿈이 필요한 경우 {`<br />`}를 넣어주세요. </div>
      <div style={{ color: 'red' }}>
        강조가 필요한 경우 문장의 앞뒤로{`<em> ~~ </em>`}를 넣어주세요.
      </div>
      <br />
      <div style={{ color: 'red' }}>
        ex) {`<em>이런 내용을 배워요!</em><br/> 핵심만 쏙쏙 담은 알찬 구성`}를 넣어주세요.
      </div>
      <br />
      <div style={{ color: 'red' }}>할인 가격 배경 색상: 가격 티켓 섹션의 타이틀 색</div>
      <CustomForm.Input
        label="타이틀"
        name={['content', 'priceSet', 'title']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="서브 타이틀"
        name={['content', 'priceSet', 'description']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="할인 가격 배경 색상"
        name={['content', 'priceSet', 'discountBackgroundColor']}
        rules={[{ required: true }]}
      />
      <CustomForm.Divider orientation="left">중간 사진부 (슬라이드, 비율 w3:h2)</CustomForm.Divider>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.List name={['content', 'priceSet', 'contents']}>
          {(imageSlideSecond, { add, remove }) => (
            <>
              {imageSlideSecond?.map((id, index) => (
                <CustomForm.GridRow gridColumns="auto 1fr auto" key={index}>
                  <CustomForm.Upload name={[index, 'image']} rules={[{ required: true }]} isCard />
                  <div>
                    <CustomForm.Input
                      label="제목"
                      name={[index, 'title']}
                      rules={[{ required: true }]}
                    />
                    <CustomForm.Input label="부가설명(필요시)" name={[index, 'description']} />
                  </div>
                  <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                    삭제
                  </CustomForm.Button>
                </CustomForm.GridRow>
              ))}
              <CustomForm.Button onClick={() => add()} htmlType="button">
                추가
              </CustomForm.Button>
            </>
          )}
        </CustomForm.List>
      </CustomForm.GridRow>
      <CustomForm.Divider orientation="left">가격 섹션</CustomForm.Divider>
      <div style={{ color: 'red' }}>
        할인 마감 날 : 해당 할인이 끝나는 시점으로 설정해주세요. ex) 2023-11-17 23:59:59
      </div>
      <div style={{ color: 'red' }}>할인율 : [n%] 혹은 [n만원] 으로 입력해주세요</div>
      <div style={{ color: 'red' }}>
        정가, 할인 금액, 월 가격, 총 가격 : ,빼고 숫자로만 입력해주세요.
      </div>
      {/* <CustomForm.GridRow gridColumns=""> */}
      <CustomForm.List name={['content', 'priceSet', 'priceSections']}>
        {(imageSlideSecond, { add, remove }) => (
          <>
            {imageSlideSecond.map((id, index) => (
              <CustomForm.GridRow gridColumns="auto auto">
                <div>
                  <CustomForm.Input
                    label="할인 마감 날"
                    rules={[
                      {
                        required: true,
                        pattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
                        message: 'yyyy-mm-dd hh:mm:ss 형식으로 작성해주세요!',
                      },
                    ]}
                    name={[index, 'actionTime']}
                  />
                  <CustomForm.Input
                    label="할인 타이틀"
                    name={[index, 'discountTitle']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.Input
                    label="할인율"
                    name={[index, 'discountValue']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.InputNumber
                    label="정가"
                    name={[index, 'originPrice']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.InputNumber
                    label="할인 금액"
                    name={[index, 'discountTotalPrice']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.InputNumber
                    label="월 가격"
                    name={[index, 'monthPrice']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.InputNumber
                    label="총 가격"
                    name={[index, 'totalPrice']}
                    rules={[{ required: true }]}
                  />
                  <br />
                </div>
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  삭제
                </CustomForm.Button>
              </CustomForm.GridRow>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              추가
            </CustomForm.Button>
          </>
        )}
      </CustomForm.List>
      {/* </CustomForm.GridRow> */}

      {/* <CustomForm.Input label="할인 타이틀" name={['content', 'priceSet', 'discountTitle']} />
      <CustomForm.InputNumber label="할인율" name={['content', 'priceSet', 'discountRate']} />
      <CustomForm.InputNumber label="정가" name={['content', 'priceSet', 'originPrice']} />
      <CustomForm.InputNumber
        label="할인 금액"
        name={['content', 'priceSet', 'discountTotalPrice']}
      />
      <CustomForm.InputNumber label="월 가격" name={['content', 'priceSet', 'monthPrice']} />
      <CustomForm.InputNumber label="총 가격" name={['content', 'priceSet', 'totalPrice']} /> */}
    </div>
  );
}
//ClassSetComponent
function ClassSetComponent({ form }: { form: FormInstance }) {
  //여기가 알찬 구성이 들어갈 수 잇는 것임.
  return (
    <div>
      <CustomForm.Divider orientation="left">상단 텍스트</CustomForm.Divider>
      <div style={{ color: 'red' }}>줄바꿈이 필요한 경우 {`<br />`}를 넣어주세요. </div>
      <div style={{ color: 'red' }}>
        강조가 필요한 경우 문장의 앞뒤로{`<em> ~~ </em>`}를 넣어주세요.
      </div>
      <br />
      <div style={{ color: 'red' }}>
        ex) {`<em>이런 내용을 배워요!</em><br/> 핵심만 쏙쏙 담은 알찬 구성`}를 넣어주세요.
      </div>
      <CustomForm.Input
        label="타이틀"
        name={['content', 'classSet', 'title']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="서브 타이틀"
        name={['content', 'classSet', 'description']}
        rules={[{ required: true }]}
      />
      <CustomForm.Divider orientation="left">하단 사진부 </CustomForm.Divider>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.List name={['content', 'classSet', 'contents']}>
          {(contents, { add, remove }) => (
            <>
              {contents.map((id, index) => (
                <CustomForm.GridRow gridColumns="auto 1fr auto" key={index}>
                  <CustomForm.Upload name={[index, 'image']} rules={[{ required: true }]} isCard />
                  <div>
                    <CustomForm.InputNumber label="순서" name={[index, 'sequence']} disabled />
                    <CustomForm.Input
                      label="타이틀"
                      name={[index, 'title']}
                      rules={[{ required: true }]}
                    />
                    <CustomForm.Input
                      label="내용"
                      name={[index, 'description']}
                      rules={[{ required: true }]}
                    />
                  </div>
                  <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                    삭제
                  </CustomForm.Button>
                </CustomForm.GridRow>
              ))}
              <CustomForm.Button onClick={() => add()} htmlType="button">
                추가
              </CustomForm.Button>
            </>
          )}
        </CustomForm.List>
      </CustomForm.GridRow>
    </div>
  );
}

function TutorSetComponent({ form }: { form: FormInstance }) {
  return (
    <div>
      <CustomForm.Divider orientation="left">메인 튜터 섹션</CustomForm.Divider>
      <div style={{ color: 'red' }}>
        * 튜터 영상 url의 경우 aws에 업로드 된 public 상태의 링크를 첨부해주세요.
      </div>
      <CustomForm.Input
        label="타이틀"
        name={['content', 'tutorSet', 'mainTutor', 'title']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="설명"
        name={['content', 'tutorSet', 'mainTutor', 'description']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="튜터 영상 url"
        name={['content', 'tutorSet', 'mainTutor', 'video']}
        rules={[{ required: true }]}
      />{' '}
      <CustomForm.GridRow gridColumns="auto auto auto">
        <CustomForm.Divider orientation="left">
          상단 아이콘
          <CustomForm.Upload
            name={['content', 'tutorSet', 'mainTutor', 'icon']}
            rules={[{ required: true }]}
            isCard
          />
        </CustomForm.Divider>
        <CustomForm.Divider orientation="left">
          메인 튜터 영상 썸네일
          <CustomForm.Upload
            name={['content', 'tutorSet', 'mainTutor', 'thumbnail']}
            rules={[{ required: true }]}
            isCard
          />
        </CustomForm.Divider>
        <CustomForm.Divider orientation="left">
          메인 튜터 추가 사진(없어도 됨)
          <CustomForm.Upload name={['content', 'tutorSet', 'mainTutor', 'image']} isCard />
        </CustomForm.Divider>
      </CustomForm.GridRow>
      <div style={{ color: 'red' }}>
        * 경력은 한줄씩 입력해주세요. ex) 건국대학교 산업디자인과 졸업
      </div>
      <CustomForm.List name={['content', 'tutorSet', 'mainTutor', 'careers']}>
        {(careers, { add, remove }) => (
          <>
            {careers.map((id, index) => (
              <>
                <CustomForm.GridRow gridColumns="1fr auto">
                  <CustomForm.Input label="경력" name={[index]} />
                  <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                    삭제
                  </CustomForm.Button>
                </CustomForm.GridRow>
              </>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              경력 추가
            </CustomForm.Button>
          </>
        )}
      </CustomForm.List>
      <CustomForm.Divider orientation="left">튜터 섹션</CustomForm.Divider>{' '}
      <div style={{ color: 'red' }}>* 이름 컬러는 16진수로 입력해주세요. ex) #FFFFFF</div>
      <CustomForm.Input
        label="타이틀"
        name={['content', 'tutorSet', 'tutorIntroduce', 'title']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="설명"
        name={['content', 'tutorSet', 'tutorIntroduce', 'description']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="이름 컬러(#FFFFFF)"
        name={['content', 'tutorSet', 'tutorIntroduce', 'nameColor']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="타이틀 컬러(#FFFFFF)"
        name={['content', 'tutorSet', 'tutorIntroduce', 'titleColor']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="설명 컬러(#FFFFFF)"
        name={['content', 'tutorSet', 'tutorIntroduce', 'descriptionColor']}
        rules={[{ required: true }]}
      />
      <br />
      <CustomForm.List name={['content', 'tutorSet', 'tutorIntroduce', 'tutors']}>
        {(tutors, { add, remove }) => (
          <>
            {tutors.map((id, index) => (
              <>
                <CustomForm.Input label="이름" name={[index, 'name']} />
                <CustomForm.Input label="튜터 설명" name={[index, 'description']} />
                <CustomForm.GridRow gridColumns="auto auto">
                  <CustomForm.List name={[index, 'careers']}>
                    {(careers, { add, remove }) => (
                      <>
                        {careers.map((id, index) => (
                          <>
                            <CustomForm.GridRow gridColumns="1fr auto">
                              <CustomForm.Input label="경력" name={[index]} />
                              <CustomForm.Button
                                onClick={() => remove(index)}
                                htmlType="button"
                                danger
                              >
                                삭제
                              </CustomForm.Button>
                            </CustomForm.GridRow>
                          </>
                        ))}
                        <CustomForm.Button onClick={() => add()} htmlType="button">
                          경력 추가
                        </CustomForm.Button>
                      </>
                    )}
                  </CustomForm.List>
                </CustomForm.GridRow>
                <CustomForm.Upload name={[index, 'image']} isCard />
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  튜터 삭제
                </CustomForm.Button>
                <br />
              </>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              튜터 추가
            </CustomForm.Button>
          </>
        )}
      </CustomForm.List>
      <CustomForm.Divider orientation="left">미리보기 영상 섹션</CustomForm.Divider>
      <div style={{ color: 'red' }}>
        * 미리보기 영상 url의 경우 aws에 업로드 된 public 상태의 링크를 첨부해주세요.
      </div>
      <CustomForm.Input
        label="타이틀"
        name={['content', 'tutorSet', 'courseTutorPreview', 'title']}
        rules={[{ required: true }]}
      />
      <CustomForm.Input
        label="설명"
        name={['content', 'tutorSet', 'courseTutorPreview', 'description']}
        rules={[{ required: true }]}
      />
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input
          label="미리보기 영상 주차"
          name={['content', 'tutorSet', 'courseTutorPreview', 'week']}
          rules={[{ required: true }]}
        />{' '}
        <CustomForm.Input
          label="미리보기 영상 url"
          //여기를 바꿔야해.
          name={['content', 'tutorSet', 'courseTutorPreview', 'video']}
          rules={[{ required: true }]}
        />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input
          label="타이틀 컬러(#FFFFFF)"
          name={['content', 'tutorSet', 'courseTutorPreview', 'titleColor']}
          rules={[{ required: true }]}
        />
        <CustomForm.Input
          label="설명 컬러(#FFFFFF)"
          name={['content', 'tutorSet', 'courseTutorPreview', 'descriptionColor']}
          rules={[{ required: true }]}
        />
      </CustomForm.GridRow>
      {/* <CustomForm.Divider orientation="left"> */}
      <CustomForm.Divider orientation="left"> 수업 미리보기 영상 썸네일</CustomForm.Divider>
      <CustomForm.Upload
        name={['content', 'tutorSet', 'courseTutorPreview', 'thumbnail']}
        rules={[{ required: true }]}
        isCard
      />
      {/* </CustomForm.Divider> */}
    </div>
  );
}

function CurriculumComponent({ form }: { form: FormInstance }) {
  return (
    <div>
      <CustomForm.Divider orientation="left">커리큘럼 </CustomForm.Divider>
      <div>1.</div>
      <div style={{ color: 'red' }}>줄바꿈이 필요한 경우 {`<br />`}를 넣어주세요. </div>
      <div style={{ color: 'red' }}>
        강조가 필요한 경우 문장의 앞뒤로{`<em> ~~ </em>`}를 넣어주세요.
      </div>
      <br />
      <div style={{ color: 'red' }}>
        ex) {`<em>이런 내용을 배워요!</em><br/> 핵심만 쏙쏙 담은 알찬 구성`}를 넣어주세요.
      </div>
      <br />
      <div>2.</div>
      <div style={{ color: 'red' }}>
        빈 챕터 배경색: 커리큘럼의 n주차 부분의 기본 배경 색 입니다.
      </div>
      <div style={{ color: 'red' }}>
        호버 시 폰트 색: 커리큘럼의 n주차 부분의 마우스가 올라갔을때의 폰트 색 입니다.
      </div>
      <div style={{ color: 'red' }}>
        호버 시 챕터 배경 색: 커리큘럼의 n주차 부분의 마우스가 올라갔을때의 배경 색 입니다.
      </div>
      <CustomForm.Input
        name={['content', 'curriculum', 'title']}
        rules={[{ required: true }]}
        label="타이틀"
      />
      <CustomForm.Input
        name={['content', 'curriculum', 'description']}
        rules={[{ required: true }]}
        label="설명"
      />
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.Input
          name={['content', 'curriculum', 'backgroundColor']}
          label="빈 챕터 배경색"
          rules={[
            {
              pattern: /^#/,
              message: '#으로 시작하는 HEX코드로 넣어주세요. ex) #FFFFFF',
              required: true,
            },
          ]}
        />
        <CustomForm.Input
          name={['content', 'curriculum', 'hoverFontColor']}
          label="호버 시 폰트 색"
          rules={[
            {
              pattern: /^#/,
              message: '#으로 시작하는 HEX코드로 넣어주세요. ex) #FFFFFF',
              required: true,
            },
          ]}
        />
        <CustomForm.Input
          name={['content', 'curriculum', 'hoverBackgroundColor']}
          label="호버 시 챕터 배경 색"
          rules={[
            {
              pattern: /^#/,
              message: '#으로 시작하는 HEX코드로 넣어주세요. ex) #FFFFFF',
              required: true,
            },
          ]}
        />
      </CustomForm.GridRow>
      <br />
      <CustomForm.Divider orientation="left">커리큘럼 챕터 추가 </CustomForm.Divider>
      <div style={{ color: 'red' }}>
        커리큘럼 순서: 숫자로만 적어주세요 강의 순서: n강 / 강이라는 글자까지 적어주세요.
      </div>
      <CustomForm.List name={['content', 'curriculum', 'classRoomChapters']}>
        {(classRoomLectures, { add, remove }) => (
          <>
            {classRoomLectures.map((id, index) => (
              <>
                <CustomForm.GridRow gridColumns="auto auto">
                  <CustomForm.InputNumber
                    label="커리큘럼 순서"
                    name={[index, 'sequence']}
                    rules={[{ required: true }]}
                  />
                  <CustomForm.Input
                    label="커리큘럼 제목"
                    name={[index, 'title']}
                    rules={[{ required: true }]}
                  />
                  {/* <CustomForm.Input label="커리큘럼 설명" name={[index, 'description']} /> */}
                  <CustomForm.Upload
                    name={[index, 'thumbnail']}
                    rules={[{ required: true }]}
                    isCard
                  />
                </CustomForm.GridRow>
                <CustomForm.GridRow gridColumns="auto auto">
                  <CustomForm.List name={[index, 'classRoomLectures']}>
                    {(classRoomLectures, { add, remove }) => (
                      <>
                        {classRoomLectures.map((id, index) => (
                          <>
                            <CustomForm.GridRow gridColumns=" auto">
                              <CustomForm.Input
                                label="강의 순서"
                                name={[index, 'sequence']}
                                rules={[{ required: true }]}
                              />
                              <CustomForm.Input
                                label="강의 제목"
                                name={[index, 'title']}
                                rules={[{ required: true }]}
                              />
                              <CustomForm.Input
                                label="강의 설명"
                                name={[index, 'description']}
                                rules={[{ required: true }]}
                              />
                              <CustomForm.Button
                                onClick={() => remove(index)}
                                htmlType="button"
                                danger
                              >
                                삭제
                              </CustomForm.Button>
                            </CustomForm.GridRow>
                          </>
                        ))}
                        <CustomForm.Button onClick={() => add()} htmlType="button">
                          강의 추가
                        </CustomForm.Button>
                      </>
                    )}
                  </CustomForm.List>
                </CustomForm.GridRow>
                <br />
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  커리큘럼 챕터 삭제
                </CustomForm.Button>
                <br />
              </>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              커리큘럼 챕터 추가
            </CustomForm.Button>
          </>
        )}
      </CustomForm.List>
    </div>
  );
}

export default function CourseComponentDetail() {
  const { courseId, id } = useParams();
  const { data: componentData } = useGetCourseComponentQuery({
    courseId: Number(courseId),
    id: Number(id),
  });
  const { patchCourseComponent, deleteCourseComponent } = useCourseComponentMutation();
  const [form] = CustomForm.useForm();
  const [curriculumData, setCurriculumData] = useState(null);
  const handleCurriculumSave = (data) => {
    // Curriculum2Component에서 전달된 데이터를 curriculumData 상태로 설정
    setCurriculumData(data);
  };
  return (
    <S.Container>
      {componentData && (
        <CustomForm
          form={form}
          initialValues={componentData}
          onFinish={(curriData) => {
            patchCourseComponent(form.getFieldsValue(true));
          }}
        >
          <div style={{ marginBottom: '40px' }}>
            <h1>
              코스 컴포넌트 관리
              <S.DeleteButton type="button" onClick={() => deleteCourseComponent({ courseId, id })}>
                삭제
              </S.DeleteButton>
            </h1>
          </div>
          <CustomForm.Switch
            checkedChildren="비공개"
            unCheckedChildren="공개"
            name="isPrivate"
            label="비공개 여부"
          />
          <CustomForm.Switch
            checkedChildren="포함"
            unCheckedChildren="미포함"
            name="isPartOfHeader"
            label="헤더 포함 여부"
          />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input name="id" label="ID" disabled />
            <CustomForm.Input name="type" label="타입" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input name="createdAt" label="생성일" disabled />
            <CustomForm.Input name="updatedAt" label="최종 수정일" disabled />
          </CustomForm.GridRow>
          <CustomForm.Input name="title" label="제목" />
          {/* {componentData.type === 'EDITOR' && <EditorComponent form={form} />}
          {componentData.type === 'INTRODUCE' && <IntroduceComponent />}
          {componentData.type === 'REPLY' && <ReplyComponent form={form} />}
          {componentData.type === 'REVIEW' && <ReplyComponent form={form} />} */}
          {componentData.type === 'FAQ' && <FAQComponent />}
          {/* {componentData.type === 'MATERIALS' && <MaterialComponent form={form} />}
          {componentData.type === 'MENTO' && <>{/* Mentor는 추가적인 입력이 없어요 </>} */}
          {/* // {componentData.type === 'SUMMARY' && <SummaryComponent form={form} />}
          // {componentData.type === 'IMAGE_SLIDE' && <ImageSlideComponent />} */}
          {componentData.type === 'CURRICULUM2' && <CurriculumComponent form={form} />}
          {componentData.type === 'CLASS_INFO_SET' && <ClassInfoComponent form={form} />}
          {componentData.type === 'PRICE_SET' && <PriceComponent form={form} />}
          {componentData.type === 'CLASS_SET' && <ClassSetComponent form={form} />}
          {componentData.type === 'TUTOR_SET' && <TutorSetComponent form={form} />}
          {componentData.type === 'SPECIAL_SECTION_1' && <> 여기는 추가적인 입력이 없어요 </>}
          {componentData.type === 'SPECIAL_SECTION_2' && <> 여기는 추가적인 입력이 없어요 </>}
          {componentData.type === 'MATERIALS_2' && <> 여기는 추가적인 입력이 없어요 </>}
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
    </S.Container>
  );
}
