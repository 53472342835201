import axios from 'axios';

const TIMEOUT = 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_API_URL;

const setupAxiosInterceptors = () => {
  const onRequestSuccess = (config) => {
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.common.Authorization = 'Bearer ' + sessionStorage.getItem('accessToken');
    }
    return config;
  };
  const onResponseSuccess = (response) => response;

  //에러일 때
  const onResponseError = (err) => {
    const status = err.status || (err.response ? err.response.status : 0);
    if (status === 403 || status === 401) {
      const code = err.response.data.code;
      switch (code) {
        case 'FORGERY_TOKEN':
        case 'INVALID_ACCESS_TOKEN':
        case 'NOT_FOUND_TOKEN': // INVALID ACCESS TOKEN
          axios({
            method: 'POST',
            url: '/user/refresh?refreshToken=Bearer ' + localStorage.getItem('refreshToken'),
          })
            .then((response) => {
              sessionStorage.setItem('accessToken', response.data.jwtToken.accessToken);
              localStorage.setItem('refreshToken', response.data.jwtToken.refreshToken);
              const originalRequest = err.config; //
              axios(originalRequest);
              window.location.reload();
            })
            .catch((error) => {
              sessionStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');
              window.location.href = '/signin';
            });
          break;
        case 'INVALID_REFRESH_TOKEN': // INVALID REFRESH TOKEN
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = '/signin';
          break;
        case 36: // EMPTY ACCESS TOKEN
          break;
        default:
          console.error(err.response.data);
          break;
      }
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
