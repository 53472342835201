import CustomForm from 'components/CustomForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChapterAlarmDetailQuery } from 'store/services/alarm';
import * as S from '../../../../styles';
import DetailTabs from 'components/DetailTabs';
import useAlarmMutation from '../../hooks/useAlarmMutation';
import { useGetChapterListForAlarmQuery } from 'store/services/classroom';

const TAB_LIST = ['알림톡'];

function ClassroomAlarmDetail() {
  const navigate = useNavigate();
  const [form] = CustomForm.useForm();

  const { id } = useParams();
  const { data: alarmDetail } = useGetChapterAlarmDetailQuery(Number(id));
  //const { refetch: refetchChapterAlarmList } = useGetChapterListForAlarmQuery(Number(id));

  const { patchAlarmLiveAt } = useAlarmMutation();
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  return (
    <>
      <S.Container>
        {alarmDetail && (
          <>
            <S.DepthNavi>
              <S.NaviButton onClick={() => navigate(`/classroom/${alarmDetail.classRoomId}`)}>
                {alarmDetail.classRoom.title}
              </S.NaviButton>
              {alarmDetail.classRoomChapter && (
                <>
                  <span> / </span>
                  <S.CurrentPage>
                    {alarmDetail.classRoomChapter.sequence} [{alarmDetail.classRoomChapter.title}]
                  </S.CurrentPage>
                </>
              )}
            </S.DepthNavi>
            <h1>알림톡 정보</h1>
            <DetailTabs tabs={tabs} selected={currentTab} />
            <br />
            <br />
            <CustomForm
              form={form}
              initialValues={alarmDetail}
              onFinish={() => {
                patchAlarmLiveAt(Number(id), form.getFieldValue('alarmAt'));
              }} //이 부분 서버 에러인듯함.
            >
              <CustomForm.GridRow gridColumns="1fr 1fr 1fr ">
                <CustomForm.Input label="알림톡 ID" name="id" disabled />
                <CustomForm.Input label="클래스 룸 ID" name="classRoomId" disabled />
                <CustomForm.Input label="클래스 룸 챕터 ID" name="classRoomChapterId" disabled />
              </CustomForm.GridRow>
              <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
                <CustomForm.Input label="알림톡 타입" name="alarmType" disabled />
                <CustomForm.Input label="알림톡 발송 상태" name="isSend" disabled />
                <CustomForm.Input label="알림톡 생성일" name="updatedAt" disabled />
              </CustomForm.GridRow>
              <CustomForm.GridRow gridColumns="1fr 1fr">
                <CustomForm.Input label="라이브 날짜" name="liveAt" disabled />
                <CustomForm.Input label="최종 수정일" name="updatedAt" disabled />
              </CustomForm.GridRow>
              <CustomForm.Input
                label="알림톡 발송 일자"
                name="alarmAt"
                rules={[
                  {
                    pattern:
                      /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/,
                    message: 'YYYY-MM-DD 형식으로 작성해주세요!',
                  },
                  { required: true },
                ]}
              />
              <br />
              <br />
              <S.SaveWrap>
                <button type="submit">저장</button>
              </S.SaveWrap>
            </CustomForm>
          </>
        )}
      </S.Container>
    </>
  );
}

export default ClassroomAlarmDetail;
