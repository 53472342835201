import { Checkbox, Table, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../../../../styles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAddChapterMutation,
  useGetChapterListForAlarmQuery,
  useGetClassroomListQuery,
} from 'store/services/classroom';
import { ChapterList } from 'model/classroom.model';
import Input from 'antd/lib/input/Input';
import { useState } from 'react';
import useBooleanState from '../../hooks/useBooleanState';
import useClassroomDetailMutation from '../../hooks/useClassroomDetailMutation';
import { checkUserDate, deleteSecond } from 'utils/commonFunction';
import Search from 'antd/lib/input/Search';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import CustomForm from 'components/CustomForm';
import useCheckboxSearch from 'hooks/useCheckboxSearch';
import { MenuOutlined } from '@ant-design/icons';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import CustomRow from 'components/CustomTable/CustomRow';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useDragSort } from 'utils/Hook/useDragSort';

const CLASSROOM_COLUMNS: ColumnsType = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '30%',
  },
  {
    title: '클래스룸명',
    dataIndex: 'title',
    key: 'title',
    width: '70%',
  },
];

function Chapter() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: chapterList } = useGetChapterListForAlarmQuery(Number(id));

  const [addChapter] = useAddChapterMutation();
  const [liveAtList, setLiveAtList] = useState<string[]>([]); //라이브 시간

  const {
    patchChapterisPrivate,
    patchChapterLiveAt,
    postClassroomChapterDuplicate,
    patchClassroomChapterSort,
  } = useClassroomDetailMutation({
    classroomId: Number(id),
  });

  const [page, setPage] = useNumberSearchParams('page');
  const [messageApi, contextHolder] = message.useMessage();

  const {
    value: isLiveAtUpdating,
    setFalse: endLiveAtUpdate,
    setTrue: startLiveAtUpdate,
  } = useBooleanState(false);

  const { value: isPublic, setFalse: hideContent, setTrue: showContent } = useBooleanState(false);

  const {
    value: isDuplicate,
    setFalse: hideDuplicate,
    setTrue: showDuplicate,
  } = useBooleanState(false);

  const { value: isSort, setFalse: hideSort, setTrue: showSort } = useBooleanState(false);

  const [targets, setTargets] = useState<number[]>([]);
  const [visible, setVisible] = useState(false);

  const {
    isCheckedList: privateIsCheckedList,
    setIsCheckedList: privateSetIsCheckedList,
    isAllChecked: privateIsAllChecked,
    handleCheckboxChange: privateHandelCheckedboxChange,
    handleSelectAllChange: privateHandelSelectAllChange,
  } = useCheckboxSearch(chapterList);

  const {
    isCheckedList: chapterIsCheckedList,
    setIsCheckedList: chapterSetIsCheckedList,
    isAllChecked: chapterIsAllChecked,
    handleCheckboxChange: chapterHandelCheckedboxChange,
    handleSelectAllChange: chapterHandelSelectAllChange,
  } = useCheckboxSearch(chapterList);

  const { data: classroomList } = useGetClassroomListQuery({
    //api불러온 파트
    page,
    stateType: 'ALL',
    ordType: 'RECENT_DESC',
    searchType: 'ALL',
    target: '',
    type: '',
  });

  const checkMode = () => {
    if (isLiveAtUpdating) return '라이브 시간 수정';
    if (isPublic) return '공개 여부 수정';
    if (isDuplicate) return '챕터 복사';
    if (isSort) return '순서 수정';
    return '기본';
  };

  const mode = checkMode();

  const COLUMNS: ColumnsType<ChapterList> = [
    isSort
      ? {
          key: 'sort',
          width: '3%',
          render: () => <MenuOutlined style={{ cursor: 'move' }} />,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: '3%',
          render: (value, record) =>
            isPublic || isDuplicate ? (
              <Checkbox
                checked={
                  isPublic
                    ? privateIsCheckedList.includes(record.id)
                    : chapterIsCheckedList.includes(record.id)
                }
                onChange={(e) => {
                  isPublic
                    ? privateHandelCheckedboxChange(record.id)
                    : chapterHandelCheckedboxChange(record.id);
                }}
              />
            ) : (
              value
            ),
        },
    {
      title: '라벨',
      dataIndex: 'sequence',
      key: 'sequence',
      width: '5%',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
    },
    {
      title: '순서',
      dataIndex: 'ord',
      key: 'ord',
      width: '10%',
    },
    {
      title: '공개 여부',
      dataIndex: 'isPrivate',
      key: 'isPrivate',
      width: '10%',
      render: (value) => (value ? '비공개' : '공개'),
    },
    {
      title: '라이브 날짜 & 시간',
      dataIndex: 'liveAt',
      key: 'liveAt',
      width: '15%',
      render: (value: string, record) =>
        isLiveAtUpdating ? (
          <Input
            defaultValue={deleteSecond(value)}
            disabled={
              chapterList
                ? !!(
                    chapterList[record.ord]?.liveClassAlarm ||
                    chapterList[record.ord]?.progressCheckAlarm
                  )
                : false
            }
            onChange={(e) => {
              //이 부분을 함수로 빼는 것이 나아보임. 정리 필요.
              e.target.style.border = checkUserDate(e.target.value)
                ? '1px solid lightgray'
                : '1px solid red';
              const updateList = [...liveAtList];
              updateList[record.ord] = e.target.value;
              setLiveAtList(updateList);
              //console.log('herer', liveAtList);
            }}
          />
        ) : (
          deleteSecond(value)
        ),
    },
  ];
  const { dataSource, onDragEnd } = useDragSort(chapterList, COLUMNS);
  console.log('챕터 chapterList', chapterList);
  console.log('챕터 dataSource', dataSource);

  const handleLiveAtButton = () => {
    const updateChapters = chapterList! //근데 이게 onclick의 관심사는 아님 따라서 이 부분을 로직을 뺴야할듯해. 그 부분은 mutation에서 처리하는 것이 나아보임.
      .filter((content) => content.liveAt !== liveAtList[content.ord] || !liveAtList[content.ord]) //이전 값이랑 다르다?!
      .map((content) => {
        //return { classRoomChapterId: content.id, liveAt: liveAtList[content.ord] + ':00' }; //그걸 담아서 보내면 될 듯함.
        const liveAtValue = liveAtList[content.ord] ? liveAtList[content.ord] + ':00' : ''; // liveAtList[content.ord]가 비어있다면 빈 문자열을 사용
        return { classRoomChapterId: content.id, liveAt: liveAtValue };
      });
    console.log(updateChapters);
    patchChapterLiveAt(id!, updateChapters)
      .then((result) => {
        endLiveAtUpdate();
        if (result) messageApi.success('성공적으로 업데이트 되었습니다.');
      })
      .catch(() => {
        messageApi.error('입력을 다시 확인하시오.');
      });
  };

  const handelSortIds = () => {
    const chapterIds = dataSource.map((content) => content.id);
    patchClassroomChapterSort(chapterIds)
      .then((result) => {
        hideSort();
        if (result) messageApi.success('성공적으로 업데이트 되었습니다.');
      })
      .catch(() => {
        messageApi.error('입력을 다시 확인하시오.');
      });
  };

  return (
    <>
      <S.Container>
        {contextHolder}
        {chapterList && (
          <>
            <h1>
              챕터 목록TEST
              {mode === '기본' && (
                <>
                  <S.AddButton onClick={() => addChapter(id)}>+ 추가</S.AddButton>
                  <S.UpdateButton
                    onClick={() => {
                      showContent();
                      privateSetIsCheckedList([]);
                    }}
                  >
                    공개 여부 수정
                  </S.UpdateButton>
                  <S.UpdateButton
                    onClick={() => {
                      startLiveAtUpdate();
                      setLiveAtList(chapterList.map((content) => content.liveAt));
                    }}
                  >
                    라이브 시간 수정
                  </S.UpdateButton>
                  <S.DuplicateButton
                    onClick={() => {
                      showDuplicate();
                      chapterSetIsCheckedList([]);
                      setTargets([]);
                    }}
                  >
                    챕터 복사
                  </S.DuplicateButton>
                  <S.SortButton
                    onClick={() => {
                      showSort();
                    }}
                  >
                    챕터 순서 정렬
                  </S.SortButton>
                </>
              )}
              {mode === '라이브 시간 수정' && (
                <>
                  <S.AddButton onClick={handleLiveAtButton}>수정 정보 저장</S.AddButton>
                  <S.CancelButton onClick={endLiveAtUpdate}>수정 취소</S.CancelButton>
                </>
              )}
              {mode === '공개 여부 수정' && (
                <>
                  <S.AddButton
                    onClick={() => {
                      patchChapterisPrivate(id!, false, privateIsCheckedList)
                        .then(() => {
                          hideContent();
                          return true;
                        })
                        .then(() => {
                          messageApi.success('성공적으로 업데이트 되었습니다.');
                        })
                        .catch(() => {
                          messageApi.error('선택 값이 없습니다.');
                        });
                    }}
                  >
                    공개전환
                  </S.AddButton>
                  <S.AddButton
                    onClick={() => {
                      patchChapterisPrivate(id!, true, privateIsCheckedList)
                        .then(() => {
                          hideContent();
                          return true;
                        })
                        .then(() => {
                          messageApi.success('성공적으로 업데이트 되었습니다.');
                        })
                        .catch(() => {
                          messageApi.error('선택 값이 없습니다.');
                        });
                    }}
                  >
                    비공개전환
                  </S.AddButton>
                  <S.CancelButton onClick={hideContent}>수정 취소</S.CancelButton>
                </>
              )}
              {mode === '챕터 복사' && (
                <>
                  <Search
                    style={{ width: '350px', marginLeft: '100px', height: '32px' }}
                    placeholder="복사할 챕터를 선택하세요."
                    allowClear
                    enterButton="검색"
                    size="large"
                    value={targets.map((target) => target).join(', ')}
                    onSearch={(e) => {
                      setVisible(true);
                    }}
                    readOnly
                  />
                  <S.DuplicatePackageButton
                    onClick={() => {
                      postClassroomChapterDuplicate(chapterIsCheckedList, targets)
                        .then(() => {
                          hideDuplicate();
                          chapterSetIsCheckedList([]);
                        })
                        .catch((error) => {});
                    }}
                  >
                    복사
                  </S.DuplicatePackageButton>
                  <S.CancelButton onClick={hideDuplicate}>수정 취소</S.CancelButton>
                </>
              )}
              {mode === '순서 수정' && (
                <>
                  <S.SortButton onClick={handelSortIds}>정렬 완료</S.SortButton>
                  <S.CancelButton onClick={hideSort}>취소</S.CancelButton>
                </>
              )}
            </h1>

            <S.TableContainer>
              {(isPublic || isDuplicate) && (
                <Checkbox
                  checked={isPublic ? privateIsAllChecked : chapterIsAllChecked}
                  onChange={isPublic ? privateHandelSelectAllChange : chapterHandelSelectAllChange}
                >
                  전체선택
                </Checkbox>
              )}
              {dataSource && (
                <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
                  <SortableContext
                    items={dataSource.map((i) => i.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    <Table
                      components={{ body: { row: CustomRow } }}
                      dataSource={dataSource as any}
                      columns={COLUMNS}
                      pagination={false}
                      rowKey={(record) => record.id}
                      onRow={(record) => ({
                        onClick: (e) => {
                          if (mode !== '기본') return;
                          if (e.ctrlKey || e.metaKey) {
                            window.open(`/classroom/${id}/chapter/${record.id}`, '_blank');
                          } else navigate(`/classroom/${id}/chapter/${record.id}`);
                        },
                      })}
                    />
                  </SortableContext>
                </DndContext>
              )}
            </S.TableContainer>
            {classroomList && (
              <CustomForm.TableSelectModal
                columns={CLASSROOM_COLUMNS}
                list={classroomList.content}
                visible={visible}
                onCancel={() => setVisible(false)}
                onSelect={(record) => {
                  if (targets.includes(record.id)) {
                    setTargets(targets.filter((id) => id !== record.id));
                  } else {
                    setTargets([...targets, record.id]);
                  }
                }}
                page={page}
                setPage={setPage}
                totalElements={classroomList.totalElements}
              />
            )}
          </>
        )}
      </S.Container>
    </>
  );
}

export default Chapter;
