import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Table } from 'antd';
import {
  useDeleteCategoryMutation,
  useGetCategoryQuery,
  usePatchCategoryMutation,
  useGetChildCategoryQuery,
} from '../../../store/services/course';
import DetailTabs from '../../../components/DetailTabs';
import TextInput from '../../../components/form/TextInput';
import useModal from '../../../hooks/useModal';
import ChildCategoryAdd from '../InputModal/ChildCategoryAddModal';
import useValidCheck from '../../../hooks/useValidCheck';
import { CategoryListHeader, GetCategory } from 'model/category.model';
import { ColumnsType } from 'antd/es/table';

function CategoryDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: categoryData } = useGetCategoryQuery(`${id}`);
  const { data: childCategoryData } = useGetChildCategoryQuery(`${id}`);
  const [deleteCategory] = useDeleteCategoryMutation();
  const [patchCategory] = usePatchCategoryMutation();

  const MATCH_LABEL = {
    id: '카테고리 ID',
    name: '카테고리 이름',
    code: '코드',
  };

  const tabs = [
    {
      name: '기본 정보',
      onClick: () => setCurrentTab('기본 정보'),
    },
    {
      name: '하위 카테고리',
      onClick: () => setCurrentTab('하위 카테고리'),
    },
  ];

  const COLUMNS: ColumnsType<CategoryListHeader> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '카테고리 이름',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: '코드',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
    },
    {
      title: '하위 카테고리 수',
      dataIndex: 'numberOfChildren',
      key: 'numberOfChildren',
    },
    {
      title: '최종 수정 시간',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ];

  const [currentTab, setCurrentTab] = useState('기본 정보');
  useEffect(() => {
    setCurrentTab('기본 정보');
  }, [id]);

  const [formData, setFormData] = useState({
    id: 0,
    name: '',
    code: '',
  });

  useEffect(() => {
    if (categoryData) {
      setFormData({
        ...formData,
        id: categoryData.id,
        name: categoryData.categoryName,
        code: categoryData.categoryCode,
      });
    }
  }, [categoryData]);

  const handlePatchClick = () => {
    patchCategory(formData)
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
        console.log('fulfilled', payload);
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const handleDeleteClick = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      deleteCategory(`${id}`)
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다.');
          navigate(-1);
        })
        .catch((error) => {
          alert(error.data.errorMessage);
        });
    }
  };

  //모달
  const categoryAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(categoryAddRef);

  const { isValid, onChangeText } = useValidCheck(formData, setFormData);
  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <ChildCategoryAdd ref={categoryAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <S.DepthNavi>
          <S.CurrentPage>{categoryData?.categoryName}</S.CurrentPage>
        </S.DepthNavi>
        <h1>
          카테고리 수정
          <S.DeleteButton onClick={handleDeleteClick}>삭제</S.DeleteButton>
        </h1>
        <DetailTabs tabs={tabs} selected={currentTab} />
        {currentTab == '기본 정보' && (
          <>
            {Object.entries(MATCH_LABEL).map(([key, value]) => {
              return (
                <TextInput
                  key={key}
                  label={MATCH_LABEL[key]}
                  name={key}
                  value={formData[key]}
                  type={'text'}
                  onChange={onChangeText}
                ></TextInput>
              );
            })}
            <S.SaveWrap>
              <button disabled={!isValid} onClick={handlePatchClick}>
                저장
              </button>
            </S.SaveWrap>
          </>
        )}
        {currentTab == '하위 카테고리' && childCategoryData && (
          <>
            <S.ButtonWrap>
              <S.AddButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  open(true);
                }}
              >
                + 추가
              </S.AddButton>
            </S.ButtonWrap>
            <S.TableContainer>
              <Table
                dataSource={childCategoryData.content}
                columns={COLUMNS}
                pagination={{ pageSize: 20 }}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/course/category/${record.id}`, '_blank');
                      } else navigate(`/course/category/${record.id}`);
                    },
                  };
                }}
              ></Table>
            </S.TableContainer>
          </>
        )}
      </S.Container>
    </>
  );
}

export default CategoryDetail;
