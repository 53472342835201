import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilMenu } from '@coreui/icons';
import { ReactComponent as Logo } from '../assets/svg/logo-dark.svg';
import { setSideBarShow } from '../store/sideNav';
import { IStore, IUser } from 'model/store.model';
import styled from 'styled-components';

export const AdminProfile = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-right: 30px;
  justify-content: center;
  align-items: center;
`;

export const ProfileImage = styled.img.attrs({
  alt: 'profile Image',
})`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #e0e9f5;
`;

export const ProfileNickName = styled.span`
  font-weight: 600;
  font-size: 17px;
`;

const AppHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarShow = useSelector<IStore>((state) => state.sideNav.sidebarShow);
  const users = useSelector<IStore, IUser>((state) => state.auth);

  return (
    <CHeader position="sticky" className="mb-4" style={{ zIndex: 1009 }}>
      <CContainer fluid>
        <CHeaderToggler className="ps-1" onClick={() => dispatch(setSideBarShow(!sidebarShow))}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" onClick={() => navigate('/')}>
          <Logo />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto"></CHeaderNav>
        <CHeaderNav></CHeaderNav>
        <CHeaderNav className="ms-3"></CHeaderNav>
        <>
          {users && (
            <AdminProfile>
              <ProfileNickName>{users.admin?.name}</ProfileNickName>
              <ProfileImage src={users?.profileImageUrl} />
            </AdminProfile>
          )}
        </>
      </CContainer>
      <></>
    </CHeader>
  );
};

export default AppHeader;
