import AppHeader from '../components/AppHeader';
import SideNav from '../components/SideNav';
import { MainSection, AppContainer } from './AppLayout.style';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IStore, SideNavState } from 'model/store.model';
import 'antd/dist/antd.css';
import { useEffect } from 'react';
import { fetchUserById } from '../store/auth';
import { AppDispatch } from 'store/index';

function AppLayout() {
  const { sidebarShow, sidebarUnfoldable } = useSelector<IStore>(
    (state) => state.sideNav
  ) as SideNavState;
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchUserById());
  }, []);

  return (
    <>
      <SideNav />
      <AppContainer sidebarShow={sidebarShow} sidebarUnfoldable={sidebarUnfoldable}>
        <AppHeader />
        <MainSection>
          <Outlet />
        </MainSection>
      </AppContainer>
    </>
  );
}

export default AppLayout;
