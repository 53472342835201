import { useState } from 'react';

const useValidCheck = (formData, setFormData) => {
  const [isValid, setIsValid] = useState(false);

  const checkValid = (e, keyName?) => {
    let valid = true;
    keyName
      ? Object.values({
          ...formData,
          [keyName]: e.target?.value,
        }).forEach((val) => {
          if (val === '' || val === null) valid = false;
        })
      : Object.values({
          ...formData,
          [e.target?.name]: e.target?.value,
        }).forEach((val) => {
          if (val === '' || val === null) valid = false;
        });

    setIsValid(valid);
  };

  const onChangeText = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    checkValid(e);
  };

  const onChangeNumber = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: Number(e.target.value),
    });
    checkValid(e);
  };

  const onChangeThumbnail = (e, url, imgVariable) => {
    setFormData({
      ...formData,
      [imgVariable]: url,
    });
    checkValid(e);
  };

  const onChangeSearch = (e, form) => {
    setFormData(form);
    checkValid(e);
  };

  const onChangeSelect = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    checkValid(e);
  };

  const onChangeBoolean = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    });
    checkValid(e);
  };

  const onChangeAccountInfoInput = (e) => {
    setFormData({
      ...formData,
      refundReceiveAccount: {
        ...formData.refundReceiveAccount,
        [e.target.name]: e.target.value,
      },
    });
    checkValid(e);
  };

  const onChangeEditor = (e, editorRef, keyName) => {
    const editedValue = editorRef.current?.getInstance().getMarkdown();
    setFormData({
      ...formData,
      [keyName]: editedValue,
    });
    checkValid(e, keyName);
  };

  return {
    isValid,
    setIsValid,
    onChangeEditor,
    onChangeText,
    onChangeNumber,
    onChangeThumbnail,
    onChangeSearch,
    onChangeSelect,
    onChangeBoolean,
    onChangeAccountInfoInput,
  };
};

export default useValidCheck;
