import * as S from '../styles';
import { useGetCheckoutQuery } from 'store/services/checkout';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import CustomForm from 'components/CustomForm';
import useCheckoutMutation from './hooks/useCheckoutMutation';
import { RefundForm } from 'model/checkout.model';
import { useGetClassroomDataQuery } from 'store/services/classroom';

function CheckoutDetail() {
  const { id } = useParams();
  const { data: checkoutData } = useGetCheckoutQuery(Number(id));
  const { data: classroomData } = useGetClassroomDataQuery(
    {
      classRoomId: checkoutData?.classRoomId,
      userId: checkoutData?.userId,
    },
    { skip: checkoutData?.userId === undefined }
  );

  console.log('TEST', checkoutData);

  const { addRefundCheckout } = useCheckoutMutation();

  //const [classroomCheckData, setClassroomCheckData] = useState<Rate>();

  const [refundForm, setRefundForm] = useState<RefundForm>({
    //흐음...
    amount: null,
    reason: null,
  });

  const [form] = CustomForm.useForm();

  return (
    <S.Container>
      <S.DepthNavi>{checkoutData?.courseTitle}</S.DepthNavi>
      {checkoutData && classroomData && (
        <>
          <h1>주문 상세 정보</h1>
          <br />
          <CustomForm initialValues={checkoutData}>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="주문 ID" name="id" readOnly />
              <CustomForm.Input label="토스페이먼츠 ID" name="tossPaymentsId" readOnly />
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="구매자 닉네임" name="userNickname" readOnly />
              <CustomForm.Input label="구매자 휴대폰번호" name="userPhoneNumber" readOnly />
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.GridRow gridColumns="1fr auto">
                <CustomForm.Input label="유저 ID" name="userId" readOnly />
                <CustomForm.Button
                  onClick={() => window.open(`/user/${checkoutData.userId}`, '_blank')}
                  htmlType="button"
                  ghost
                >
                  이동
                </CustomForm.Button>
              </CustomForm.GridRow>
              <CustomForm.Input label="결제 일시" name="createdAt" readOnly />
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="코스명" name="courseTitle" readOnly />
              <CustomForm.GridRow gridColumns="1fr auto">
                <CustomForm.Input label="코스 ID" name="courseId" readOnly />
                <CustomForm.Button
                  onClick={() => window.open(`/course/${checkoutData.courseId}`, '_blank')}
                  htmlType="button"
                  ghost
                >
                  이동
                </CustomForm.Button>
              </CustomForm.GridRow>
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="클래스룸" name="classRoomTitle" readOnly />
              <CustomForm.GridRow gridColumns="1fr auto">
                <CustomForm.Input label="클래스룸 ID" name="classRoomId" readOnly />
                <CustomForm.Button
                  onClick={() => window.open(`/course/${checkoutData.classRoomId}`, '_blank')}
                  htmlType="button"
                  ghost
                >
                  이동
                </CustomForm.Button>
              </CustomForm.GridRow>
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="패키지명" name="packageTitle" readOnly />
              <CustomForm.GridRow gridColumns="1fr auto">
                <CustomForm.Input label="패키지명 ID" name="packageId" readOnly />
                <CustomForm.Button
                  onClick={() =>
                    window.open(
                      `/course/${checkoutData.courseId}/package/${checkoutData.packageId}`,
                      '_blank'
                    )
                  }
                  htmlType="button"
                  ghost
                >
                  이동
                </CustomForm.Button>
              </CustomForm.GridRow>
            </CustomForm.GridRow>
            <CustomForm.Input label="판매 가격" name="salesPrice" readOnly />
            <CustomForm.Input label="쿠폰 할인액" name="couponDiscountAmount" readOnly />
            <CustomForm.Input label="실 결제액" name="actualAmount" readOnly />
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="결제 상태" name="status" readOnly />
              <CustomForm.Input label="결제 방법" name="method" readOnly />
            </CustomForm.GridRow>
            <CustomForm.Input label="총 환불액" name="totalCancelAmount" readOnly />
          </CustomForm>
          <br />
          <h1>수업 진도</h1>
          <CustomForm initialValues={classroomData}>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="수강 시작일시" name="startDate" readOnly />
              <CustomForm.Input label="최근 수강일시" name="recentDate" readOnly />
            </CustomForm.GridRow>
            <CustomForm.GridRow gridColumns="1fr 1fr">
              <CustomForm.Input label="영상 진도율" name="vodRate" readOnly />
              <CustomForm.Input label="미션 진도율" name="missinRate" readOnly />
            </CustomForm.GridRow>
          </CustomForm>
        </>
      )}
      <br />
      <h1>환불</h1>
      <CustomForm
        form={form}
        initialValues={refundForm}
        onFinish={() => {
          addRefundCheckout(form.getFieldsValue(true));
        }}
      >
        <CustomForm.InputNumber label="환불 금액" name="amount" rules={[{ required: true }]} />
        <CustomForm.Input label="환불 사유" name="reason" rules={[{ required: true }]} />
        <br />
        <br />
        <S.SaveWrap>
          <button type="submit">환불 및 취소하기</button>
        </S.SaveWrap>
      </CustomForm>
    </S.Container>
  );
}

export default CheckoutDetail;
