import { UserMemoDetail } from 'model/user.model';
import { useNavigate } from 'react-router-dom';
import {
  useDeleteUserMemoMutation,
  usePatchUserMemoMutation,
  usePostUserMemoMutation,
} from 'store/services/user';

export default function useUserMemoMutation() {
  //const navigate = useNavigate();
  const [postUserMemoRequest] = usePostUserMemoMutation();
  const [deleteUserMemoRequest] = useDeleteUserMemoMutation();
  const [patchUserMemoRequest] = usePatchUserMemoMutation();
  const postUserMemo = (id: number) => {
    postUserMemoRequest(id)
      .unwrap()
      .then(() => {
        alert('새 메모가 추가되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deleteUserMemo = (id: number, memoId: number) => {
    if (window.confirm('선택하신 메모를 삭제하시겠습니까?'))
      deleteUserMemoRequest({ id, memoId })
        .unwrap()
        .then(() => {
          alert('메모가 삭제되었습니다.');
        })
        .catch((error) => alert(error.data.errorMessage));
  };

  const patchUserMemo = (form: Partial<UserMemoDetail>) => {
    const body: Partial<UserMemoDetail> = {
      memoId: form.memoId,
      consultationRoute: form.consultationRoute,
      content: form.content,
      cxHashtagIds: form.cxHashtagIds,
      transmissionType: form.transmissionType,
      id: form.userId,
      etc: form.etc,
    };
    patchUserMemoRequest(body)
      .unwrap()
      .then(() => {
        alert('메모가 수정되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };
  return { postUserMemo, deleteUserMemo, patchUserMemo };
}
