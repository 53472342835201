export const checkUserDate = (date: string) => {
  const dateReg = /^\s*$|^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/; ///^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/;
  return dateReg.test(date);
};
export const checkServerDate = (date: string) => {
  const dateReg = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return dateReg.test(date);
};
export const deleteSecond = (date: string) => {
  if (date) return date.slice(0, -3);
};
export const addSecond = (date: string) => {
  if (date) return date + ':00';
};

export const getNowDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};
