import CustomForm from 'components/CustomForm';
import * as S from 'pages/Product/styles';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetClassroomMentorQuery } from 'store/services/classroom';
import { useGetMentorListQuery } from 'store/services/mento';
import useClassroomDetailMutation from '../hooks/useClassroomDetailMutation';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '튜터 이름',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: '튜터 직업',
    dataIndex: 'displayJob',
    key: 'displayJob',
  },
];

function ClassroomMentor() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { data: classroomMentorData } = useGetClassroomMentorQuery(Number(id));
  const { data: mentorList } = useGetMentorListQuery({
    page: page,
    ordType: 'CREATED_AT_DESC',
    searchType: 'ALL',
    target: '',
    size: 10,
  });
  const [form] = CustomForm.useForm();
  const { patchClassroomMentor } = useClassroomDetailMutation({ classroomId: Number(id) });
  const [visible, setVisible] = useState<Boolean>(false);
  const [selectedLine, setSelectedLine] = useState<number>(0);

  return (
    <S.Container>
      {classroomMentorData && (
        <CustomForm form={form} initialValues={classroomMentorData} onFinish={patchClassroomMentor}>
          <CustomForm.List name="mentorList">
            {(courseMentorList, { add, remove }) => (
              <div>
                {courseMentorList.map((id, index) => (
                  <CustomForm.GridRow gridColumns="0.7fr 1fr 1fr auto auto" key={index}>
                    <CustomForm.InputNumber label="ID" name={[index, 'id']} />
                    <CustomForm.InputNumber label="이름" name={[index, 'name']} />
                    <CustomForm.InputNumber label="직업" name={[index, 'job']} />
                    <CustomForm.Button
                      onClick={() => {
                        setSelectedLine(index);
                        setVisible(true);
                      }}
                      htmlType="button"
                      ghost
                    >
                      검색
                    </CustomForm.Button>
                    <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                      삭제
                    </CustomForm.Button>
                  </CustomForm.GridRow>
                ))}
                <CustomForm.Button onClick={() => add()} htmlType="button">
                  추가
                </CustomForm.Button>
              </div>
            )}
          </CustomForm.List>
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
      {mentorList && (
        <CustomForm.TableSelectModal
          columns={COLUMNS}
          list={mentorList.content}
          visible={visible}
          onCancel={() => setVisible(false)}
          onSelect={(record) => {
            form.setFieldValue(['mentorList', selectedLine], {
              id: record.id,
              name: record.displayName,
              job: record.displayJob,
            });
            setVisible(false);
          }}
          page={page}
          setPage={setPage}
          totalElements={mentorList.totalElements}
        />
      )}
    </S.Container>
  );
}

export default ClassroomMentor;
