import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Checkout, CheckoutList, GetCheckout, GetChekoutList } from 'model/checkout.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const checkoutApi = createApi({
  reducerPath: 'checkout',
  tagTypes: ['Checkout', 'ClassroomData'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/checkout',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCheckoutList: builder.query({
      query: ({ page, ordType, searchType, target }) =>
        `/?page=${page - 1}&size=15&ordType=${ordType}&searchType=${searchType}&query=${target}`,
      providesTags: ['Checkout'],
      transformResponse: (response: CheckoutList): GetChekoutList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          const cancelAmount = tableData?.iamportResponse?.response?.cancelAmount;
          const actualAmount = tableData?.amount - cancelAmount;
          return {
            id: tableData.id,
            createdAt: tableData.createdAt,
            status: tableData.status,
            amount: tableData.amount,
            title: tableData.productPackage.course.title,
            userNickname: tableData.user.nickname,
            userPhoneNumber: tableData.user?.oauthUser?.phoneNumber,
            actualAmount: actualAmount ? actualAmount.toLocaleString() + '원' : '0원',
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    getCheckout: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Checkout', id }],
      transformResponse: (response: Checkout): GetCheckout => {
        const checkout = response;
        const cancelAmount = response?.iamportResponse?.response?.cancelAmount;

        const tableHeaderData = {
          id: checkout.id,
          tossPaymentsId: checkout.number,
          userNickname: checkout.user.nickname,
          userPhoneNumber: checkout.iamportResponse?.response?.buyerTel,
          createdAt: checkout.createdAt,
          courseTitle: checkout.productPackage.course.title,
          courseId: checkout.productPackage.course.id,
          packageId: checkout.productPackage.id,
          packageTitle: checkout.productPackage.title,
          salesPrice: checkout?.amount + (checkout?.couponDiscountAmount || 0),
          couponDiscountAmount: checkout.couponDiscountAmount,
          actualAmount: checkout.amount ? checkout.amount - cancelAmount : 0, //checkout?.amount - cancelAmount,
          status: checkout.status,
          method: checkout.method,
          totalCancelAmount: cancelAmount,
          classRoomTitle: checkout.productPackage.classRoom.title,
          classRoomId: checkout.productPackage.classRoom.id,
          userId: checkout.user.id,
        };
        return tableHeaderData;
      },
    }),

    addRefundCheckout: builder.mutation({
      query: ({ id, ...refundForm }) => ({
        url: `/${id}/refund`,
        method: 'POST',
        body: refundForm,
      }),
      invalidatesTags: ['Checkout'],
    }),
  }),
});

export const { useGetCheckoutListQuery, useGetCheckoutQuery, useAddRefundCheckoutMutation } =
  checkoutApi;
