import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';

export const useDragSort = (initialData, COLUMNS) => {
  const extractTableData = (dataList, columns) => {
    //여기가 그냥 테이블에 들어있는 데이터를 뽑아내는 부분
    return dataList.map((dataItem) => {
      let newObj = {};
      columns.forEach((col) => {
        if (col.dataIndex) {
          newObj[col.dataIndex] = dataItem[col.dataIndex];
        }
      });
      return newObj;
    });
  };

  useEffect(() => {
    if (initialData) {
      setDataSource(extractTableData(initialData, COLUMNS));
    }
  }, [initialData]);

  const [dataSource, setDataSource] = useState(initialData);

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const newDataSource = arrayMove(previous, activeIndex, overIndex);
        return [...newDataSource]; // 새로운 배열을 반환

        // return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  return { dataSource, onDragEnd };
};
