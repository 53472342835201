import { useGetPackageQuery } from 'store/services/package';
import { useNavigate, useParams } from 'react-router-dom';
import * as S from '../styles';
import { useEffect, useState } from 'react';
import DetailTabs from 'components/DetailTabs';
import { useGetCourseQuery } from 'store/services/course';
import PackageDiscount from './components/PackageDiscount';
import PackageInfo from './components/PackageInfo';
import usePackageInfoMutation from '../Package/hook/usePackageInfoMutation';

const TAB_LIST = ['기본 정보', '할인 정보'];

function PackageDetail() {
  const { id } = useParams();
  const { data } = useGetPackageQuery(Number(id));
  console.log('data', data);
  const { data: courseData } = useGetCourseQuery(Number(data?.courseId), {
    skip: !data,
  });

  const { deletePackage } = usePackageInfoMutation(Number(id));
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  const navigate = useNavigate();

  return (
    <S.Container>
      <S.DepthNavi>
        <S.NaviButton
          onClick={() => {
            navigate(`/course/${data?.courseId}`);
          }}
        >
          {courseData?.title}
        </S.NaviButton>
        <span> / </span>
        <S.CurrentPage>{data?.title}</S.CurrentPage>
      </S.DepthNavi>
      {data && (
        <>
          <h1>
            패키지 수정
            <S.DeleteButton onClick={deletePackage}>삭제</S.DeleteButton>
          </h1>
          <DetailTabs tabs={tabs} selected={currentTab} />
          {currentTab === TAB_LIST[0] && <PackageInfo />}
          {currentTab === TAB_LIST[1] && <PackageDiscount />}
        </>
      )}
    </S.Container>
  );
}

export default PackageDetail;
