import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useEffect, useState } from 'react';
import {
  useDeleteHashtagMutation,
  useGetHashtagDetailQuery,
  usePatchHashtagMutation,
} from '../../../store/services/hashtag';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';

const HashtagPatchModal = React.forwardRef<HTMLDivElement, any>(({ open, hashtagId }, ref) => {
  const MATCH_LABEL = [{ name: '해시태그 이름', variable: 'tag', type: 'text' }];

  const [patchHashtag] = usePatchHashtagMutation();
  const [deleteHashtag] = useDeleteHashtagMutation();
  const { data: hashtagData } = useGetHashtagDetailQuery(hashtagId);

  useEffect(() => {
    if (hashtagData) {
      setPatchHashtagForm({
        tag: '',
        ...hashtagData,
      });
    }
  }, [hashtagData]);

  // 해시태그 수정
  const [patchHashtagForm, setPatchHashtagForm] = useState({
    tag: '',
  });

  const onPatchHashtag = () => {
    patchHashtag({ patchHashtagForm, id: hashtagId }) //here
      .unwrap()
      .then((payload) => {
        open(false);
        alert('업데이트가 완료되었습니다');
        console.log('fulfilled', payload);
      })
      .catch((error) => {
        console.log(error.data.errorMessage);
      });
  };

  //해시태그 삭제
  const onDeleteHashtag = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      deleteHashtag(hashtagId);
      open(false);
    }
  };

  const { isValid, onChangeText } = useValidCheck(patchHashtagForm, setPatchHashtagForm);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>해시태그 수정</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={patchHashtagForm[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
              hasRemoveAction
              remove={onDeleteHashtag}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={onPatchHashtag} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default HashtagPatchModal;
