import { tags } from 'model/hashtag.model';
import {
  useAddCxHashtagMutation,
  useDeleteCxHashtagMutation,
  usePatchCxHashtagMutation,
} from 'store/services/hashtag';

export default function useCxHashtagMutation() {
  const [postCxHashtagRequest] = useAddCxHashtagMutation();
  const [deleteCxHashtagRequest] = useDeleteCxHashtagMutation();
  const [patchCxHashtagRequest] = usePatchCxHashtagMutation();
  const postCxHashtag = (form: Partial<tags>) => {
    const body: Partial<tags> = {
      tag: form.tag,
    };
    postCxHashtagRequest(body)
      .unwrap()
      .then(() => {
        alert('해시태그가 추가되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deleteCxHashtag = (id: number) => {
    if (window.confirm('선택하신 해시태그를 삭제하시겠습니까?')) {
      deleteCxHashtagRequest(id)
        .unwrap()
        .then(() => {
          alert('해시태그가 삭제되었습니다.');
        })
        .catch((error) => alert(error.data.errorMessage));
    }
  };
  const patchCxHashtag = (id: number, editTag: Partial<tags>) => {
    const body = editTag;
    patchCxHashtagRequest({ id: id, patchCxHashtagForm: body })
      .unwrap()
      .then(() => {
        alert('해시태그가 수정되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  return { postCxHashtag, deleteCxHashtag, patchCxHashtag };
}
