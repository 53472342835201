import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CouponDetail, CouponListRes, GetCoupon } from 'model/coupon.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const couponApi = createApi({
  reducerPath: 'coupon',
  tagTypes: ['Coupon', 'CouponCategory', 'CouponCategoryDetail'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/coupon',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCouponList: builder.query<
      GetCoupon,
      {
        page: number;
      }
    >({
      query: ({ page }) => `/admin?size=15&page=${page - 1}&ordType=RECENT`,
      providesTags: ['Coupon'],
      transformResponse: (response: CouponListRes): GetCoupon => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            name: tableData.name,
            discountType: tableData.discountType,
            discountAmount: tableData.discountAmount,
            issueStartAt: tableData.issueStartAt,
            issueEndAt: tableData.issueEndAt,
            usedAmount: tableData.usedAmount,
            issuedAmount: tableData.issuedAmount,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),
    getCoupon: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Coupon', id }],
      transformResponse: (response: any): CouponDetail => {
        return {
          ...response,
          collectionName: response.collection.name,
        };
      },
    }),

    patchCoupon: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Coupon', id },
        { type: 'CouponCategoryDetail' },
        { type: 'CouponCategory' }, //쿠폰 카테고리 불러오기
      ],
    }),

    deleteCoupon: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Coupon', id },
        { type: 'CouponCategoryDetail' },
      ],
    }),

    addCoupon: builder.mutation({
      query: ({ ...couponAddForm }) => ({
        url: '',
        method: 'POST',
        body: couponAddForm,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Coupon', id },
        { type: 'CouponCategoryDetail' },
      ],
    }),

    issueCoupon: builder.mutation({
      query: ({ id, userIds }) => ({
        url: `/${id}/issue`,
        method: 'POST',
        body: userIds,
      }),
      invalidatesTags: ['Coupon'],
    }),
    getCouponCategory: builder.query({
      query: (
        { rootId, isVisible, page, size } //쿠폰 카테고리 불러오기
      ) =>
        `/category?size=${size}&page=${
          page - 1
        }&isVisible=${isVisible}&rootCouponCategoryId=${rootId}`,
      providesTags: (result, error, rootId) => ['CouponCategory'],
    }),

    patchCouponCategory: builder.mutation({
      //쿠폰 카테고리 수정
      query: ({ id, ...patch }) => ({
        url: `/category/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CouponCategory' }],
      //providesTags: (result, error, rootId) => [{ type: 'CouponCategory', rootId }],
    }),
    deleteCouponCategory: builder.mutation({
      //쿠폰 카테고리 삭제
      query: (id) => ({
        url: `/category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CouponCategory' }],
    }),

    postCouponCategory: builder.mutation({
      //쿠폰 카테고리 생성
      query: ({ ...patch }) => ({
        url: `/category`,
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CouponCategory' }],
    }),

    getCouponCategoryDetail: builder.query({
      //하위 불러오나?
      query: (id) => `/category/${id}`,
      providesTags: (result, error, id) => [{ type: 'CouponCategoryDetail', id }],
    }),

    getCouponCategoryCoupons: builder.query({
      //쿠폰 조회
      query: ({ categoryId, isVisible, couponPage }) =>
        `/?size=5&page=${couponPage - 1}&isVisible=${isVisible}&couponCategoryId=${categoryId}`,
      //providesTags: (result, error, id) => [{ type: 'CouponCategoryDetail', id }],
      providesTags: (result, error, categoryId) => [{ type: 'CouponCategoryDetail', categoryId }],
    }),
  }),
});

export const {
  useGetCouponQuery,
  useGetCouponListQuery,
  useAddCouponMutation,
  usePatchCouponMutation,
  useDeleteCouponMutation,
  useIssueCouponMutation,
  useGetCouponCategoryQuery, //
  usePatchCouponCategoryMutation,
  usePostCouponCategoryMutation,
  useDeleteCouponCategoryMutation,
  useGetCouponCategoryDetailQuery,
  useGetCouponCategoryCouponsQuery,
} = couponApi;
