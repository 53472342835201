import styled from 'styled-components';
import { onNotLarge } from '../../styles/mediaQueries';
import { Checkbox, Switch } from 'antd';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h1 {
    justify-self: left;
    display: flex;
    align-items: center;
    font-size: 25px;
    width: 100%;
    ${onNotLarge} {
      /* width: 750px; */
      flex-wrap: wrap;
    }
  }
`;

export const TableContainer = styled.div`
  margin-top: 32px;
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  ${onNotLarge} {
    /* width: 800px; */
  }
  .ant-table tbody {
    cursor: pointer;
    tr {
      td {
        height: 50px;
      }
    }
  }
  .ant-table-cell {
    padding: 8px 16px 8px 16px;
  }
  .ant-table tr:nth-child(2n + 1) {
    background-color: #f3f3f3;
  }
  .ant-table tr.unSellable-row {
    //background-color: red;
    // border: 1px solid red;

    opacity: 0.3;
  }
  .ant-pagination {
    margin: 20px 20px 0 0;
    align-self: flex-end;
  }
  .ant-pagination-item-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /* .unsellable-row {
    background-color: red;
    //opacity: 0.5;
    //    background-color: #f3f3f3;
    
  } */
`;

export const StaticTableContainer = styled(TableContainer)`
  height: 410px;
`;

export const StyledSwitch = styled(Switch)`
  margin-left: 20px;
  width: 60px;
`;

export const Statistics = styled.div`
  padding-left: 20px;
  font-size: 25px;
  font-weight: 600;
`;
export const DepthNavi = styled.div`
  font-size: 14px;
  color: grey;
  margin: 0px 0px 15px 20px;
  /* padding-bottom: 5px; */
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: 16px;
`;
export const NaviButton = styled.button`
  border: none;
  background-color: white;
  color: grey;

  &:hover {
    color: black;
  }
`;

export const CurrentPage = styled.span`
  color: black;
`;

export const DetailButton = styled.button`
  width: 100px;
  height: 35px;
  background-color: white;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #000000;

  border-bottom: 3px solid #000000;

  &:hover {
    color: black;
    border-bottom: 3px solid;
  }
  &:focus {
    color: black;
    border-bottom: 3px solid;
  }
  &:active {
    color: black;
    border-bottom: 3px solid;
  }
`;

export const AddButton = styled.button`
  border-radius: 8px;
  background-color: #2b77f5;
  height: 32px;
  padding: 7px 12px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  margin: 0 0 0 15px;
  color: white;
  line-height: 18px;
`;

export const AddHashtagButton = styled(AddButton)`
  background-color: green;
`;
export const SortButton = styled(AddButton)`
  background-color: purple;
`;

export const DuplicatePackageButton = styled(AddButton)`
  background-color: green;
`;
export const CancelDuplicatePackageButton = styled(AddButton)`
  background-color: orangered;
`;
export const DeleteButton = styled.button`
  width: auto;
  height: 22px;
  background-color: #fc5e5e;
  border-radius: 8px;
  padding: 4px 8px;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 0 15px;
  margin-top: 15px;
  color: white;
`;
export const CopyButton = styled(DeleteButton)`
  background-color: green;
`;
export const EditButton = styled(DeleteButton)`
  background-color: #2b77f5;
  margin: 0 0 0 0;
`;
export const UpdateButton = styled(AddButton)`
  background-color: #2b77f5;
`;

export const CancelButton = styled(AddButton)`
  background-color: red;
`;

export const DuplicateButton = styled(AddButton)`
  background-color: green;
`;

export const CheckAllButton = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  //margin-left: 20px;
`;

export const ReserveButton = styled.button`
  width: 50px;
  height: 32px;
  padding: 7px 12px;
  background-color: #2b77f5;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  margin: 0 0 0 15px;
  color: white;
`;

export const AlramUpdateButton = styled(ReserveButton)`
  width: 100px;
  height: 32px;
  margin: 10px 22px 15px 0;
  font-size: 15px;
`;

export const CancelUpdateButton = styled(AlramUpdateButton)`
  width: 100px;
  height: 32px;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 15px;
  background-color: red;
`;
export const SaveButton = styled.button``;

export const ButtonWrap = styled.div`
  /* padding-bottom: 30px; */
  margin: 0px 16px 0px 16px;
`;

export const InputButtonWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 100px;
`;

export const SaveWrap = styled.div<{ modal: boolean; isNull: boolean }>`
  padding-bottom: 30px;
  margin: ${({ modal }) => (modal ? '0px 16px 0px 41px;' : '0px 16px 0px 16px;')};
  button {
    width: 100%;
    height: 50px;
    background: #2b77f5;
    border-radius: 8px;
    padding: 12px 7px;
    margin-top: 20px;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: white;
    &:disabled {
      background: gray;
      &:hover {
        background: gray;
        cursor: auto;
      }
    }
    &:hover {
      background-color: #0d67f9;
    }
  }
`;

export const SubEditButton = styled.button`
  width: auto;
  height: 22px;
  padding: 4px 8px;
  background: #d2e3ff;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => (props.state ? 'orangered' : '#2b77f5')};
`;

export const SubDeletedButton = styled(SubEditButton)`
  margin-left: 10px;
  color: orangered;
  background: #ffd9d9;
`;
export const SubEditButton2 = styled(SubEditButton)`
  width: auto;
  height: 22px;
  padding: 4px 8px;
  background: #d2e3ff;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${(props) => (props.state ? 'orangered' : '#2b77f5')};
`;

export const SubMoveDownArrow = styled.img`
  width: auto;
  height: 28px;
  padding: 4px 8px;
  transform: rotate(180deg);
`;
export const SubMoveUpArrow = styled.img`
  width: auto;
  height: 28px;
  padding: 4px 8px;
`;

export const SubDeleteButton = styled.button<{ modal: boolean }>`
  height: 22px;
  padding: 4px 8px;
  background: #ffd9d9;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  margin-top: ${({ modal }) => modal && '10px'};

  color: #ff0000;
`;

export const SubAddButton = styled.button`
  width: 37px;
  height: 22px;
  padding: 4px 8px;
  background: #2b77f5;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: white;
`;

export const SubExtraButton = styled.button`
  width: 37px;
  height: 22px;
  padding: 4px 8px;
  background: #000000;
  border-radius: 8px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: white;
`;

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

export const Section = styled.div<{ width: string }>`
  display: flex;
  width: ${({ width }) => (width === 'short' ? '540px' : '1080px')};
  height: 36px;
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
`;

export const SectionName = styled.div`
  width: 119px;
  background: #f3f3f3;
  border: solid #d9d9d9;
  border-width: 1px 1px 0px 0px;
  border-collapse: collapse;
  text-align: center;
`;

export const SectionValue = styled.div<{ width: string }>`
  width: ${({ width }) => (width === 'short' ? '421px' : '961px')};
  border: solid #d9d9d9;
  border-width: 1px 0px 0px 0px;
  border-collapse: collapse;
  padding-left: 11px;
`;

export const CheckoutTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1082px;
  margin: 24px 0 66px 20px;
`;

export const RefundTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 1082px;
  height: 67px;
  margin: 24px 0 82px 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  width: 500px;
  height: 45px;
  justify-content: left;
  ${onNotLarge} {
    width: 400px;
  }
  input {
    margin-top: 5px;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    width: 300px;
    height: 35px;
    /* padding: 15px; */
    padding-left: 30px;
    border-radius: 0 8px 8px 0;
    border: 1px solid #dad9d9;
    ${onNotLarge} {
      width: 190px;
    }
  }
`;

export const ThumbnailContainer = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
`;

export const SNSWrapper = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 2fr;
`;

export const MissionContainer = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  font-weight: 600;
  .ant-checkbox {
    margin-right: 15px;
  }
`;

export const Label = styled.label`
  font-size: 15px;
  /* font-weight: 600; */
`;
export const alarmStateSpan = styled.span`
  margin-right: 40px;

  //color: ${(props) => (props.state ? 'red' : 'blue')};
`;
