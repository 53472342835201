import { useGetCxHasgtagListQuery } from 'store/services/hashtag';
import * as S from '../styles';
import { Input, Pagination, Table } from 'antd';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import Search from 'antd/lib/input/Search';
import useCxHashtagMutation from './Hook/useCxHashtagMutation';
import { useState } from 'react';

function CxHashtagList() {
  const [page, setPage] = useNumberSearchParams('page');
  const [editHashtag, setEditHashtag] = useState<string>(''); //수정 여부
  const { postCxHashtag, deleteCxHashtag, patchCxHashtag } = useCxHashtagMutation();
  const { data: CxHashtagList } = useGetCxHasgtagListQuery({
    ordType: 'UPDATED_AT_DESC',
    page: 0,
  });

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '20%',
    },
    {
      title: 'HashTag',
      dataIndex: 'tag',
      key: 'tag',
      width: '50%',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Input
            defaultValue={value}
            onChange={(e) => {
              // console.log(e.target.value);
              setEditHashtag(e.target.value);
            }}
          />
        ) : (
          <span>{value}</span>
        );
        //해당 열의 tag만 input으로 바뀌었으면 해
        //수정 필요
      },
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (value, record) => {
        const isEditing = record.id === editingId;

        return isEditing ? (
          <>
            <S.SubEditButton
              onClick={() => {
                console.log(record);
                //저장하는 로직
                patchCxHashtag(record.id, { tag: editHashtag });
                setEditingId(null);
              }}
            >
              저장
            </S.SubEditButton>
            <S.SubDeletedButton
              onClick={() => {
                setEditingId(null);
              }}
            >
              취소
            </S.SubDeletedButton>
          </>
        ) : (
          <>
            <S.SubEditButton
              onClick={() => {
                console.log(record);
                setEditingId(record.id);
                setEditHashtag(record.tag);
              }}
            >
              수정
            </S.SubEditButton>
            <S.SubDeletedButton
              onClick={() => {
                console.log(record);
                deleteCxHashtag(record.id);
              }}
            >
              삭제
            </S.SubDeletedButton>
          </>
        );
      },
    },
  ];
  const [editingId, setEditingId] = useState<number | null>(null); //현재 수정중인 id

  return (
    <S.Container>
      <h1>
        CX 해시태그
        <Search
          style={{ width: '300px', marginLeft: '30px', borderRadius: '10px' }}
          placeholder="새 태그를 추가하세요."
          allowClear
          enterButton="추가"
          size="large"
          onSearch={(e) => {
            console.log(e);
            postCxHashtag({ tag: String(e) });
          }}
        />
      </h1>
      {CxHashtagList && (
        <>
          <S.TableContainer>
            <Table
              style={{ width: '100%' }}
              size={'small'}
              dataSource={CxHashtagList.content}
              columns={COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              // onRow={(record) => {
              //   return {
              //     onClick: (e) => {},
              //   };
              // }}
            />
            {CxHashtagList && (
              <Pagination
                pageSize={15}
                current={page}
                total={CxHashtagList.totalElements}
                onChange={setPage}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        </>
      )}
    </S.Container>
  );
}
export default CxHashtagList;
