import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteMentorMutation, usePatchMentorMutation } from 'store/services/mento';

export default function useMentorMutation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [patchMentor] = usePatchMentorMutation();
  const [deleteMentor] = useDeleteMentorMutation();

  const deleteMentorMutation = () => {
    try {
      if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
        deleteMentor(`${id}`);
        alert('삭제가 완료되었습니다.');
        navigate('/mentor');
      }
    } catch (e) {
      alert(e);
    }
  };

  const patchMentorMutation = (formData) => {
    patchMentor({ ...formData, id })
      .unwrap()
      .then(() => {
        alert('업데이트가 완료되었습니다');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  return { patchMentorMutation, deleteMentorMutation };
}
