import { GetCourse, ICollection, ICollectionRes } from 'model/store.model';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  CollectionCourseListResponse,
  CollectionListRes,
  ModalCourse,
} from 'model/collection.model';
import { CourseListRes } from 'model/course.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const collectionApi = createApi({
  reducerPath: 'collection',
  tagTypes: ['Collection', 'CollectionCourseList'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/collection',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCollectionList: builder.query<CollectionListRes, { page: number }>({
      query: ({ page }) => `/?size=15&page=${page - 1}&ordType=RECENT`,
      providesTags: ['Collection'],
    }),

    getCollection: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Collection', id }],
      transformResponse: (response: ICollectionRes): ICollection => {
        return {
          ...response,
          productPackageIds: response.collectionProductPackages?.map(
            (item) => item.productPackage.id
          ),
        };
      },
    }),

    patchCollection: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Collection'],
    }),

    deleteCollection: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Collection'],
    }),

    addCollection: builder.mutation({
      query: (addCollectionForm) => ({
        url: '/',
        method: 'POST',
        body: addCollectionForm,
      }),
      invalidatesTags: ['Collection'],
    }),

    getCollectionCourseList: builder.query({
      query: ({ id, type, page }) =>
        `/${id}/course/?size=15&page=${page - 1}&collectionFilter=${type}`,
      providesTags: (result, error, id) => [{ type: 'CollectionCourseList', id }], // 수정중.
      transformResponse: (response: any) => {
        return {
          ...response,
          content: response.content.map((item: any) => ({
            ...item,
            catagoryName: item.courseCategory.name,
          })),
        };
      },
    }),

    getCollectionCourseDetail: builder.query({
      query: ({ collectionId, courseId, type, page }) =>
        `/${collectionId}/course/${courseId}/package/?size=15&page=${
          page - 1
        }&collectionFilter=${type}`,
      providesTags: (result, error, id) => [{ type: 'CollectionCourseList', id }],
    }),
    deleteCollectionPackage: builder.mutation({
      query: ({ collectionId, idList }) => ({
        url: `/${collectionId}/package`,
        method: 'DELETE',
        body: idList,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CollectionCourseList', id }],
    }),
    patchCollectionPackage: builder.mutation({
      query: ({ collectionId, idList }) => ({
        url: `/${collectionId}/package`,
        method: 'PATCH',
        body: idList,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'CollectionCourseList', id }],
    }),
  }),
});

export const {
  useGetCollectionQuery,
  useGetCollectionListQuery, //
  useAddCollectionMutation,
  usePatchCollectionMutation,
  useDeleteCollectionMutation,
  useGetCollectionCourseListQuery,
  useGetCollectionCourseDetailQuery,
  useDeleteCollectionPackageMutation,
  usePatchCollectionPackageMutation,
} = collectionApi;
