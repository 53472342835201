import * as S from './InputModal.style';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import CustomForm from 'components/CustomForm';
import { useGetCouponCategoryQuery, usePostCouponCategoryMutation } from 'store/services/coupon';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { useGetBannerCategoryQuery, usePostBannerCategoryMutation } from 'store/services/banner';
import { useParams } from 'react-router-dom';
import { useGetPackageCategoryQuery, usePostPackageCategoryMutation } from 'store/services/package';

const COLLECTION_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
  {
    title: '하위 패키지 수',
    dataIndex: 'numberOfProductPackages',
    key: 'numberOfProductPackages',
  },
];

interface EditData {
  courseId: number;
  name: string;
  code: string;
  rootId: number | null;
  productPackageCategoryIdName?: string;
  groupName: string;
  saleStartAt: string;
  saleEndAt: string;
  isPublic: boolean;
}

const PackageCategoryAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  // const [categoryPage, setCategoryPage] = useNumberSearchParams('categoryPage');
  const [categoryModalPage, setCategoryModalPage] = useNumberSearchParams('categoryModalPage');
  const [patchPackageCategory] = usePostPackageCategoryMutation();
  const { id } = useParams();
  const { data: packageCategory } = useGetPackageCategoryQuery({
    //이게 카테고리 목록 불러온 것으로 진행.
    courseId: Number(id),
    size: 5,
    page: categoryModalPage,
    rootProductPackageCategoryId: 0,
    isPublic: true,
  });

  const handleFinishValue = (values: any) => {
    console.log('values', values);
    const { productPackageCategoryIdName, ...finalValues } = values;
    console.log('finalValues', finalValues);
    AddPackageCategory(finalValues);
  };

  const AddPackageCategory = (value) => {
    console.log('value', value);
    patchPackageCategory(value)
      .unwrap()
      .then(() => {
        alert('카테고리가 생성되었습니다.');
        open(false);
      })
      .catch((error) => {
        alert('입력하신 정보를 확인해주세요');
        console.log(error.data.message);
      });
  };

  const [form] = CustomForm.useForm<EditData>();
  const [categoryVisible, setCategoryVisible] = useState(false);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>패키지 카테고리 생성</S.Title>
      <S.InputList2 style={{ height: 450 }}>
        <CustomForm
          form={form}
          initialValues={{ courseId: Number(id) }}
          onFinish={() => handleFinishValue(form.getFieldsValue(true))}
        >
          <CustomForm.Input label="카테고리 제목" name="name" rules={[{ required: true }]} />
          <CustomForm.Input label="카테고리 코드" name="code" rules={[{ required: true }]} />
          <CustomForm.Input
            label="하위 카테고리 섹션 제목"
            name="groupName"
            rules={[{ required: true }]}
          />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.Input label="상위 카테고리 ID" name="rootId" />
              <CustomForm.Button onClick={() => setCategoryVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.Input
              label="상위 카테고리 제목"
              name="productPackageCategoryIdName"
              disabled
            />
          </CustomForm.GridRow>
          <CustomForm.Input
            label="판매 시작 일시"
            name="saleStartAt"
            rules={[
              { required: true },
              {
                pattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
                message: 'yyyy-mm-dd hh:mm:ss 형식으로 작성해주세요!',
              },
            ]}
          />
          <CustomForm.Input
            label="판매 종료 일시"
            name="saleEndAt"
            rules={[
              { required: true },
              {
                pattern: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/,
                message: 'yyyy-mm-dd hh:mm:ss 형식으로 작성해주세요!',
              },
            ]}
          />
          <CustomForm.Switch
            label="공개 여부"
            name="isPublic"
            checkedChildren="공개"
            unCheckedChildren="비공개"
          />
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
          {packageCategory && (
            <CustomForm.TableSelectModal
              columns={COLLECTION_COLUMNS}
              list={packageCategory.content}
              visible={categoryVisible}
              onCancel={() => setCategoryVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ rootId: record.id }); //그냥 표시
                form.setFieldsValue({ productPackageCategoryIdName: record.name }); //그냥 표시
                setCategoryVisible(false);
              }}
              size={5}
              page={categoryModalPage}
              setPage={setCategoryModalPage}
              totalElements={packageCategory.totalElements}
            />
          )}
        </CustomForm>
      </S.InputList2>
    </S.Container>
  );
});

export default PackageCategoryAddModal;

//추후 가능하다면modal 관련 팝업 컴포넌트도 하나로 다 뭉치면 좋을듯 하나의 컴포넌트에서 값을 뿌리는 방향은 어려울라나...
