import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetCouponQuery, useDeleteCouponMutation } from '../../../store/services/coupon';
import DetailTabs from '../../../components/DetailTabs';
import CouponInfo from './CouponInfo';
import CouponIssue from './CouponIssue';

function CouponDetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data } = useGetCouponQuery(`${id}`);
  const [deleteCoupon] = useDeleteCouponMutation();

  const TAB_LIST = ['쿠폰 정보', '쿠폰 발급'];
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  const handleDeleteClick = () => {
    deleteCoupon(`${id}`)
      .unwrap()
      .then((payload) => {
        if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
          alert('삭제가 완료되었습니다.');
          navigate('/coupon');
          console.log('fulfilled', payload);
        }
      })
      .catch((error) => {
        alert(error.data.errorMessage);
      });
  };
  return (
    <S.Container>
      <S.DepthNavi>
        <S.CurrentPage>{data?.name}</S.CurrentPage>
      </S.DepthNavi>
      <h1 style={{ paddingBottom: '35px' }}>
        쿠폰 수정
        <S.DeleteButton onClick={handleDeleteClick}>삭제</S.DeleteButton>
      </h1>
      <DetailTabs tabs={tabs} selected={currentTab} />
      {currentTab === TAB_LIST[0] && <CouponInfo />}
      {currentTab === TAB_LIST[1] && <CouponIssue />}
    </S.Container>
  );
}

export default CouponDetail;
