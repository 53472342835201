import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import { useAddChildCategoryMutation, useGetCategoryQuery } from '../../../store/services/course';
import { useParams } from 'react-router-dom';
import useValidCheck from '../../../hooks/useValidCheck';

const ChildCategoryAdd = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const { id } = useParams();
  const { data } = useGetCategoryQuery(`${id}`);
  const [addChildCategory] = useAddChildCategoryMutation();
  const [childForm, setChildForm] = useState({});

  const MATCH_LABEL = [
    { name: '하위 카테고리 이름', variable: 'name', type: 'text' },
    { name: '코드', variable: 'code', type: 'number' },
  ];

  useEffect(() => {
    if (data) {
      setChildForm({
        code: '',
        name: '',
        rootId: id,
      });
    }
  }, [data]);

  const handleAddClick = () => {
    addChildCategory(childForm);
    open(false);
  };

  const { isValid, onChangeText } = useValidCheck(childForm, setChildForm);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>하위 카테고리 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={childForm[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={handleAddClick} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default ChildCategoryAdd;
