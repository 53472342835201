import useNumberSearchParams from 'hooks/useNumberSearchParams';
import * as S from '../styles';
import {
  useGetCouponCategoryDetailQuery,
  useGetCouponCategoryQuery,
  useGetCouponCategoryCouponsQuery,
  usePatchCouponCategoryMutation,
  useDeleteCouponCategoryMutation,
} from 'store/services/coupon';
import { Pagination, Select, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal';
import useFileSearchParams from 'hooks/useFileSearchParams';
import useBooleanSearchParams from 'hooks/useBooleanSearchParams';
import { useRef, useState } from 'react';
import CouponCategoryAddModal from '../InputModal/CouponCategoryAddModal';
interface EditData {
  name: string;
  code: string;
  isVisible: boolean | null;
  rootId: number | null;
}
const COUPON_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '쿠폰 이름',
    dataIndex: 'name',
    key: 'name',
  },
  { title: '할인 종류', dataIndex: 'discountType', key: 'discountType' },
  {
    title: '할인 가격(율)',
    dataIndex: 'discount',
    key: 'discount',
    render: (text, record) => {
      return record.discountType === 'AMOUNT' ? record.discountAmount : record.discountRate;
    },
  },
  { title: '적용 시작 기간', dataIndex: 'issueStartAt', key: 'issueStartAt' },
  { title: '적용 종료 기간', dataIndex: 'issueEndAt', key: 'issueEndAt' },
];

function NewCouponList() {
  const navigate = useNavigate();
  const [page, setPage] = useNumberSearchParams('page');
  const [couponPage, setCouponPage] = useNumberSearchParams('couponPage');
  const [parentId, setParentId] = useFileSearchParams('parentId');
  const [isPublic, setIsPublic] = useBooleanSearchParams('public');
  const [isCouponPublic, setIsCouponPublic] = useBooleanSearchParams('couponPublic');
  const { data: couponCategory } = useGetCouponCategoryQuery({
    size: 5,
    page,
    isVisible: isPublic,
    rootId: parentId,
  });
  const { data: couponDetailData } = useGetCouponCategoryDetailQuery(parentId, {
    skip: !parentId,
  });

  const { data: couponCategoryCoupons } = useGetCouponCategoryCouponsQuery({
    couponPage,
    isVisible: isCouponPublic,
    categoryId: parentId,
  });

  const [editData, setEditData] = useState<EditData>({
    name: '',
    code: '',
    isVisible: null,
    rootId: null,
  });
  const [addCouponCategory] = usePatchCouponCategoryMutation();
  const [deleteCouponCategory] = useDeleteCouponCategoryMutation();
  const [editingId, setEditingId] = useState<number | null>(null); //현재 수정중인 id

  const editCategory = (id: number) => {
    setEditingId(id); // 편집 모드로 변경//현재 편집중인 열의 id를 저장
    setEditData({
      //그 id에 해당하는 데이터를 저장
      ...editData,
    });
  };
  const handleInputChange = (fieldName, value) => {
    //수정을 다루는 함수
    setEditData((prevEditData) => ({
      ...prevEditData,
      [fieldName]: value, // 필드 이름에 해당하는 상태만 업데이트
    }));
  };

  const saveCategory = (id: number) => {
    setEditingId(null); // 저장 후 편집 모드 종료
    console.log(editData);
    if (editData.rootId === null || editData.rootId === 0) {
      editData.rootId = null;
    }
    addCouponCategory({ ...editData, id })
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
      })
      .catch((error) => alert(error.data.message)); //여기가 patch

    setEditData({
      name: '',
      code: '',
      isVisible: null,
      rootId: null,
    });
  };

  const COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '카테고리 이름',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                name: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.name}</span>
        );
      },
    },

    {
      title: '카테고리 코드',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                code: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.code}</span>
        );
      },
    },
    {
      title: '공개 상태',
      dataIndex: 'isVisible',
      key: 'isVisible',
      render: (text, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Select
            defaultValue={text ? '공개' : '비공개'}
            style={{ width: 120 }}
            bordered={false}
            options={[
              { value: 'true', label: '공개' },
              { value: 'false', label: '비공개' },
            ]}
            onChange={(e) => {
              handleInputChange('isVisible', e);
            }}
          />
        ) : (
          <span>{record.isVisible ? '공개' : '비공개'}</span>
        );
      },
    },
    editingId
      ? {
          title: '상위 카테고리 ID',
          dataIndex: 'rootId',
          key: parentId,
          render: (text, record) => {
            const isEditing = record.id === editingId;
            return isEditing ? (
              <input
                defaultValue={parentId}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    rootId: Number(e.target.value),
                  });
                }}
              />
            ) : (
              <span>{parentId}</span>
            );
          },
        }
      : { title: '하위 카테고리 수', dataIndex: 'numberOfChildren', key: 'numberOfChildren' },
    { title: '포함 쿠폰 수', dataIndex: 'numberOfCoupons', key: 'numberOfCoupons' },
    {
      title: '편집',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <span>
            <S.EditButton
              onClick={(e) => {
                e.stopPropagation();
                console.log(record.id);
                console.log(editData);
                saveCategory(record.id);
              }}
            >
              저장
            </S.EditButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                setEditingId(null);
              }}
            >
              취소
            </S.DeleteButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation(); // 이벤트 버블링을 중단합니다.
                console.log(record);
                if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
                  deleteCouponCategory(record.id)
                    .unwrap()
                    .then((payload) => {
                      alert('삭제가 완료되었습니다');
                    })
                    .catch((error) => alert(error.data.message));
                }
                setEditingId(null);
              }}
            >
              삭제
            </S.DeleteButton>
          </span>
        ) : (
          <S.EditButton
            disabled={editingId !== null}
            onClick={(e) => {
              e.stopPropagation(); // 이벤트 버블링을 중단합니다.
              //편집을 눌렀을떄 해당 record를 가지고 셋을 다시 해
              editCategory(record.id);
              setEditData({
                ...editData,
                name: record.name,
                code: record.code,
                isVisible: record.isVisible,
                rootId: Number(parentId),
              });
            }}
          >
            편집
          </S.EditButton>
        );
      },
    },
  ];

  //모달
  const couponCategoryAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(couponCategoryAddRef, true);

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <CouponCategoryAddModal ref={couponCategoryAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          쿠폰 카테고리 조회
          <S.AddButton
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 카테고리 추가
          </S.AddButton>
        </h1>
      </S.Container>
      <S.StyledSwitch
        checkedChildren="공개"
        unCheckedChildren="비공개"
        checked={isPublic}
        onChange={setIsPublic}
      />
      {couponCategory && (
        <S.StaticTableContainer>
          <h4>{parentId && couponDetailData?.name}</h4>
          <Table
            dataSource={couponCategory.content.map((content) => {
              return {
                ...content,

                tags: ['수정'],
              };
            })}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: async (e) => {
                  if (editingId) return;

                  console.log(record.id);
                  setParentId(record.id);
                  //if (record.numberOfChildren) setParentId2(record.id);
                },
              };
            }}
          />
          {couponCategory && (
            <Pagination
              pageSize={5}
              current={page}
              total={couponCategory.totalElements}
              onChange={setPage}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </S.StaticTableContainer>
      )}
      <S.Container>
        <h1>
          쿠폰 조회 <S.AddButton onClick={() => navigate('/newaddcoupon')}>+ 쿠폰 추가</S.AddButton>
        </h1>
      </S.Container>
      <S.StyledSwitch
        checkedChildren="공개"
        unCheckedChildren="비공개"
        checked={isCouponPublic}
        onChange={setIsCouponPublic}
      />
      {couponCategoryCoupons && (
        <S.TableContainer>
          <Table
            dataSource={couponCategoryCoupons.content.map((content) => {
              const newContent = JSON.parse(JSON.stringify(content));
              return { ...newContent, tags: ['수정'] };
            })}
            columns={COUPON_COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  //console.log(record.id);
                  // onClick: (e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/coupon/${record.id}`, '_blank');
                  } else navigate(`/coupon/${record.id}`);
                },
              };
            }}
          />
          {couponCategoryCoupons && (
            <Pagination
              pageSize={5}
              current={couponPage}
              total={couponCategoryCoupons.totalElements}
              onChange={setCouponPage}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </S.TableContainer>
      )}
    </>
  );
}

export default NewCouponList;
