import './App.scss';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AppLayout from './pages/AppLayout';
import MainPage from './pages/MainPage/MainPage';
import SigninPage from './pages/SigninPage/SigninPage';
import Course from './pages/Product/Course/CourseList/Course';
import PackageDetail from './pages/Product/Package/PackageDetail';
import CourseDetail from './pages/Product/Course/CourseDetail';
import Classroom from './pages/Product/Classroom/Classroom';
import ClassroomDetail from './pages/Product/Classroom/ClassroomDetail/index';
import PackageDiscount from './pages/Product/Package/components/PackageDiscount';
import PackageDiscountDetail from './pages/Product/Package/components/PackageDiscountDetail';
import MentorList from './pages/Product/Mentor/MentorList';
import MentorDetail from './pages/Product/Mentor/MentorDetail';
//import CouponList from './pages/Product/Coupon/CouponList';
import CourseCategory from './pages/Product/Course/CourseCategory';
import Hashtag from './pages/Product/Course/Hashtag';
//import Collection from './pages/Product/Coupon/Collection';
//import CollectionDetail from './pages/Product/Coupon/CollectionDetail';
//import CouponDetail from './pages/Product/Coupon/CouponDetail';
import CategoryDetail from './pages/Product/Course/CourseCategoryDetail';
import MarkdownEditor from './components/form/MarkdownEditor';
import FileDetail from './pages/Product/Classroom/FileDetail';
import Checkout from './pages/Product/Checkout/Checkout';
import CheckoutDetail from './pages/Product/Checkout/CheckoutDetail';
import SpecificReplyList from 'pages/Product/SpecificReply/SpecificReplyList';
import UserList from 'pages/Product/User/UserList';
import UserDetail from 'pages/Product/User/UserDetail';
import CourseComponentDetail from 'pages/Product/Course/CourseDetail/components/CourseComponentDetail';
import ChapterDetail from 'pages/Product/Classroom/ClassroomDetail/components/Chapter/ChapterDetail';
import LectureDetail from 'pages/Product/Classroom/ClassroomDetail/components/Lecture/LectureDetail';
import AlarmList from 'pages/Product/Alarm/AlarmList';
import ClassroomAlarmDetail from 'pages/Product/Classroom/ClassroomDetail/components/ClassroomAlarm/ClassroomAlarmDetail';
import MemoList from 'pages/Product/User/MemoList';
import CxHashtagList from 'pages/Product/User/CxHashtagList';
import NewCollection from 'pages/Product/Coupon/NewCollection';
import NewCollectionDetail from 'pages/Product/Coupon/NewCollectionDetail';
import CollectionCourseDetail from 'pages/Product/Coupon/CollectionCourseDetail';
import NewCouponList from 'pages/Product/Coupon/NewCouponList';
import NewCouponDetail from 'pages/Product/Coupon/NewCouponDetail';
import CouponAddNew from 'pages/Product/Coupon/CouponAddNew';
import BannerList from 'pages/Product/Banner/BannerList';
import BannerAddNew from 'pages/Product/Banner/BannerAddNew';
import BannerDetail from 'pages/Product/Banner/BannerDetail';
import PackageInfoAddNew from 'pages/Product/Package/components/PackageInfoAddNew';

function App() {
  return (
    <div className="App">
      <DndProvider backend={HTML5Backend}>
        <BrowserRouter>
          <Routes>
            <Route path="/signin" element={<SigninPage />} />
            <Route path="/" element={<AppLayout />}>
              <Route index element={<Course />} />
              <Route path="/course" element={<Course />} />
              <Route path="/course/:id" element={<CourseDetail />} />
              {/* <Route path="/course/:courseId/package/:id" element={<PackageDetail />} /> */}

              <Route path="package/:packageId/discount/:id" element={<PackageDiscountDetail />} />
              {/* <Route path="/newPackage" element={<BannerAddNew />} /> */}
              <Route path="/package/:id" element={<PackageDetail />} />
              <Route path="/newaddpackage/:id" element={<PackageInfoAddNew />} />
              <Route path="/course/:courseId/component/:id" element={<CourseComponentDetail />} />
              <Route path="/course/category" element={<CourseCategory />} />
              <Route path="/course/category/:id" element={<CategoryDetail />} />
              <Route path="/course/hashtag" element={<Hashtag />} />
              {/* <Route path="/package" element={<Package />} /> */}
              <Route path="/classroom" element={<Classroom />} />
              <Route path="/classroom/:id" element={<ClassroomDetail />} />
              {/* <Route path="/classroom/:id" element={<ClassroomAlarmDetail />} /> */}
              <Route path="/classroom/:classroomId/chapter/:id" element={<ChapterDetail />} />
              <Route
                path="/classroom/:classroomId/chapter/:chapterId/lecture/:id"
                element={<LectureDetail />}
              />
              <Route
                path="/classroom/:classroomId/chapter/:chapterId/lecture/:lectureId/file/:id"
                element={<FileDetail />}
              />
              <Route path="/user" element={<UserList />} />
              <Route path="/user/:id" element={<UserDetail />} />
              <Route path="/user/memo" element={<MemoList />} />
              <Route path="/user/cxhashtag" element={<CxHashtagList />} />
              <Route path="/mentor" element={<MentorList />} />
              <Route path="/mentor/:id" element={<MentorDetail />} />
              {/* <Route path="/coupon" element={<CouponList />} /> */}
              {/* <Route path="/coupon/:id" element={<CouponDetail />} /> */}

              {/* <Route path="/newcoupon/:parentId" element={<NewCouponList />} /> */}
              <Route path="/banner" element={<BannerList />} />
              <Route path="/newaddbanner" element={<BannerAddNew />} />
              <Route path="/banner/:id" element={<BannerDetail />} />
              <Route path="/coupon" element={<NewCouponList />} />
              <Route path="/newaddcoupon" element={<CouponAddNew />} />
              <Route path="/coupon/:id" element={<NewCouponDetail />} />
              {/* <Route path="/collection" element={<Collection />} /> */}
              <Route path="/collection" element={<NewCollection />} />
              {/* <Route path="/collection/:id" element={<CollectionDetail />} /> */}
              <Route path="/collection/:id" element={<NewCollectionDetail />} />
              <Route
                path="/collection/:collectionId/course/:courseId"
                element={<CollectionCourseDetail />}
              />
              <Route path="/markdowneditor" element={<MarkdownEditor />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/checkout/:id" element={<CheckoutDetail />} />
              <Route path="/specific-reply" element={<SpecificReplyList />} />
              <Route path="/alarm" element={<AlarmList />} />
              <Route path="/alarm/:id" element={<ClassroomAlarmDetail />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </DndProvider>
    </div>
  );
}

export default App;
