import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { IUser } from 'model/store.model';
import axios from 'axios';

export const fetchUserById = createAsyncThunk<IUser>('users/me', async () => {
  const response = await axios.get('user/me');
  const data = await response.data;
  return data;
});

const initialState = null as IUser | null;

const counterSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn(state: IUser, action: { payload: any; type: string }) {
      return (state = action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserById.fulfilled, (state, action) => {
      return (state = action.payload);
    });
    builder.addCase(fetchUserById.rejected, (state, action) => {
      throw new Error(String(action.error));
    });
  },
});

// export const { increment, decrement, incrementByAmount } = counterSlice.actions
export const { signIn } = counterSlice.actions;
export default counterSlice.reducer;
