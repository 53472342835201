import { LectureDetailForm } from 'model/classroom.model';
import { useNavigate, useParams } from 'react-router-dom';
import {
  usePatchLectureMutation,
  useDeleteLectureMutation,
  useDeleteLectureFileMutation,
  useAddLectureFileMutation,
} from 'store/services/classroom';

const useLectureMutation = () => {
  const navigate = useNavigate();
  const { classroomId, chapterId, id } = useParams();

  const [patchLectureRequest] = usePatchLectureMutation();
  const [deleteLectureRequest] = useDeleteLectureMutation();
  const [deleteLectureFileRequest] = useDeleteLectureFileMutation();
  const [addLectureFileRequest] = useAddLectureFileMutation();

  const patchLecture = (form: LectureDetailForm) => {
    const body: Partial<LectureDetailForm> & {
      classroomId: number;
      chapterId: number;
      id: number;
    } = {
      classroomId: Number(classroomId),
      chapterId: Number(chapterId),
      id: Number(id),
      content: form.content,
      classRoomLectureFiles: form.classRoomLectureFiles,
      createdAt: form.createdAt,
      hasMission: form.hasMission,
      hasVod: form.hasVod,
      isActive: form.isActive,
      isPreview: form.isPreview,
      isPrivate: form.isPrivate,
      ord: form.ord,
      runningTime: form.runningTime,
      sequence: form.sequence,
      time: form.time,
      title: form.title,
      updatedAt: form.updatedAt,
      mission: form.mission,
    };
    if (form.hasVod) {
      const vodInfo = {
        vodFile: form.vodFile,
        vodFileId: form.vodFileId,
      };
      Object.assign(body, vodInfo);
    }

    patchLectureRequest(body)
      .unwrap()
      .then(() => alert('강의 정보가 변경되었습니다.'));
  };

  const deleteLecture = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요.'))
      deleteLectureRequest({
        classroomId,
        chapterId,
        id,
      })
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다');
          navigate(-1);
        })
        .catch((error) => alert(error.data.errorMessage));
  };

  const deleteLectureFile = (fileId: number) => {
    deleteLectureFileRequest({ classroomId, chapterId, lectureId: id, id: fileId })
      .unwrap()
      .then(() => {
        alert('파일 삭제가 완료되었습니다.');
      });
  };

  const addLectureFile = (fileForm) => {
    addLectureFileRequest({ classroomId, chapterId, id, fileForm })
      .unwrap()
      .then((payload) => {
        console.log('fulfilled', payload);
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  return {
    patchLecture,
    deleteLecture,
    addLectureFile,
    deleteLectureFile,
  };
};

export default useLectureMutation;
