import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useGetCouponQuery, useDeleteCouponMutation } from '../../../store/services/coupon';
import DetailTabs from '../../../components/DetailTabs';
import BannerInfo from './BannerInfo';
import { useGetBannerDetailQuery } from 'store/services/banner';
import useBannerDetailMutation from './hooks/useBannerDetailMutation';

function BannerDetail() {
  const navigate = useNavigate();
  const { id } = useParams();

  //const { data } = useGetCouponQuery(`${id}`);
  const { data: bannerData } = useGetBannerDetailQuery(`${id}`);

  const { deleteBannerInfo } = useBannerDetailMutation();
  const TAB_LIST = ['배너 정보'];
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  return (
    <S.Container>
      <S.DepthNavi>
        <S.CurrentPage>{bannerData?.title}</S.CurrentPage>
      </S.DepthNavi>
      <h1 style={{ paddingBottom: '35px' }}>
        배너 수정
        <S.DeleteButton
          onClick={() => {
            console.log('id', id);
            deleteBannerInfo(Number(id));
          }}
        >
          삭제
        </S.DeleteButton>
      </h1>
      <DetailTabs tabs={tabs} selected={currentTab} />
      <BannerInfo />
      {/* {currentTab === TAB_LIST[0] && <BannerInfo />} */}
    </S.Container>
  );
}

export default BannerDetail;
