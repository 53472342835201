import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BannerCategoryList, BannerItem } from 'model/banner.model';
import serverImageToForm from 'utils/serverImageToForm';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const bannerApi = createApi({
  reducerPath: 'banner',
  tagTypes: ['Banner', 'BannerCategory', 'BannerCategoryDetail'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/banner',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getBannerCategory: builder.query<
      BannerCategoryList,
      { rootBannerCategoryId: string | number | null; page: number; size: number }
    >({
      query: ({ rootBannerCategoryId, page, size }) =>
        `/category?size=${size}&page=${page - 1}&rootBannerCategoryId=${rootBannerCategoryId}`,
      providesTags: (result, error, rootId) => ['BannerCategory'],
    }),
    getBannerCategoryDetail: builder.query({
      //하위 불러오나?
      query: ({ id, isPrivate }) => `?categoryId=${id}&isPrivate=${isPrivate}`,
      providesTags: (result, error, id) => [{ type: 'BannerCategoryDetail', id }],
      //  providesTags: (result, error, id) => [{ type: 'BannerCategoryDetail', id }],
    }),
    patchBannerCategory: builder.mutation({
      //수정
      query: ({ id, ...patch }) => ({
        url: `/category/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'BannerCategory' }],
    }),
    postBannerCategory: builder.mutation({
      //쿠폰 카테고리 생성
      query: ({ ...patch }) => ({
        url: `/category`,
        method: 'POST',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'BannerCategory' }],
    }),
    deleteBannerCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'BannerCategory' }],
    }),

    getBannerDetail: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'BannerCategoryDetail' }],
      transformResponse: (response: any): BannerItem => {
        return {
          ...response,
          bannerCategoryName: response.bannerCategory?.name,
          imgSrc: serverImageToForm(response.imgSrc),
          mimgSrc: serverImageToForm(response.mimgSrc),
        };
      },
    }),

    patchBanner: builder.mutation({
      query: (formData) => ({
        url: `/${formData.id}`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'BannerCategory' },
        { type: 'BannerCategoryDetail' },
      ],
    }), // 코스 상세 수정
    deleteBanner: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'BannerCategory' },
        { type: 'BannerCategoryDetail' },
      ],
    }), // 코스 상세 수정'
    postBanner: builder.mutation({
      query: (formData) => ({
        url: ``,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'BannerCategory' },
        { type: 'BannerCategoryDetail' },
      ],
    }), // 코스 상세 추가
    patchBannerSort: builder.mutation({
      query: ({ id, sortIds }) => ({
        url: `/order/${id}`,
        method: 'PATCH',
        body: sortIds,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'Banner' },
        { type: 'BannerCategoryDetail' },
      ],
    }),
  }),
});

export const {
  useGetBannerCategoryQuery,
  useGetBannerCategoryDetailQuery,
  usePatchBannerCategoryMutation,
  usePostBannerCategoryMutation,
  useDeleteBannerCategoryMutation,
  useGetBannerDetailQuery,
  usePatchBannerMutation,
  useDeleteBannerMutation,
  usePostBannerMutation,
  usePatchBannerSortMutation,
} = bannerApi;

// BannerItem[],
// { id: number | string | undefined; isPrivate: boolean }

// /<void, Partial<PatchCourseDetailForm> & { id: number }>
