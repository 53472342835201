import CustomForm from 'components/CustomForm';
import * as S from 'pages/Product/styles';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCourseHashtagQuery } from 'store/services/course';
import { useGetHashtagListQuery } from 'store/services/hashtag';
import useCourseDetailMutation from '../hooks/useCourseDetailMutation';

const HASHTAG_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'HashTag',
    dataIndex: 'tag',
    key: 'tag',
  },
];

function CourseHashTag() {
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const { data: courseHashtagData } = useGetCourseHashtagQuery(Number(id));
  const { data: hashtagList } = useGetHashtagListQuery(page);
  const [form] = CustomForm.useForm();
  const { patchCourseHashtag } = useCourseDetailMutation(Number(id));
  const [visible, setVisible] = useState(false);
  const [selectedLine, setSelectedLine] = useState(0);

  return (
    <S.Container>
      {courseHashtagData && (
        <CustomForm form={form} initialValues={courseHashtagData} onFinish={patchCourseHashtag}>
          <CustomForm.List name="hashtagList">
            {(courseHashtagList, { add, remove }) => (
              <div>
                {courseHashtagList.map((id, index) => (
                  <CustomForm.GridRow gridColumns="1fr 1fr auto auto" key={index}>
                    <CustomForm.InputNumber label="ID" name={[index, 'id']} />
                    <CustomForm.InputNumber label="이름" name={[index, 'name']} />
                    <CustomForm.Button
                      onClick={() => {
                        setSelectedLine(index);
                        setVisible(true);
                      }}
                      htmlType="button"
                      ghost
                    >
                      검색
                    </CustomForm.Button>
                    <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                      삭제
                    </CustomForm.Button>
                  </CustomForm.GridRow>
                ))}
                <CustomForm.Button onClick={() => add()} htmlType="button">
                  추가
                </CustomForm.Button>
              </div>
            )}
          </CustomForm.List>
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
      {hashtagList && (
        <CustomForm.TableSelectModal
          columns={HASHTAG_COLUMNS}
          list={hashtagList.content}
          visible={visible}
          onCancel={() => setVisible(false)}
          onSelect={(record) => {
            form.setFieldValue(['hashtagList', selectedLine], { id: record.id, name: record.tag });
            setVisible(false);
          }}
          page={page}
          setPage={setPage}
          totalElements={hashtagList.totalElements}
        />
      )}
    </S.Container>
  );
}
export default CourseHashTag;
