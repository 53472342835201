import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Category, CategoryList, GetCategory, GetCategoryList } from 'model/category.model';
import {
  CourseIntroduceCardsHeader,
  CourseListRes,
  GetCourse,
  CourseTypeComponent,
  Course,
  CourseDetailForm,
  PatchCourseDetailForm,
  CourseMentorResponse,
  CourseMentorForm,
  PatchCourseMentorParams,
  CourseHashtagForm,
  CourseComponent,
  CourseContentHashtag,
} from 'model/course.model';
import serverImageToForm from 'utils/serverImageToForm';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const courseApi = createApi({
  reducerPath: 'course',
  tagTypes: ['Course', 'Hashtag', 'Mentor', 'Category', 'Component'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/course',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCourseList: builder.query<
      GetCourse,
      {
        page: number;
        stepType: string;
        stateType: string;
        ordType: string;
        searchType: string;
        target: string;
      }
    >({
      query: ({ page, stepType, stateType, ordType, searchType, target }) =>
        `/?size=15&page=${
          page - 1
        }&stepType=${stepType}&stateType=${stateType}&ordType=${ordType}&searchType=${searchType}&target=${target}`,
      providesTags: ['Course'],
      transformResponse: (response: CourseListRes): GetCourse => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            title: tableData.title,
            categoryName: tableData?.courseCategory?.name ?? '카테고리 미지정',
            courseStep: tableData.courseStep,
            isPrivate: tableData.isPrivate,
            createdAt: tableData.createdAt,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    addCourse: builder.mutation({
      query: () => ({
        url: '/',
        method: 'POST',
      }),
      invalidatesTags: ['Course'],
    }), // 코스 추가

    getCourse: builder.query<CourseDetailForm, string | number>({
      query: (id) => ({ url: `/${id}`, method: 'GET' }),
      providesTags: (result, error, id) => [{ type: 'Course', id }],
      transformResponse: (response: Course): CourseDetailForm => {
        const {
          id,
          age,
          title,
          subTitle,
          previewVideo,
          week,
          isPrivate,
          courseStep,
          difficulty,
          thumbnail,
          mobileThumbnail,
          createdAt,
          updatedAt,
          exposureScore,
          courseContentHashtags,
          description,
          courseCategory,
          courseMentoProfiles,
          missionCount,
          lectureCount,
          info,
          startAt,
          endAt,
          courseType,
          isVisible,
          sellingUrl,
          mobileDetailThumbnail,
          detailThumbnail,
          mainColor,
          packageTitle,
        } = response;
        return {
          id,
          age,
          title,
          subTitle,
          previewVideoId: previewVideo?.id,
          week,
          isPrivate,
          courseStep,
          difficulty,
          thumbnail: serverImageToForm(thumbnail),
          mobileThumbnail: serverImageToForm(mobileThumbnail),
          createdAt,
          updatedAt,
          exposureScore,
          courseContentHashTags: courseContentHashtags.map((hashTag) => ({
            contentHashTagId: hashTag.id, //hashTag.contentHashtag.id,
          })),
          description,
          missionCount,
          lectureCount,
          courseCategoryId: courseCategory?.id ?? 0,
          courseMentoProfiles: courseMentoProfiles.map((mentorProfile) => ({
            id,
            mentoProfileId: mentorProfile.id,
          })),
          info: info?.map((item) => ({
            icon: item.icon,
            content: item.content,
            description: item?.description,
          })),
          startAt: startAt ? startAt.slice(0, 10) : '',
          endAt: endAt ? endAt.slice(0, 10) : '',
          courseType,
          isVisible,
          sellingUrl,
          mobileDetailThumbnail: serverImageToForm(mobileDetailThumbnail),
          detailThumbnail: serverImageToForm(detailThumbnail),
          mainColor,
          packageTitle,
        };
      },
      keepUnusedDataFor: 10000,
    }), // 상세 코스 조회

    patchCourse: builder.mutation<void, Partial<PatchCourseDetailForm> & { id: number }>({
      query: (formData) => ({
        url: `/${formData.id}?updateType=ALL`,
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: ['Course'],
    }), // 코스 상세 수정

    deleteCourse: builder.mutation({
      query: ({ id }) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }), // 코스 상세 삭제

    getCourseMentor: builder.query<CourseMentorForm, number>({
      query: (id) => `/${id}/mentor`,
      providesTags: (result, error, id) => [{ type: 'Mentor', id }],
      transformResponse: (response: CourseMentorResponse) => {
        return {
          mentorList: response.map((mentor) => ({
            id: mentor.mentoProfile.id,
            name: mentor.mentoProfile.displayName,
            job: mentor.mentoProfile.displayJob,
          })),
        };
      },
      keepUnusedDataFor: 10000,
    }), // 튜터리스트 조회

    patchCourseMentor: builder.mutation<void, PatchCourseMentorParams>({
      query: ({ id, mentorList }) => ({
        url: `/${id}/mentor`,
        method: 'PATCH',
        body: mentorList,
      }),
      invalidatesTags: ['Mentor'],
    }), // 튜터 수정

    getCourseHashtag: builder.query<CourseHashtagForm, number>({
      query: (id) => `/${id}/hashtag`,
      providesTags: (result, error, id) => [{ type: 'Hashtag', id }],
      transformResponse: (response: CourseContentHashtag[]) => {
        return {
          hashtagList: response.map((hashtag) => ({
            id: hashtag.contentHashtag.id,
            name: hashtag.contentHashtag.tag,
          })),
        };
      },
      keepUnusedDataFor: 10000,
    }),

    patchCourseHashtag: builder.mutation({
      query: ({ id, hashtagForm }) => ({
        url: `/${id}/hashtag`,
        method: 'PATCH',
        body: hashtagForm,
      }),
      invalidatesTags: ['Hashtag'],
    }), // 해시태그 수정

    getCourseComponentList: builder.query({
      query: (id) => `/${id}/component`,
      providesTags: (result, error, id) => [{ type: 'Component', id }],
      keepUnusedDataFor: 10000,
    }), // 코스 컴포넌트 리스트 조회

    addCourseComponent: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${id}/component`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Component'],
    }), // 코스 컴포넌트 추가

    getCourseComponent: builder.query({
      query: ({ courseId, id }) => `/${courseId}/component/${id}`,
      providesTags: (result, error, id) => [{ type: 'Component', id: `${id}` }],
      transformResponse: (res: CourseComponent) => {
        if (res.courseSummary) {
          return {
            ...res,
            courseSummary: {
              editorFirst: res.courseSummary.editorFirst,
              editorSecond: res.courseSummary.editorSecond,
              imageSlideFirst: res.courseSummary.imageSlideFirst.map((item) => ({
                image: serverImageToForm(item.image),
                title: item.title,
              })),
              imageSlideSecond: res.courseSummary.imageSlideSecond.map((item) => ({
                image: serverImageToForm(item.image),
                title: item.title,
                subTitle: item.subTitle,
              })),
            },
          };
        } else if (res.courseImageSlides) {
          return {
            ...res,
            courseImageSlides: res.courseImageSlides.map((item) => ({
              image: serverImageToForm(item.image),
            })),
          };
        } else if (res.content?.priceSet)
          return {
            ...res,
            content: {
              ...res.content,
              priceSet: {
                ...res.content.priceSet,
                contents: res?.content?.priceSet?.contents.map((item) => ({
                  ...item,
                  image: serverImageToForm(item.image),
                })),
              },
            },
          };
        else if (res.content?.classSet) {
          return {
            ...res,
            content: {
              ...res.content,
              classSet: {
                ...res.content.classSet,
                contents: res.content.classSet.contents?.map((item) => ({
                  ...item,
                  image: serverImageToForm(item.image),
                })),
              },
            },
          }; //여기 수정이요
        } else if (res.content?.tutorSet) {
          return {
            ...res,
            content: {
              ...res.content,
              tutorSet: {
                mainTutor: {
                  ...res.content.tutorSet.mainTutor,
                  image: serverImageToForm(res.content.tutorSet.mainTutor?.image),
                  thumbnail: serverImageToForm(res.content.tutorSet.mainTutor?.thumbnail),
                  icon: serverImageToForm(res.content.tutorSet.mainTutor?.icon),
                },
                tutorIntroduce: {
                  ...res.content.tutorSet.tutorIntroduce,
                  tutors: res.content.tutorSet?.tutorIntroduce?.tutors.map((item) => ({
                    ...item,
                    image: serverImageToForm(item?.image),
                  })),
                },
                courseTutorPreview: {
                  ...res.content.tutorSet.courseTutorPreview,
                  thumbnail: serverImageToForm(
                    res.content?.tutorSet?.courseTutorPreview?.thumbnail
                  ),
                },
              },
            },
          };
        } else if (res.content?.curriculum?.classRoomChapters) {
          return {
            ...res,
            content: {
              ...res.content,
              curriculum: {
                ...res.content.curriculum,
                classRoomChapters: res.content?.curriculum?.classRoomChapters.map((item) => ({
                  //여기; 중요한 잘 보기 이상한 부분이야... 값이 잘 안들억..
                  ...item,
                  thumbnail: serverImageToForm(item.thumbnail),
                })),
              },
            },
          };
        }
        return res;
      },
    }), // 코스 상세 컴포넌트

    getIntroduceCardsList: builder.query({
      query: ({ courseId, id }) => `/${courseId}/component/${id}`,
      providesTags: (result, error, id) => [{ type: 'Component', id }],
      transformResponse: (response: CourseTypeComponent): CourseIntroduceCardsHeader[] => {
        const { courseIntroduce } = response;
        const tableHeaderData = courseIntroduce?.courseIntroduceCards?.map((card, idx) => {
          return {
            lectureCount: card.lectureCount,
            missionCount: card.missionCount,
            thumbnail: card.thumbnail,
            title: card.title,
            url: card.url,
            tags: ['삭제'],
          };
        });
        return tableHeaderData ?? [];
      },
    }), //소개 카드 리스트

    patchCourseComponent: builder.mutation({
      query: ({ courseId, id, componentForm }) => ({
        url: `/${courseId}/component/${id}`,
        method: 'PATCH',
        body: componentForm,
      }),
      invalidatesTags: ['Component'],
    }), // 해시태그 수정

    deleteCourseComponent: builder.mutation({
      query: ({ courseId, id }) => ({
        url: `/${courseId}/component/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Component'],
    }), // 코스 상세 삭제

    patchComponentOrd: builder.mutation({
      query: ({ id, componentIds }) => ({
        url: `/${id}/component/`,
        method: 'PATCH',
        body: componentIds,
      }),
      invalidatesTags: ['Component'],
    }),

    getCategoryRoute: builder.query({
      query: () => `/category/root`,
      providesTags: ['Category'],
    }),

    getCategoryList: builder.query({
      query: ({ page, searchType, target }) =>
        `/category/?size=15&page=${page - 1}&searchType=${searchType}&target=${target}`,
      providesTags: ['Category'],
      transformResponse: (response: CategoryList): GetCategoryList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            categoryName: tableData.name,
            categoryCode: tableData.code,
            numberOfChildren: tableData.numberOfChildren,
            updatedAt: tableData.updatedAt,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
      keepUnusedDataFor: 10000,
    }),

    addCategory: builder.mutation({
      query: (formdata) => ({
        url: `/category`,
        method: 'POST',
        body: formdata,
      }),
      invalidatesTags: ['Category'],
    }),

    addChildCategory: builder.mutation({
      query: (formData) => ({
        url: `/category`,
        method: 'POST',
        body: formData,
      }),
      invalidatesTags: ['Category'],
    }),

    getCategory: builder.query({
      query: (id) => `/category/${id}`,
      providesTags: (result, error, id) => [{ type: 'Category', id }],
      transformResponse: (response: Category): GetCategory => {
        const category = response;
        const tableHeaderData = {
          id: category.id,
          categoryName: category.name,
          categoryCode: category.code,
        };
        return tableHeaderData;
      },
    }),

    getChildCategory: builder.query({
      query: (id) => `/category?rootCourseCategoryId=${id}`,
      providesTags: (result, error, id) => [{ type: 'Category', id }],
      transformResponse: (response: CategoryList): GetCategoryList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            categoryName: tableData.name,
            categoryCode: tableData.code,
            numberOfChildren: tableData.numberOfChildren,
            updatedAt: tableData.updatedAt,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    patchCategory: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/category/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Category'],
    }),

    deleteCategory: builder.mutation({
      query: (id) => ({
        url: `/category/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Category'],
    }),

    postCourseDuplicate: builder.mutation<Course, number>({
      query: (id) => ({
        url: `/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['Course'],
    }),
  }),
});

export const {
  useGetCourseListQuery,
  useGetCourseQuery,
  usePatchCourseMutation,
  useDeleteCourseMutation,
  useAddCourseMutation,
  useGetCourseMentorQuery,
  usePatchCourseMentorMutation,
  useGetCourseHashtagQuery,
  usePatchCourseHashtagMutation,
  useGetCourseComponentListQuery,
  useAddCourseComponentMutation,
  useGetCourseComponentQuery,
  useGetIntroduceCardsListQuery,
  usePatchCourseComponentMutation,
  useDeleteCourseComponentMutation,
  usePatchComponentOrdMutation,
  useGetCategoryQuery,
  usePatchCategoryMutation,
  useDeleteCategoryMutation,
  useAddCategoryMutation,
  useAddChildCategoryMutation,
  useGetCategoryRouteQuery,
  useGetCategoryListQuery,
  useGetChildCategoryQuery,
  usePostCourseDuplicateMutation,
} = courseApi;
