import * as S from '../styles';
import { useGetCheckoutListQuery } from '../../../store/services/checkout';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { CheckoutListHeader } from 'model/checkout.model';
import Pagination from 'antd/es/pagination';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { ChangeEvent, useState } from 'react';
import SelectOrderInput from 'components/form/SelectOrderInput';

const COLUMNS: ColumnsType<CheckoutListHeader> = [
  {
    title: '주문 ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '결제일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '결제 상태',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '액수',
    dataIndex: 'actualAmount',
    key: 'actualAmount',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '구매자 닉네임',
    dataIndex: 'userNickname',
    key: 'userNickname',
  },
  {
    title: '구매자 휴대폰번호',
    dataIndex: 'userPhoneNumber',
    key: 'userPhoneNumber',
  },
];

const ORD_TYPE = ['최신순'];
const ORD_TYPE_MAPPED = {
  최신순: 'RECENT_DESC',
};

const SEARCH_TYPE = ['선택', '닉네임', '휴대폰번호'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  닉네임: 'NICKNAME',
  휴대폰번호: 'PHONE_NUMBER',
};

function Checkout() {
  const [page, setPage] = useNumberSearchParams('page');

  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    ordType: '최신순',
    searchType: '선택',
    target: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const { data: checkoutList } = useGetCheckoutListQuery({
    page,
    ordType: ORD_TYPE_MAPPED[filter.ordType],
    searchType: SEARCH_TYPE_MAPPED[filter.searchType],
    target: filter.target,
  });

  return (
    <S.Container>
      <h1>
        주문서 전체 조회
        <SelectOrderInput
          selectValue={filter.ordType}
          array={ORD_TYPE}
          label="정렬"
          name="ordType"
          onChange={handleFilterChange}
          type="order"
        />
        <S.SearchContainer>
          <SelectOrderInput
            selectValue={filter.searchType}
            array={SEARCH_TYPE}
            label="검색"
            name="searchType"
            onChange={handleFilterChange}
            type="search"
          />
          <input name="target" value={filter.target} onChange={handleFilterChange} />
        </S.SearchContainer>
      </h1>
      <S.TableContainer>
        {checkoutList && (
          <Table
            dataSource={checkoutList.content}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/checkout/${record.id}`, '_blank');
                  } else navigate(`/checkout/${record.id}`);
                },
              };
            }}
          />
        )}
        {checkoutList && (
          <Pagination
            pageSize={15}
            current={page}
            total={checkoutList.totalElements}
            onChange={
              setPage
              //setPageParams(page);
            }
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default Checkout;
