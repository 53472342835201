import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import { useAddCategoryMutation } from '../../../store/services/course';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';

const CategoryAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const [addCategory] = useAddCategoryMutation();

  const MATCH_LABEL = [
    { name: '카테고리 이름', variable: 'name', type: 'text' },
    { name: '코드', variable: 'code', type: 'text' },
  ];

  const handleAddClick = () => {
    addCategory(formData)
      .unwrap()
      .then(() => {
        setFormData({
          name: '',
          code: '',
        });
        open(false);
      })
      .catch((error) => {
        alert('입력하신 정보를 확인해주세요');
        console.log(error.data.message);
      });
  };

  //카테고리 생성
  const [formData, setFormData] = useState({
    name: '',
    code: '',
  });

  const { isValid, onChangeText } = useValidCheck(formData, setFormData);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>카테고리 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={formData[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={handleAddClick} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default CategoryAddModal;
