import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  ClassroomUserListRes,
  GetClassroomUserList,
  GetUser,
  UesrDetail,
  UserInfo,
  UserMemoRes,
  UserRes,
} from 'model/user.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const userApi = createApi({
  reducerPath: 'user',
  tagTypes: ['user', 'userMemo'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/user',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getUserList: builder.query<
      GetUser,
      {
        page: number;
        ordType: string;
        searchType: string;
        state: string;
        target: string;
        type: string;
      }
    >({
      query: ({ page, ordType, searchType, state, target, type }) =>
        `/search/?size=15&page=${
          page - 1
        }&ordType=${ordType}&searchType=${searchType}&state=${state}&target=${target}&type=${type}`,
      providesTags: ['user'],
      transformResponse: (response: UserRes): GetUser => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            name: tableData.oauthUser.name,
            nickname: tableData.nickname,
            email: tableData.email,
            birthYear: tableData.oauthUser.birthYear,
            userType: tableData.userType,
            phoneNumber: tableData.oauthUser.phoneNumber,
            id: tableData.id,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),
    getUserDeatil: builder.query<UesrDetail, number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'user', id }],
      transformResponse: (response: UserInfo): UesrDetail => {
        return {
          id: response.id,
          childrenList: response.childrenList,
          createdAt: response.createdAt,
          email: response.email,
          name: response.oauthUser.name || '',
          nickname: response.nickname,
          phoneNumber: response.oauthUser.phoneNumber || '',
          userType: response.userType,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['user'],
    }),

    getClassroomUserList: builder.query<
      GetClassroomUserList,
      {
        page: number;
        size: number;
        ordType: string;
        searchType: string;
        state: string;
        target: string;
        type: string;
      }
    >({
      query: ({ page, size, ordType, searchType, state, target, type }) =>
        `/search?page=${page}&size=${size}&ordType=${ordType}&searchType=${searchType}&state=${state}&target=${target}&type=${type}`,
      transformResponse: (response: ClassroomUserListRes): GetClassroomUserList => {
        const { content, totalElements, totalPages } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            nickname: tableData.nickname,
            email: tableData.email,
            name: tableData.oauthUser.name,
            phoneNumber: tableData.oauthUser.phoneNumber,
            userType: tableData.userType,
          };
        });
        return { content: tableHeaderData, totalElements, totalPages };
      },
    }),
    getUserMemo: builder.query<
      UserMemoRes,
      {
        ordType: string;
        searchType: string;
        TransmissionType: string;
        ConsultationRoute: string;
        target: string;
        page: number;
        size: number;
      }
    >({
      query: ({ ordType, searchType, TransmissionType, ConsultationRoute, target, page, size }) =>
        `/memo?ordType=${ordType}&searchType=${searchType}&transmissionType=${TransmissionType}&consultationRoute=${ConsultationRoute}&target=${target}&page=${
          page - 1
        }&size=${size}`,
      providesTags: ['userMemo'],
    }),

    postUserMemo: builder.mutation({
      query: (id) => ({
        url: `/${id}/memo`,
        method: 'POST',
      }),
      invalidatesTags: ['userMemo'],
    }),

    deleteUserMemo: builder.mutation({
      query: ({ id, memoId }) => ({
        url: `/${id}/memo/${memoId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['userMemo'],
    }),

    patchUserMemo: builder.mutation({
      query: ({ id, memoId, ...patch }) => ({
        url: `/${id}/memo/${memoId}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: (result, error, id) => [{ type: 'userMemo' }],
    }),
  }),
});

export const {
  useGetUserListQuery,
  useDeleteUserMutation,
  useGetUserDeatilQuery,
  useGetClassroomUserListQuery,
  useGetUserMemoQuery,
  usePostUserMemoMutation,
  useDeleteUserMemoMutation,
  usePatchUserMemoMutation,
} = userApi;
