import {
  ChapterDetailForm,
  ClassroomDetailForm,
  ClassroomMentorForm,
  LiveAtForm,
  PatchChapterDetailForm,
  PatchClassroomDetailForm,
} from 'model/classroom.model';
import { useNavigate } from 'react-router-dom';
import {
  usePatchClassroomMutation,
  useDeleteClassroomMutation,
  usePatchClassroomMentorMutation,
  useDeleteChapterMutation,
  usePatchChapterMutation,
  usePostClassroomDuplicateMutation,
  usePatchChapterPrivateMutation,
  usePatchLiveAtMutation,
  usePostClasroomChapterDuplicateMutation,
  usePostClassroomLectureDuplicateMutation,
  usePatchClassroomChapterSortMutation,
  usePatchClassroomLectureSortMutation,
} from 'store/services/classroom';
import { checkServerDate } from 'utils/commonFunction';

interface Args {
  lectureId?: number;
  classroomId?: number;
  chapterId?: number;
}

const useClassroomDetailMutation = ({ classroomId, chapterId, lectureId }: Args) => {
  const navigate = useNavigate();
  const [patchClassroomRequest] = usePatchClassroomMutation();
  const [patchChapterisPrivateRequest] = usePatchChapterPrivateMutation();
  const [patchClassroomMentorRequest] = usePatchClassroomMentorMutation();
  const [deleteClassroomRequest] = useDeleteClassroomMutation();
  const [patchChapterRequest] = usePatchChapterMutation();
  const [deleteChapterRequest] = useDeleteChapterMutation();
  const [postClassroomDuplicateRequest] = usePostClassroomDuplicateMutation();
  const [patchChapterLiveAtRequest] = usePatchLiveAtMutation();
  const [postClassroomChapterDuplicateRequest] = usePostClasroomChapterDuplicateMutation();
  const [postClassroomLectureDuplicateRequest] = usePostClassroomLectureDuplicateMutation();
  const [patchClassroomChapterSortRequest] = usePatchClassroomChapterSortMutation();
  const [patchClassroomLectureSortRequest] = usePatchClassroomLectureSortMutation();
  const patchClassroom = (form: ClassroomDetailForm) => {
    const body: Partial<PatchClassroomDetailForm> & { id: number } = {
      createdAt: form.createdAt,
      classRoomEnrollmentCount: form.classRoomEnrollmentCount,
      deadline: form.deadline + ' 00:00:00',
      endDate: form.endDate,
      id: classroomId!,
      isPrivate: form.isPrivate,
      lectureCount: form.lectureCount,
      maxUserCount: form.maxUserCount,
      missionCount: form.missionCount,
      startDate: form.startDate,
      term: form.term,
      thumbnail: form.thumbnail[0].url,
      time: form.time,
      title: form.title,
      type: form.type,
      updatedAt: form.updatedAt,
      week: form.week,
      info: [],
      isVisible: true,
      liveId: form.liveId,
      livePassword: form.livePassword,
      liveUrl: form.liveUrl,
      liveMaterials: form.liveMaterials,
      mentorLetterUrl: form.mentorLetterUrl,
    };

    if (form.type === 'WITH_FRIEND' && form.liveId && form.livePassword && form.liveUrl) {
      const liveInfo = {
        liveId: form.liveId,
        livePassword: form.livePassword,
        liveUrl: form.liveUrl,
        liveMaterials: form.liveMaterials,
        mentorLetterUrl: form.mentorLetterUrl,
      };
      Object.assign(body, liveInfo);
    }

    patchClassroomRequest(body)
      .unwrap()
      .then(() => {
        alert('업데이트가 완료되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deleteClassroom = (formData) => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요.'))
      deleteClassroomRequest(formData)
        .unwrap()
        .then((payload) => {
          alert('삭제가 완료되었습니다');
          navigate('/classroom');
        })
        .catch((error) => alert(error.data.errorMessage));
  };

  const patchClassroomMentor = (form: ClassroomMentorForm) => {
    patchClassroomMentorRequest({
      id: classroomId!,
      mentorList: form.mentorList.map((mentor) => ({ mentoProfileId: mentor.id })),
    })
      .unwrap()
      .then(() => alert('튜터 정보가 변경되었습니다.'));
  };

  const patchChapterisPrivate = (classroomId: string, isPrivate: boolean, updateData: number[]) => {
    return new Promise((resolve, reject) => {
      if (updateData.length === 0) {
        reject('No data to update');
        return;
      }
      patchChapterisPrivateRequest({ classroomId, isPrivate, updateData })
        .unwrap()
        .then(() => {
          resolve('Success');
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  //here
  const patchChapterLiveAt = (classroomId: string, liveAt: LiveAtForm[]) => {
    return new Promise((resolve, reject) => {
      const updatedLiveAt = liveAt.map((content) => ({
        ...content,
        liveAt: content.liveAt === '' ? null : content.liveAt,
      }));

      const isValidLiveAtList = updatedLiveAt.every((content) => {
        if (content.liveAt === null) return true;
        return content.liveAt !== undefined ? checkServerDate(content.liveAt!) : true;
      });
      if (updatedLiveAt.length === 0) {
        reject('No data to update');
        return;
      } else if (!isValidLiveAtList) {
        reject('Wrong data to update');
        return;
      } else {
        patchChapterLiveAtRequest({ classroomId, liveAt: updatedLiveAt })
          .unwrap()
          .then(() => {
            resolve('Success');
          })
          .catch((error) => alert(error.data.errorMessage));
      }
    });
  };

  const patchChapter = (form: ChapterDetailForm) => {
    //
    const body: Partial<PatchChapterDetailForm> & { classroomId: number; id: number } = {
      classroomId: classroomId!,
      id: chapterId!,
      createdAt: form.createdAt,
      updatedAt: form.updatedAt,
      liveAt: form.liveAt,
      title: form.title,
      ord: form.ord,
      sequence: form.sequence,
      isPrivate: form.isPrivate,
      thumbnail: form.thumbnail[0].url,
      classRoomLectures: form.classRoomLectures,
      isDeleted: form.isDeleted,
    };
    patchChapterRequest(body)
      .unwrap()
      .then(() => {
        alert('업데이트가 완료되었습니다.');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deleteChapter = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요.'))
      deleteChapterRequest({ classroomId, id: chapterId })
        .unwrap()
        .then((payload) => {
          alert('삭제가 완료되었습니다');
          navigate(-1);
        })
        .catch((error) => alert(error.data.errorMessage));
  };

  const postClassroomDuplicate = () => {
    postClassroomDuplicateRequest(classroomId!)
      .unwrap()
      .then((res) => {
        window.open(`/classroom/${res.id}`, '_blank');
      });
  };

  const postClassroomChapterDuplicate = async (ids: number[], targets: number[]) => {
    if (ids.length === 0) {
      alert('복사할 챕터가 없습니다!');
      throw new Error('No packages to duplicate'); // 에러를 던짐
    }
    if (targets.length === 0) {
      targets.push(classroomId!);
    }
    for (const target of targets) {
      for (const id of ids) {
        const requestParams = {
          classroomId: classroomId,
          chapterId: id,
          targetClassRoomId: target,
        };
        try {
          await postClassroomChapterDuplicateRequest(requestParams).unwrap();
        } catch (error) {
          alert('복사 중 문제가 발생했습니다.');
          return; // 혹시나 에러가 발생하면 함수 종료
        }
      }
    }
    alert('모든 복사가 완료되었습니다.');
  };

  const patchClassroomChapterSort = (classRoomChapterIds: number[]) => {
    return new Promise((resolve, reject) => {
      patchClassroomChapterSortRequest({ classroomId, classRoomChapterIds })
        .unwrap()
        .then(() => {
          resolve('Success');
        })
        .catch((error) => reject(error));
    });
  };

  const patchClassroomLectureSort = (classRoomLectureIds: number[]) => {
    return new Promise((resolve, reject) => {
      patchClassroomLectureSortRequest({ classroomId, chapterId, classRoomLectureIds })
        .unwrap()
        .then(() => {
          resolve('Success');
        })
        .catch((error) => reject(error));
    });
  };

  const postClassroomLectureDuplicate = async (ids: number[], targets: number[]) => {
    if (ids.length === 0) {
      alert('복사할 강의가 없습니다!');
      throw new Error('No packages to duplicate'); // 에러를 던짐
    }
    if (targets.length === 0) {
      targets.push(chapterId!);
    }
    for (const target of targets) {
      for (const id of ids) {
        const requestParams = {
          classroomId: classroomId,
          classRoomChapterId: chapterId,
          lectureId: id,
          targetChapterId: target,
        };
        try {
          await postClassroomLectureDuplicateRequest(requestParams).unwrap();
        } catch (error) {
          alert('복사 중 문제가 발생했습니다.');
          return; // 혹시나 에러가 발생하면 함수 종료
        }
      }
    }
    alert('모든 복사가 완료되었습니다.');
  };

  //

  return {
    deleteClassroom,
    patchClassroom,
    patchChapterisPrivate,
    patchClassroomMentor,
    patchChapter,
    deleteChapter,
    postClassroomDuplicate,
    patchChapterLiveAt,
    postClassroomChapterDuplicate,
    postClassroomLectureDuplicate,
    patchClassroomChapterSort,
    patchClassroomLectureSort,
  };
};

export default useClassroomDetailMutation;
