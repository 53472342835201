import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { CxHashTag, GetHastag, HashtagList } from 'model/hashtag.model';
import { userApi } from './user';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const hashTagApi = createApi({
  reducerPath: 'hashtag',
  tagTypes: ['HashTag', 'CxHashTag'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/hashtag',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getHashtagList: builder.query({
      query: (page) => `/content/?size=15&page=${page - 1}`,
      providesTags: ['HashTag'],
      transformResponse: (response: HashtagList): GetHastag => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            tag: tableData.tag,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
      keepUnusedDataFor: 10000,
    }),

    addHashtag: builder.mutation({
      query: (addHashTagForm) => ({
        url: `/content`,
        method: 'POST',
        body: addHashTagForm,
      }),
      invalidatesTags: ['HashTag'],
    }),

    getHashtagDetail: builder.query({
      query: (id) => `/content/${id}`,
      providesTags: (result, error, id) => [{ type: 'HashTag', id }],
    }),

    patchHashtag: builder.mutation({
      query: ({ patchHashtagForm, id }) => ({
        url: `/content/${id}`,
        method: 'PATCH',
        body: patchHashtagForm,
      }),
      invalidatesTags: ['HashTag'],
    }),

    deleteHashtag: builder.mutation({
      query: (id) => ({
        url: `/content/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['HashTag'],
    }),

    getCxHasgtagList: builder.query<
      CxHashTag,
      {
        ordType: string;
        page: number;
      }
    >({
      query: ({ ordType, page }) => `/cx?ordType=${ordType}&page=${page}&size=10`,
      providesTags: ['CxHashTag'],
    }),

    addCxHashtag: builder.mutation({
      query: (addCxHashTagForm) => ({
        url: `/cx`,
        method: 'POST',
        body: addCxHashTagForm,
      }),
      invalidatesTags: ['CxHashTag'],
    }),

    deleteCxHashtag: builder.mutation({
      query: (id) => ({
        url: `/cx/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(userApi.util.invalidateTags(['userMemo']));
        });
      },
      invalidatesTags: ['CxHashTag'],
    }),

    patchCxHashtag: builder.mutation({
      query: ({ id, patchCxHashtagForm }) => ({
        url: `/cx/${id}`,
        method: 'PATCH',
        body: patchCxHashtagForm,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(userApi.util.invalidateTags(['userMemo']));
        });
      },
      invalidatesTags: ['CxHashTag'],
    }),
  }),
});

export const {
  useGetHashtagListQuery,
  useAddHashtagMutation,
  useDeleteHashtagMutation,
  usePatchHashtagMutation,
  useGetHashtagDetailQuery,
  useGetCxHasgtagListQuery,
  useAddCxHashtagMutation,
  useDeleteCxHashtagMutation,
  usePatchCxHashtagMutation,
} = hashTagApi;
