import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../../styles';
import { useNavigate } from 'react-router-dom';
import { useGetCourseListQuery, useAddCourseMutation } from 'store/services/course';
import SelectOrderInput from 'components/form/SelectOrderInput';
import { ChangeEvent, useState } from 'react';
import Pagination from 'antd/es/pagination';
import { CourseListHeader } from 'model/course.model';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const COLUMNS: ColumnsType<CourseListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
    width: '38%',
  },
  {
    title: '시리즈(카테고리)',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: '15%',
  },
  {
    title: '코스 스텝',
    dataIndex: 'courseStep',
    key: 'courseStep',
    width: '10%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    render: (value) => (value == true ? '비공개' : '공개'),
    width: '8%',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '16%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: '8%',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = 'green';
          return <S.SubEditButton key={tag}>{tag.toUpperCase()}</S.SubEditButton>;
        })}
      </>
    ),
  },
];

const ORD_TYPE = ['최신순', '좋아요순', '노출점수순'];
const ORD_TYPE_MAPPED = {
  최신순: 'RECENT',
  좋아요순: 'POPULAR',
  노출점수순: 'EXPOSURE_POPULAR',
};
const STATE_TYPE = ['ALL', '공개', '비공개'];
const STATE_TYPE_MAPPED = {
  ALL: 'ALL',
  공개: 'PUBLIC',
  비공개: 'PRIVATE',
};
const FILTER_TYPE = ['ALL', 'SELLABLE', 'SELLING', 'EARLYBIRD', 'SEED', 'CLOSED'];
const SEARCH_TYPE = ['선택', '코스명', '코스 설명', '태그', '튜터'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  코스명: 'TITLE',
  '코스 설명': 'DESCRIPTION',
  태그: 'TAG',
  튜터: 'MENTO',
};

function Course() {
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();
  const [addCourse] = useAddCourseMutation();
  const [filter, setFilter] = useState({
    ordType: '최신순',
    stateType: 'ALL',
    filterType: 'ALL',
    searchType: '선택',
    target: '',
  });

  const { data: courseList } = useGetCourseListQuery({
    page,
    stepType: filter.filterType,
    stateType: STATE_TYPE_MAPPED[filter.stateType],
    ordType: ORD_TYPE_MAPPED[filter.ordType],
    searchType: SEARCH_TYPE_MAPPED[filter.searchType],
    target: filter.target,
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const handleAddClick = () => {
    addCourse('');
  };

  return (
    <S.Container>
      <h1>
        코스
        <S.AddButton onClick={handleAddClick}>+ 추가</S.AddButton>
        <SelectOrderInput
          selectValue={filter.ordType}
          array={ORD_TYPE}
          label="정렬"
          name="ordType"
          onChange={handleFilterChange}
          type="order"
        />
        <SelectOrderInput
          selectValue={filter.filterType}
          array={FILTER_TYPE}
          label="필터"
          name="filterType"
          onChange={handleFilterChange}
          type="filter"
        />
        <SelectOrderInput
          selectValue={filter.stateType}
          array={STATE_TYPE}
          label="공개 여부"
          name="stateType"
          onChange={handleFilterChange}
          type="state"
        />
        <S.SearchContainer>
          <SelectOrderInput
            selectValue={filter.searchType}
            array={SEARCH_TYPE}
            label="검색"
            name="searchType"
            onChange={handleFilterChange}
            type="search"
          />
          <input name="target" value={filter.target} onChange={handleFilterChange} />
        </S.SearchContainer>
      </h1>
      <S.TableContainer>
        {courseList && (
          <Table
            dataSource={courseList?.content.map((content) => {
              return { ...content, tags: ['수정'] };
            })}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/course/${record.id}`, '_blank');
                  } else navigate(`/course/${record.id}`);
                },
              };
            }}
          />
        )}
        {courseList && (
          <Pagination
            pageSize={15}
            current={page}
            total={courseList.totalElements}
            onChange={setPage} //
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default Course;
