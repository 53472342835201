import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IStore } from 'model/store.model';
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react';
import { ReactComponent as Logo } from '../assets/svg/logo-dark.svg';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { setSideBarShow, setSideBarUnfoldable } from '../store/sideNav';
import { AppSidebarNav } from './AppSideNav';
import category from '../static/category';
import { useNavigate } from 'react-router-dom';

const AppSidebar = () => {
  const dispatch = useDispatch();
  const { sidebarShow, sidebarUnfoldable } = useSelector((state: IStore) => state.sideNav);
  const Navigate = useNavigate();

  return (
    <CSidebar
      position="fixed"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch(setSideBarShow(visible));
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <Logo
          onClick={() => {
            Navigate(`/course`);
          }}
          cursor="pointer"
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={category} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(setSideBarUnfoldable(!sidebarUnfoldable))}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
