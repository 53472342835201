import { useNavigate, useParams } from 'react-router-dom';
import { Modal, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../../../../styles';
import { LectureTableList } from 'model/classroom.model';
import { useState } from 'react';
import FileUpload from 'components/form/FileUpload';
import { useAddLectureFileMutation, useGetLectureFileListQuery } from 'store/services/classroom';
import useLectureMutation from './hooks/useLectureMutation';

const COLUMNS: ColumnsType<LectureTableList> = [
  {
    title: 'Id',
    dataIndex: 'fileId',
    key: 'fileId',
  },
  {
    title: '파일 제목',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '파일 형식',
    dataIndex: 'ext',
    key: 'ext',
  },
  {
    title: '생성 날짜',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '수정 날짜',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
  },
];

function LectureFile() {
  const navigate = useNavigate();
  const { classroomId, chapterId, id } = useParams();
  const { data: fileData } = useGetLectureFileListQuery({ classroomId, chapterId, id });
  const [fileModal, setFileModal] = useState(false);
  const [fileForm, setFileForm] = useState({
    classRoomLectureId: id,
    fileId: 0,
  });
  const { addLectureFile } = useLectureMutation();

  return (
    <>
      {fileData && (
        <>
          <div style={{ margin: '0px 16px' }}>
            <S.SubEditButton
              onClick={() => {
                setFileModal(true);
              }}
            >
              추가
            </S.SubEditButton>
          </div>
          <Modal
            title="수업 자료 업로드"
            style={{ alignContent: 'center' }}
            centered
            visible={fileModal}
            onOk={() => setFileModal(false)}
            onCancel={() => setFileModal(false)}
            width={'70%'}
            zIndex={2000}
            footer={null}
            closable={true}
            maskClosable={false}
          >
            <FileUpload formData={fileForm} setFormData={setFileForm} label={'수업 자료'} />
            <S.SaveWrap>
              <button
                onClick={() => {
                  addLectureFile(fileForm);
                  setFileModal(false);
                }}
              >
                저장
              </button>
            </S.SaveWrap>
          </Modal>
          <S.TableContainer>
            <Table
              dataSource={fileData}
              columns={COLUMNS}
              pagination={{ pageSize: 15 }}
              rowKey={(record) => record.fileId}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`file/${record?.fileId}`, '_blank');
                    } else navigate(`file/${record?.fileId}`);
                  },
                };
              }}
            />
          </S.TableContainer>
        </>
      )}
    </>
  );
}

export default LectureFile;
