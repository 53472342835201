import { Pagination, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import * as S from '../styles';
import { useNavigate } from 'react-router-dom';
import { useAddClassroomMutation, useGetClassroomListQuery } from 'store/services/classroom';
import { ChangeEvent, useState } from 'react';
import SelectOrderInput from 'components/form/SelectOrderInput';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { ClassroomListHeader } from 'model/classroom.model';

const COLUMNS: ColumnsType<ClassroomListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '클래스룸명',
    dataIndex: 'title',
    key: 'title',
    width: '30%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    width: '10%',
    render: (value) => (value == true ? '비공개' : '공개'),
  },
  {
    title: '기수',
    dataIndex: 'term',
    key: 'term',
    width: '10%',
  },
  {
    title: '수강 방식',
    dataIndex: 'type',
    key: 'type',
    width: '10%',
  },
  {
    title: '수강 요일 및 시간',
    dataIndex: 'time',
    key: 'time',
    width: '15%',
  },

  {
    title: '첫수업 시작일',
    dataIndex: 'startDate',
    key: 'startDate',
    width: '15%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <S.SubEditButton key={tag}>{tag.toUpperCase()}</S.SubEditButton>;
        })}
      </>
    ),
  },
];

const ORD_TYPE = ['최신순', '업데이트순'];
const ORD_TYPE_MAPPED = {
  최신순: 'RECENT_DESC',
  업데이트순: 'UPDATED_AT_DESC',
};

const STATE_TYPE = ['ALL', '공개', '비공개'];
const STATE_TYPE_MAPPED = {
  ALL: 'ALL',
  공개: 'PUBLIC',
  비공개: 'PRIVATE',
};

const SEARCH_TYPE = ['선택', '클래스룸명', '튜터'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  클래스룸명: 'TITLE',
  튜터: 'MENTO',
};

const FILTER_TYPE = ['ALL', '스스로 자유롭게', '친구들과 함께', '미정'];
const FILTER_TYPE_MAPPED = {
  ALL: '',
  '스스로 자유롭게': 'FREE_ALONE',
  '친구들과 함께': 'WITH_FRIEND',
  미정: 'NONE',
};

function Classroom() {
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();
  const [addClassroom] = useAddClassroomMutation();

  const [filter, setFilter] = useState({
    ordType: '최신순',
    stateType: 'ALL',
    filterType: 'ALL',
    searchType: '선택',
    target: '',
  });

  const { data: classroomList } = useGetClassroomListQuery({
    page,
    stateType: STATE_TYPE_MAPPED[filter.stateType],
    ordType: ORD_TYPE_MAPPED[filter.ordType],
    searchType: SEARCH_TYPE_MAPPED[filter.searchType],
    target: filter.target,
    type: FILTER_TYPE_MAPPED[filter.filterType],
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  const handleAddClick = () => {
    addClassroom('');
  };

  return (
    <S.Container>
      <h1>
        클래스룸
        <S.AddButton onClick={handleAddClick}>+ 추가</S.AddButton>
        <SelectOrderInput
          selectValue={filter.ordType}
          array={ORD_TYPE}
          label="정렬"
          name="ordType"
          onChange={handleFilterChange}
          type="order"
        />
        <SelectOrderInput
          selectValue={filter.stateType}
          array={STATE_TYPE}
          label="공개 여부"
          name="stateType"
          onChange={handleFilterChange}
          type="state"
        />
        <SelectOrderInput
          selectValue={filter.filterType}
          array={FILTER_TYPE}
          label="수업 종류"
          name="filterType"
          onChange={handleFilterChange}
          type="state"
        />
        <S.SearchContainer>
          <SelectOrderInput
            selectValue={filter.searchType}
            array={SEARCH_TYPE}
            label="검색"
            name="serchType"
            onChange={handleFilterChange}
            type="search"
          />
          <input name="target" value={filter.target} onChange={handleFilterChange} />
        </S.SearchContainer>
      </h1>
      <S.TableContainer>
        {classroomList?.content && (
          <Table
            dataSource={classroomList?.content.map((content) => ({ ...content, tags: ['수정'] }))}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record: any) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  if (e.ctrlKey || e.metaKey) {
                    window.open(`/classroom/${record.id}`, '_blank');
                  } else navigate(`/classroom/${record.id}`);
                },
              };
            }}
          />
        )}
        {classroomList && (
          <Pagination
            pageSize={15}
            current={page}
            total={classroomList.totalElements}
            onChange={setPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default Classroom;
