import { SetChapterAlarm } from 'model/classroom.model';

import {
  useAddAlarmMutation,
  useDeleteAlarmMutation,
  usePatchAlarmMutation,
} from 'store/services/alarm';

const useAlarmMutation = () => {
  const [addAlarmRequest] = useAddAlarmMutation();
  const [deleteAlarmRequest] = useDeleteAlarmMutation();
  const [patchAlarmLiveAtRequest] = usePatchAlarmMutation();

  const addAlarm = (form: SetChapterAlarm): Promise<void> => {
    return new Promise((resolve, reject) => {
      const body = {
        alarmType: form.alarmType,
        classRoomId: form.classRoomId,
        classRoomChapterId: form.classRoomChapterId,
      };
      if (window.confirm('정말 알림톡을 예약하시겠습니까?'))
        addAlarmRequest(body)
          .unwrap()
          .then(() => {
            alert('알림톡이 예약되었습니다.');
            resolve();
          })
          .catch((err) => {
            alert('알림톡 예약에 실패했습니다. \n' + err.data.errorMessage);
            reject();
          });
      else {
        resolve();
      }
    });
  };

  const addProgressAlarms = (type: string, id: number, AlarmList: number[]): Promise<void> => {
    const promises = AlarmList.map((alarm) => {
      return new Promise<void>((resolve, reject) => {
        const body = {
          alarmType: type,
          classRoomId: id,
          classRoomChapterId: alarm, //여기를 이제 promiseall을 통해서 alarmList를 돌며 다 보내
        };
        addAlarmRequest(body)
          .unwrap()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject();
          });
      });
    });
    return Promise.all(promises)
      .then(() => {})
      .catch(() => {
        alert('알림톡 예약에 실패했습니다.');
      });
  };

  const deleteAlarm = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (window.confirm('선택하신 알림톡을 삭제하시겠습니까?')) {
        deleteAlarmRequest(id)
          .unwrap()
          .then(() => {
            alert('삭제가 완료되었습니다');
            resolve();
          })
          .catch((error) => {
            alert(error.error);
            reject();
          });
      } else {
        resolve();
      }
    });
  };

  const deleteProgressAlarms = (AlarmList: number[]): Promise<void[]> => {
    const promises = AlarmList.map((alarm) => {
      return new Promise<void>((resolve, reject) => {
        const failedAlarms: number[] = [];
        deleteAlarmRequest(alarm)
          .unwrap()
          .then(() => {
            resolve();
          })
          .catch((err) => {
            failedAlarms.push(alarm);
            reject();
          });
      });
    });
    return Promise.all(promises);
  };

  const patchAlarmLiveAt = (id: number, liveAt: string) => {
    return new Promise<void>((resolve, reject) => {
      patchAlarmLiveAtRequest({ id: id, newLiveAt: { alarmAt: liveAt } })
        .unwrap()
        .then(() => {
          alert('알림톡 발송 일자가 변경되었습니다.');
        });
    });
  };
  return {
    addAlarm,
    addProgressAlarms,
    deleteAlarm,
    patchAlarmLiveAt,
    deleteProgressAlarms,
  };
};

export default useAlarmMutation;
