import { Modal, Pagination, Table } from 'antd';
import * as S from 'pages/Product/styles';

export default function CustomTableSelectModal({
  onSelect,
  onCancel,
  visible,
  list,
  columns,
  page,
  setPage,
  totalElements,
  size = 10,
}) {
  return (
    <Modal
      centered
      visible={visible}
      onCancel={onCancel}
      width={'60%'}
      zIndex={2000}
      footer={null}
      closable={true}
      maskClosable={false}
    >
      <S.TableContainer>
        <Table
          style={{ width: '100%' }}
          dataSource={list}
          columns={columns}
          pagination={false}
          rowKey={(record) => {
            return record.id ?? record.type;
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: () => onSelect(record),
            };
          }}
        />
        <Pagination
          pageSize={size} //10
          current={page}
          total={totalElements}
          onChange={(page) => {
            setPage(page);
          }}
          showSizeChanger={false}
          showQuickJumper
        />
      </S.TableContainer>
    </Modal>
  );
}
