import { useNavigate, useParams } from 'react-router-dom';
import * as S from '../styles';

import { useGetCollectionCourseListQuery } from '../../../store/services/collection';
import { Table, Pagination } from 'antd';

import useNumberSearchParams from 'hooks/useNumberSearchParams';
import useAntdModal from 'hooks/useAntdModal';
import AddCollectionCourse from '../User/components/AddCollectionCourse';
const PACKAGE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '부제목',
    dataIndex: 'subTitle',
    key: 'subTitle',
  },
  {
    title: '적용 패키지 수',
    dataIndex: 'collectionPackageCount',
    key: 'collectionPackageCount',
  },
];
function CollectionCourseList() {
  const { id } = useParams();
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();
  const { isModalOpen, showModal, handleCancel, handleOk } = useAntdModal();

  const { data: collectionCourseList } = useGetCollectionCourseListQuery({
    id,
    page,
    type: 'ONLY',
  });
  console.log('testtesttes', collectionCourseList);

  return (
    <>
      {collectionCourseList && (
        <>
          <h1>
            적용 코스
            <S.AddButton onClick={showModal}>추가</S.AddButton>
            <AddCollectionCourse
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              collectionId={Number(id)}
            />
          </h1>
          <S.TableContainer>
            <Table
              dataSource={collectionCourseList.content}
              columns={PACKAGE_COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/collection/${id}/course/${record.id}`, '_blank');
                    } else navigate(`/collection/${id}/course/${record.id}`);
                  },
                };
              }}
            />
            <Pagination
              pageSize={15}
              current={page}
              total={collectionCourseList.totalElements}
              onChange={(page) => {
                setPage(page);
              }}
              showSizeChanger={false}
              showQuickJumper
            />
          </S.TableContainer>
        </>
      )}
    </>
  );
}

export default CollectionCourseList;
