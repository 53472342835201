import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetFile, ModalFileList } from 'model/file.model';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const fileApi = createApi({
  reducerPath: 'file',
  tagTypes: ['file'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/file',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getPublicVideoList: builder.query({
      query: ({ page }) => `?page=${page}&searchType=NAME&size=10&target=m3u8&type=PUBLIC`,
      providesTags: ['file'],
      transformResponse: (response: GetFile): ModalFileList => {
        return {
          content: response.content.map((file) => ({
            createdAt: file.createdAt,
            id: file.id,
            name: file.name,
          })),
          totalElements: response.totalElements,
          totalPages: response.totalPages,
        };
      },
      keepUnusedDataFor: 10000,
    }),
  }),
});

export const { useGetPublicVideoListQuery } = fileApi;
