import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import styled from 'styled-components';
import { useRef, useEffect } from 'react';
import youtubeLogo from '../../assets/images/youtube-icon.png';
import getJSON from '../../assets/images/getJSON.jpeg';
import axios from 'axios';
import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

const Button = styled.button`
  width: 100px;
  height: 30px;
  margin-bottom: 15px;
  background-color: #dcddff;
  border-radius: 10px;
  font-weight: bold;
  &:focus {
    background-color: #5d5fef;
    color: #e5e5e5;
  }
  &:hover {
    background-color: #5d5fef;
    color: #e5e5e5;
  }
`;

const EditorContainer = styled.div`
  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
  }
  .video-container iframe,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default function CustomMarkdownEditor({
  name,
  form,
  height = '700px',
}: {
  name: NamePath;
  form: FormInstance;
  height?: string;
}) {
  const editorRef = useRef<Editor>(null);
  const FileSaver = require('file-saver');

  useEffect(() => {
    editorRef.current
      ?.getInstance()
      .addCommand('markdown', 'addYoutube', (payload, state, dispatch, view) => {
        let url = prompt('추가할 youtube 영상의 주소창 url을 담아주세요!');
        if (!url) return false;
        url = url?.split('=').at(-1) ?? '';
        const str = `<div class="video-container"><iframe src="https://www.youtube.com/embed/${url}" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
        editorRef.current?.getInstance().insertText(str);
        return true;
      });

    editorRef.current?.getInstance().insertToolbarItem(
      { groupIndex: 3, itemIndex: 3 },
      {
        name: 'youtube',
        tooltip: 'youtube',
        className: 'toastui-editor-toolbar-icons',
        style: { backgroundImage: `url(${youtubeLogo})`, backgroundSize: '25px', color: 'red' },
        command: 'addYoutube',
      }
    );
    editorRef.current?.getInstance().removeHook('addImageBlobHook');
    editorRef.current?.getInstance().addHook('addImageBlobHook', async (file) => {
      const formData = new FormData();
      formData.append('multipartFile', file);
      await axios({
        method: 'post',
        url: '/file?fileType=IMAGE',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(({ data }) => {
          editorRef.current?.getInstance().insertText(`<img src="${data.url}" alt=""/>`);
          editorRef.current?.getInstance().eventEmitter.emit('closePopup');
        })
        .catch((error) => {
          console.error(error);
        });
    });

    editorRef.current?.getInstance().insertToolbarItem(
      { groupIndex: 4, itemIndex: 3 },
      {
        name: 'getJSON',
        tooltip: 'getJSON',
        className: 'toastui-editor-toolbar-icons',
        style: { backgroundImage: `url(${getJSON})`, backgroundSize: '25px', color: 'red' },
        command: 'getJSON',
      }
    );
    editorRef.current
      ?.getInstance()
      .addCommand('markdown', 'getJSON', (payload, state, dispatch, view) => {
        const content = editorRef.current?.getInstance().getMarkdown();
        const contentToJson = JSON.stringify({ content });
        const file = new File([contentToJson], 'notecontent.json', {
          type: 'text/plain;charset=utf-8',
        });
        FileSaver.saveAs(file);
        return true;
      });
  }, []);

  const handleFileUpload = (e) => {
    if (!window.confirm('기존에 작성된 내용이 전부 초기화됩니다. 진행하시겠습니까?')) return;
    var file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = function (event) {
      editorRef.current
        ?.getInstance()
        .setMarkdown(JSON.parse(String(event.target?.result)).content);
    };
    reader.readAsText(file);
  };

  return (
    <EditorContainer>
      <Editor
        previewStyle="vertical"
        height={height}
        ref={editorRef}
        initialValue={form.getFieldValue(name)}
        onChange={(e) => form.setFieldValue(name, editorRef.current?.getInstance().getMarkdown())}
        customHTMLRenderer={{
          htmlBlock: {
            iframe(node) {
              return [
                {
                  type: 'openTag',
                  tagName: 'iframe',
                  outerNewLine: true,
                  attributes: node.attrs,
                },
                { type: 'html', content: node.childrenHTML ?? '' },
                { type: 'closeTag', tagName: 'iframe', outerNewLine: true },
              ];
            },
            div(node) {
              return [
                { type: 'openTag', tagName: 'div', outerNewLine: true, attributes: node.attrs },
                { type: 'html', content: node.childrenHTML ?? '' },
                { type: 'closeTag', tagName: 'div', outerNewLine: true },
              ];
            },
          },
          htmlInline: {
            big(node, { entering }) {
              return entering
                ? { type: 'openTag', tagName: 'big', attributes: node.attrs }
                : { type: 'closeTag', tagName: 'big' };
            },
          },
        }}
      />
      <div>
        <input type="file" onChange={handleFileUpload} accept="application/json"></input>
      </div>
    </EditorContainer>
  );
}
