import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useEffect, useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';
import { setSideBarShow } from '../../../store/sideNav';
import { useDispatch } from 'react-redux';
import { useAddCollectionMutation } from '../../../store/services/collection';
import { Table, Modal } from 'antd';
import Pagination from 'antd/es/pagination';
import PackageAddModal from './PackageAddModal';
import PackageCategoryAddModal from './PackageCategoryAddModal';
import AddPackageModalSection from '../User/components/AddPackageModalSection';

const CollectionAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const [addCollection] = useAddCollectionMutation();
  //const { data: packageData } = useGetPackageListQuery(page); //이거 수정 필요.
  const [selectedPackageId, setSelectedPackageId] = useState<number[]>([]);

  const MATCH_LABEL = [
    { name: '컬렉션 이름', variable: 'name', type: 'text' },
    { name: '컬렉션 설명', variable: 'description', type: 'text' },
  ];

  const PACKAGE_COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '패키지 이름',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  // 쿠폰 추가
  const [addCollectionForm, setAddCollectionForm] = useState<any>({
    description: '',
    name: '',
    productPackageIds: [], //selectedPackageId
  });

  const onAddCollection = async () => {
    if (addCollectionForm?.productPacageIds?.length !== 0) {
      // setAddCollectionForm({ ...addCollectionForm, productPackageIds: selectedPackageId });
      console.log(addCollectionForm);
    }
    try {
      // addCollection 함수가 비동기 함수인 경우, await를 사용하여 완료될 때까지 기다립니다.
      await addCollection(addCollectionForm).unwrap();

      setAddCollectionForm({
        description: '',
        name: '',
        productPackageIds: [],
      });
      open(false);
    } catch (error) {
      alert('입력하신 정보를 확인해주세요');
      //console.log(error.data.message);
    }
  };

  const { isValid, onChangeText, onChangeSearch } = useValidCheck(
    addCollectionForm,
    setAddCollectionForm
  );

  // 패키지 모달
  const [isPackageModalOpen, setIsPackageModalOpen] = useState(false);
  const [packageIndex, setPackageIndex] = useState(0);
  const searchPackage = (e, index) => {
    dispatch(setSideBarShow(false));
    setIsPackageModalOpen(true);
    setPackageIndex(index);
  };

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>컬렉션 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={addCollectionForm[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
            />
          );
        })}
        <Modal
          title="패키지 선택"
          centered
          visible={isPackageModalOpen}
          onOk={() => setIsPackageModalOpen(false)}
          onCancel={() => setIsPackageModalOpen(false)}
          width={'30%'}
          zIndex={2000}
          footer={null}
          closable={true}
          maskClosable={false}
        >
          <AddPackageModalSection
            addCollectionForm={addCollectionForm}
            setAddCollectionForm={setAddCollectionForm}
          />
        </Modal>
        <TextInput
          key="productPackageIds"
          label="적용 패키지"
          name={'productPackageIds'}
          //value={selectedPackageId}
          value={addCollectionForm.productPackageIds}
          type={'text'}
          hasSearchAction
          search={searchPackage}
          modal={true}
        ></TextInput>
      </S.InputList>
      <SS.SaveWrap onClick={onAddCollection} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default CollectionAddModal;
