import styled from 'styled-components';
import * as S from '../../pages/Product/styles';

export const Container = styled.div<{ modal?: boolean; checkout?: boolean }>`
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 30px;
  margin: ${({ modal }) => (modal ? '0px 16px 0px 41px' : '0px 16px 0px 16px')};
  label {
    font-size: ${({ modal, checkout }) => (modal || checkout ? '15px' : '18px')};
    font-weight: ${({ modal, checkout }) => (modal || checkout ? '500' : '700')};
    margin-bottom: 9px;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
    appearance: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }
`;
export interface IInputProps {
  label?: string;
  name?: string;
  value?: any;
  type?: string;
  onChange?: any;
  hasRemoveAction?: boolean;
  remove?: any;
  hasSearchAction?: boolean;
  search?: any;
  modal?: boolean;
  checkout?: boolean;
}
function TextInput({
  label,
  name,
  value,
  type,
  onChange,
  hasRemoveAction,
  remove,
  hasSearchAction,
  search,
  modal,
  checkout,
}: IInputProps) {
  const handleOnInput = (e, maxlength) => {
    if (e.target.value.length > maxlength) {
      e.target.value = e.target.value.substr(0, maxlength);
    }
  };
  return (
    <Container modal={modal} checkout={checkout}>
      <label>{label}</label>
      <div>
        <input
          style={{ marginRight: '15px' }}
          disabled={
            name == 'id' ||
            name == 'courseId' ||
            name == 'packageId' ||
            name == 'createdAt' ||
            name == 'updatedAt' ||
            name == 'numberOfLike' ||
            name == 'classRoomEnrollmentCount' || //currentUserCount
            name == 'issuedAmount' ||
            name == 'usedAmount' ||
            name == 'couponId' ||
            name == 'currentHashTagId' ||
            name == 'ord' ||
            name == 'remain'
          }
          type={type}
          name={name}
          value={name == 'ord' ? value : value || null}
          onChange={onChange}
          onInput={(e) => {
            type == 'number' && handleOnInput(e, 7);
          }}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {hasSearchAction && (
          <S.SubExtraButton modal={modal} style={{ marginRight: '6px' }} onClick={search}>
            검색
          </S.SubExtraButton>
        )}
        {hasRemoveAction && (
          <S.SubDeleteButton onClick={remove} modal={modal}>
            삭제
          </S.SubDeleteButton>
        )}
      </div>
    </Container>
  );
}

export default TextInput;
