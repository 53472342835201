import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  Classroom,
  ClassroomDetailForm,
  ClassroomListRes,
  GetClassroom,
  GetUserClassroom,
  ClassroomMentorForm,
  ClassroomMentorResponse,
  UserClassroomListRes,
  PatchClassroomMentorParams,
  ChapterListRes,
  ChapterDetailForm,
  Chapter,
  Lecture,
  LectureDetailForm,
  ChapterListResForAlarm,
  Rate,
  ClassroomForAlarm,
} from 'model/classroom.model';
import { ILecture } from 'model/store.model';
import serverImageToForm from 'utils/serverImageToForm';
const baseUrl = process.env.REACT_APP_SERVER_API_URL;
export interface IChapter {
  title: string;
}
export const classroomApi = createApi({
  reducerPath: 'classroom',
  tagTypes: ['Classroom', 'Chapter', 'Lecture', 'Alarm', 'ClassroomData'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/classroom',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    // rsp에 대한 type / parameter type
    getClassroomList: builder.query<
      GetClassroom,
      {
        page: number;
        stateType: string;
        ordType: string;
        searchType: string;
        target: string;
        type: string;
        size?: number;
      }
    >({
      query: ({ page, stateType, ordType, searchType, target, type, size = 15 }) =>
        `/?size=${size}&page=${
          page - 1
        }&stateType=${stateType}&ordType=${ordType}&searchType=${searchType}&target=${target}&type=${type}`,
      providesTags: ['Classroom'],
      transformResponse: (response: ClassroomListRes): GetClassroom => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            title: tableData.title,
            isPrivate: tableData.isPrivate,
            startDate: tableData.startDate,
            term: tableData.term,
            type: tableData.type,
            time: tableData.time,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    getClassroomForAlarm: builder.query<ClassroomForAlarm, number>({
      query: (id) => `/${id}?readType=FOR_ALARM`,
      providesTags: ['Classroom'],
      transformResponse: (response: ClassroomForAlarm): ClassroomForAlarm => {
        return {
          ...response,
          classStartAlarmId: response?.classStartAlarm?.id,
          title: response.title,
          term: response.term,
          classStartLiveAt: response?.classStartAlarm?.liveAt,
          classStartAlarmAt: response?.classStartAlarm?.alarmAt,
          classStartAlarmState: response?.classStartAlarm?.status,
        };
      },
    }),

    getClassroomDetail: builder.query<ClassroomDetailForm, string | number>({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Classroom', id }],
      transformResponse: (response: Classroom): ClassroomDetailForm => {
        const { deadline, endDate, startDate, thumbnail } = response;
        return {
          ...response,
          deadline: deadline ? deadline.slice(0, 10) : '',
          endDate: endDate ? endDate.slice(0, 10) : '',
          startDate: startDate ? startDate.slice(0, 10) : '',
          thumbnail: serverImageToForm(thumbnail),
        };
      },
    }),

    patchClassroom: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Classroom'],
    }),

    deleteClassroom: builder.mutation({
      query: ({ id, ...patch }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: {
          ...patch,
          isVisible: false,
          thumbnail: patch.thumbnail[0].url,
          info: [],
          deadline: patch.deadline + ' 00:00:00',
        },
      }),
      invalidatesTags: ['Classroom'],
    }),

    addClassroom: builder.mutation({
      query: () => ({
        url: '/',
        method: 'POST',
      }),
      invalidatesTags: ['Classroom'],
    }),

    getClassroomMentor: builder.query<ClassroomMentorForm, number>({
      query: (id) => `/${id}/mentor`,
      // providesTags: (result, error, id) => [{ type: 'Classroom', id }],
      transformResponse: (response: ClassroomMentorResponse): ClassroomMentorForm => {
        return {
          mentorList: response.map((mentor) => ({
            id: mentor.mentoProfile.id,
            name: mentor.mentoProfile.displayName,
            job: mentor.mentoProfile.displayJob,
          })),
        };
      },
    }), // 튜터리스트 조회

    patchClassroomMentor: builder.mutation<void, PatchClassroomMentorParams>({
      query: ({ id, mentorList }) => ({
        url: `/${id}/mentor`,
        method: 'PATCH',
        body: mentorList,
      }),
      invalidatesTags: ['Classroom'],
    }), // 튜터 수정

    getChapter: builder.query<ChapterDetailForm, { classroomId: number; id: number }>({
      query: ({ classroomId, id }) => `/${classroomId}/chapter/${id}?readType=FOR_ADMIN`,
      providesTags: (result, error, id) => [{ type: 'Chapter', id: String(id) }],
      transformResponse: (response: Chapter): ChapterDetailForm => {
        const { thumbnail } = response;
        return {
          ...response,
          thumbnail: serverImageToForm(thumbnail),
        };
      },
    }),

    getChapterList: builder.query<ChapterListRes, number>({
      // 여기가 ReadType이 필요한거 FOR_ALARAM
      query: (id) => `/${id}/chapter`,
      //providesTags: ['Chapter'],
      providesTags: (result, error, id) => [{ type: 'Chapter', id }],
    }),

    getChapterListForAlarm: builder.query<ChapterListResForAlarm, number>({
      //알람버전으로 list받아와. 여기 더 수정해야해.
      query: (id) => `/${id}/chapter?readType=FOR_ALARM`,
      //providesTags: ['Chapter'],
      providesTags: (result, error, id) => [{ type: 'Chapter', id }],
    }),

    getChaptertitle: builder.query({
      query: ({ classroomId, chapterId }) =>
        `/${classroomId}/chapter/${chapterId}?readType=FOR_ADMIN`,
      providesTags: (result, error, chapterId) => [{ type: 'Chapter', chapterId }],
      transformResponse: (response: IChapter) => {
        return {
          title: response.title,
        };
      },
    }),

    addChapter: builder.mutation({
      query: (id) => ({
        url: `/${id}/chapter`,
        method: 'POST',
      }),
      invalidatesTags: ['Chapter'],
    }),

    patchChapterPrivate: builder.mutation({
      query: ({ isPrivate, classroomId, updateData }) => ({
        url: `/${classroomId}/chapter/isprivate?isPrivate=${isPrivate}`,
        method: 'PATCH',
        body: updateData,
      }),
      invalidatesTags: ['Chapter'],
      //이게 공개 비공개 patch부분
    }),

    patchChapter: builder.mutation({
      //참고
      query: ({ classroomId, id, ...patch }) => ({
        url: `/${classroomId}/chapter/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Classroom', 'Chapter'],
    }),

    deleteChapter: builder.mutation({
      query: ({ classroomId, id }) => ({
        url: `/${classroomId}/chapter/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Chapter'],
    }),

    addLecture: builder.mutation({
      query: ({ classroomId, id }) => ({
        url: `/${classroomId}/chapter/${id}/lecture`,
        method: 'POST',
      }),
      invalidatesTags: ['Chapter'],
    }),

    getLecture: builder.query<
      LectureDetailForm,
      { classroomId: number; chapterId: number; id: number }
    >({
      query: ({ classroomId, chapterId, id }) =>
        `/${classroomId}/chapter/${chapterId}/lecture/${id}?readType=FOR_DETAIL`,
      providesTags: (result, error, id) => [{ type: 'Lecture', id: String(id) }],
      transformResponse: (response: Lecture): LectureDetailForm => {
        if (response.hasVod && response.vodFile) {
          return {
            ...response,
            vodFileId: response.vodFile.id,
          };
        }
        return {
          ...response,
        };
      },
    }),

    patchLecture: builder.mutation({
      query: ({ classroomId, chapterId, id, ...patch }) => ({
        url: `/${classroomId}/chapter/${chapterId}/lecture/${id}`,
        method: 'PATCH',
        body: patch,
      }),
      invalidatesTags: ['Chapter', 'Lecture'],
    }),

    deleteLecture: builder.mutation({
      query: ({ classroomId, chapterId, id }) => ({
        url: `/${classroomId}/chapter/${chapterId}/lecture/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Chapter', 'Lecture'],
    }),

    getLectureFileList: builder.query({
      query: ({ classroomId, chapterId, id }) =>
        `/${classroomId}/chapter/${chapterId}/lecture/${id}?readType=FOR_DETAIL`,
      providesTags: (result, error, id) => [{ type: 'Lecture', id }],
      transformResponse: (response: ILecture) => {
        return response.classRoomLectureFiles?.map((item) => ({ ...item.file, fileId: item.id }));
      },
    }),

    addLectureFile: builder.mutation({
      query: ({ classroomId, chapterId, id, fileForm }) => ({
        url: `/${classroomId}/chapter/${chapterId}/lecture/${id}/file`,
        method: 'POST',
        body: fileForm,
      }),
      invalidatesTags: ['Chapter', 'Lecture'],
    }),

    getLectureFile: builder.query({
      query: ({ classroomId, chapterId, lectureId, id }) =>
        `/${classroomId}/chapter/${chapterId}/lecture/${lectureId}/file/${id}`,
      providesTags: (result, error, id) => [{ type: 'Lecture', id }],
    }),

    deleteLectureFile: builder.mutation({
      query: ({ classroomId, chapterId, lectureId, id }) => ({
        url: `/${classroomId}/chapter/${chapterId}/lecture/${lectureId}/file/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Chapter', 'Lecture'],
    }),

    getClassroomEnrollment: builder.query<GetUserClassroom, { page: number; userId: number }>({
      query: ({ page, userId }) => `/enrollment?userId=${userId}`,
      providesTags: (result, error, { userId }) => [{ type: 'Lecture', id: String(userId) }],
      transformResponse: (response: UserClassroomListRes): GetUserClassroom => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            classRoomId: tableData.classRoom.id,
            packageId: tableData.productPackage.id,
            title: tableData.classRoom.title,
            method: tableData.method,
            type: tableData.classRoom.type,
            endAt: tableData.endAt,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    addClassroomEnrollment: builder.mutation<
      void,
      {
        classRoomId: number;
        userId: number;
        endAt: string;
        productPackageId: number;
        isCount: boolean;
      }
    >({
      query: (body) => ({
        url: `/enrollment`,
        method: 'POST',
        body: {
          ...body,
          type: 'ADMIN',
        },
      }),
      invalidatesTags: (result, error, body) => [{ type: 'Lecture', id: String(body.userId) }],
    }),

    deleteClassroomEnrollment: builder.mutation<
      void,
      { classRoomEnrollmentId: number; userId: number }
    >({
      query: ({ classRoomEnrollmentId }) => ({
        url: `/enrollment/${classRoomEnrollmentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { userId }) => [{ type: 'Lecture', id: userId }],
    }),

    transferEnrollment: builder.mutation<
      void,
      { classRoomEnrollmentId: number; userId: number; targetUserId: number }
    >({
      query: ({ classRoomEnrollmentId, targetUserId }) => ({
        url: `/enrollment/${classRoomEnrollmentId}/transfer`,
        method: 'PATCH',
        body: {
          targetUserId,
        },
      }),
      invalidatesTags: (result, error, { userId, targetUserId }) => [
        { type: 'Lecture', id: userId },
        { type: 'Lecture', id: targetUserId },
      ],
    }),

    changeEnrollment: builder.mutation<
      void,
      { classRoomEnrollmentId: number; userId: number; productPackageId: number }
    >({
      query: ({ classRoomEnrollmentId, productPackageId }) => ({
        url: `/enrollment/${classRoomEnrollmentId}/change`,
        method: 'PATCH',
        body: {
          productPackageId,
        },
      }),
      invalidatesTags: (result, error, { userId }) => [{ type: 'Lecture', id: userId }],
    }),

    postClassroomDuplicate: builder.mutation<Classroom, number>({
      query: (id) => ({
        url: `/${id}/duplicate`,
        method: 'POST',
      }),
      invalidatesTags: ['Classroom'],
    }),

    getClassroomData: builder.query({
      query: ({ classRoomId, userId }) => `/${classRoomId}/check?doUpdate=false&userId=${userId}`,
      providesTags: ['ClassroomData'],
      transformResponse: (response: any): Rate => {
        const vodRate =
          response?.classRoomCheck?.vodCount / response?.classRoomCheck?.totalVodCount;
        const totalVodCount = response?.classRoomCheck?.totalVodCount;
        const vodCount = response?.classRoomCheck?.vodCount;
        const missionRate =
          response?.classRoomCheck?.missionCount / response?.classRoomCheck?.totalMissionCount;
        const totalMissionCount = response?.classRoomCheck?.totalMissionCount;
        const missionCount = response?.classRoomCheck?.missionCount;

        return {
          startDate: null,
          recentDate: null,
          vodRate: `${(vodRate * 100).toFixed(2)}% / ${totalVodCount}개 중 ${vodCount}개 완료`,
          missinRate: `${(missionRate * 100).toFixed(
            2
          )}% / ${totalMissionCount}개 중 ${missionCount}개 완료`,
        };
      },
    }),

    patchLiveAt: builder.mutation({
      query: ({ classroomId, liveAt }) => ({
        url: `/${classroomId}/chapter/liveat`,
        method: 'PATCH',
        body: liveAt,
      }),
      invalidatesTags: ['Chapter'],
    }),

    postClasroomChapterDuplicate: builder.mutation({
      //여기서 chapterId는 복사할 챕터 녀석들로 NumberList임.
      query: ({ classroomId, chapterId, targetClassRoomId }) => ({
        url: `/${classroomId}/chapter/${chapterId}/duplicate?targetClassRoomId=${targetClassRoomId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Chapter'],
    }),

    patchClassroomChapterSort: builder.mutation({
      //챕터 순서 변경 api
      query: ({ classroomId, classRoomChapterIds }) => ({
        url: `/${classroomId}/chapter/order`,
        method: 'PATCH',
        body: classRoomChapterIds,
      }),
      invalidatesTags: ['Chapter'],
    }),

    patchClassroomLectureSort: builder.mutation({
      //챕터 내 강의 순서 변경 api
      query: ({ classroomId, chapterId, classRoomLectureIds }) => ({
        url: `/${classroomId}/chapter/${chapterId}/lecture`,
        method: 'PATCH',
        body: classRoomLectureIds,
      }),
      invalidatesTags: ['Lecture'],
    }),

    ///classroom/{classRoomId}/chapter/{id}/duplicate/lectures // 이 부분 api 수정후 다시 작업
    postClassroomLectureDuplicate: builder.mutation({
      query: ({ classroomId, classRoomChapterId, lectureId, targetChapterId }) => ({
        url: `/${classroomId}/chapter/${classRoomChapterId}/lecture/${lectureId}/duplicate?targetChapterId=${targetChapterId}`,
        method: 'POST',
      }),
      invalidatesTags: ['Chapter', 'Lecture'],
    }),
  }),
});

export const {
  useGetClassroomListQuery,
  useGetClassroomDetailQuery,
  usePatchClassroomMutation,
  useDeleteClassroomMutation,
  useAddClassroomMutation,
  useGetChapterListQuery,
  useGetChapterListForAlarmQuery,
  useGetChapterQuery,
  useAddChapterMutation,
  usePatchChapterMutation,
  usePatchChapterPrivateMutation,
  useAddLectureMutation,
  useGetLectureQuery,
  usePatchLectureMutation,
  useDeleteChapterMutation,
  useDeleteLectureMutation,
  useGetChaptertitleQuery,
  useGetClassroomMentorQuery,
  usePatchClassroomMentorMutation,
  useGetLectureFileListQuery,
  useGetLectureFileQuery,
  useAddLectureFileMutation,
  useDeleteLectureFileMutation,
  useGetClassroomEnrollmentQuery,
  useAddClassroomEnrollmentMutation,
  useDeleteClassroomEnrollmentMutation,
  useTransferEnrollmentMutation,
  useChangeEnrollmentMutation,
  usePostClassroomDuplicateMutation,
  useGetClassroomDataQuery,
  useGetClassroomForAlarmQuery,
  usePatchLiveAtMutation,
  usePostClasroomChapterDuplicateMutation,
  usePostClassroomLectureDuplicateMutation,
  usePatchClassroomChapterSortMutation,
  usePatchClassroomLectureSortMutation,
} = classroomApi;
