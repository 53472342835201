import * as S from '../styles';
import TextInput from '../../../components/form/TextInput';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { useIssueCouponMutation } from '../.././../store/services/coupon';
import { AnyArray } from 'immer/dist/internal';
import { useEffect } from 'react';

function CouponIssue() {
  const { id } = useParams();
  const [userIdsArray, setUserIdsArray] = useState<AnyArray>([]);
  const [userIds, setUserIds] = useState('');
  const [issueCoupon] = useIssueCouponMutation();

  const onChangeText = (e) => {
    setUserIds(e.target.value);
  };

  useEffect(() => {
    const array = userIds.split(',').map(Number);
    setUserIdsArray(array);
  }, [userIds]);

  const handelIssueCoupon = () => {
    issueCoupon({ id, userIds: userIdsArray })
      .unwrap()
      .then((payload) => {
        if (window.confirm('쿠폰을 발급하기 전 한번 더 정보를 확인해주세요')) {
          alert('정상적으로 발급되었습니다');
          console.log('fulfilled', payload);
        }
      })
      .catch((error) => {
        alert(error.data.errorMessage);
      });
  };

  return (
    <>
      <TextInput
        key="couponId"
        label="쿠폰 ID"
        name="couponId"
        value={id}
        type={'number'}
      ></TextInput>
      <TextInput
        key="userIds"
        label="유저 ID (id, id, ....)"
        name="userIds"
        value={userIds}
        type={'text'}
        onChange={onChangeText}
      ></TextInput>
      <S.SaveWrap>
        <button onClick={handelIssueCoupon}>쿠폰 발급</button>
      </S.SaveWrap>{' '}
    </>
  );
}

export default CouponIssue;
