import useNumberSearchParams from 'hooks/useNumberSearchParams';
import * as S from '../styles';
import { Pagination, Select, Table, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useFileSearchParams from 'hooks/useFileSearchParams';
import { useRef, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import useBooleanSearchParams from 'hooks/useBooleanSearchParams';
import useBooleanState from '../Classroom/ClassroomDetail/hooks/useBooleanState';
import { useDragSort } from 'utils/Hook/useDragSort';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import CustomRow from 'components/CustomTable/CustomRow';
import useModal from 'hooks/useModal';
import {
  useDeletePackageCategoryMutation,
  useGetPackageCategoryPackageListQuery,
  useGetPackageCategoryQuery,
  useGetPackageStatisticsQuery,
  usePatchPackageCategoryMutation,
  usePatchPackageCategorySortMutation,
  usePatchPackageSortMutation,
} from 'store/services/package';
import PackageCategoryAddModal from 'pages/Product/InputModal/PackageCategoryAddModal';

interface EditData {
  courseId: number;
  name: string;
  code: string;
  rootId: number | null;
  groupName: string;
  saleStartAt: string;
  saleEndAt: string;
  isPublic: boolean;
}

function PackageList() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [categoryPage, setCategoryPage] = useNumberSearchParams('categoryPage');
  const [page, setPage] = useNumberSearchParams('page');

  const [isPackageCategoryPublic, setIsPackageCategoryPublic] =
    useBooleanSearchParams('categoryPublic');
  const [isPackagePublic, setIsPackagePublic] = useBooleanSearchParams('packagePublic');

  const [parentId, setParentId] = useFileSearchParams('parentId');

  const { value: isSort, setFalse: hideSort, setTrue: showSort } = useBooleanState(false);
  const {
    value: isCategorySort,
    setFalse: hideCategorySort,
    setTrue: showCategorySort,
  } = useBooleanState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { data: packageCategory } = useGetPackageCategoryQuery({
    //여기가 새로운 패키지
    courseId: Number(id),
    rootProductPackageCategoryId: parentId,
    page: categoryPage,
    size: 5,
    isPublic: isPackageCategoryPublic,
  });

  const { data: packageCategoryPackage } = useGetPackageCategoryPackageListQuery({
    //여기가 새로운 패키지 진짜 패키지 목록들
    courseId: Number(id),
    productPackageCategoryId: parentId,
    page: page,
    size: 5,
    isPublic: isPackagePublic,
  });

  const [editData, setEditData] = useState<EditData>({
    courseId: Number(id),
    name: '',
    code: '',
    rootId: null,
    groupName: '',
    saleStartAt: '',
    saleEndAt: '',
    isPublic: isPackageCategoryPublic,
  });

  const { data: packageStatistics } = useGetPackageStatisticsQuery({
    courseId: Number(id),
    productPackageCategoryId: parentId,
  });

  const [patchPackageCategory] = usePatchPackageCategoryMutation();
  const [deletePackageCategory] = useDeletePackageCategoryMutation();
  const [patchPackageSort] = usePatchPackageSortMutation();
  const [patchPackageCategorySort] = usePatchPackageCategorySortMutation();
  const [editingId, setEditingId] = useState<number | null>(null); //현재 수정중인 id

  const editCategory = (id: number) => {
    setEditingId(id); // 편집 모드로 변경//현재 편집중인 열의 id를 저장
    setEditData({
      //그 id에 해당하는 데이터를 저장
      ...editData,
    });
  };

  const saveCategory = (id: number) => {
    setEditingId(null); // 저장 후 편집 모드 종료

    if (editData.rootId === null || editData.rootId === 0) {
      editData.rootId = null;
    }
    patchPackageCategory({ ...editData, id })
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
      })
      .catch((error) => alert(error.data.message));

    setEditData({
      courseId: Number(id),
      name: '',
      code: '',
      rootId: null,
      groupName: '',
      saleStartAt: '',
      saleEndAt: '',
      isPublic: isPackageCategoryPublic,
    });
  };

  const COLUMNS = [
    isCategorySort
      ? {
          key: 'sort',
          // width: '3%',
          render: () => <MenuOutlined style={{ cursor: 'move' }} />,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          //width: '3%',
        },

    {
      title: '카테고리 이름',
      dataIndex: 'name',
      key: 'name',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                name: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.name}</span>
        );
      },
    },

    {
      title: '카테고리 코드',
      dataIndex: 'code',
      key: 'code',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            style={{ width: 120 }}
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                code: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.code}</span>
        );
      },
    },

    editingId
      ? {
          title: '상위 카테고리 ID',
          dataIndex: 'packageCategoryId',
          key: parentId,
          render: (text, record) => {
            const isEditing = record.id === editingId;
            return isEditing ? (
              <input
                style={{ width: 100 }}
                defaultValue={parentId}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    rootId: Number(e.target.value),
                  });
                }}
              />
            ) : (
              <span>{parentId}</span>
            );
          },
        }
      : { title: '하위 카테고리 수', dataIndex: 'numberOfChildren', key: 'numberOfChildren' },
    {
      title: ' 하위 패키지 수',
      dataIndex: 'numberOfProductPackages',
      key: 'numberOfProductPackages',
    },
    {
      title: ' 하위 그룹 이름',
      dataIndex: 'groupName',
      key: 'groupName',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                groupName: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.groupName}</span>
        );
      },
    },
    {
      title: '판매시작일시',
      dataIndex: 'saleStartAt',
      key: 'saleStartAt',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                saleStartAt: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.saleStartAt}</span>
        );
      },
    },
    {
      title: '판매종료일시',
      dataIndex: 'saleEndAt',
      key: 'saleEndAt',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <input
            defaultValue={value}
            onChange={(e) => {
              setEditData({
                ...editData,
                saleEndAt: e.target.value,
              });
            }}
          />
        ) : (
          <span>{record.saleEndAt}</span>
        );
      },
    },
    {
      title: '상태',
      dataIndex: 'isPublic',
      key: 'isPublic',
      //width: '4%',
      render: (value, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Select
            defaultValue={value}
            style={{ width: 80 }}
            bordered={false}
            options={[
              { value: true, label: '공개' },
              { value: false, label: '비공개' },
            ]}
            onChange={(e) => {
              setEditData({
                ...editData,
                isPublic: e,
              });
            }}
          />
        ) : (
          <span>{record.isPublic ? '공개' : '비공개'}</span>
        );
      },
    },
    {
      title: '판매 상태',
      dataIndex: 'isSellable',
      key: 'isSellable',
      //width: '28%',
      render: (e) => (e ? '판매중' : '미판매중'),
    },
    {
      title: '편집',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <div style={{ width: 150 }}>
            <S.EditButton
              onClick={(e) => {
                e.stopPropagation();
                saveCategory(record.id);
              }}
            >
              저장
            </S.EditButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation();
                setEditingId(null);
              }}
            >
              취소
            </S.DeleteButton>
            <S.DeleteButton
              onClick={(e) => {
                e.stopPropagation(); // 이벤트 버블링을 중단합니다.

                if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
                  deletePackageCategory(record.id)
                    .unwrap()
                    .then((payload) => {
                      alert('삭제가 완료되었습니다');
                    })
                    .catch((error) => alert(error.data.message));
                }
                setEditingId(null);
              }}
            >
              삭제
            </S.DeleteButton>
          </div>
        ) : (
          <S.EditButton
            disabled={editingId !== null}
            onClick={(e) => {
              e.stopPropagation(); // 이벤트 버블링을 중단합니다.
              //편집을 눌렀을떄 해당 record를 가지고 셋을 다시 해
              editCategory(record.id);
              setEditData({
                ...editData,
                name: record.name,
                code: record.code,
                rootId: Number(parentId),
                groupName: record.groupName,
                saleStartAt: record.saleStartAt,
                saleEndAt: record.saleEndAt,
              });
            }}
          >
            편집
          </S.EditButton>
        );
      },
    },
  ];

  const COUPON_COLUMNS = [
    isSort
      ? {
          key: 'sort',
          width: '3%',
          render: () => <MenuOutlined style={{ cursor: 'move' }} />,
        }
      : {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          width: '3%',
        },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
      width: '8%',
    },
    {
      title: '최대 자리',
      dataIndex: 'capacity',
      key: 'capacity',
      width: '13%',
    },
    {
      title: '판매 시작일',
      dataIndex: 'saleStartAt',
      key: 'saleStartAt',
      width: '28%',
    },
    {
      title: '판매 종류일',
      dataIndex: 'saleEndAt',
      key: 'saleEndAt',
      width: '28%',
    },
    {
      title: '판매 상태',
      dataIndex: 'isSellable',
      key: 'isSellable',
      width: '28%',
      render: (e) => (e ? '판매중' : '미판매중'),
    },
  ];

  const { dataSource: categoryDataSource, onDragEnd: categoryOnDragEnd } = useDragSort(
    packageCategory?.content,
    COLUMNS
  );

  const { dataSource, onDragEnd } = useDragSort(packageCategoryPackage?.content, COUPON_COLUMNS);

  const handelSortIds = () => {
    const sortBannerIds = dataSource.map((item) => item.id);
    patchPackageSort({
      courseId: Number(id),
      sortIds: sortBannerIds,
      productPackageCategoryId: parentId,
      isPublic: isPackagePublic,
    })
      .then((payload) => {
        hideSort();
        if (payload) {
          if (payload) messageApi.success('정렬이 완료되었습니다.');
        }
      })
      .catch((error) => {
        messageApi.error(error.data.message);
      });
  };

  const handelCategorySortIds = () => {
    const sortBannerIds = categoryDataSource.map((item) => item.id);
    patchPackageCategorySort({
      courseId: Number(id),
      sortIds: sortBannerIds,
      productPackageCategoryId: parentId,
      isPublic: isPackageCategoryPublic,
    })
      .then((payload) => {
        hideSort();
        if (payload) {
          if (payload) messageApi.success('정렬이 완료되었습니다.');
        }
      })
      .catch((error) => {
        messageApi.error(error.data.message);
      });
  };

  //모달
  const bannerCategoryAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(bannerCategoryAddRef, true);

  const getRowClassName = (record) => {
    // 'status' 속성 값에 따라 다른 클래스 이름을 반환

    if (record.isSellable === true) {
      return 'sellable-row'; // 원하는 클래스 이름
    } else {
      return 'unSellable-row'; // 다른 클래스 이름
    }
    // 특정 조건에 맞지 않는 경우 기본 클래스 이름 반환
    return '';
  };

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <PackageCategoryAddModal ref={bannerCategoryAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          패키지 카테고리 조회
          {isCategorySort ? (
            <>
              <S.SortButton
                onClick={() => {
                  handelCategorySortIds();
                }}
              >
                정렬 완료
              </S.SortButton>
              <S.CancelButton onClick={hideCategorySort}>취소</S.CancelButton>
            </>
          ) : (
            <>
              <S.AddButton
                onClick={(event: any) => {
                  event.stopPropagation();
                  open(true);
                }}
              >
                + 패키지 카테고리 추가
              </S.AddButton>
              <S.SortButton
                onClick={() => {
                  showCategorySort();
                }}
              >
                순서 변경
              </S.SortButton>
            </>
          )}
        </h1>
        <S.StyledSwitch
          unCheckedChildren="비공개"
          checkedChildren="공개"
          checked={isPackageCategoryPublic}
          onChange={setIsPackageCategoryPublic}
        />
        <S.Statistics>
          공개 패키지 카테고리 요소: {packageStatistics?.nonPublicProductPackageCategoryCount}
        </S.Statistics>
        <S.Statistics>
          비공개 패키지 카테고리 요소: {packageStatistics?.publicProductPackageCategoryCount}
        </S.Statistics>
      </S.Container>
      <S.TableContainer>
        {categoryDataSource && (
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={categoryOnDragEnd}>
            <SortableContext
              items={categoryDataSource.map((i) => i.id)} //
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{ body: { row: CustomRow } }}
                dataSource={categoryDataSource as any}
                columns={COLUMNS}
                pagination={false}
                rowClassName={getRowClassName} // 각 행의 클래스 이름을 지정하는 함수
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: async (e) => {
                      if (editingId) return;

                      setParentId(record.id);
                      //setCategoryId(record.id);
                    },
                  };
                }}
              />{' '}
            </SortableContext>
          </DndContext>
        )}
        {packageCategory && (
          <Pagination
            pageSize={5}
            current={categoryPage}
            total={packageCategory.totalElements}
            onChange={setCategoryPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>

      <S.Container>
        <h1>
          패키지 조회
          {isSort ? (
            <>
              <S.SortButton
                onClick={() => {
                  handelSortIds();
                }}
              >
                정렬 완료
              </S.SortButton>
              <S.CancelButton onClick={hideSort}>취소</S.CancelButton>
            </>
          ) : (
            <>
              <S.AddButton onClick={() => navigate(`/newaddpackage/${id}`)}>
                + 패키지 추가
              </S.AddButton>
              <S.SortButton
                onClick={() => {
                  showSort();
                }}
              >
                순서 변경
              </S.SortButton>
            </>
          )}
        </h1>
      </S.Container>
      <S.StyledSwitch
        unCheckedChildren="비공개"
        checkedChildren="공개"
        checked={isPackagePublic}
        onChange={setIsPackagePublic}
      />
      <S.Statistics>
        공개 패키지 요소: {packageStatistics?.nonPublicProductPackageCount}
      </S.Statistics>
      <S.Statistics>
        비공개 패키지 요소: {packageStatistics?.publicProductPackageCount}
      </S.Statistics>
      <S.TableContainer>
        {dataSource && (
          <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
              items={dataSource.map((i) => i.id)} //
              strategy={verticalListSortingStrategy}
            >
              <Table
                components={{ body: { row: CustomRow } }}
                dataSource={dataSource as any}
                columns={COUPON_COLUMNS}
                pagination={false}
                rowClassName={getRowClassName} // 각 행의 클래스 이름을 지정하는 함수
                rowKey={(record) => record.id}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      if (e.ctrlKey || e.metaKey) {
                        window.open(`/package/${record.id}`, '_blank');
                      } else navigate(`/package/${record.id}`);
                    },
                  };
                }}
              />
            </SortableContext>
          </DndContext>
        )}
        {packageCategoryPackage && (
          <Pagination
            pageSize={5}
            current={page}
            total={packageCategoryPackage.totalElements}
            onChange={setPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </>
  );
}

export default PackageList;
