import {
  usePatchProductPackageDiscountMutation,
  useDeleteProductPackageDiscountMutation,
} from 'store/services/package';
import { useNavigate } from 'react-router-dom';
import { PackageDiscount } from 'model/package.model';

const usePackageDetailMutation = (id: Number) => {
  const [patchDiscountInfo] = usePatchProductPackageDiscountMutation(); //네이밍 통일화
  const [deleteDiscountInfo] = useDeleteProductPackageDiscountMutation();
  const navigate = useNavigate();

  const patchDiscount = (form: PackageDiscount) => {
    const body: Partial<PackageDiscount> & { id: number } = {
      id: form.id,
      packageId: form.packageId,
      title: form.title,
      discountRate: form.discountRate,
      discountPrice: form.discountPrice,
      discountInstallmentsMonth: form.discountInstallmentsMonth,
      discountInstallmentsPrice: form.discountInstallmentsPrice,
      startAt: form.startAt,
      endAt: form.endAt,
      isDeleted: form.isDeleted,
    };
    patchDiscountInfo(body)
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
        console.log('fulfilled', payload);
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deleteDiscount = () => {
    navigate(-1);
    deleteDiscountInfo(id)
      .unwrap()
      .then(() => {
        alert('삭제가 완료되었습니다');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  return { patchDiscount, deleteDiscount };
};

export default usePackageDetailMutation;
