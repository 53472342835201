import { useEffect, useState } from 'react';
import * as S from 'components/CustomForm/CustomForm.style';
import styled from 'styled-components';
import { NamePath } from 'antd/lib/form/interface';
import { FormInstance } from 'antd';

const ICONS = [
  'https://image.schooldots.me/assets/info-icons/VedioIcon.png',
  'https://image.schooldots.me/assets/info-icons/NoteIcon.png',
  'https://image.schooldots.me/assets/info-icons/LiveIcon.png',
  'https://image.schooldots.me/assets/info-icons/CalendarIcon.png',
  'https://image.schooldots.me/assets/info-icons/FeedbackIcon.png',
];

export default function InfoIcon({ name, form }: { name: NamePath; form: FormInstance }) {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (!form.getFieldValue(name)) form.setFieldValue(name, ICONS[0]);
    else {
      setSelected(ICONS.findIndex((val) => val === form.getFieldValue(name)));
    }
  }, []);

  return (
    <IconWrapper>
      <S.FormItem label="아이콘">
        <Icon src={ICONS[selected]} alt="" />

        <IconList>
          {ICONS.map((src, idx) => (
            <Icon
              src={src}
              key={src}
              alt=""
              onClick={() => {
                setSelected(idx);
                form.setFieldValue(name, ICONS[idx]);
              }}
            />
          ))}
        </IconList>
      </S.FormItem>
    </IconWrapper>
  );
}

const Icon = styled.img`
  width: 48px;
  height: 48px;
`;

const IconList = styled.div`
  display: none;
  position: absolute;
  top: -46px;
  left: 0;
  border: 1px gray solid;
  cursor: pointer;
  background: white;

  &:hover {
    display: flex;
  }
`;

const IconWrapper = styled.div`
  position: relative;

  ${Icon}:hover ~${IconList} {
    display: flex;
  }
`;
