import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';
import { useAddProductPackageDiscountMutation } from '../../../store/services/package';
import { useParams } from 'react-router-dom';
import { DATETIME_REGEXP_SEC } from 'static/regexp';

const MATCH_LABEL = [
  { name: '패키지 ID', variable: 'packageId', type: 'number' },
  { name: '할인 이름', variable: 'title', type: 'text' },
  { name: '할인율(%)', variable: 'discountRate', type: 'number' },
  { name: '가격 (할인가)', variable: 'discountPrice', type: 'number' },
  { name: 'N개월 할부', variable: 'discountInstallmentsMonth', type: 'number' },
  { name: '월 가격 (N개월 할부 시)', variable: 'discountInstallmentsPrice', type: 'number' },
  { name: '할인 시작 (YYYY-MM-DD HH:MM:SS)', variable: 'startAt', type: 'text' },
  { name: '할인 종료 (YYYY-MM-DD HH:MM:SS)', variable: 'endAt', type: 'text' },
];

const STATUS_ARRAY = ['선택해주세요', 'SALE', 'END_OF_SALE'];

const PackageDiscountAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const { id } = useParams();
  const [addPackageDiscount] = useAddProductPackageDiscountMutation();

  // 패키지 추가
  const emptyPackageDiscountForm = {
    discountPrice: 0,
    discountRate: 0,
    endAt: '',
    packageId: `${id}`,
    startAt: '',
    title: '',
    discountInstallmentsMonth: 0,
    discountInstallmentsPrice: 0,
  };

  const [addPackageDiscountForm, setAddPackageDiscountForm] =
    useState<any>(emptyPackageDiscountForm);

  const onAddPackageDiscount = () => {
    if (
      addPackageDiscountForm?.productPacageIds?.length !== 0 &&
      DATETIME_REGEXP_SEC.test(addPackageDiscountForm.startAt) &&
      DATETIME_REGEXP_SEC.test(addPackageDiscountForm.endAt)
    ) {
      addPackageDiscount(addPackageDiscountForm)
        .unwrap()
        .then(() => {
          setAddPackageDiscountForm({
            emptyPackageDiscountForm,
          });
          open(false);
        })
        .catch((error) => {
          alert('입력하신 정보를 확인해주세요');
          console.log(error.data.message);
        });
    } else alert('날짜 정보를 확인해주세요');
  };

  const { isValid, onChangeText, onChangeNumber } = useValidCheck(
    addPackageDiscountForm,
    setAddPackageDiscountForm
  );

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>패키지 할인 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={addPackageDiscountForm[label.variable]}
              type={label.type}
              onChange={label.type === 'text' ? onChangeText : onChangeNumber}
              modal={true}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={onAddPackageDiscount} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default PackageDiscountAddModal;
