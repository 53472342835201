import * as S from '../styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteUserMutation, useGetUserDeatilQuery } from 'store/services/user';
import {
  useDeleteClassroomEnrollmentMutation,
  useGetClassroomEnrollmentQuery,
} from 'store/services/classroom';
import { Fragment, useState } from 'react';
import { Modal, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import useAntdModal from 'hooks/useAntdModal';
import AddClassroomModal from './components/AddClassroomModal';
import { UserClassroomListHeader } from 'model/classroom.model';
import CIcon from '@coreui/icons-react';
import { cilWarning } from '@coreui/icons';
import ModifyEnrollmentModal from './components/ModifyEnrollmentModal';
import UserMemoInfo from './components/UserMemoInfo';

const CHECKOUT_DETAIL = [
  { name: '유저 ID', dataIndex: 'id', localeString: false, width: 'short' },
  { name: '회원가입일', dataIndex: 'createdAt', localeString: false, width: 'short' },
  { name: '이름', dataIndex: 'name', localeString: false, width: 'long' },
  { name: '닉네임', dataIndex: 'nickname', localeString: false, width: 'long' },
  { name: '신분', dataIndex: 'userType', localeString: false, width: 'long' },
  { name: '전화번호', dataIndex: 'phoneNumber', localeString: false, width: 'long' },
  { name: '이메일', dataIndex: 'email', localeString: false, width: 'long' },
];

const COLUMNS: ColumnsType<UserClassroomListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '클래스룸 ID',
    dataIndex: 'classRoomId',
    key: 'classRoomId',
    width: '7%',
  },
  {
    title: '패키지 ID',
    dataIndex: 'packageId',
    key: 'packageId',
    width: '6%',
  },
  {
    title: '클래스룸명',
    dataIndex: 'title',
    key: 'title',
    width: '27%',
  },
  {
    title: '수강 방식',
    dataIndex: 'type',
    key: 'type',
    width: '9%',
  },
  {
    title: '구매 방식',
    dataIndex: 'method',
    key: 'method',
    width: '9%',
  },
  {
    title: '강의 유효기간',
    dataIndex: 'endAt',
    key: 'endAt',
    width: '15%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    key: 'actions',
    render: (param, { tags }) => (
      <>
        {tags.map((tag) => {
          return <S.SubDeleteButton key={tag}>{tag.toUpperCase()}</S.SubDeleteButton>;
        })}
      </>
    ),
  },
];

function useUserMutation() {
  const [deleteUserRequest] = useDeleteUserMutation();
  const [deleteClassroomEnrollmentRequest] = useDeleteClassroomEnrollmentMutation();
  const navigate = useNavigate();

  const deleteUser = (id: number) => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      deleteUserRequest(id)
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다');
          navigate(-1);
        })
        .catch((error) => alert(error.data.errorMessage));
    }
  };

  const confirmDeleteClassroomEnrollment = (classRoomEnrollmentId, userId) => {
    Modal.confirm({
      title: '수강 권한 회수',
      icon: (
        <span className="anticon">
          <CIcon icon={cilWarning} size="3xl" />
        </span>
      ),
      content: '해당 사용자가 선택한 수업에 접근할 수 없습니다.',
      okText: '회수하기',
      cancelText: '취소',
      onOk: () => deleteClassroomEnrollmentRequest({ classRoomEnrollmentId, userId }),
    });
  };

  return { deleteUser, confirmDeleteClassroomEnrollment };
}

function UserDetail() {
  const { id } = useParams();
  const { data: userData } = useGetUserDeatilQuery(Number(id));
  const [page, setPage] = useState(1);
  const { data: classroomList } = useGetClassroomEnrollmentQuery({ page, userId: Number(id) });
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState(0);
  const { deleteUser, confirmDeleteClassroomEnrollment } = useUserMutation();
  const { isModalOpen, showModal, handleCancel, handleOk } = useAntdModal();
  const {
    isModalOpen: isModifyModalOpen,
    showModal: showModifyModal,
    handleCancel: handleModifyCancel,
    handleOk: handleModifyOk,
  } = useAntdModal();

  return (
    <S.Container>
      {userData && (
        <>
          <S.DepthNavi>{userData.name}</S.DepthNavi>
          <h1>유저 상세 정보</h1>
          <S.CheckoutTable>
            {CHECKOUT_DETAIL.map((item, idx) => {
              return (
                <S.Section key={idx} width={item.width}>
                  <S.SectionName>{item.name}</S.SectionName>
                  <S.SectionValue width={item.width}>{userData[item.dataIndex]}</S.SectionValue>
                </S.Section>
              );
            })}
          </S.CheckoutTable>
          {userData.childrenList.length !== 0 && (
            <>
              <h1>자녀 정보</h1>
              <S.CheckoutTable>
                {userData.childrenList.map((children) => (
                  <Fragment key={children.name}>
                    <S.Section width={'long'}>
                      <S.SectionName>이름</S.SectionName>
                      <S.SectionValue width={'long'}>{children.name}</S.SectionValue>
                    </S.Section>
                    <S.Section width={'short'}>
                      <S.SectionName>생일</S.SectionName>
                      <S.SectionValue width={'short'}>{children.dateOfBirth}</S.SectionValue>
                    </S.Section>
                    <S.Section width={'short'}>
                      <S.SectionName>성별</S.SectionName>
                      <S.SectionValue width={'short'}>{children.gender}</S.SectionValue>
                    </S.Section>
                  </Fragment>
                ))}
              </S.CheckoutTable>
            </>
          )}
        </>
      )}
      {classroomList && (
        <>
          <h1>
            <span>수강 중인 강의</span>
            <S.AddButton type="primary" onClick={showModal} style={{ marginLeft: '20px' }}>
              강의 권한 추가
            </S.AddButton>
            <AddClassroomModal
              visible={isModalOpen}
              onOk={handleOk}
              onCancel={handleCancel}
              userId={Number(id)}
            />
            <ModifyEnrollmentModal
              visible={isModifyModalOpen}
              enrollmentId={selectedEnrollmentId}
              onOk={handleModifyOk}
              onCancel={handleModifyCancel}
              userId={Number(id)}
            />
          </h1>
          <S.TableContainer>
            {classroomList?.content && (
              <Table
                dataSource={classroomList?.content.map((content) => ({
                  ...content,
                  tags: content.method === 'ADMIN' ? ['권한 회수'] : ['권한 수정'],
                }))}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record: any) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      if (record.method === 'ADMIN') {
                        confirmDeleteClassroomEnrollment(record.id, Number(id));
                      } else {
                        setSelectedEnrollmentId(record.id);
                        showModifyModal();
                      }
                    },
                  };
                }}
              />
            )}
            {classroomList && (
              <Pagination
                pageSize={15}
                current={page}
                total={classroomList.totalElements}
                onChange={(page) => {
                  setPage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        </>
      )}
      <UserMemoInfo />
      <S.DeleteButton onClick={() => deleteUser(Number(id))}>탈퇴</S.DeleteButton>
      <br />
    </S.Container>
  );
}

export default UserDetail;
