export default function serverImageToForm(url: string) {
  return [
    {
      uid: url,
      url: url,
      name: url,
      status: 'done',
    },
  ];
}
