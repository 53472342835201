import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IJWTTOKEN } from '../../model/auth.model';
import { useEffect } from 'react';
import { fetchUserById } from '../../store/auth';
import { AppDispatch } from 'store/index';
import DotsLogo from '../../assets/svg/logo-dark.svg';
import * as S from './SigninPage.style';
import BrownJelly from '../../assets/images/BrownJelly.png';
import RedJelly from '../../assets/images/RedJelly.png';

const SigninPage = () => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const handleOnClick = () => {
    window.location.href = process.env.REACT_APP_GOOGLE_REDIRECT_URL ?? '';
  };

  useEffect(() => {
    if (sessionStorage.getItem('accessToken')) {
      navigate('/');
    }
  });

  useEffect(() => {
    if (!location.search.split('=')[1]) return;
    const userId = window.atob(location.search.split('=')[1]);
    const userToken: IJWTTOKEN = JSON.parse(userId);
    sessionStorage.setItem('accessToken', userToken.accessToken as string);
    localStorage.setItem('refreshToken', userToken.refreshToken as string);
    dispatch(fetchUserById());
    navigate('/');
  }, [navigate, dispatch, location]);

  return (
    <S.Container>
      <S.AlignContainer>
        <S.LogoWrap>
          <S.DotsLogo src={DotsLogo}></S.DotsLogo>
        </S.LogoWrap>
        <S.ButtonWrap>
          <S.SigninButton type="button" onClick={handleOnClick}>
            구글로 로그인하기
          </S.SigninButton>
        </S.ButtonWrap>
        <S.Red src={RedJelly}></S.Red>
        <S.Brown src={BrownJelly}></S.Brown>
      </S.AlignContainer>
    </S.Container>
  );
};

export default SigninPage;
