import { useEffect, useState } from 'react';

const useLazyState = <T>(lazyState: T) => {
  const [state, setState] = useState(lazyState);

  useEffect(() => {
    setState(lazyState);
  }, [lazyState]);

  return [state, setState] as const;
};

export default useLazyState;
