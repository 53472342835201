import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';
import { useGetCategoryListQuery, useGetCourseQuery } from 'store/services/course';
import * as S from 'pages/Product/styles';
import useCourseDetailMutation from '../hooks/useCourseDetailMutation';
import { useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { CategoryListHeader } from 'model/category.model';
import { useGetPublicVideoListQuery } from 'store/services/file';
import InfoIcon from './InfoIcon';

const COURSE_STEP_LIST = {
  NONE: 'NONE',
  SEED: 'SEED',
  EARLYBIRD: 'EARLYBIRD',
  SELLING: 'SELLING',
  CLOSED: 'CLOSED',
};

const COURSE_DIFFICULTY_LIST = {
  0: '미정',
  1: '기초',
  2: '기본',
  3: '심화',
};

const COURSE_TYPE_LIST = {
  FREE_ALONE: 'FREE_ALONE',
  WITH_FRIEND: 'WITH_FRIEND',
};

const COLUMNS: ColumnsType<CategoryListHeader> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '20%',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: '50%',
  },
  {
    title: '코드',
    dataIndex: 'categoryCode',
    key: 'categoryCode',
    width: '30%',
  },
];

const FILE_COLUMNS: ColumnsType = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '20%',
  },
  {
    title: '파일명',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '30%',
  },
];

export default function CourseInfo() {
  const { id } = useParams();
  const { data: courseData } = useGetCourseQuery(Number(id));
  const [form] = CustomForm.useForm();
  const { patchCourseInfo } = useCourseDetailMutation(Number(id));
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const { data: categoryList } = useGetCategoryListQuery({ page, searchType: 'ALL', target: '' });

  const [videoVisible, setVideoVisible] = useState(false);
  const [videoPage, setVideoPage] = useState(1);
  const { data: videoList } = useGetPublicVideoListQuery({ page: videoPage });

  return (
    <CustomForm form={form} initialValues={courseData} onFinish={patchCourseInfo}>
      <CustomForm.Checkbox name="isPrivate" label="코스 비공개" />
      <CustomForm.InputNumber label="ID" name="id" disabled />
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input label="생성일" name="createdAt" disabled />
        <CustomForm.Input label="최종 수정일" name="updatedAt" disabled />
      </CustomForm.GridRow>
      <CustomForm.Input label="코스 제목" name="title" rules={[{ required: true }]} />
      <CustomForm.TextArea label="결제창 문구" name="subTitle" />
      <CustomForm.Input label="코스 설명(미사용)" name="description" />
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.InputNumber label="나이(+)" name="age" />
        <CustomForm.InputNumber label="총 주차" name="week" />
        <CustomForm.Select label="난이도" name="difficulty" options={COURSE_DIFFICULTY_LIST} />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.InputNumber label="노출 점수" name="exposureScore" />
        <CustomForm.InputNumber label="강의 개수" name="lectureCount" />
        <CustomForm.InputNumber label="미션 개수" name="missionCount" />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input
          label="시작일"
          name="startAt"
          rules={[
            {
              pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
              message: 'YYYY-MM-DD 형식으로 작성해주세요!',
              required: true,
            },
          ]}
        />
        <CustomForm.Input
          label="종료일"
          name="endAt"
          rules={[
            {
              pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
              message: 'YYYY-MM-DD 형식으로 작성해주세요!',
              required: true,
            },
          ]}
        />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Select label="판매 종류" name="courseType" options={COURSE_TYPE_LIST} />
        <CustomForm.GridRow gridColumns="1fr auto">
          <CustomForm.InputNumber label="미리보기 VOD ID" name="previewVideoId" />
          <CustomForm.Button onClick={() => setVideoVisible(true)} htmlType="button" ghost>
            검색
          </CustomForm.Button>
        </CustomForm.GridRow>
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Select label="판매 단계" name="courseStep" options={COURSE_STEP_LIST} />
        <CustomForm.GridRow gridColumns="1fr auto">
          <CustomForm.InputNumber label="카테고리 ID" name="courseCategoryId" />
          <CustomForm.Button onClick={() => setVisible(true)} htmlType="button" ghost>
            검색
          </CustomForm.Button>
        </CustomForm.GridRow>
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input label="판매시 이동 url" name="sellingUrl" />
        <CustomForm.Input label="메인 컬러" name="mainColor" />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input label="최상단 카테고리 타이틀" name="packageTitle" />
      </CustomForm.GridRow>
      <CustomForm.Divider orientation="left">썸네일 (w:400, h:400)</CustomForm.Divider>
      <CustomForm.Upload name="thumbnail" />
      <CustomForm.Divider orientation="left">모바일 썸네일 (w:312, h:180)</CustomForm.Divider>
      <CustomForm.Upload name="mobileThumbnail" />
      <CustomForm.Divider orientation="left">상세페이지 썸네일 </CustomForm.Divider>
      <CustomForm.Upload name="detailThumbnail" />
      <CustomForm.Divider orientation="left">
        모바일 상세페이지 썸네일 (w:312, h:180)
      </CustomForm.Divider>
      <CustomForm.Upload name="mobileDetailThumbnail" />
      <CustomForm.Divider orientation="left">강의정보</CustomForm.Divider>
      <CustomForm.List name="info">
        {(info, { add, remove }) => (
          <div>
            {info.map((id, index) => (
              <CustomForm.GridRow gridColumns="auto 1fr 1fr auto" key={index}>
                <InfoIcon name={['info', index, 'icon']} form={form} />
                <CustomForm.Input label="설명" name={[index, 'content']} />
                <CustomForm.Input label="추가 설명" name={[index, 'description']} />
                <CustomForm.Button onClick={() => remove(index)} htmlType="button" danger>
                  삭제
                </CustomForm.Button>
              </CustomForm.GridRow>
            ))}
            <CustomForm.Button onClick={() => add()} htmlType="button">
              추가
            </CustomForm.Button>
          </div>
        )}
      </CustomForm.List>
      <S.SaveWrap>
        <button type="submit">저장</button>
      </S.SaveWrap>
      {categoryList && (
        <CustomForm.TableSelectModal
          columns={COLUMNS}
          list={categoryList.content}
          visible={visible}
          onCancel={() => setVisible(false)}
          onSelect={(record) => {
            form.setFieldValue('courseCategoryId', record.id);
            setVisible(false);
          }}
          page={page}
          setPage={setPage}
          totalElements={categoryList.totalElements}
        />
      )}
      {videoList && (
        <CustomForm.TableSelectModal
          columns={FILE_COLUMNS}
          list={videoList.content}
          visible={videoVisible}
          onCancel={() => setVideoVisible(false)}
          onSelect={(record) => {
            form.setFieldValue('previewVideoId', record.id);
            setVideoVisible(false);
          }}
          page={videoPage}
          setPage={setVideoPage}
          totalElements={videoList.totalElements}
        />
      )}
    </CustomForm>
  );
}
