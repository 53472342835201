import * as S from 'pages/Product/styles';
import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';
import useClassroomDetailMutation from '../../hooks/useClassroomDetailMutation';

function ChapterInfo({ chapterData }) {
  const { classroomId, id } = useParams();
  const [form] = CustomForm.useForm();
  const { patchChapter } = useClassroomDetailMutation({
    chapterId: Number(id),
    classroomId: Number(classroomId),
  });
  return (
    <CustomForm form={form} initialValues={chapterData} onFinish={patchChapter}>
      <CustomForm.Checkbox name="isPrivate" label="챕터 비공개" />
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr 1fr">
        <CustomForm.InputNumber label="챕터 ID" name="id" disabled />
        <CustomForm.InputNumber label="챕터 순서" name="ord" disabled />
        <CustomForm.Input label="생성일" name="createdAt" disabled />
        <CustomForm.Input label="최종 수정일" name="updatedAt" disabled />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.Input label="챕터 라벨 (N주차)" name="sequence" rules={[{ required: true }]} />
        <CustomForm.Input label="챕터 제목" name="title" rules={[{ required: true }]} />
        <CustomForm.Input label="라이브 날짜&시간" name="liveAt" disabled />
      </CustomForm.GridRow>

      <CustomForm.Divider orientation="left">챕터 썸네일 (비율 w3:h2)</CustomForm.Divider>
      <CustomForm.Upload name="thumbnail" />
      <S.SaveWrap>
        <button type="submit">저장</button>
      </S.SaveWrap>
    </CustomForm>
  );
}

export default ChapterInfo;
