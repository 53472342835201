import { useParams } from 'react-router-dom';
import { useGetClassroomDetailQuery } from 'store/services/classroom';
import DetailTabs from 'components/DetailTabs';
import * as S from '../../styles';
import useClassroomDetailMutation from './hooks/useClassroomDetailMutation';
import ClassroomInfo from '../ClassroomDetail/components/ClassroomInfo';
import ClassroomMentor from './components/ClassroomMentor';
import Chapter from './components/Chapter/ChapterList';
import ClassroomUserList from './components/ClassroomUser/ClassroomUserList';
import ClassroomAlarm from './components/ClassroomAlarm/ClassroomAlarm';

const TAB_LIST = ['기본 정보', '라이브 튜터', '챕터', '수강 중인 유저', '알림톡'];

function ClassroomDetail() {
  const { id } = useParams();
  const { data: classroomData } = useGetClassroomDetailQuery(Number(id));
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);
  const { deleteClassroom } = useClassroomDetailMutation({ classroomId: Number(id) });
  const { postClassroomDuplicate } = useClassroomDetailMutation({ classroomId: Number(id) });
  return (
    <S.Container>
      {classroomData && (
        <>
          <S.DepthNavi>
            <S.CurrentPage>{classroomData?.title}</S.CurrentPage>
          </S.DepthNavi>
          <h1>
            클래스룸 수정
            <S.DeleteButton onClick={() => deleteClassroom(classroomData)}>삭제</S.DeleteButton>
            <S.CopyButton onClick={() => postClassroomDuplicate()}>복사</S.CopyButton>
          </h1>
          <DetailTabs tabs={tabs} selected={currentTab} />
          {currentTab === TAB_LIST[0] && <ClassroomInfo />}
          {currentTab === TAB_LIST[1] && <ClassroomMentor />}
          {currentTab === TAB_LIST[2] && <Chapter />}
          {currentTab === TAB_LIST[3] && <ClassroomUserList />}
          {currentTab === TAB_LIST[4] && <ClassroomAlarm />}
        </>
      )}
    </S.Container>
  );
}

export default ClassroomDetail;
