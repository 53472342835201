import { configureStore } from '@reduxjs/toolkit';
import { logger } from 'redux-logger';
import sideNav from './sideNav';
import auth from './auth';
import { packageApi } from './services/package';
import { courseApi } from './services/course';
import { classroomApi } from './services/classroom';
import { mentoApi } from './services/mento';
import { hashTagApi } from './services/hashtag';
import { couponApi } from './services/coupon';
import { collectionApi } from './services/collection';
import { checkoutApi } from './services/checkout';
import { specificReplyApi } from './services/specificReply';
import { userApi } from './services/user';
import { fileApi } from './services/file';
import { alarmApi } from './services/alarm';
import { bannerApi } from './services/banner';

const store = configureStore({
  reducer: {
    sideNav,
    auth,
    [packageApi.reducerPath]: packageApi.reducer,
    [courseApi.reducerPath]: courseApi.reducer,
    [classroomApi.reducerPath]: classroomApi.reducer,
    [mentoApi.reducerPath]: mentoApi.reducer,
    [hashTagApi.reducerPath]: hashTagApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [collectionApi.reducerPath]: collectionApi.reducer,
    [checkoutApi.reducerPath]: checkoutApi.reducer,
    [specificReplyApi.reducerPath]: specificReplyApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [alarmApi.reducerPath]: alarmApi.reducer,
    [bannerApi.reducerPath]: bannerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      logger,
      packageApi.middleware,
      courseApi.middleware,
      classroomApi.middleware,
      mentoApi.middleware,
      hashTagApi.middleware,
      couponApi.middleware,
      collectionApi.middleware,
      checkoutApi.middleware,
      specificReplyApi.middleware,
      userApi.middleware,
      fileApi.middleware,
      alarmApi.middleware,
      bannerApi.middleware
    ),
});

export default store;
export type AppDispatch = typeof store.dispatch;
