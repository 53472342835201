import { Button, Input, Modal, Pagination, Table } from 'antd';
import { useState } from 'react';
import { useGetUserListQuery } from 'store/services/user';
import * as S from '../../styles';
import { ColumnsType } from 'antd/es/table';
import { UserListHeader } from 'model/user.model';
import {
  useChangeEnrollmentMutation,
  useTransferEnrollmentMutation,
} from 'store/services/classroom';
import { useGetCourseListQuery } from 'store/services/course';
import {
  useGetPackageCategoryPackageListQuery,
  useGetPackageCategoryQuery,
} from 'store/services/package';
import { CourseListHeader } from 'model/course.model';
import useFileSearchParams from 'hooks/useFileSearchParams';
import useBooleanSearchParams from 'hooks/useBooleanSearchParams';

const COURSE_COLUMNS: ColumnsType<CourseListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '코스명',
    dataIndex: 'title',
    key: 'title',
    width: '38%',
  },
  {
    title: '시리즈(카테고리)',
    dataIndex: 'categoryName',
    key: 'categoryName',
    width: '15%',
  },
  {
    title: '코스 스텝',
    dataIndex: 'courseStep',
    key: 'courseStep',
    width: '10%',
  },
  {
    title: '공개 여부',
    dataIndex: 'isPrivate',
    key: 'isPrivate',
    render: (value) => (value == true ? '비공개' : '공개'),
    width: '8%',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <Button key={tag}>{tag.toUpperCase()}</Button>;
        })}
      </>
    ),
  },
];

// const PACKAGE_COLUMNS = [
//   {
//     title: 'ID',
//     dataIndex: 'id',
//     key: 'id',
//   },
//   {
//     title: '패키지명',
//     dataIndex: 'title',
//     key: 'title',
//   },
//   {
//     title: '연결된 클래스룸 ID',
//     dataIndex: 'classroomId',
//     key: 'classroomId',
//   },
//   {
//     title: '연결된 클래스룸 이름',
//     dataIndex: 'classroomTitle',
//     key: 'classroomTitle',
//   },
//   {
//     title: '생성일',
//     dataIndex: 'createdAt',
//     key: 'createdAt',
//   },
// ];

const PACKAGE_CATEGORY_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '카테고리 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
  {
    title: '하위 패키지 수',
    dataIndex: 'numberOfProductPackages',
    key: 'numberOfProductPackages',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];
const PACKAGE_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '패키지명',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: '최대 자리',
    dataIndex: 'capacity',
    key: 'capacity',
  },
  {
    title: '연결된 클래스룸 이름',
    dataIndex: 'classroomTitle',
    key: 'classroomTitle',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

const COLUMNS: ColumnsType<UserListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '성함',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '닉네임',
    dataIndex: 'nickname',
    key: 'nickname',
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: '생년월일',
    dataIndex: 'birthYear',
    key: 'birthYear',
  },
  {
    title: '휴대폰 번호',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: '유저 타입',
    dataIndex: 'userType',
    key: 'userType',
  },
];

function ModifyEnrollmentModal({ visible, onOk, onCancel, userId, enrollmentId }) {
  const [isTransfer, setIsTransfer] = useState<boolean | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [target, setTarget] = useState('');
  const [userPage, setUserPage] = useState(1);
  const { data: userList } = useGetUserListQuery(
    {
      page: userPage,
      searchType: 'ALL',
      target,
      type: 'ALL',
      state: 'ALL',
      ordType: '',
    },
    {
      skip: !isTransfer,
    }
  );
  const [transferEnrollment] = useTransferEnrollmentMutation();
  const [page, setPage] = useState(1);

  const { data: courseList } = useGetCourseListQuery({
    page,
    stepType: 'ALL',
    stateType: 'ALL',
    searchType: 'ALL',
    ordType: 'RECENT',
    target: '',
  });
  const [selectedCourseId, setSelectedCourseId] = useState<null | number>(null);
  const [selectedPackageId, setSelectedPackageId] = useState<null | number>(null);
  const [selectedCourseTitle, setSelectedCourseTitle] = useState<null | string>(null);

  const [parentId, setParentId] = useFileSearchParams('parentId');
  const [isPackageCategoryPublic, setIsPackageCategoryPublic] =
    useBooleanSearchParams('categoryPublic');
  const [isPackagePublic, setIsPackagePublic] = useBooleanSearchParams('packagePublic');
  const [packagePage, setPackagePage] = useState(1); //패키지 관련
  const [categoryPage, setCategoryPage] = useState(1); //카테고리 관련한 페이지네이션

  const { data: packageCategory } = useGetPackageCategoryQuery(
    {
      //여기가 새로운 패키지
      courseId: Number(selectedCourseId),
      rootProductPackageCategoryId: parentId,
      page: categoryPage,
      size: 3,
      isPublic: isPackageCategoryPublic,
    },
    { skip: selectedCourseId === null }
  );
  console.log(
    'packageCategory 테스트입니다 이게 패키지 카테고리 조회에 띄울 녀석이에요 그리고 선택을 하면  RootId가 바뀌어여얗효요',
    packageCategory
  );

  const { data: packageCategoryPackage } = useGetPackageCategoryPackageListQuery(
    {
      //여기가 새로운 패키지 진짜 패키지 목록들
      courseId: Number(selectedCourseId),
      productPackageCategoryId: parentId,
      page: packagePage,
      size: 3,
      isPublic: isPackagePublic,
    },
    { skip: selectedCourseId === null }
  );
  console.log('packageCategoryPackage 테스트입니다 ', packageCategoryPackage);

  const [changeEnrollment] = useChangeEnrollmentMutation();

  return (
    <Modal
      title="강의 권한 수정"
      visible={visible}
      onOk={() => {
        if (isTransfer === true) {
          transferEnrollment({
            classRoomEnrollmentId: Number(enrollmentId),
            userId,
            targetUserId: selectedUserId!,
          });
        } else if (isTransfer === false) {
          changeEnrollment({
            classRoomEnrollmentId: Number(enrollmentId),
            userId,
            productPackageId: selectedPackageId!,
          });
        }
        onOk();
      }}
      onCancel={() => {
        onCancel();
        setSelectedCourseId(null);
        setSelectedCourseTitle(null);
        setSelectedPackageId(null);
        setParentId('');
      }}
      width={1000}
    >
      {isTransfer === null ? (
        <>
          <Button onClick={() => setIsTransfer(true)}>수강권 이전</Button>
          <Button onClick={() => setIsTransfer(false)}>수강 반 변경</Button>
        </>
      ) : (
        <>
          <Button type="primary" onClick={() => setIsTransfer(null)}>
            수정 방식 변경
          </Button>
        </>
      )}
      {isTransfer === true && (
        <S.Container>
          <h1>이전 대상 ID: {selectedUserId}</h1>
          <div>
            닉네임/전화번호로 검색:
            <Input onChange={(e) => setTarget(e.target.value)} value={target} />
          </div>
          <S.TableContainer>
            {userList && (
              <Table
                dataSource={userList?.content.map((user, idx) => {
                  return { ...user, tags: ['수정'] };
                })}
                columns={COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      setSelectedUserId(record.id);
                    },
                  };
                }}
              />
            )}
            {userList && (
              <Pagination
                pageSize={15}
                current={userPage}
                total={userList.totalElements}
                onChange={(page) => {
                  setUserPage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        </S.Container>
      )}
      {isTransfer === false && (
        <S.Container>
          <Button
            type="primary"
            onClick={() => {
              setSelectedCourseId(null);
              setSelectedCourseTitle(null);
              setSelectedPackageId(null);
              setParentId('');
            }}
            style={{ position: 'absolute', left: 140, top: 78 }}
          >
            <strong>{'코스 목록으로'}</strong>
          </Button>
          <h1>옮길 코스 타이틀: {selectedCourseTitle}</h1>
          <h1>패키지 ID: {selectedPackageId}</h1>
          {courseList?.content && (
            <S.TableContainer>
              {selectedCourseId && packageCategory ? (
                <>
                  {' '}
                  <S.StyledSwitch
                    unCheckedChildren="비공개"
                    checkedChildren="공개"
                    checked={isPackageCategoryPublic}
                    onChange={setIsPackageCategoryPublic}
                  />
                  <Table
                    dataSource={packageCategory.content}
                    columns={PACKAGE_CATEGORY_COLUMNS}
                    pagination={false}
                    rowKey={(record) => {
                      return record.id;
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => {
                          setParentId(record.id); //이거는 이제 카테고리 고를때야
                        },
                      };
                    }}
                  />
                  <Pagination
                    pageSize={3}
                    current={categoryPage}
                    total={packageCategory.totalElements}
                    onChange={(categoryPage) => {
                      setCategoryPage(categoryPage);
                    }}
                    showSizeChanger={false}
                    showQuickJumper
                  />{' '}
                  <S.StyledSwitch
                    unCheckedChildren="비공개"
                    checkedChildren="공개"
                    checked={isPackagePublic}
                    onChange={setIsPackagePublic}
                  />
                  <Table
                    dataSource={packageCategoryPackage?.content}
                    columns={PACKAGE_COLUMNS}
                    pagination={false}
                    rowKey={(record) => {
                      return record.id;
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => {
                          setSelectedPackageId(record.id); //이거는 이제 패키지 고를때야
                          //setParentId(record.id); //이거는 이제 카테고리 고를때야
                        },
                      };
                    }}
                  />
                  <Pagination
                    pageSize={3}
                    current={packagePage}
                    total={packageCategoryPackage?.totalElements}
                    onChange={(packagePage) => {
                      setPackagePage(packagePage);
                    }}
                    showSizeChanger={false}
                    showQuickJumper
                  />
                </>
              ) : (
                <>
                  <Table
                    dataSource={courseList?.content.map((content) => ({
                      ...content,
                      tags: ['선택'],
                    }))}
                    columns={COURSE_COLUMNS}
                    pagination={false}
                    rowKey={(record: any) => {
                      return record.id;
                    }}
                    onRow={(record, rowIndex) => {
                      return {
                        onClick: (e) => {
                          setSelectedCourseId(record.id);
                          setSelectedCourseTitle(record.title);
                        },
                      };
                    }}
                  />
                  <Pagination
                    pageSize={10}
                    current={page}
                    total={courseList.totalElements}
                    onChange={(page) => {
                      setPage(page);
                    }}
                    showSizeChanger={false}
                    showQuickJumper
                  />
                </>
              )}
            </S.TableContainer>
          )}
        </S.Container>
      )}
    </Modal>
  );
}

export default ModifyEnrollmentModal;
