import * as S from '../styles';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CustomForm from 'components/CustomForm';

import { useGetBannerCategoryQuery, useGetBannerDetailQuery } from 'store/services/banner';
import useBannerDetailMutation from './hooks/useBannerDetailMutation';

const COLLECTION_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '컬렉션 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '하위 배너 수',
    dataIndex: 'numberOfBanners',
    key: 'numberOfBanners',
  },
  {
    title: '하위 카테고리 수',
    dataIndex: 'numberOfChildren',
    key: 'numberOfChildren',
  },
];

function BannerInfo() {
  const { id } = useParams();

  const { data: bannerData } = useGetBannerDetailQuery(`${id}`);

  const { patchBannerInfo } = useBannerDetailMutation();
  const [categoryPage, setcategoryPage] = useState(1);

  const { data: bannerCategory } = useGetBannerCategoryQuery({
    size: 5,
    page: categoryPage,
    rootBannerCategoryId: 0,
  });

  const [categoryVisible, setCategoryVisible] = useState(false);

  const [form] = CustomForm.useForm();

  return (
    <>
      {bannerData && (
        <CustomForm
          form={form}
          initialValues={bannerData}
          // onFinish={() => handlePatchCoupon(form.getFieldsValue(true))}
          onFinish={(values) => patchBannerInfo(Number(id), values)}
        >
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="배너 ID" name="id" disabled />
            <CustomForm.Input label="배너 제목" name="title" rules={[{ required: true }]} />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.InputNumber
                label="배너 카테고리 ID"
                name="bannerCategoryId"
                // rules={[{ required: true }]}
              />
              <CustomForm.Button onClick={() => setCategoryVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.Input label="배너 카테고리" name="bannerCategoryName" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input label="배경색" name="backgroundColor" rules={[{ required: true }]} />
            <CustomForm.Input label="이동 url" name="url" />
          </CustomForm.GridRow>
          <CustomForm.Divider orientation="left">PC 배너 이미지</CustomForm.Divider>
          <CustomForm.Upload name="imgSrc" rules={[{ required: true }]} />
          <CustomForm.Divider orientation="left">Mobile 배너 이미지</CustomForm.Divider>
          <CustomForm.Upload name="mimgSrc" rules={[{ required: true }]} />
          <CustomForm.Switch
            label="공개범위"
            checkedChildren="비공개"
            unCheckedChildren="공개"
            name="isPrivate"
          />

          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>

          {bannerCategory && (
            <CustomForm.TableSelectModal
              columns={COLLECTION_COLUMNS}
              list={bannerCategory.content}
              visible={categoryVisible}
              onCancel={() => setCategoryVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ bannerCategoryId: record.id }); //그냥 표시
                form.setFieldsValue({ bannerCategoryName: record.name }); //그냥 표시
                setCategoryVisible(false);
              }}
              page={categoryPage}
              setPage={setcategoryPage}
              totalElements={bannerCategory.totalElements}
            />
          )}
        </CustomForm>
      )}
    </>
  );
}

export default BannerInfo;
