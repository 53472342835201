import * as S from './InputModal.style';
import * as SS from '../styles';
import close from '../../../assets/images/close.png';
import React, { useState } from 'react';
import { useAddHashtagMutation } from '../../../store/services/hashtag';
import TextInput from '../../../components/form/TextInput';
import useValidCheck from '../../../hooks/useValidCheck';

const HashtagAddModal = React.forwardRef<HTMLDivElement, any>(({ open }, ref) => {
  const MATCH_LABEL = [{ name: '해시태그 이름', variable: 'tag', type: 'text' }];
  const [addHashtag] = useAddHashtagMutation();

  // 해시태그 추가
  const [addHashtagForm, setAddHashtagForm] = useState<any>({
    tag: '',
  });

  const onAddHashTag = () => {
    addHashtag(addHashtagForm)
      .unwrap()
      .then(() => {
        setAddHashtagForm({
          tag: '',
        });
        open(false);
      })
      .catch((error) => {
        console.log(error.data.errorMessage);
      });
  };

  const { isValid, onChangeText } = useValidCheck(addHashtagForm, setAddHashtagForm);

  return (
    <S.Container ref={ref}>
      <S.CloseButton src={close} onClick={() => open(false)} />
      <S.Title>해시태그 생성</S.Title>
      <S.InputList>
        {MATCH_LABEL.map((label) => {
          return (
            <TextInput
              key={label.name}
              label={label.name}
              name={label.variable}
              value={addHashtagForm[label.variable]}
              type={label.type}
              onChange={onChangeText}
              modal={true}
            />
          );
        })}
      </S.InputList>
      <SS.SaveWrap onClick={onAddHashTag} modal={true}>
        <button disabled={!isValid}>저장</button>
      </SS.SaveWrap>
    </S.Container>
  );
});

export default HashtagAddModal;
