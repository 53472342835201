import * as S from '../../styles';
import { useGetCourseQuery } from 'store/services/course';
import { useParams } from 'react-router-dom';
import DetailTabs from 'components/DetailTabs';
import CourseInfo from './components/CourseInfo';
import CourseMentor from './components/CourseMentor';
import CourseHashTag from './components/CourseHashtag';
import CourseComponentList from './components/CourseComponentList';
import useCourseDetailMutation from './hooks/useCourseDetailMutation';
import PackageList from '../../Package/PackageList';

const TAB_LIST = [
  '코스 정보',
  '동영상 튜터',
  '해시태그',
  '컴포넌트',
  // '패키지 정보',
  '패키지 정보',
];

function CourseDetail() {
  const { id } = useParams();
  const { data: courseData } = useGetCourseQuery(Number(id));
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);
  const { deleteCourse, postCourseDuplicate } = useCourseDetailMutation(Number(id));

  return (
    <S.Container>
      {courseData && (
        <>
          <S.DepthNavi>
            <S.CurrentPage>{courseData?.title}</S.CurrentPage>
          </S.DepthNavi>
          <h1>
            코스 수정
            <S.DeleteButton onClick={() => deleteCourse(courseData)}>삭제</S.DeleteButton>
            <S.CopyButton onClick={() => postCourseDuplicate()}>복사</S.CopyButton>
          </h1>
          <DetailTabs tabs={tabs} selected={currentTab} />
          {currentTab === TAB_LIST[0] && <CourseInfo />}
          {currentTab === TAB_LIST[1] && <CourseMentor />}
          {currentTab === TAB_LIST[2] && <CourseHashTag />}
          {currentTab === TAB_LIST[3] && <CourseComponentList />}
          {/* {currentTab === TAB_LIST[4] && <CoursePackage />} */}
          {currentTab === TAB_LIST[4] && <PackageList />}
        </>
      )}
    </S.Container>
  );
}

export default CourseDetail;
