import * as S from 'pages/Product/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetChapterQuery, useGetClassroomDetailQuery } from 'store/services/classroom';
import DetailTabs from 'components/DetailTabs';
import ChapterInfo from './ChapterInfo';
import LectureList from '../Lecture/LectureList';
import useClassroomDetailMutation from '../../hooks/useClassroomDetailMutation';

const TAB_LIST = ['챕터', '강의'];

function ChapterDetail() {
  const navigate = useNavigate();
  const { classroomId, id } = useParams();
  const { data: classroomData } = useGetClassroomDetailQuery(Number(classroomId));
  const { data: chapterData } = useGetChapterQuery({
    classroomId: Number(classroomId),
    id: Number(id),
  });
  const { deleteChapter } = useClassroomDetailMutation({
    chapterId: Number(id),
    classroomId: Number(classroomId),
  });
  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  return (
    <S.Container>
      {chapterData && (
        <>
          <S.DepthNavi>
            <S.NaviButton onClick={() => navigate(`/classroom/${classroomId}`)}>
              {classroomData?.title}
            </S.NaviButton>
            <span> / </span>
            <S.CurrentPage>{chapterData?.title}</S.CurrentPage>
          </S.DepthNavi>
          <h1>
            챕터 정보
            <S.DeleteButton onClick={deleteChapter}>삭제</S.DeleteButton>
          </h1>
          <DetailTabs tabs={tabs} selected={currentTab} />
          {currentTab === TAB_LIST[0] && <ChapterInfo chapterData={chapterData} />}
          {currentTab === TAB_LIST[1] && <LectureList chapterData={chapterData} />}
        </>
      )}
    </S.Container>
  );
}

export default ChapterDetail;
