import { useNavigate, useParams } from 'react-router-dom';
import {
  useAddCourseComponentMutation,
  useDeleteCourseComponentMutation,
  usePatchComponentOrdMutation,
  usePatchCourseComponentMutation,
} from 'store/services/course';

const useCourseComponentMutation = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [addCourseComponentRequest] = useAddCourseComponentMutation();
  const [patchCourseComponentRequest] = usePatchCourseComponentMutation();
  const [deleteCourseComponentRequest] = useDeleteCourseComponentMutation();
  const [patchCourseComponentOrdRequest] = usePatchComponentOrdMutation();

  const addCourseComponent = (type) => {
    const body: any = {
      title: type,
      type,
      isPrivate: true,
      isPartOfHeader: false,
    };

    switch (type) {
      case 'EDITOR':
        body.editor = '';
        break;
      case 'INTRODUCE':
        body.editor = '';
        body.courseIntroduce = {
          title: '',
          subtitle: '',
          hashValue: '',
          summary: '',
          description: '',
          courseIntroduceCards: [],
        };
        break;
      case 'REPLY':
      case 'REVIEW':
        body.classRoomIds = [];
        break;
      case 'FAQ':
        body.content = {};
        break;
      case 'MATERIALS':
        body.materials = {
          exists: true,
          content: '',
        };
        break;
      case 'MENTO':
        break;
      case 'SUMMARY':
        body.courseSummary = {
          editorFirst: '',
          editorSecond: '',
          imageSlideFirst: [],
          imageSlideSecond: [],
        };
        break;
      case 'IMAGE_SLIDE':
        body.courseImageSlides = [];
        break;
      case 'CURRICULUM2':
        body.content = {
          curriculum: {},
        };
        break;
      case 'CLASS_INFO_SET':
        body.content = {
          classInfoSet: {},
        };
        break;
      case 'PRICE_SET':
        body.content = {
          priceSet: {
            contents: [],
          },
        };
        break;
      case 'CLASS_SET':
        body.content = {
          classSet: {},
        };
        break;
      case 'TUTOR_SET':
        body.content = {
          tutorSet: {},
        };
        break;
      case 'SPECIAL_SECTION_1':
        body.content = {};
        break;
      case 'SPECIAL_SECTION_2':
        body.content = {};
        break;
      case 'MATERIALS_2':
        body.content = {};
        break;
    }
    addCourseComponentRequest({
      id,
      ...body,
    });
  };

  const patchCourseComponent = (form) => {
    if (form.courseSummary) {
      form.courseSummary = {
        editorFirst: form.courseSummary.editorFirst,
        editorSecond: form.courseSummary.editorSecond,
        imageSlideFirst: form.courseSummary.imageSlideFirst.map((item) => ({
          image: item.image[0].url,
          title: item.title,
        })),
        imageSlideSecond: form.courseSummary.imageSlideSecond.map((item) => ({
          image: item.image[0].url,
          title: item.title,
          subTitle: item.subTitle,
        })),
      };
    }
    if (form.courseImageSlides) {
      form.courseImageSlides = form.courseImageSlides.map((item) => ({
        image: item.image[0].url,
      }));
    }
    if (form.content.priceSet) {
      form.content.priceSet = {
        ...form.content.priceSet,
        contents: form.content.priceSet.contents.map((item: any) => ({
          ...item,
          image: item.image[0].url,
        })),
      };
    }
    if (form.content.classSet) {
      form.content.classSet = {
        ...form.content.classSet,
        contents: form.content.classSet.contents.map((item: any) => ({
          ...item,
          image: item.image[0].url,
        })),
      };
    }
    if (form.content.tutorSet) {
      form.content.tutorSet = {
        mainTutor: {
          ...form.content.tutorSet.mainTutor,
          image: form.content.tutorSet.mainTutor.image[0].url,
          icon: form.content.tutorSet.mainTutor.icon[0].url,
          thumbnail: form.content.tutorSet.mainTutor.thumbnail[0].url,
        },
        tutorIntroduce: {
          title: form.content.tutorSet.tutorIntroduce.title,
          description: form.content.tutorSet.tutorIntroduce.description,
          nameColor: form.content.tutorSet.tutorIntroduce.nameColor,
          titleColor: form.content.tutorSet.tutorIntroduce.titleColor,
          descriptionColor: form.content.tutorSet.tutorIntroduce.descriptionColor,
          tutors: form.content.tutorSet.tutorIntroduce.tutors.map((item: any) => ({
            ...item,
            image: item.image[0].url,
          })),
        },
        courseTutorPreview: {
          ...form.content.tutorSet.courseTutorPreview,
          thumbnail: form.content.tutorSet.courseTutorPreview.thumbnail[0].url,
        },
      };
    }
    if (form.content.curriculum) {
      form.content.curriculum = {
        title: form.content.curriculum.title,
        description: form.content.curriculum.description,
        backgroundColor: form.content.curriculum.backgroundColor,
        hoverFontColor: form.content.curriculum.hoverFontColor,
        hoverBackgroundColor: form.content.curriculum.hoverBackgroundColor,
        classRoomChapters: form.content.curriculum.classRoomChapters.map((item: any) => ({
          ...item,
          thumbnail: item.thumbnail[0].url,
        })),
      };
    }

    console.log(form);
    patchCourseComponentRequest({
      courseId: id,
      id: form.id,
      componentForm: form,
    })
      .unwrap()
      .then(() => {
        alert('컴포넌트 수정이 완료되었습니다.');
        // 이상하게 Summary 업데이트할때 에러가 나서 강제로 새고로침을 유지해둔 상태.
        // Upload쪽에서 무언가 에러가 나는거같은데 찾아갈 수가 없네영..ㅠ
        window.location.href = window.location.href;
      });
  };

  const deleteCourseComponent = ({ courseId, id }) => {
    deleteCourseComponentRequest({ courseId, id })
      .unwrap()
      .then(() => {
        alert('성공적으로 삭제되었습니다.');
        navigate(-1);
      });
  };

  const patchCourseComponentOrd = (list) => {
    patchCourseComponentOrdRequest({ id, componentIds: list.map((data) => data.id) });
  };

  return {
    addCourseComponent,
    patchCourseComponent,
    deleteCourseComponent,
    patchCourseComponentOrd,
  };
};

export default useCourseComponentMutation;
