import {
  usePatchPackageMutation,
  useDeletePackageMutation,
  usePostPackageDuplicateMutation,
} from 'store/services/package';
import { PackageForm } from 'model/package.model';
import { useNavigate } from 'react-router-dom';

const usePackageInfoMutation = (id: number) => {
  const [patchPackageInfo] = usePatchPackageMutation();
  const [deletePackageInfo] = useDeletePackageMutation();
  const [postPackageDuplicateRequest] = usePostPackageDuplicateMutation();
  const navigate = useNavigate();

  const patchPackage = (form: PackageForm) => {
    const body: Partial<PackageForm> & { id: number } = {
      id: form.id,
      //courseId: form.courseId,
      classRoomId: form.classRoomId,
      title: form.title,
      description: form.description,
      capacity: form.capacity,
      salePrice: form.salePrice,
      installmentsMonth: form.installmentsMonth,
      installmentsPrice: form.installmentsPrice,
      saleEndAt: form.saleEndAt + ' 23:59:59',
      saleStartAt: form.saleStartAt + ' 00:00:00',
      productPackageCategoryId: form.productPackageCategoryId,
      minimumAmount: form.minimumAmount,
      isPublic: form.isPublic,
      //status: form.status,
      isAddressRequired: form.isAddressRequired,
      ord: form.ord,
      isDeleted: form.isDeleted,
      isVisible: form.isVisible,
    };
    patchPackageInfo(body)
      .unwrap()
      .then((payload) => {
        alert('업데이트가 완료되었습니다');
        console.log('fulfilled', payload);
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const deletePackage = () => {
    navigate(-1);
    deletePackageInfo(id)
      .unwrap()
      .then(() => {
        alert('삭제가 완료되었습니다');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const postPackageDuplicate = async (ids: number[], targets: number[]) => {
    if (ids.length === 0) {
      alert('복사할 패키지가 없습니다!');
      throw new Error('No packages to duplicate'); // 에러를 던짐
    }
    if (targets.length === 0) {
      targets.push(id!);
    }
    for (const target of targets) {
      for (const id of ids) {
        const requestParams = { id: id, targetCourseId: target };
        try {
          await postPackageDuplicateRequest(requestParams).unwrap();
        } catch (error) {
          alert('복사 중 문제가 발생했습니다.');
          return; // 혹시나 에러가 발생하면 함수 종료
        }
      }
    }
    alert('모든 복사가 완료되었습니다.\n판매 상태를 다시 세팅해주세요.');
  };

  return { patchPackage, deletePackage, postPackageDuplicate };
};
export default usePackageInfoMutation;
