import { Table } from 'antd';
import * as S from '../styles';
import { useNavigate } from 'react-router-dom';
import { useGetCategoryListQuery } from '../../../store/services/course';
import { ChangeEvent, useRef, useState } from 'react';
import useModal from '../../../hooks/useModal';
import CategoryAddModal from '../InputModal/CategoryAddModal';
import SelectOrderInput from '../../../components/form/SelectOrderInput';
import { CategoryListHeader } from 'model/category.model';
import { ColumnsType } from 'antd/es/table';
import Pagination from 'antd/es/pagination';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const SEARCH_TYPE = ['선택', '이름', '코드'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  이름: 'NAME',
  코드: 'CODE',
};
function CourseCategory() {
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();
  const [filter, setFilter] = useState({
    searchType: '선택',
    target: '',
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };
  // const [searchForm, setSearchForm] = useState({ searchType: '', target: '' });

  const { data: categoryList } = useGetCategoryListQuery({
    page,
    searchType: SEARCH_TYPE_MAPPED[filter.searchType] || 'ALL',
    target: filter.target,
  });

  const COLUMNS: ColumnsType<CategoryListHeader> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: '카테고리 이름',
      dataIndex: 'categoryName',
      key: 'categoryName',
    },
    {
      title: '코드',
      dataIndex: 'categoryCode',
      key: 'categoryCode',
    },
    {
      title: '하위 카테고리 수',
      dataIndex: 'numberOfChildren',
      key: 'numberOfChildren',
    },
    {
      title: '최종 수정 시간',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ];

  //모달
  const categoryAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(categoryAddRef);

  //검색 기준 설정
  // const selectSearchType = (e: any) => {
  //   setSearchForm({ ...searchForm, searchType: e.target.value });
  // };

  // //검색어 onChange
  // const onChangeTarget = (e: any) => {
  //   setSearchForm({
  //     ...searchForm,
  //     target: e.target.value,
  //   });
  // };

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <CategoryAddModal ref={categoryAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          카테고리
          <S.AddButton
            onClick={(event: any) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 추가
          </S.AddButton>
          <S.SearchContainer>
            <SelectOrderInput
              selectValue={filter.searchType}
              array={SEARCH_TYPE}
              label="검색"
              name="searchType"
              onChange={handleFilterChange}
              type="search"
            />
            <input name="target" value={filter.target} onChange={handleFilterChange} />
          </S.SearchContainer>
        </h1>
        <S.TableContainer>
          {categoryList && (
            <Table
              dataSource={categoryList.content.map((content) => ({ ...content, tags: ['수정'] }))}
              columns={COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`${record.id}`, '_blank');
                    } else navigate(`${record.id}`);
                  },
                };
              }}
            ></Table>
          )}
          {categoryList && (
            <Pagination
              pageSize={15}
              current={page}
              total={categoryList.totalElements}
              onChange={setPage}
              showSizeChanger={false}
              showQuickJumper
            />
          )}
        </S.TableContainer>
      </S.Container>
    </>
  );
}

export default CourseCategory;
