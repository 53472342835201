import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useNumberSearchParams = (key: string): [number, (value: number) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(key) ? Number(searchParams.get(key)) : 1;
  const setPage = useCallback(
    (nextPage: number, _?: number, replace?: boolean) => {
      const params = new URLSearchParams(searchParams);
      params.set(key, String(nextPage));
      if (replace) setSearchParams(params, { replace: true });
      else setSearchParams(params);
    },
    [key, searchParams, setSearchParams]
  );
  useEffect(() => {
    if (searchParams.get(key) === null) {
      setPage(1, 15, true); //replace 가 필요한 듯 하다.
    }
  }, [key, searchParams, setPage]);

  return [page, setPage];
};

export default useNumberSearchParams;
