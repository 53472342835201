import { BannerItem } from 'model/banner.model';

import { useNavigate } from 'react-router-dom';
import {
  useDeleteBannerMutation,
  usePatchBannerMutation,
  usePatchBannerSortMutation,
  usePostBannerMutation,
} from 'store/services/banner';

const useBannerDetailMutation = () => {
  const navigate = useNavigate();
  // const [deleteCourseRequest] = useDeleteCourseMutation();
  const [patchBannerRequest] = usePatchBannerMutation();
  const [postBannerRequest] = usePostBannerMutation();
  const [deleteBannerRequest] = useDeleteBannerMutation();
  const [patchBannerSortRequest] = usePatchBannerSortMutation();

  const patchBannerInfo = (id: number, form: BannerItem) => {
    const body: Partial<BannerItem> & { id: number } = {
      id,
      title: form.title,
      bannerCategoryId: form.bannerCategoryId,
      backgroundColor: form.backgroundColor,
      url: form.url,
      isPrivate: form.isPrivate,
      mimgSrc: form.mimgSrc[0].url,
      imgSrc: form.imgSrc[0].url,
    };
    console.log('body', body);
    patchBannerRequest(body) //이 부분만 푸르면 댐.
      .unwrap()
      .then(() => {
        alert('수정이 완료되었습니다');
        // navigate('/banner');
      })
      .catch((error) => alert(error.data.errorMessage));
  };

  const postBannerInfo = (form: BannerItem) => {
    console.log('form', form);
    const body: Partial<BannerItem> = {
      title: form.title,
      bannerCategoryId: form.bannerCategoryId,
      backgroundColor: form.backgroundColor,
      url: form.url,
      isPrivate: form.isPrivate,
      mimgSrc: form.mimgSrc[0].url,
      imgSrc: form.imgSrc[0].url,
    };
    console.log('body', body);
    postBannerRequest(body) //이 부분만 푸르면 댐.
      .unwrap()
      .then(() => {
        alert('저장이 완료되었습니다');
        navigate('/banner');
      })
      .catch((error) => alert(error.data.errorMessage));
  };
  const deleteBannerInfo = (id: number) => {
    console.log('id', id);

    deleteBannerRequest(id)
      .unwrap()
      .then((payload) => {
        if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
          alert('삭제가 완료되었습니다.');
          navigate(`/banner?${id}`);
          console.log('fulfilled', payload);
        }
      })
      .catch((error) => {
        alert(error.data.errorMessage);
      });
  };
  const patchBannerSort = (id: number, sortIds: number[]) => {
    return new Promise((resolve, reject) => {
      patchBannerSortRequest({ id, sortIds })
        .unwrap()
        .then((payload) => {
          resolve('Success');
        })
        .catch((error) => alert(error.data.errorMessage));
    });
  };
  return { patchBannerInfo, postBannerInfo, deleteBannerInfo, patchBannerSort };
};
export default useBannerDetailMutation;
