import React from 'react';
import CIcon from '@coreui/icons-react';
import {
  cilCalculator,
  cilKeyboard,
  cilPencil,
  cilUserFemale,
  cilMoney,
  cilDollar,
  cilRectangle,
  cilPeople,
  cilAlarm,
  cilWallpaper,
} from '@coreui/icons';
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react';

const category = [
  {
    component: CNavTitle,
    name: '주문서',
  },
  {
    component: CNavItem,
    name: '결제',
    to: '/checkout',
    icon: <CIcon icon={cilDollar} customClassName="nav-icon" />,
  },

  {
    component: CNavTitle,
    name: '상품',
  },
  {
    component: CNavGroup,
    name: '코스',
    to: '/course',
    icon: <CIcon icon={cilPencil} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: '코스 조회',
        to: '/course',
      },
      {
        component: CNavItem,
        name: '카테고리',
        to: '/course/category',
      },
      {
        component: CNavItem,
        name: '해시태그',
        to: '/course/hashtag',
      },
    ],
  },
  {
    component: CNavItem,
    name: '클래스룸',
    to: '/classroom',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  {
    component: CNavGroup,
    name: '쿠폰',
    to: '/coupon',
    icon: <CIcon icon={cilMoney} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: '쿠폰 조회',
        to: '/coupon',
      },

      {
        component: CNavItem,
        name: '컬렉션',
        to: '/collection',
      },
    ],
  },
  {
    component: CNavTitle,
    name: '랜딩 배너',
  },
  {
    component: CNavItem,
    name: '배너 목록',
    to: '/banner',
    icon: <CIcon icon={cilWallpaper} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: '알림톡',
  },
  {
    component: CNavItem,
    name: '예약 목록',
    to: '/alarm',
    icon: <CIcon icon={cilAlarm} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: '유저',
  },
  {
    component: CNavGroup,
    name: '유저 정보',
    to: '/user',
    icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: '유저 조회',
        to: '/user',
      },
      {
        component: CNavItem,
        name: '유저 메모',
        to: '/user/memo',
      },
      {
        component: CNavItem,
        name: 'CX 해시태그',
        to: '/user/cxhashtag',
      },
    ],
  },
  {
    component: CNavItem,
    name: '튜터',
    to: '/mentor',
    icon: <CIcon icon={cilUserFemale} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: '에디터',
    to: '/markdowneditor',
    icon: <CIcon icon={cilKeyboard} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: '기타',
  },
  {
    component: CNavItem,
    name: '모범댓글',
    to: '/specific-reply',
    icon: <CIcon icon={cilRectangle} customClassName="nav-icon" />,
  },
];

export default category;
