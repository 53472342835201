import styled from 'styled-components';
import axios from 'axios';

const Container = styled.div`
  label {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 9px;
  }
  input {
    width: 600px;
    height: 50px;
    padding: 15px;
    border-radius: 5px;
    margin: 10px;
  }
  img {
    width: 150px;
    height: 150px;
  }
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding-bottom: 30px;
  margin: 0px 16px 0px 16px;
`;

function FileUpload({ label, formData, setFormData }: any) {
  const handleFileUpload = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];

    if (e.target.files) {
      const fileData = new FormData();
      fileData.append('multipartFile', file);
      await axios({
        method: 'post',
        url: '/file?fileType=PRIVATE',
        data: fileData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          setFormData({
            ...formData,
            fileId: response.data.id,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Container>
      <label>{label}</label>
      <div>
        <label htmlFor={'classRoomLectureFiles'}></label>
        <input type="file" onChange={handleFileUpload} accept="/*" />
        <div style={{ padding: '5px' }}></div>
      </div>
    </Container>
  );
}

export default FileUpload;
