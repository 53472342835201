import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import * as S from '../styles';
import type { ColumnsType } from 'antd/es/table';
import { useGetMentorListQuery } from 'store/services/mento';
import { IMentoList } from 'model/store.model';
import { ChangeEvent, useRef, useState } from 'react';
import useModal from 'hooks/useModal';
import MentorAddModal from '../InputModal/MentorAddModal';
import SelectOrderInput from 'components/form/SelectOrderInput';
import Pagination from 'antd/es/pagination';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const COLUMNS: ColumnsType<IMentoList> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '튜터 이름',
    dataIndex: 'displayName',
    key: 'displayName',
  },
  {
    title: '튜터 직업',
    dataIndex: 'displayJob',
    key: 'displayJob',
  },
  {
    title: '최종 수정 시간',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
  },
  {
    title: '생성일',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <S.SubEditButton key={tag}>{tag.toUpperCase()}</S.SubEditButton>;
        })}
      </>
    ),
  },
];

const ORD_TYPE = ['최신순', '업데이트순'];
const ORD_TYPE_MAPPED = {
  최신순: 'CREATED_AT_DESC',
  업데이트순: 'UPDATED_AT_DESC',
};

const SEARCH_TYPE = ['선택', '이름', '직업'];
const SEARCH_TYPE_MAPPED = {
  선택: 'ALL',
  이름: 'NAME',
  직업: 'JOB',
};

function MentorList() {
  const [page, setPage] = useNumberSearchParams('page');
  const navigate = useNavigate();

  const [filter, setFilter] = useState({
    ordType: '최신순',
    searchType: '선택',
    target: '',
  });
  const { data: tutorList } = useGetMentorListQuery({
    page,
    ordType: ORD_TYPE_MAPPED[filter.ordType] || 'UPDATED_AT_DESC',
    searchType: SEARCH_TYPE_MAPPED[filter.searchType] || 'ALL',
    target: filter.target,
  });

  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
    setPage(1);
  };

  //모달
  const mentorAddRef = useRef<HTMLDivElement>(null);
  const [isAddModalOpen, open] = useModal(mentorAddRef);

  return (
    <>
      {isAddModalOpen && (
        <S.ModalBackground>
          <MentorAddModal ref={mentorAddRef} open={open} />
        </S.ModalBackground>
      )}
      <S.Container>
        <h1>
          튜터
          <S.AddButton
            onClick={(event: MouseEvent) => {
              event.stopPropagation();
              open(true);
            }}
          >
            + 추가
          </S.AddButton>
          <SelectOrderInput
            selectValue={filter.ordType}
            array={ORD_TYPE}
            label="정렬"
            name="ordType"
            onChange={handleFilterChange}
            type="order"
          />
          <S.SearchContainer>
            <SelectOrderInput
              selectValue={filter.searchType}
              array={SEARCH_TYPE}
              label="검색"
              name="searchType"
              onChange={handleFilterChange}
              type="search"
            />
            <input name="target" value={filter.target} onChange={handleFilterChange} />
          </S.SearchContainer>
        </h1>
        {tutorList && (
          <S.TableContainer>
            <Table
              dataSource={tutorList?.content.map((content) => ({ ...content, tags: ['수정'] }))}
              columns={COLUMNS}
              pagination={false}
              rowKey={(record) => {
                return record.id;
              }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (e) => {
                    if (e.ctrlKey || e.metaKey) {
                      window.open(`/mentor/${record.id}`, '_blank');
                    } else navigate(`/mentor/${record.id}`);
                  },
                };
              }}
            />
            {tutorList && (
              <Pagination
                pageSize={15}
                current={page}
                total={tutorList.totalElements}
                onChange={setPage}
                showSizeChanger={false}
                showQuickJumper
              />
            )}
          </S.TableContainer>
        )}
      </S.Container>
    </>
  );
}

export default MentorList;
