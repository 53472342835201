import * as S from '../styles';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  useGetCouponQuery,
  usePatchCouponMutation,
  useGetCouponCategoryQuery,
} from '../../../store/services/coupon';

import CustomForm from 'components/CustomForm';
import { useGetCollectionListQuery } from 'store/services/collection';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

const COLLECTION_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '컬렉션 이름',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '설명',
    dataIndex: 'description',
    key: 'description',
  },
];

const COUPONCATEGORY_COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '쿠폰 카테고리 ',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '카테고리 코드',
    dataIndex: 'code',
    key: 'code',
  },
];

const selectDiscountType = { AMOUNT: 'AMOUNT', PERCENTAGE: 'PERCENTAGE' };
const selectIssueType = { CODE: 'CODE', PUBLIC: 'PUBLIC', PRIVATE: 'PRIVATE', SIGNUP: 'SIGNUP' };

function CouponInfo() {
  const { id } = useParams();
  const { data: couponData } = useGetCouponQuery(`${id}`); // 쿠폰 상세 정보
  // const [page, setPage] = useNumberSearchParams('page');
  // const [categoryPage, setcategoryPage] = useNumberSearchParams('catagoryPage');
  const [page, setPage] = useState(1);
  const [categoryPage, setcategoryPage] = useState(1);

  const { data: collectionList } = useGetCollectionListQuery({ page });
  const { data: couponCategory } = useGetCouponCategoryQuery({
    size: 5,
    page: categoryPage,
    isVisible: true,
    rootId: 0,
  });

  const [patchCoupon] = usePatchCouponMutation();
  const [visible, setVisible] = useState(false);
  const [categoryVisible, setCategoryVisible] = useState(false);

  const [form] = CustomForm.useForm();

  const [issueType, setIssueType] = useState<string>('');
  const [discountType, setDiscountType] = useState<string>('');

  const handleIssueSelectChange = (value: string) => {
    setIssueType(value);
  };
  const handleDiscountSelectChange = (value: string) => {
    setDiscountType(value);
  };
  const [isIssueFieldsDisabled, setIsIssueFieldsDisabled] = useState(false);
  const [isDiscountFieldsDisabled, setIsDiscountFieldsDisabled] = useState(false);

  useEffect(() => {
    if (couponData) {
      setIssueType(couponData.issueType);
      setDiscountType(couponData.discountType);
    }
  }, [couponData]);

  useEffect(() => {
    if (discountType === 'AMOUNT') {
      setIsDiscountFieldsDisabled(true);
    } else {
      setIsDiscountFieldsDisabled(false);
    }
  }, [discountType]);

  useEffect(() => {
    if (issueType === 'CODE') {
      setIsIssueFieldsDisabled(true);
    } else {
      setIsIssueFieldsDisabled(false);
    }
  }, [issueType]);

  const handleFinishValue = (values: any) => {
    const {
      collectionName,
      updatedAt,
      usedAmount,
      collection,
      createdAt,
      expiredAmount,
      id,
      isDeleted,
      issuedAmount,
      ...restOfValues
    } = values;
    let finalValues = restOfValues;

    if (issueType !== 'CODE') {
      finalValues = { ...finalValues, code: null };
    }
    if (discountType === 'AMOUNT') {
      finalValues = { ...finalValues, discountRate: null, maximumDiscountAmount: null };
    }
    if (discountType === 'PERCENTAGE') {
      finalValues = { ...finalValues, discountAmount: null };
    }
    handlePatchCoupon(finalValues);
  };

  const handlePatchCoupon = (value) => {
    patchCoupon({ ...value, id })
      .unwrap()
      .then((payload) => {
        if (window.confirm('쿠폰을 수정 전 한번 더 정보를 확인해주세요')) {
          alert('정상적으로 수정되었습니다');
          console.log('fulfilled', payload);
        }
      })
      .catch((error) => {
        alert(error.data.errorMessage);
      });
  };
  return (
    <>
      {couponData && (
        <CustomForm
          form={form}
          initialValues={couponData}
          onFinish={() => handleFinishValue(form.getFieldsValue(true))}
        >
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="쿠폰 ID" name="id" disabled />
            <CustomForm.Input label="쿠폰 제목" name="name" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber label="쿠폰 발급량" name="issuedAmount" disabled />
            <CustomForm.InputNumber label="쿠폰 사용률" name="usedAmount" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.InputNumber label="컬랙션 ID" name="collectionId" />
              <CustomForm.Button onClick={() => setVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.Input label="컬랙션 제목" name="collectionName" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.GridRow gridColumns="1fr auto">
              <CustomForm.InputNumber label="쿠폰 카테고리 ID" name="couponCategoryId" />
              <CustomForm.Button onClick={() => setCategoryVisible(true)} htmlType="button" ghost>
                검색
              </CustomForm.Button>
            </CustomForm.GridRow>
            <CustomForm.Input label="쿠폰 카테고리" name="couponCategoryName" disabled />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Select
              label="쿠폰 발급 타입"
              name="issueType"
              options={selectIssueType}
              onChange={handleIssueSelectChange}
            />
            <CustomForm.Input
              label="코드"
              name="code"
              rules={[
                {
                  required: isIssueFieldsDisabled,
                },
              ]}
              disabled={!isIssueFieldsDisabled}
            />
          </CustomForm.GridRow>
          <CustomForm.Input label="쿠폰설명" name="description" />
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Select
              label="할인 타입"
              name="discountType"
              options={selectDiscountType}
              onChange={handleDiscountSelectChange}
            />

            <CustomForm.InputNumber
              label="할인금액"
              name="discountAmount"
              disabled={!isDiscountFieldsDisabled}
              rules={[
                {
                  pattern: /^\+?[1-9]\d*$/,
                  message: '양수의 값만 넣어주세요.',
                  required: isDiscountFieldsDisabled,
                },
              ]}
            />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber
              label="할인율(%)"
              name="discountRate"
              disabled={isDiscountFieldsDisabled}
              rules={[
                {
                  pattern: /^(?!0)(?!100)\d{1,2}$/,
                  message: '1~99까지의 정수만 넣어주세요.',
                  required: !isDiscountFieldsDisabled,
                },
              ]}
            />
            <CustomForm.InputNumber
              label="최대 결제 금액"
              name="maximumDiscountAmount"
              rules={[
                {
                  pattern: /^\+?[1-9]\d*$/,
                  message: '양수의 값만 넣어주세요.',
                  required: !isDiscountFieldsDisabled,
                },
              ]}
              disabled={isDiscountFieldsDisabled}
            />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input
              label="쿠폰 발급 시작 일시"
              name="issueStartAt"
              rules={[
                {
                  pattern:
                    /^\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|([12]\d)|(3[01])) (([01]\d)|(2[0-3])):[0-5]\d$/,
                  message: 'YYYY-MM-DD HH:MM 형식으로 작성해주세요!',
                  required: true,
                },
              ]}
            />
            <CustomForm.Input
              label="쿠폰 발급 마감 일시"
              name="issueEndAt"
              rules={[
                {
                  pattern:
                    /^\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|([12]\d)|(3[01])) (([01]\d)|(2[0-3])):[0-5]\d$/,
                  message: 'YYYY-MM-DD HH:MM 형식으로 작성해주세요!',
                  required: true,
                },
              ]}
            />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.InputNumber
              label="발급 후 사용 가능 기간"
              name="availableDaysAfterIssue"
              rules={[
                {
                  message: '일로 작성해주세요!',
                  required: true,
                },
              ]}
            />
            <CustomForm.Input
              label="쿠폰 만료 일시"
              name="expiredAt"
              rules={[
                {
                  pattern:
                    /^\d{4}-((0[1-9])|(1[0-2]))-((0[1-9])|([12]\d)|(3[01])) (([01]\d)|(2[0-3])):[0-5]\d$/,
                  message: 'YYYY-MM-DD HH:MM 형식으로 작성해주세요!',
                  required: true,
                },
              ]}
            />
          </CustomForm.GridRow>
          <CustomForm.InputNumber label="최대 발급 갯수" name="maxIssue" />
          <CustomForm.GridRow gridColumns="1fr">
            <CustomForm.InputNumber
              label="최소 결제 금액"
              name="minimumOrderAmount"
              rules={[{ pattern: /^\+?[1-9]\d*$/, message: '양수의 값만 넣어주세요.' }]}
            />
          </CustomForm.GridRow>
          <CustomForm.Switch
            label="공개범위"
            checkedChildren="공개"
            unCheckedChildren="비공개"
            name="isVisible"
          />
          <br />
          <div> * 한 번에 많은 양의 쿠폰 발급 시, 서버에 무리가 갈 수 있습니다.</div>
          <div> * 최대 30개로 나누어 발급 부탁드립니다.</div>
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
          {collectionList && (
            <CustomForm.TableSelectModal
              columns={COLLECTION_COLUMNS}
              list={collectionList.content}
              visible={visible}
              onCancel={() => setVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ collectionId: record.id });
                form.setFieldsValue({ collectionName: record.name });
                setVisible(false);
              }}
              page={page}
              setPage={setPage}
              totalElements={collectionList.totalElements}
            />
          )}
          {couponCategory && (
            <CustomForm.TableSelectModal
              columns={COUPONCATEGORY_COLUMNS}
              list={couponCategory.content}
              visible={categoryVisible}
              onCancel={() => setCategoryVisible(false)}
              onSelect={(record) => {
                form.setFieldsValue({ couponCategoryId: record.id });
                form.setFieldsValue({ couponCategoryName: record.name });
                setCategoryVisible(false);
              }}
              page={categoryPage}
              setPage={setcategoryPage}
              totalElements={couponCategory.totalElements}
            />
          )}
        </CustomForm>
      )}
    </>
  );
}

export default CouponInfo;
