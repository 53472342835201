import { Table } from 'antd';
import Pagination from 'antd/es/pagination';
import { ColumnsType } from 'antd/es/table';
import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { AlarmListHeader } from 'model/alarm.model';
import { useGetAlarmListQuery } from 'store/services/alarm';
import * as S from '../styles';
import useAlarmMutation from '../Classroom/ClassroomDetail/hooks/useAlarmMutation';

const COLUMNS: ColumnsType<AlarmListHeader & { tags: string[] }> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '5%',
  },
  {
    title: '알림톡 종류',
    dataIndex: 'alarmType',
    key: 'alarmType',
    width: '10%',
    render: (value) =>
      value === 'PROGRESS_CHECK'
        ? '진도 알림톡'
        : value === 'LIVE_CLASS'
        ? '1시간 전 알림톡'
        : '1주일 전 알림톡',
  },
  {
    title: '클래스룸명',
    dataIndex: 'classRoomTitle',
    key: 'classRoomTitle',
    width: '30%',
  },
  {
    title: '요청 일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '15%',
  },
  {
    title: '예약발송 일시',
    dataIndex: 'alarmAt',
    key: 'alarmAt',
    width: '15%',
  },
  {
    title: '예약 상태',
    dataIndex: 'isSend',
    key: 'isSend',
    width: '7%',
    render: (value) => (value === true ? '발송 완료' : '예약'),
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    width: '8%',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          return <S.DeleteButton key={tag}>{tag.toUpperCase()}</S.DeleteButton>;
        })}
      </>
    ),
  },
];

function AlarmList() {
  const [page, setPage] = useNumberSearchParams('page');
  const { deleteAlarm } = useAlarmMutation();
  const { data: alarmList } = useGetAlarmListQuery({
    page,
  });

  return (
    <S.Container>
      <h1>알림톡 예약 목록</h1>
      <S.TableContainer>
        {alarmList && (
          <Table
            dataSource={alarmList?.content.map((content) => {
              return { ...content, tags: ['삭제'] };
            })}
            columns={COLUMNS}
            pagination={false}
            rowKey={(record) => {
              return record.id;
            }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (e) => {
                  deleteAlarm(record.id);
                  console.log('deleteAlarm: ', record.id);
                },
              };
            }}
          />
        )}
        {alarmList && (
          <Pagination
            pageSize={15}
            current={page}
            total={alarmList.totalElements}
            onChange={
              setPage
              //setPageParams(page);
            }
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
}

export default AlarmList;
