import { Table, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import {
  ChapterList,
  ChapterListResForAlarm,
  ClassroomAlarmInfo,
  ClassroomForAlarm,
} from 'model/classroom.model';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetChapterListForAlarmQuery,
  useGetClassroomForAlarmQuery,
} from 'store/services/classroom';
import * as S from '../../../../styles';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import useBooleanState from '../../hooks/useBooleanState';
import useAlarmMutation from '../../hooks/useAlarmMutation';
import { useState } from 'react';

function ClassroomAlarm() {
  const [messageApi, contextHolder] = message.useMessage();

  const { id } = useParams();
  const navigate = useNavigate();
  const { addAlarm, deleteAlarm, addProgressAlarms, deleteProgressAlarms } = useAlarmMutation();

  const { data: chapterAlarmList } = useGetChapterListForAlarmQuery(Number(id));
  console.log(chapterAlarmList);
  const { data: classroomAlarmData, refetch: refetchClassroomAlarmData } =
    useGetClassroomForAlarmQuery(Number(id));

  const [isProgressCheckedList, setIsProgressCheckedList] = useState<Array<boolean>>([]); //check
  const [isLiveCheckedList, setIsLiveCheckedList] = useState<Array<boolean>>([]); //check

  const handleProgressCheckboxChange = (index: number, checked: boolean) => {
    const progressUpdatedList = [...isProgressCheckedList];
    progressUpdatedList[index] = checked;
    setIsProgressCheckedList(progressUpdatedList);
  };

  const handleLiveCheckboxChange = (index: number, checked: boolean) => {
    const LiveUpdatedList = [...isLiveCheckedList];
    LiveUpdatedList[index] = checked;
    setIsLiveCheckedList(LiveUpdatedList);
  };

  const {
    value: isAlarmReserving,
    setFalse: endAlarmReserve,
    setTrue: startAlarmReserve,
  } = useBooleanState(false);

  const {
    value: isAlarmClosing,
    setFalse: endAlarmClose,
    setTrue: startAlarmClose,
  } = useBooleanState(false);

  const checkMode = () => {
    if (isAlarmClosing) return '알림톡 취소';
    if (isAlarmReserving) return '알림톡 예약';
    return '기본';
  };

  const navigateToAlarmDetail = (id: number) => {
    navigate(`/alarm/${id}`);
  };

  let reserveAction: JSX.Element | null;

  const generateReserveAction = (text: string, reserveInfo: ClassroomAlarmInfo) => (
    <>
      <S.alarmStateSpan onClick={() => navigateToAlarmDetail(reserveInfo.id)}>
        {text}
      </S.alarmStateSpan>
      <S.SubEditButton
        state={reserveInfo}
        onClick={(e) => {
          e.stopPropagation(); // 버블링 방지
          deleteAlarm(reserveInfo.id).then(() => {
            refetchClassroomAlarmData();
          });
        }}
      >
        {'취소하기'}
      </S.SubEditButton>
    </>
  );

  const checkMissingAlarms = (alarmType: string, totalList: ChapterListResForAlarm) => {
    if (alarmType === 'progressCheckAlarm') {
      if (isAlarmReserving)
        return totalList.filter((item) => item.progressCheckAlarm === undefined && item.liveAt)
          .length;
      return totalList.filter((item) => item.progressCheckAlarm !== undefined && item.liveAt)
        .length;
    } else if (alarmType === 'liveClassAlarm') {
      if (isAlarmReserving)
        return totalList.filter((item) => item.liveClassAlarm === undefined && item.liveAt).length;
      return totalList.filter((item) => item.liveClassAlarm !== undefined && item.liveAt).length;
    }
  };

  let isProgressAllChecked = false; //전체 선택과 관련부분 // 체크박스의 갯수를 세고 그 갯수가 전체 리스트에서 같은 개수만큼 채크가 되었는지 확인
  let isLiveAllChecked = false;
  if (chapterAlarmList) {
    const progressTrueCount = isProgressCheckedList.filter((item) => item === true).length;
    const missingProgressAlarms = checkMissingAlarms('progressCheckAlarm', chapterAlarmList);
    const liveTrueCount = isLiveCheckedList.filter((item) => item === true).length;
    const missingLiveAlarms = checkMissingAlarms('liveClassAlarm', chapterAlarmList);

    isProgressAllChecked =
      missingProgressAlarms === 0 ? false : progressTrueCount === missingProgressAlarms;
    isLiveAllChecked = missingLiveAlarms === 0 ? false : liveTrueCount === missingLiveAlarms;
  }

  const checkReserveAction = (reserveInfo: ClassroomAlarmInfo) => {
    switch (true) {
      case reserveInfo.status === 'PENDING':
        reserveAction = reserveAction = generateReserveAction(
          reserveInfo.alarmAt + ' 발송 예정',
          reserveInfo
        );
        break;
      case reserveInfo.status === 'EXPIRED':
        reserveAction = generateReserveAction('발송 만료', reserveInfo);
        break;
      case reserveInfo.status === 'SENT':
        reserveAction = generateReserveAction('발송 완료', reserveInfo);
        break;
      default:
        reserveAction = null;
        break;
    }
  };
  const mode = checkMode();

  const getUpdatedChapter = (
    isCheckedList: boolean[],
    chapterAlarmList: ChapterListResForAlarm,
    type?: string
  ): number[] => {
    return isCheckedList
      .map((isChecked, idx) => {
        if (isChecked) {
          const alarm = chapterAlarmList[idx];
          return type ? alarm?.[type]?.id : alarm?.id;
        }
        return null;
      })
      .filter((content) => content !== null) as number[];
  };

  const handleAddAlarm = (alarmType, classRoomId, classRoomChapterId) => {
    addAlarm({
      alarmType,
      classRoomId,
      classRoomChapterId,
    });
  };

  const clearCheckedList = () => {
    setIsProgressCheckedList(new Array(chapterAlarmList?.length).fill(false));
    setIsLiveCheckedList(new Array(chapterAlarmList?.length).fill(false));
  };
  const CLASSROOMALARMCOLUMNS: ColumnsType<ClassroomForAlarm> = [
    {
      title: '클래스룸 ID',
      dataIndex: 'id',
      key: 'id',
      width: '5%',
    },
    {
      title: '기수',
      dataIndex: 'term',
      key: 'term',
      width: '5%',
    },
    {
      title: '클래스룸명',
      dataIndex: 'title',
      key: 'title',
      width: '15%',
    },
    {
      title: '수강 시작 일자',
      dataIndex: 'startDate',
      key: 'startDate',
      width: '10%',
    },

    {
      title: '1주일 전 알림톡 상태',
      dataIndex: 'actions',
      key: 'actions',
      width: '15%',
      render: (value, record) => {
        classroomAlarmData?.classStartAlarm &&
          checkReserveAction(classroomAlarmData?.classStartAlarm);
        if (classroomAlarmData?.classStartAlarm) return reserveAction;
        else
          return (
            <>
              <S.alarmStateSpan>예약 없음</S.alarmStateSpan>
              <>
                {classroomAlarmData && (
                  <S.SubEditButton
                    state={classroomAlarmData.classStartAlarm}
                    onClick={(e) => {
                      // 이 부분도 함수로 빼자
                      e.stopPropagation(); // 버블링 방지
                      handleAddAlarm('CLASS_START', Number(id), null);
                    }}
                  >
                    예약하기
                  </S.SubEditButton>
                )}
              </>
            </>
          );
      },
    },
  ];

  const CHAPTERALARMCOLUMNS: ColumnsType<ChapterList> = [
    {
      title: '라벨',
      dataIndex: 'sequence',
      key: 'sequence',
      width: '4%',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      width: '10%',
    },
    {
      title: '라이브 날짜',
      dataIndex: 'liveAt',
      key: 'liveAt',
      width: '8%',
    },
    ...(isAlarmClosing || isAlarmReserving
      ? [
          {
            title: '진도 알림톡',
            dataIndex: 'actions',
            width: '10%',
            key: 'actions',
            render: (value, record) => {
              return (
                chapterAlarmList &&
                record.liveAt &&
                ((isAlarmClosing && chapterAlarmList[record.ord].progressCheckAlarm) ||
                  (!isAlarmClosing && !chapterAlarmList[record.ord].progressCheckAlarm)) && (
                  <Checkbox
                    checked={isProgressCheckedList[record.ord]}
                    onChange={(e) => {
                      handleProgressCheckboxChange(record.ord, e.target.checked);
                    }}
                  />
                )
              );
            },
          },
          {
            title: '1시간 전 알림톡 ',
            dataIndex: 'actions',
            width: '10%',
            key: 'actions',
            render: (value, record) => {
              return (
                chapterAlarmList &&
                record.liveAt &&
                ((isAlarmClosing && chapterAlarmList[record.ord].liveClassAlarm) ||
                  (!isAlarmClosing && !chapterAlarmList[record.ord].liveClassAlarm)) && (
                  <Checkbox
                    checked={isLiveCheckedList[record.ord]}
                    onChange={(e) => {
                      handleLiveCheckboxChange(record.ord, e.target.checked);
                    }}
                  />
                )
              );
            },
          },
        ]
      : [
          {
            title: '진도 알림톡 상태',
            dataIndex: 'actions',
            width: '13%',
            key: 'actions',
            render: (value, record) => {
              if (!record.liveAt) return null;
              if (chapterAlarmList && chapterAlarmList[record.ord].progressCheckAlarm) {
                checkReserveAction(chapterAlarmList[record.ord].progressCheckAlarm);
                if (chapterAlarmList[record.ord].progressCheckAlarm) return reserveAction;
              }
              return (
                <>
                  <S.alarmStateSpan>예약 없음</S.alarmStateSpan>
                  <>
                    {chapterAlarmList && (
                      <S.SubEditButton
                        state={chapterAlarmList[record.ord].progressCheckAlarm}
                        onClick={(e) => {
                          e.stopPropagation(); // 버블링 방지
                          handleAddAlarm('PROGRESS_CHECK', Number(id), record.id);
                        }}
                      >
                        {'예약하기'}
                      </S.SubEditButton>
                    )}
                  </>
                </>
              );
            },
          },
          {
            title: '1시간 전 알림톡 상태',
            dataIndex: 'actions', // 여기는 해당에 맞는 res key값이 되어야함.
            width: '13%',
            key: 'actions', // 여기는 해당에 맞는 res key값이 되어야함.
            render: (value, record) => {
              if (!record.liveAt) return null;
              if (chapterAlarmList && chapterAlarmList[record.ord].liveClassAlarm) {
                checkReserveAction(chapterAlarmList[record.ord].liveClassAlarm);
                if (chapterAlarmList[record.ord].liveClassAlarm) return reserveAction;
              }
              return (
                <>
                  <S.alarmStateSpan>예약 없음</S.alarmStateSpan>
                  <>
                    {chapterAlarmList && (
                      <S.SubEditButton
                        state={chapterAlarmList[record.ord].liveClassAlarm}
                        onClick={(e) => {
                          e.stopPropagation(); // 버블링 방지
                          handleAddAlarm('LIVE_CLASS', Number(id), record.id);
                        }}
                      >
                        {'예약하기'}
                      </S.SubEditButton>
                    )}
                  </>
                </>
              );
            },
          },
        ]),
  ];
  return (
    <S.Container>
      {contextHolder}
      {chapterAlarmList && (
        <>
          <br />
          <h1>1주일 전 알림톡</h1>
          {classroomAlarmData && (
            <S.TableContainer>
              {classroomAlarmData && (
                <Table
                  dataSource={[{ ...classroomAlarmData }]}
                  columns={CLASSROOMALARMCOLUMNS}
                  pagination={false}
                  rowKey={(record) => record.id}
                />
              )}
            </S.TableContainer>
          )}
          <br />
          {mode === '기본' && (
            <S.ButtonWrap>
              <S.AlramUpdateButton
                onClick={() => {
                  startAlarmReserve();
                  clearCheckedList();
                }}
                isAlarmReserving={isAlarmReserving}
              >
                알림톡 예약
              </S.AlramUpdateButton>
              <S.AlramUpdateButton
                onClick={() => {
                  startAlarmClose();
                  clearCheckedList();
                }}
                isAlarmClosing={isAlarmClosing}
              >
                알림톡 삭제
              </S.AlramUpdateButton>
            </S.ButtonWrap>
          )}
          {mode === '알림톡 예약' && (
            <>
              <S.ButtonWrap>
                <S.AlramUpdateButton
                  onClick={() => {
                    Promise.all([
                      addProgressAlarms(
                        'PROGRESS_CHECK',
                        Number(id),
                        getUpdatedChapter(isProgressCheckedList, chapterAlarmList)
                      ),
                      addProgressAlarms(
                        'LIVE_CLASS',
                        Number(id),
                        getUpdatedChapter(isLiveCheckedList, chapterAlarmList)
                      ),
                    ])
                      .then(() => {
                        messageApi.success('성공적으로 업데이트 되었습니다.');
                        endAlarmReserve();
                      })
                      .catch(() => {
                        messageApi.error('업데이트에 오류가 있었습니다.');
                      });
                  }}
                  isAlarmReserving={isAlarmReserving}
                >
                  알림톡 예약
                </S.AlramUpdateButton>
                <S.CancelUpdateButton onClick={endAlarmReserve} isAlarmClosing={isAlarmClosing}>
                  취소
                </S.CancelUpdateButton>
              </S.ButtonWrap>
            </>
          )}
          {mode === '알림톡 취소' && (
            <S.ButtonWrap>
              <S.AlramUpdateButton
                onClick={() => {
                  Promise.all([
                    deleteProgressAlarms(
                      getUpdatedChapter(
                        isProgressCheckedList,
                        chapterAlarmList,
                        'progressCheckAlarm'
                      )
                    ),
                    deleteProgressAlarms(
                      getUpdatedChapter(isLiveCheckedList, chapterAlarmList, 'liveClassAlarm')
                    ),
                  ])
                    .then(() => {
                      messageApi.success('성공적으로 업데이트 되었습니다.');
                      endAlarmClose();
                    })
                    .catch(() => {
                      messageApi.error('업데이트에 오류가 있었습니다.');
                    });
                }}
                isAlarmReserving={isAlarmReserving}
              >
                알림톡 삭제
              </S.AlramUpdateButton>
              {/* 여기에 delete 핸들링 */}
              <S.CancelUpdateButton onClick={endAlarmClose} isAlarmClosing={isAlarmClosing}>
                취소
              </S.CancelUpdateButton>
            </S.ButtonWrap>
          )}
          <h1>진도 및 1시간 전 알림톡</h1>
          {mode !== '기본' && (
            <S.ButtonWrap>
              <Checkbox
                checked={isProgressAllChecked}
                onChange={(e) => {
                  //이제 위에서 체크박스의 전체 선택을 하는 것이 아니라 체크박스가 생긴 녀석만 선택하게 해야함.
                  //이걸 상태에 따라서 나눠야해
                  setIsProgressCheckedList(
                    isProgressCheckedList.map((item, index) => {
                      const isLiveAt = chapterAlarmList![index].liveAt;
                      const isProgressCheckAlarm = chapterAlarmList![index].progressCheckAlarm;
                      if (
                        (isAlarmReserving && !isProgressCheckAlarm && isLiveAt) || //이 부분이 문제인듯
                        (isAlarmClosing && isProgressCheckAlarm && isLiveAt)
                      ) {
                        return e.target.checked;
                      }
                      return item;
                    })
                  );
                }}
                style={{ fontSize: '18px' }}
              >
                진도 알림톡 전체 선택
              </Checkbox>

              <Checkbox
                checked={isLiveAllChecked}
                onChange={(e) =>
                  setIsLiveCheckedList(
                    isLiveCheckedList.map((item, index) => {
                      const isLiveAt = chapterAlarmList![index].liveAt;
                      const isLiveCheckAlarm = chapterAlarmList![index].liveClassAlarm;
                      if (
                        (isAlarmReserving && !isLiveCheckAlarm && isLiveAt) ||
                        (isAlarmClosing && isLiveCheckAlarm && isLiveAt)
                      ) {
                        return e.target.checked;
                      }
                      return item;
                    })
                  )
                }
                style={{ fontSize: '18px', marginLeft: '20px' }}
              >
                1시간 전 알림톡 전체 선택
              </Checkbox>
            </S.ButtonWrap>
          )}
          {chapterAlarmList && (
            <S.TableContainer>
              {chapterAlarmList && (
                <Table
                  dataSource={chapterAlarmList.map((content) => ({
                    ...content,
                    tags: ['예약하기'],
                  }))}
                  columns={CHAPTERALARMCOLUMNS}
                  pagination={false}
                  rowKey={(record) => record.id}
                />
              )}
            </S.TableContainer>
          )}
        </>
      )}
    </S.Container>
  );
}
export default ClassroomAlarm;
