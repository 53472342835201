import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AlarmListRes, GetAlarmList } from 'model/alarm.model';
import { classroomApi } from './classroom';

const baseUrl = process.env.REACT_APP_SERVER_API_URL;

export const alarmApi = createApi({
  reducerPath: 'alarm',
  tagTypes: ['Alarm', 'Chapter'],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl + '/alarm',
    prepareHeaders: (headers) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) headers.set('Authorization', `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAlarmList: builder.query<GetAlarmList, { page: number }>({
      query: ({ page }) => `/?size=15&page=${page - 1}`,
      providesTags: ['Alarm'],
      transformResponse: (response: AlarmListRes): GetAlarmList => {
        const { content, totalPages, totalElements } = response;
        const tableHeaderData = content.map((tableData) => {
          return {
            id: tableData.id,
            createdAt: tableData.createdAt,
            alarmType: tableData.alarmType,
            alarmAt: tableData.alarmAt,
            isSend: tableData.isSend,
            classRoomTitle: tableData.classRoom.title,
          };
        });
        return { content: tableHeaderData, totalPages, totalElements };
      },
    }),

    addAlarm: builder.mutation({
      query: (reserveAlarmForm) => ({
        url: '/',
        method: 'POST',
        body: reserveAlarmForm,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(classroomApi.util.invalidateTags(['Chapter', 'Classroom']));
        });
      },
      invalidatesTags: ['Alarm'],
    }),

    deleteAlarm: builder.mutation({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(classroomApi.util.invalidateTags(['Chapter', 'Classroom']));
        });
      },
      invalidatesTags: ['Alarm'],
    }),

    getChapterAlarmDetail: builder.query({
      query: (id) => `/${id}`,
      providesTags: (result, error, id) => [{ type: 'Alarm', id }],
    }),

    patchAlarm: builder.mutation({
      query: ({ id, newLiveAt }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: newLiveAt,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(classroomApi.util.invalidateTags(['Chapter', 'Classroom']));
        });
      },
      invalidatesTags: (result, error, id) => [{ type: 'Alarm', id }],
    }),
  }),
});

export const {
  useGetAlarmListQuery,
  useAddAlarmMutation,
  useDeleteAlarmMutation,
  useGetChapterAlarmDetailQuery,
  usePatchAlarmMutation,
} = alarmApi;
