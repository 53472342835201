import * as S from '../../styles';

import { Input, Pagination, Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetUserMemoQuery } from 'store/services/user';
import { UserMemoDetail } from 'model/user.model';
import useUserMemoMutation from '../Hook/useUserMemoMutation';
import { useGetCxHasgtagListQuery } from 'store/services/hashtag';
import useNumberSearchParams from 'hooks/useNumberSearchParams';

export default function UserMemoInfo() {
  const [page, setPage] = useNumberSearchParams('page');
  const { TextArea } = Input;
  const { id } = useParams();
  const { data: userMemoList } = useGetUserMemoQuery({
    ordType: 'REG_DATE_DESC',
    searchType: 'USER_ID',
    TransmissionType: '',
    ConsultationRoute: '',
    target: String(id),
    page: page,
    size: 10,
  });

  console.log('userMemoList', userMemoList);
  const { data: CxHashtagList } = useGetCxHasgtagListQuery({
    ordType: 'UPDATED_AT_DESC',
    page: 0,
  });
  const { postUserMemo, deleteUserMemo, patchUserMemo } = useUserMemoMutation();

  const [editData, setEditData] = useState<Partial<UserMemoDetail>>({
    memoId: undefined,
    userId: Number(id),
    consultationRoute: null,
    content: '',
    cxHashtagIds: [],
    transmissionType: null,
    etc: '',
  });

  const handleInputChange = (fieldName, value) => {
    //수정을 다루는 함수
    setEditData((prevEditData) => ({
      ...prevEditData,
      [fieldName]: value, // 필드 이름에 해당하는 상태만 업데이트
    }));
  };

  const cxHashtagOption = CxHashtagList?.content.map((item) => ({
    //cxHashtagList를 받아와서 option으로 만들어줘야해
    label: item.tag,
    value: item.id,
  }));

  const MEMOCOLUMNS: ColumnsType<UserMemoDetail> = [
    {
      title: '태그',
      dataIndex: 'userMemoCxHashtags',
      key: 'userMemoCxHashtags',
      width: '7%',
      render: (value, record: UserMemoDetail) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Select
            mode="multiple"
            allowClear
            defaultValue={value.map((item) => item.cxHashtagId)} //getCxHashtagIdsFromValue(value)
            style={{ width: 120 }}
            bordered={false}
            options={cxHashtagOption}
            optionLabelProp="label"
            onChange={(e) => {
              handleInputChange('cxHashtagIds', e); //중간에 풀렸다가 돌아가는 거
            }}
          />
        ) : (
          <span>{value.map((item) => item.cxHashtag.tag).join(', ')}</span>
        );
      },
    },
    {
      title: '상담 내용', //이 부분은 수정이 가능하게 진행되어야 함
      dataIndex: 'content',
      key: 'content',
      width: '20%',
      render: (value, record: UserMemoDetail) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <TextArea
            defaultValue={value}
            onChange={(e) => {
              handleInputChange('content', e.target.value);
              value = e.target.value;
            }}
          />
        ) : (
          <span>{record.content}</span>
        );
      },
    },
    {
      title: '<->',
      dataIndex: 'transmissionType',
      key: 'transmissionType',
      width: '4%',
      render: (value, record: UserMemoDetail) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Select
            defaultValue={value}
            style={{ width: 120 }}
            bordered={false}
            options={[
              { value: 'SENDING', label: 'SENDING' },
              { value: 'RECEIVING', label: 'RECEIVING' },
            ]}
            onChange={(e) => {
              handleInputChange('transmissionType', e);
            }}
          />
        ) : (
          <span>{record.transmissionType}</span>
        );
      },
    },
    {
      title: '상담경로',
      dataIndex: 'consultationRoute',
      key: 'consultationRoute',
      width: '8%',
      render: (value, record: UserMemoDetail) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <Select
            defaultValue={value}
            style={{ width: 120 }}
            bordered={false}
            options={[
              { value: 'CHANNEL_TALK', label: 'CHANNEL_TALK' },
              { value: 'MESSAGE', label: 'MESSAGE' },
              { value: 'PHONE', label: 'PHONE' },
              { value: 'OTHERS', label: 'OTHERS' },
            ]}
            onChange={(e) => {
              handleInputChange('consultationRoute', e);
            }}
          />
        ) : (
          <span>{record.consultationRoute}</span>
        );
      },
    },
    {
      title: '작성일자',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '14%',
    },
    {
      title: '기타',
      dataIndex: 'etc',
      key: 'etc',
      width: '15%',
      render: (value, record: UserMemoDetail) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <TextArea
            defaultValue={value}
            onChange={(e) => {
              handleInputChange('etc', e.target.value);
            }}
          />
        ) : (
          <span>{record.etc}</span>
        );
      },
    },

    {
      title: '편집',
      dataIndex: 'action',
      key: 'action',
      width: '15%',
      render: (param, record: any & { tags: string[] }) => {
        const isEditing = record.id === editingId;
        return isEditing ? (
          <span>
            <S.EditButton onClick={() => saveMemo(record.id)}>저장</S.EditButton>
            <S.DeleteButton onClick={() => setEditingId(null)}>취소</S.DeleteButton>
            <S.DeleteButton
              onClick={(e) => {
                console.log(record);
                setEditingId(null);
                deleteUserMemo(Number(record.userId), record.id);
              }}
            >
              삭제
            </S.DeleteButton>
          </span>
        ) : (
          <S.EditButton
            disabled={editingId !== null}
            onClick={(e) => {
              //편집을 눌렀을떄 해당 record를 가지고 셋을 다시 해
              editMemo(record.id);
              setEditData({
                ...editData,
                userId: Number(id),
                memoId: record.id,
                consultationRoute: record.consultationRoute,
                content: record.content,
                cxHashtagIds: record.userMemoCxHashtags.map((item) => item.cxHashtagId),
                transmissionType: record.transmissionType,
                etc: record.etc,
              });
            }}
          >
            편집
          </S.EditButton>
        );
      },
    },
  ];

  const [editingId, setEditingId] = useState<number | null>(null); //현재 수정중인 id

  const editMemo = (id: number) => {
    setEditingId(id); // 편집 모드로 변경//현재 편집중인 열의 id를 저장
    setEditData({
      //그 id에 해당하는 데이터를 저장
      ...editData,
      memoId: id,
    });
  };

  const saveMemo = (id: number) => {
    setEditingId(null); // 저장 후 편집 모드 종료
    console.log(editData);
    patchUserMemo(editData); //여기가 patch
    setEditData({
      memoId: undefined,
      userId: Number(id),
      consultationRoute: null,
      content: '',
      cxHashtagIds: [],
      transmissionType: null,
      etc: '',
    });
  };

  return (
    <>
      <h1>
        <span>유저 정보 메모</span>
        <S.AddButton style={{ marginLeft: '20px' }} onClick={() => postUserMemo(Number(id))}>
          새 메모
        </S.AddButton>
      </h1>

      <S.TableContainer>
        {userMemoList && (
          <Table
            dataSource={userMemoList.content.map((item) => ({
              ...item,
              tags: ['편집'],
            }))}
            columns={MEMOCOLUMNS}
            pagination={false}
            rowKey={(record: UserMemoDetail) => record.id}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  e.preventDefault();
                },
              };
            }}
          />
        )}
        {userMemoList && (
          <Pagination
            pageSize={15}
            current={page}
            total={userMemoList.totalElements}
            onChange={setPage}
            showSizeChanger={false}
            showQuickJumper
          />
        )}
      </S.TableContainer>
    </>
  );
}
