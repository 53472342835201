import useNumberSearchParams from 'hooks/useNumberSearchParams';
import { useParams } from 'react-router-dom';
import {
  useDeleteCollectionPackageMutation,
  useGetCollectionCourseDetailQuery,
  useGetCollectionQuery,
} from 'store/services/collection';
import * as S from '../styles';
import { useGetCourseQuery } from 'store/services/course';
import DetailTabs from 'components/DetailTabs';
import { Checkbox, Pagination, Table } from 'antd';
import useBooleanState from '../Classroom/ClassroomDetail/hooks/useBooleanState';
import useCheckboxSearch from 'hooks/useCheckboxSearch';

function CollectionCourseDetail() {
  const { collectionId, courseId } = useParams();
  const { data: courseData } = useGetCourseQuery(Number(courseId));
  const [page, setPage] = useNumberSearchParams('page');
  const { data } = useGetCollectionQuery(`${collectionId}`);
  const [deleteCollecitonPackage] = useDeleteCollectionPackageMutation();

  const { data: collectionCourseDetailList } = useGetCollectionCourseDetailQuery({
    collectionId: collectionId,
    courseId: courseId,
    page,
    type: 'ONLY',
  });
  //위에 녀석에 belong 값 있음.

  const TAB_LIST = ['적용 패키지'];
  const {
    //체크 박스 관련 hook
    isCheckedList: packageIsCheckedList,
    setIsCheckedList: packageSetIsCheckedList,
    isAllChecked: packageIsAllChecked,
    handleCheckboxChange: packageHandelCheckedboxChange,
    handleSelectAllChange: PackageHandelSelectAllChange,
  } = useCheckboxSearch(collectionCourseDetailList?.content);

  const {
    value: isPackageDeleting,
    setFalse: endPackageDeleting,
    setTrue: startPackageDeleting,
  } = useBooleanState(false);

  const { tabs, currentTab } = DetailTabs.useTabs(TAB_LIST);

  const PACKAGE_COLUMNS = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (value, record) =>
        isPackageDeleting ? (
          <Checkbox
            checked={packageIsCheckedList.includes(record.id)}
            onChange={(e) => {
              packageHandelCheckedboxChange(record.id);
            }}
          />
        ) : (
          value
        ),
    },
    {
      title: '패키지명',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '설명',
      dataIndex: 'description',
      key: 'description',
    },
  ];
  const handleDeleteClick = () => {
    if (window.confirm('삭제하기 전 한 번 더 확인해주세요!')) {
      deleteCollecitonPackage({
        collectionId: collectionId,
        idList: packageIsCheckedList,
      })
        .unwrap()
        .then(() => {
          alert('삭제가 완료되었습니다.');
          endPackageDeleting();
          packageSetIsCheckedList([]);
        })
        .catch((e: any) => {
          alert('컬렉션을 삭제할 수 없습니다');
          throw new Error(e);
        });
    }
  };

  return (
    <>
      <S.Container>
        {collectionCourseDetailList && data && courseData && (
          <>
            <S.DepthNavi>
              <S.CurrentPage>{data.name} </S.CurrentPage>
              <span> / </span>
              <S.CurrentPage>{courseData.title} </S.CurrentPage>
            </S.DepthNavi>
            <h1>{courseData.title}</h1>
            <DetailTabs tabs={tabs} selected={currentTab} />
            <h1>
              적용 패키지
              {isPackageDeleting ? (
                <>
                  <S.CancelDuplicatePackageButton
                    onClick={(event: any) => {
                      handleDeleteClick();
                    }}
                  >
                    삭제
                  </S.CancelDuplicatePackageButton>
                  <S.AddHashtagButton
                    onClick={() => {
                      endPackageDeleting();
                    }}
                  >
                    취소
                  </S.AddHashtagButton>
                </>
              ) : (
                <>
                  <S.CancelDuplicatePackageButton
                    onClick={(event: any) => {
                      event.stopPropagation();
                      console.log('delete');
                      startPackageDeleting();
                      packageSetIsCheckedList([]);
                    }}
                  >
                    삭제
                  </S.CancelDuplicatePackageButton>
                </>
              )}
            </h1>
            <S.TableContainer>
              <Table
                dataSource={collectionCourseDetailList.content}
                columns={PACKAGE_COLUMNS}
                pagination={false}
                rowKey={(record) => {
                  return record.id;
                }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (e) => {
                      //console.log('test', record.id);
                    },
                  };
                }}
              />
              <Pagination
                pageSize={15}
                current={page}
                total={collectionCourseDetailList.totalElements}
                onChange={(page) => {
                  setPage(page);
                }}
                showSizeChanger={false}
                showQuickJumper
              />
            </S.TableContainer>
          </>
        )}
      </S.Container>
    </>
  );
}

export default CollectionCourseDetail;
