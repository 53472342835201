import { ColumnsType } from 'antd/lib/table';
import moveDownArrow from 'assets/images/moveDownArrow.png';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const SubMoveDownArrow = styled.img`
  width: auto;
  height: 28px;
  padding: 4px 8px;
  transform: rotate(180deg);
`;
const SubMoveUpArrow = styled.img`
  width: auto;
  height: 28px;
  padding: 4px 8px;
`;

const useChangeOrd: <T extends Array<Object>>({
  data,
  baseColumn,
}: {
  data;
  baseColumn: T;
}) => any = ({ data, baseColumn }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    if (data) setList(data);
  }, [data]);

  const moveUp = (idx: number) => {
    if (idx === 0 || !list) return;
    const newOrderList = list.slice();
    const temp = newOrderList[idx];
    newOrderList[idx] = newOrderList[idx - 1];
    newOrderList[idx - 1] = temp;
    setList(newOrderList);
  };

  const moveDown = (idx: number) => {
    if (!list.length) return;
    if (idx === list.length - 1 || !list) return;
    const newOrderList = list.slice();
    const temp = newOrderList[idx];
    newOrderList[idx] = newOrderList[idx + 1];
    newOrderList[idx + 1] = temp;
    setList(newOrderList);
  };

  const columns: ColumnsType = baseColumn.concat({
    title: '순서 변경',
    dataIndex: 'changeOrder',
    key: 'changeOrder',
    width: '10%',
    render: (_, col, idx) => {
      return (
        <>
          <SubMoveUpArrow
            key={col.tags[0]}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              moveUp(idx);
            }}
            src={moveDownArrow}
            alt=""
          />
          <SubMoveDownArrow
            key={col.tags[1]}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              moveDown(idx);
            }}
            src={moveDownArrow}
            alt=""
          />
        </>
      );
    },
  });

  return { list, columns };
};

export default useChangeOrd;
