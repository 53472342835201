import CustomForm from 'components/CustomForm';
import { useParams } from 'react-router-dom';
import { useGetClassroomDetailQuery } from 'store/services/classroom';
import * as S from 'pages/Product/styles';
import useClassroomDetailMutation from '../hooks/useClassroomDetailMutation';

const CLASSROOM_TYPE = { NONE: 'NONE', FREE_ALONE: 'FREE_ALONE', WITH_FRIEND: 'WITH_FRIEND' };

function ClassroomInfo() {
  const { id } = useParams();
  const { data: classroomData } = useGetClassroomDetailQuery(Number(id));
  const [form] = CustomForm.useForm();
  const { patchClassroom } = useClassroomDetailMutation({ classroomId: Number(id) });

  return (
    <CustomForm form={form} initialValues={classroomData} onFinish={patchClassroom}>
      <CustomForm.Checkbox name="isPrivate" label="클래스룸 비공개" />
      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.InputNumber label="ID" name="id" disabled />
        <CustomForm.InputNumber label="생성일" name="createdAt" disabled />
        <CustomForm.InputNumber label="최종 수정일" name="updatedAt" disabled />
      </CustomForm.GridRow>
      <CustomForm.Input label="이름" name="title" rules={[{ required: true }]} />
      <CustomForm.Input
        label="수강 마감 기한"
        name="deadline"
        rules={[
          {
            pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
            message: 'YYYY-MM-DD 형식으로 작성해주세요!',
          },
          { required: true },
        ]}
      />
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input label="수강 요일 및 시간" name="time" />
        <CustomForm.Select label="수업 종류" name="type" options={CLASSROOM_TYPE} />
      </CustomForm.GridRow>
      <CustomForm.GridRow gridColumns="1fr 1fr">
        <CustomForm.Input
          label="수강 시작일"
          name="startDate"
          rules={[
            {
              pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
              message: 'YYYY-MM-DD 형식으로 작성해주세요!',
            },
            { required: true },
          ]}
        />
        <CustomForm.Input
          label="수강 종료일"
          name="endDate"
          rules={[
            {
              pattern: /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/,
              message: 'YYYY-MM-DD 형식으로 작성해주세요!',
            },
            { required: true },
          ]}
        />
      </CustomForm.GridRow>

      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.InputNumber label="현재 인원" name="classRoomEnrollmentCount" disabled />
        <CustomForm.InputNumber label="최대 인원" name="maxUserCount" />
        <CustomForm.InputNumber label="주차" name="week" />
      </CustomForm.GridRow>

      <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
        <CustomForm.Input label="기수 명" name="term" />
        <CustomForm.InputNumber label="미션 수" name="missionCount" />
        <CustomForm.InputNumber label="강의 수" name="lectureCount" />
      </CustomForm.GridRow>
      {classroomData?.type === 'WITH_FRIEND' && (
        <>
          <CustomForm.GridRow gridColumns="1fr 1fr 1fr">
            <CustomForm.Input label="라이브 수업 ID" name="liveId" />
            <CustomForm.Input label="라이브 수업 PW" name="livePassword" />
            <CustomForm.Input label="라이브 수업 URL" name="liveUrl" />
          </CustomForm.GridRow>
          <CustomForm.GridRow gridColumns="1fr 1fr">
            <CustomForm.Input label="라이브 수업 준비물" name="liveMaterials" />
            <CustomForm.Input label="선생님 편지 URL" name="mentorLetterUrl" />
          </CustomForm.GridRow>
        </>
      )}

      <CustomForm.Divider orientation="left">
        썸네일 (미리보기 영상이 있을 경우 w664:h373)
      </CustomForm.Divider>
      <CustomForm.Upload name="thumbnail" />
      <S.SaveWrap>
        <button type="submit">저장</button>
      </S.SaveWrap>
    </CustomForm>
  );
}

export default ClassroomInfo;
