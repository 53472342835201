import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useBooleanSearchParams = (key: string): [boolean, (value: boolean) => void] => {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(key) === 'true';
  const setValue = useCallback(
    (nextValue: boolean, replace?: boolean) => {
      const params = new URLSearchParams(searchParams);
      params.set(key, String(nextValue));
      if (replace) setSearchParams(params, { replace: true });
      else setSearchParams(params);
    },
    [key, searchParams, setSearchParams]
  );

  useEffect(() => {
    if (searchParams.get(key) === null) {
      setValue(true, true);
    }
  }, [key, searchParams, setValue]);

  return [value, setValue];
};

export default useBooleanSearchParams;
