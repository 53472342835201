import { useGetPackageQuery, useGetProductPackageDiscountQuery } from 'store/services/package';
import * as S from '../../styles';
import { useParams, useNavigate } from 'react-router-dom';
import CustomForm from 'components/CustomForm';
import { useGetCourseQuery } from 'store/services/course';
import usePackageDetailMutation from '../hook/usePackageDiscountMutation';
import { DATETIME_REGEXP_SEC } from 'static/regexp';

function PackageDiscountDetail() {
  const { courseId, packageId, id } = useParams();
  const { patchDiscount, deleteDiscount } = usePackageDetailMutation(Number(id));
  const navigate = useNavigate();
  const { data } = useGetProductPackageDiscountQuery(id);
  const [form] = CustomForm.useForm();
  
  const { data: packagedata } = useGetPackageQuery(Number(packageId));
  const { data: coursedata } = useGetCourseQuery(Number(packagedata?.courseId));
  
  return (
    <div>
      <S.DepthNavi>
        <S.NaviButton
          onClick={() => {
            navigate(`/course/${courseId}`);
          }}
        >
          {coursedata?.title}
        </S.NaviButton>
        <span> / </span>
        <S.NaviButton
          onClick={() => {
            navigate(`/course/${courseId}/package/${packageId}`);
          }}
        >
          {packagedata?.title}
        </S.NaviButton>
        <span> / </span>
        <S.CurrentPage>{data?.title}</S.CurrentPage>
      </S.DepthNavi>
      <h1 style={{ marginBottom: '35px' }}>
        패키지 할인 수정
        <S.DeleteButton onClick={deleteDiscount}>삭제</S.DeleteButton>
      </h1>

      {data && (
        <CustomForm
          form={form}
          initialValues={data}
          onFinish={() => patchDiscount(form.getFieldsValue(true))}
        >
          <CustomForm.InputNumber label="해당 패키지 ID" name="packageId" disabled />
          <CustomForm.InputNumber label="할인 ID" name="id" disabled />
          <CustomForm.Input label="할인 이름" name="title" />
          <CustomForm.InputNumber label="할인율 (%)" name="discountRate" />
          <CustomForm.InputNumber label="가격 (할인가)" name="discountPrice" />
          <CustomForm.InputNumber label="N개월 할부" name="discountInstallmentsMonth" />
          <CustomForm.InputNumber label="월 가격(N개월 할부 시)" name="discountInstallmentsPrice" />
          <CustomForm.Input
            label="할인 시작"
            name="startAt"
            rules={[
              {
                pattern: DATETIME_REGEXP_SEC,
                message: 'YYYY-MM-DD HH:MM:SS 형식으로 작성해주세요!',
              },
              { required: true },
            ]}
          />
          <CustomForm.Input
            label="할인 종료"
            name="endAt"
            rules={[
              {
                pattern: DATETIME_REGEXP_SEC,
                message: 'YYYY-MM-DD HH:MM:SS 형식으로 작성해주세요!',
              },
              { required: true },
            ]}
          />
          <CustomForm.Input label="생성일" name="createdAt" disabled />
          <CustomForm.Input label="최종 수정 시간" name="updatedAt" disabled />
          <S.SaveWrap>
            <button type="submit">저장</button>
          </S.SaveWrap>
        </CustomForm>
      )}
    </div>
  );
}
export default PackageDiscountDetail;
